import constants, { TEST_STATUS, LANDING_STATUS_TEXT } from "./../constants";

export const TEST_STATUS_COLOR = {
  [TEST_STATUS.PASSED]: "var(--test-status-passed)", //pass
  [TEST_STATUS.FAILED]: "var(--test-status-failed)", //fail
  [TEST_STATUS.WARNING]: "var(--test-status-warning)", //warning
  [TEST_STATUS.UNTESTED]: "var(--test-status-untested)", //untested
  [TEST_STATUS.NO_LIMIT]: "var(--test-status-no-limit)", // no_limit
};

export const TEST_STATUS_COLOR_RGB = {
  [TEST_STATUS.PASSED]: "rgb(	75, 177, 2)", //pass
  [TEST_STATUS.FAILED]: "rgb(223, 42, 2)", //fail
  [TEST_STATUS.WARNING]: "rgb(236, 178, 1)", //warning
  [TEST_STATUS.UNTESTED]: "rgb(172, 171, 171)", //untested
  [TEST_STATUS.NO_LIMIT]: "rgb(34, 46, 62)", // no_limit
};

export const TEST_STATUS_TEXT_COLOR = {
  [TEST_STATUS.PASSED]: "white",
  [TEST_STATUS.FAILED]: "white",
  [TEST_STATUS.WARNING]: "white",
  [TEST_STATUS.UNTESTED]: "black",
  [TEST_STATUS.NO_LIMIT]: "white",
};

export const LANDING_COLOR = {
  [LANDING_STATUS_TEXT[constants.LANDING_STATUS_ARCHIVED]]:
    "var(--landing-archived)",
  [LANDING_STATUS_TEXT[constants.LANDING_STATUS_PROCESS]]:
    "var(--landing-in-process)",
  [LANDING_STATUS_TEXT[constants.LANDING_STATUS_NOT_START]]:
    "var(--landing-not-start)",
};
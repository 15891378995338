import React, { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
} from "@amzn/awsui-components-react-v3/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  TableEmptyState,
  TableNoMatchState,
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../../overview/common";
import {
  TESTPLAN_HISTORY_TABLE_COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
  DEFAULT_SORTING_COLUMN,
  DEFAULT_SORTING_DESCENDING,
} from "./table-config";
import constants, {METRIC_INFO} from "../../../../constants";
import { config } from "../../../../config/config.js";
import {parseS3Link} from "../../../../utils/s3-utils";
import endpoint from "../../../../endpoints/endpoints";
import {DownloadPreSignedURLRequest} from "../../../../model/http-json";
import {postData, Response} from "../../../../utils/cognito-fetch";
import {getLogData} from "../../../../utils/general-utils";
import {getMetricInfo} from "../manual-upload-module/utils";
import {connect} from "react-redux";

const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

interface StateProps {
  downloadReducer: any;
}

type Props = {
  tabledata: any;
  loading: any;
  dispatch: any;
} & StateProps;

const HistoryTable = ({ tabledata, loading, dispatch, downloadReducer }: Props) => {

  const  onDownloadButtonClick = async (url: string) => {
    const s3_components = parseS3Link(url);
    const endpointURL = config.UPLOAD_BASE_URL + endpoint.downloadEndpoint()
    const data: DownloadPreSignedURLRequest = {
      file_name: s3_components.file_path,
      object_key: s3_components.object_key,
      bucket_name: s3_components.bucket_name,
      version_id: s3_components.version_id
    }
    const response: Response = await postData(
        endpointURL,
        data,
        getLogData(
            METRIC_INFO.DOWNLOAD_TEST_DATA,
            getMetricInfo(constants.INITIATIVE_EMI, METRIC_INFO)
        )
    );
    window.location.href = response.json.preSignedDownloadURL;
  }

  const [s3Url, setS3Url] = useState("");
  // default table preferences
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES(s3Url, onDownloadButtonClick));
  const currentTableData = tabledata.length > 0 ? tabledata : [];

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(currentTableData, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: TableEmptyState("Test Plan History Data"),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: DEFAULT_SORTING_COLUMN,
        isDescending: DEFAULT_SORTING_DESCENDING,
      },
    },
    selection: {},
  });
  return (
    <Table
      {...collectionProps}
      columnDefinitions={TESTPLAN_HISTORY_TABLE_COLUMN_DEFINITIONS(s3Url, onDownloadButtonClick)}
      visibleColumns={preferences.visibleContent}
      items={items}
      loadingText="Loading resources"
      loading={
        loading != constants.LOADING_SUCCESS &&
        loading != constants.LOADING_FAIL
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header counter={`(${tabledata.length})`}>Test Plan History</Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
    />
  );
};

const mapStateToProps = (state) => {
  return {
    downloadReducer: state.downloadReducer
  };
};

export default connect<StateProps>(mapStateToProps)(React.memo(HistoryTable));

import React from "react";

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "region",
    header: () => centerAlign("Region"),
    cell: (e) => centerAlign(e.region),
    label: (sortState) => {
      return sort(sortState, "region", "Region");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "band",
    header: () => centerAlign("Band"),
    cell: (e) => centerAlign(e.band),
    label: (sortState) => {
      return sort(sortState, "band", "Band");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "channel",
    header: () => centerAlign("Channel"),
    cell: (e) => centerAlign(e.channel),
    label: (sortState) => {
      return sort(sortState, "channel", "Channel");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "frequency",
    header: () => centerAlign("Frequency"),
    cell: (e) => centerAlign(e.frequency),
    label: (sortState) => {
      return sort(sortState, "frequency", "Frequency");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "data_rate",
    header: () => centerAlign("Data rate"),
    cell: (e) => centerAlign(e.data_rate),
    label: (sortState) => {
      return sort(sortState, "data_rate", "Data rate");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "antenna",
    header: () => centerAlign("Antenna"),
    cell: (e) => centerAlign(e.antenna),
    label: (sortState) => {
      return sort(sortState, "antenna", "Antenna");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "internal_margin",
    header: () => centerAlign("Internal Margin"),
    cell: (e) => centerAlign(e.internal_margin),
    label: (sortState) => {
      return sort(sortState, "internal_margin", "Internal Margin");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "target",
    header: () => centerAlign("Target Power"),
    cell: (e) => centerAlign(e.target),
    label: (sortState) => {
      return sort(sortState, "target", "Target Power");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "minimum_power",
    header: () => centerAlign("Min Power"),
    cell: (e) => centerAlign(e.minimum_power),
    label: (sortState) => {
      return sort(sortState, "minimum_power", "Min Power");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "antenna_gain",
    header: () => centerAlign("Antenna Gain"),
    cell: (e) => centerAlign(e.antenna_gain),
    label: (sortState) => {
      return sort(sortState, "antenna_gain", "Antenna Gain");
    },
    visible: true,
    minWidth: "100px",
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "region";

// define sortable columns
export const sortableColumns = [
  { id: "region", field: "Region" },
  { id: "band", field: "Band" },
  { id: "channel", field: "Channel" },
  { id: "frequency", field: "Frequency" },
  { id: "data_rate", field: "data_rate" },
  { id: "antenna", field: "Antenna" },
  { id: "internal_margin", field: "Internal Margin" },
  { id: "target", field: "Target Power" },
  { id: "minimum_power", field: "Min Power" },
  { id: "antenna_gain", field: "Antenna Gain" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "region", label: "Region", visible: true },
      { id: "band", label: "Band", visible: true },
      { id: "channel", label: "Channel", visible: true },
      { id: "frequency", label: "Frequency", visible: true },
      { id: "data_rate", label: "Data rate", visible: true },
      { id: "antenna", label: "Antenna", visible: true },
      { id: "internal_margin", label: "Internal Margin", visible: true },
      { id: "target", label: "Target Power", visible: true },
      {
        id: "minimum_power",
        label: "Min Power",
        visible: true,
      },
      { id: "antenna_gain", label: "Antenna Gain", visible: true },
    ],
  },
];

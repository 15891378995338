import React from "react";
import { Modal, SpaceBetween } from "@amzn/awsui-components-react-v3/polaris";
import ManualUploadPanelExcel from "./manual-upload-module/upload-panel";
import constants from "../../../constants";

// declare prop check
type Props = {
  visible: boolean;
  setVisible: any;
  setExcelTestCases: (testcases: any) => {};
};

function ImportExcel({ visible, setVisible, setExcelTestCases }: Props) {
  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      header="Import Test Cases from an Excel File"
    >
      <SpaceBetween direction="vertical" size="l">
        <h2>{constants.EXCEL_INSTRUCTIONS}</h2>
        <ul>
          <li>{constants.EXCEL_EXTENSION_ERROR}</li>
          <li>{constants.IN_VALID_DATA_ERROR}</li>
        </ul>
        <br />
      </SpaceBetween>
      <ManualUploadPanelExcel
        initiative={constants.INITIATIVE_EMI}
        setExcelTestCases={setExcelTestCases}
      />
    </Modal>
  );
}

export default ImportExcel;

import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { Button } from "@amzn/awsui-components-react/polaris";
import { FileStatus } from "../../../model/upload";
import { getIcon, deleteButton } from "./file-list";
import "./file-dropzone.css";

// declare prop check
type Props = {
  fileList: Array<File>;
  fileCheckDict: { [key: string]: FileStatus };
  onChange: (files: Array<File>) => void;
  deleteFile: (filename: string) => void;
} & typeof defaultProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  files: [],
});

class UploadFileDropzone extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  onDrop(files) {
    // alert(JSON.stringify(files.map(f => f.name)));
    this.setState({ files });
    this.props.onChange(files);
  }

  onCancel() {
    this.setState({
      files: [],
    });
  }

  render() {
    const { fileList, fileCheckDict, deleteFile } = this.props;
    let fileListView: null | JSX.Element[] = null;

    // if there is any file uploaded, then show file list below the dropzone
    if (fileList.length > 0) {
      fileListView = fileList.map((file: File, index: number) => (
        <li key={index} style={{ fontSize: "14px" }}>
          {file.name} - <i style={{ color: "grey" }}>{file.size} bytes</i>
          &nbsp; &nbsp;
          {deleteButton(deleteFile, file.name)}
          <span style={{ marginLeft: "20px" }}>
            {getIcon(
              fileCheckDict[file.name].status, // status
              fileCheckDict[file.name].message // will show error msg when failed
            )}
          </span>
        </li>
      ));
    }

    return (
      <section>
        <Dropzone
          onDrop={this.onDrop.bind(this)}
          noClick={true}
          onFileDialogCancel={this.onCancel.bind(this)}
        >
          {({ getRootProps, getInputProps, open }) => {
            return (
              <div className="compliance-upload-dropzone-container">
                <div
                  {...getRootProps({
                    className: "compliance-upload-dropzone",
                  })}
                >
                  <input {...getInputProps()} />
                  <i
                    className="fas fa-cloud-upload-alt"
                    style={{ fontSize: "30px" }}
                  ></i>
                  <p style={{ fontSize: "18px" }}>Drag and drop files here</p>
                  <br />
                  <Button onClick={open} icon="upload" iconAlign="left">
                    Click to select File
                  </Button>
                </div>
              </div>
            );
          }}
        </Dropzone>
        <aside>
          {fileList.length > 0 && (
            <React.Fragment>
              <span style={{ fontWeight: "bold", fontSize: "14px" }}>
                Files:
              </span>
              <ul>{fileListView}</ul>
            </React.Fragment>
          )}
        </aside>
      </section>
    );
  }
}

export default UploadFileDropzone;

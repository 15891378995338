import testplanType from "../types/testplan-type";
import constants from "../../constants";

interface state {
  countries: any;
  techSpec: any;
  loadingConfigStatus: Number;
  createStatus: Number;
  createDraftStatus: Number;
  message: string;
  responseData: any;
  loadingLatestTestplanStatus: Number;
  loadingTestplanDraftStatus: Number;
  loadingImportTestplanStatus: Number;
  loadingSendMailTestPlanStatus: Number;
  sendMailTestPlanMessage: string;
  latestTestplanData: any;
  testOverviewDetails: any;
}

const initialState: state = {
  countries: {},
  techSpec: {},
  loadingConfigStatus: constants.LOADING_DEFAULT,
  createStatus: constants.LOADING_DEFAULT,
  createDraftStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
  loadingLatestTestplanStatus: constants.LOADING_DEFAULT,
  loadingTestplanDraftStatus: constants.LOADING_DEFAULT,
  loadingImportTestplanStatus: constants.LOADING_DEFAULT,
  loadingSendMailTestPlanStatus: constants.LOADING_DEFAULT,
  sendMailTestPlanMessage: "",
  latestTestplanData: {},
  testOverviewDetails: {},
};

const testplanReducer = (state = initialState, action) => {
  switch (action.type) {
    case testplanType.GET_CONFIG:
      return {
        ...state,
        loadingConfigStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_CONFIG_COMMIT:
      return {
        ...state,
        loadingConfigStatus: constants.LOADING_SUCCESS,
        countries:
          action.payload.data.countries &&
          Array.isArray(action.payload.data.countries)
            ? formatCountries(action.payload.data.countries)
            : {},
        techSpec:
          action.payload.data.tech_specs &&
          Array.isArray(action.payload.data.tech_specs)
            ? formatTechSpec(action.payload.data.tech_specs)
            : {},
      };
    case testplanType.GET_CONFIG_ROLLBACK:
      return {
        ...state,
        loadingConfigStatus: constants.LOADING_FAIL,
      };
    case testplanType.GET_LATEST_TESTPLAN:
      return {
        ...state,
        loadingLatestTestplanStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_LATEST_TESTPLAN_COMMIT:
      return {
        ...state,
        loadingLatestTestplanStatus: constants.LOADING_SUCCESS,
        latestTestplanData: action.payload.data,
      };
    case testplanType.GET_LATEST_TESTPLAN_ROLLBACK:
      return {
        ...state,
        loadingLatestTestplanStatus: constants.LOADING_FAIL,
        latestTestplanData: action.payload.data,
      };
    case testplanType.GET_TESTPLAN_DRAFT:
      return {
        ...state,
        loadingTestplanDraftStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_TESTPLAN_DRAFT_COMMIT:
      return {
        ...state,
        loadingTestplanDraftStatus: constants.LOADING_SUCCESS,
        latestTestplanData: action.payload.data,
      };
    case testplanType.GET_TESTPLAN_DRAFT_ROLLBACK:
      return {
        ...state,
        loadingTestplanDraftStatus: constants.LOADING_FAIL,
        latestTestplanData: action.payload.data,
      };
    case testplanType.IMPORT_TESTPLAN:
      return {
        ...state,
        loadingImportTestplanStatus: constants.LOADING_LOAD,
      };
    case testplanType.IMPORT_TESTPLAN_COMMIT:
      return {
        ...state,
        loadingImportTestplanStatus: constants.LOADING_SUCCESS,
        latestTestplanData: formatImportData(
          action.payload.data,
          state.latestTestplanData
        ),
      };
    case testplanType.IMPORT_TESTPLAN_ROLLBACK:
      return {
        ...state,
        loadingImportTestplanStatus: constants.LOADING_FAIL,
        latestTestplanData: action.payload.data,
      };
    case testplanType.CREATE_TESTPLAN:
      return {
        ...state,
        createStatus: constants.LOADING_LOAD,
      };
    case testplanType.CREATE_TESTPLAN_COMMIT:
      return {
        ...state,
        createStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case testplanType.CREATE_TESTPLAN_ROLLBACK:
      return {
        ...state,
        createStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case testplanType.CREATE_TESTPLAN_DRAFT:
      return {
        ...state,
        createDraftStatus: constants.LOADING_LOAD,
      };
    case testplanType.CREATE_TESTPLAN_DRAFT_COMMIT:
      return {
        ...state,
        createDraftStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case testplanType.CREATE_TESTPLAN_DRAFT_ROLLBACK:
      return {
        ...state,
        createDraftStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case testplanType.RESET_CREATE_TESTPLAN_ON_SUCCESS:
      return {
        ...state,
        createStatus: constants.LOADING_DEFAULT,
        createDraftStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
        loadingLatestTestplanStatus: constants.LOADING_DEFAULT,
        loadingTestplanDraftStatus: constants.LOADING_DEFAULT,
        loadingImportTestplanStatus: constants.LOADING_DEFAULT,
        latestTestplanData: {},
      };
    case testplanType.RESET_CREATE_TESTPLAN_ON_FAILURE:
      return {
        ...state,
        createStatus: constants.LOADING_DEFAULT,
        createDraftStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {},
      };
    case testplanType.SEND_MAIL_TESTPLAN:
      return {
        ...state,
        loadingSendMailTestPlanStatus: constants.LOADING_LOAD,
      };
    case testplanType.SEND_MAIL_TESTPLAN_COMMIT:
      return {
        ...state,
        loadingSendMailTestPlanStatus: constants.LOADING_SUCCESS,
        sendMailTestPlanMessage: action.payload.message,
      };
    case testplanType.SEND_MAIL_TESTPLAN_ROLLBACK:
      return {
        ...state,
        loadingSendMailTestPlanStatus: constants.LOADING_FAIL,
        sendMailTestPlanMessage: action.payload.message,
      };
    case testplanType.RESET_SEND_MAIL_TESTPLAN:
      return {
        ...state,
        loadingSendMailTestPlanStatus: constants.LOADING_DEFAULT,
      };
    case testplanType.SET_TEST_PLAN_DATA:
      return {
        ...state,
        testOverviewDetails: action.payload.data,
      };
    case testplanType.CLEAR_TEST_PLAN_DATA:
      return {
        ...state,
        testOverviewDetails: {},
      };
    default:
      return state;
  }
};

const formatCountries = (countries: Array<any>): any => {
  const countriesFilter: {} = {};
  countries.forEach((country) => {
    const item = {
      name: country.name,
      is_supported: country.is_supported,
    };
    countriesFilter[country.id] = item;
  });
  return countriesFilter;
};

const formatTechSpec = (techSpec: Array<any>): any => {
  const techSpecFilter: {} = {};
  techSpec.forEach((tech) => {
    const item = {
      name: tech.name,
      children: {},
    };
    techSpecFilter[tech.id] = item;
    tech.children.forEach((spec) => {
      techSpecFilter[tech.id].children[spec.id] = {
        name: spec.name,
      };
    });
  });
  return techSpecFilter;
};

const formatImportData = (importData: any, curTestplanData: any): any => {
  let latestTestplanData = {...curTestplanData};
  latestTestplanData.countries = importData.countries;
  latestTestplanData.tech_specs = importData.tech_specs
  return latestTestplanData;
};

export default testplanReducer;

import base_config from "./base_config";

const prod_config = {
  ...base_config,
  STAGE: "prod",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_7jaT8GnzE",
    aws_user_pools_web_client_id: "7h5ec3if4vohn56bdh9ol04p8v",
    oauth: {
      domain: "wise-general-cog-prod.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },
  // Base url (update this with correct URL)
  BASE_URL: "https://7g3805yjs8.execute-api.us-west-2.amazonaws.com/prod/v1",
  BASE_AUTHZ_URL: "https://7g3805yjs8.execute-api.us-west-2.amazonaws.com/prod",

  //Jira Url
  JIRA_URL: "https://4rippjtw4m.execute-api.us-west-2.amazonaws.com/prod",

  // Upload service base url
  UPLOAD_BASE_URL:
    "https://oyyu1bze98.execute-api.us-west-2.amazonaws.com/prod",
  WEBSITE_BASE_URL: "https://lens-compliance.lab126.amazon.dev/",
  ANALYTICS_DASHBOARD:
    "https://search-wise-es-fqemetrics-cgj4xsdhpq2rgvcntsyxnxoyue.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/f852a060-fe77-11eb-948a-b92a2c807097?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-7d%2Cmode%3Aquick%2Cto%3Anow))",
  // Configuration of Cloudwatch RUM
  APPLICATION_ID: "ff64adbd-5d40-4852-9247-2419581bfea8",
  APPLICATION_VERSION: "1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn:
      "arn:aws:iam::599891808472:role/RUM-Monitor-us-west-2-599891808472-9651394158071-Unauth",
    identityPoolId: "us-west-2:7757914d-8afe-446d-a46e-8d7982b1c244",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: [
      "performance",
      "errors",
      ["http", { recordAllRequests: true }],
    ],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true,
  },
};

export default prod_config;

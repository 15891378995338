import React, { Component } from "react";
import { Dispatch } from "redux";
import "./table.css";
import { getTestcaseRawData } from "../../../redux/actions/visualization-action";

import {
  Popover,
  StatusIndicator,
} from "@amzn/awsui-components-react-v3/polaris";

// declare prop check
type Props = {
  testCaseId: string;
  powerSetting: string;
  data_type: string;
  value: string;
  calculatedValue: string;
  category: string;
  project: string;
  build: string;
  build_config: string;
  dispatch: Dispatch<any>;
  resetSelectedPoints: any;
} & typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class GraphCell extends Component<Props> {
  static defaultProps = defaultProps;

  componentDidMount() {}

  showGraph = () => {
    const {
      testCaseId,
      powerSetting,
      category,
      project,
      build,
      build_config,
      data_type,
    } = this.props;
    // url parameters cannot contain "/"
    const formatedPowerSetting = powerSetting.includes("/")
      ? powerSetting.replace("/", ":")
      : powerSetting;
    this.props.dispatch(
      getTestcaseRawData(
        category,
        project,
        build,
        Number(build_config),
        Number(testCaseId),
        formatedPowerSetting,
        data_type
      )
    );
    this.props.resetSelectedPoints();
  };

  render() {
    const { testCaseId, powerSetting, data_type, value, calculatedValue } =
      this.props;

    return (
      <>
        {value && !calculatedValue && (
          <div className='cell-style'>
            <p>__</p>
          </div>
        )}
        {!value && !calculatedValue && (
          <div className='cell-style'>
            <p>__</p>
          </div>
        )}
        <div className='cell-style'>
          {!value && calculatedValue && (
            <Popover
              dismissButton={false}
              position='bottom'
              size='medium'
              triggerType='custom'
              content={
                <StatusIndicator type='info'>
                  Only calculated value is found.
                </StatusIndicator>
              }>
              <p
                style={{ color: "#0077CC" }}
                onClick={this.showGraph}
                className='p-style'>
                {calculatedValue}
              </p>
            </Popover>
          )}
          {value && calculatedValue && value !== calculatedValue && (
            <Popover
              dismissButton={false}
              position='bottom'
              size='large'
              triggerType='custom'
              content={
                <StatusIndicator type='error'>
                  Lab value and calculated value do not match.
                </StatusIndicator>
              }>
              <p
                style={{ color: "red" }}
                onClick={this.showGraph}
                className='p-style'>
                {calculatedValue}
              </p>
            </Popover>
          )}
          {value && calculatedValue && value === calculatedValue && (
            <Popover
              dismissButton={false}
              position='bottom'
              size='large'
              triggerType='custom'
              content={
                <StatusIndicator type='success'>
                  Lab value and calculated value match.
                </StatusIndicator>
              }>
              <p
                style={{ color: "green" }}
                onClick={this.showGraph}
                className='p-style'>
                {calculatedValue}
              </p>
            </Popover>
          )}
        </div>
      </>
    );
  }
}

export default GraphCell;

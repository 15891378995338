import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import BuildForm from "./build-form";
import "../create-form.css";

interface StateProps {
  metadataReducer:any
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class CreateBuild extends Component<Props> {
  render() {
    return (
      <div className="create-form">
        <BuildForm history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(CreateBuild));

const overviewType = {
  GET_TCS_ALL: "GET_TCS_ALL",
  GET_TCS_ALL_COMMIT: "GET_TCS_ALL_COMMIT",
  GET_TCS_ALL_ROLLBACK: "GET_TCS_ALL_ROLLBACK",

  UPDATE_BUILD_STATUS: "UPDATE_BUILD_STATUS",
  UPDATE_BUILD_STATUS_COMMIT: "UPDATE_BUILD_STATUS_COMMIT",
  UPDATE_BUILD_STATUS_ROLLBACK: "UPDATE_BUILD_STATUS_ROLLBACK",
  RESET_UPDATE_BUILD_STATUS: "RESET_UPDATE_BUILD_STATUS",

  RESET_OVERVIEW: "RESET_OVERVIEW",
};

export default overviewType;

import React from "react";
import { NavLink } from "react-router-dom";
import DashboardProgressCell from "./progress-cell";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import { LANDING_COLOR } from "../../../commons/color-config";
import { TEST_CATEGORY } from "../../../constants";
import { StatusSchema } from "../../../model/overview";

export const defaultPageSize = 20;

// define columns
export const columnDefinitions = [
  {
    id: "category",
    header: "Category",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "category";
      const ascending = !sortState.sortingDescending;
      return `Category, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.category),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "project",
    header: "Project",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "project";
      const ascending = !sortState.sortingDescending;
      return `Project, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.project),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "build",
    header: "Build",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "build";
      const ascending = !sortState.sortingDescending;
      return `Build, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.build),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "version",
    header: "Version",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "version";
      const ascending = !sortState.sortingDescending;
      return `Version, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => getNavLink(item, item.version),
    minWidth: "75px",
    visible: true,
  },
  {
    id: "test_category",
    header: "Test Category",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "test_category";
      const ascending = !sortState.sortingDescending;
      return `Test Category, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.test_category,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "street_date",
    header: "Street Date",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "street_date";
      const ascending = !sortState.sortingDescending;
      return `Street Date, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.street_date,
    minWidth: "100px",
    visible: false,
  },
  {
    id: "start_time",
    header: "Start Date",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "start_time";
      const ascending = !sortState.sortingDescending;
      return `Start Date, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.start_time,
    minWidth: "100px",
    visible: false,
  },
  {
    id: "end_time",
    header: "End Date",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "end_time";
      const ascending = !sortState.sortingDescending;
      return `End Date, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.end_time,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <div
        style={{
          color: `${
            item.status in LANDING_COLOR ? LANDING_COLOR[item.status] : `black`
          }`,
        }}
      >
        {item.status}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "progress",
    header: "Test Progress",
    cell: (item) => {
      let progress: StatusSchema = {
        passed: item.passed,
        failed: item.failed,
        untested: item.untested,
        total: item.total,
        warning: 0,
        no_limit: 0,
      };
      return <DashboardProgressCell progressData={progress} />;
    },
    minWidth: "200px",
    visible: true,
  },
];

// define sortable columns
export const sortableColumns = [
  { id: "category", field: "category" },
  { id: "project", field: "project" },
  { id: "build", field: "build" },
  { id: "version", field: "version" },
  { id: "street_date", field: "street_date" },
  { id: "start_time", field: "start_time" },
  { id: "end_time", field: "end_time" },
  { id: "test_category", field: "test_category" },
  { id: "status", field: "status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "category", label: "Category", visible: true, editable: false },
      { id: "project", label: "Project", visible: true, editable: false },
      { id: "build", label: "Build", visible: true, editable: false },
      { id: "version", label: "Version", visible: true, editable: false },
      {
        id: "test_category",
        label: "Test Category",
        visible: true,
      },
      {
        id: "street_date",
        label: "Street Date",
        visible: true,
      },
      {
        id: "start_time",
        label: "Start Date",
        visible: false,
      },
      {
        id: "end_time",
        label: "End Date",
        visible: false,
      },
      { id: "status", label: "Status", visible: true },
      { id: "progress", label: "Progress", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Category",
    propertyKey: "category",
    propertyLabel: "Category",
    values: [],
  },
  {
    groupValuesLabel: "Project",
    propertyKey: "project",
    propertyLabel: "Project",
    values: [],
  },
  {
    groupValuesLabel: "Build",
    propertyKey: "build",
    propertyLabel: "Build",
    values: [],
  },
  {
    groupValuesLabel: "Version",
    propertyKey: "version",
    propertyLabel: "Version",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
  {
    groupValuesLabel: "Street Date",
    propertyKey: "street_date",
    propertyLabel: "Street Date",
    values: [],
  },
  {
    groupValuesLabel: "Start Date",
    propertyKey: "start_time",
    propertyLabel: "Start Date",
    values: [],
  },
  {
    groupValuesLabel: "End Date",
    propertyKey: "end_time",
    propertyLabel: "End Date",
    values: [],
  },
  {
    groupValuesLabel: "Test Category",
    propertyKey: "test_category",
    propertyLabel: "Test Category",
    values: [],
  },
];

export const features = [
  "propertyFiltering",
  "pagination",
  // "selection",
  "sorting",
];

const getNavLink = (item, text) => {
  // desense doesn't have an overview page
  if (
    item.test_category === TEST_CATEGORY.RF ||
    item.test_category === TEST_CATEGORY.EMI
  ) {
    return (
      <NavLink
        to={`/categories/${item.category_id}/projects/${
          item.project_id
        }/builds/${item.build_id}/overview/${item.test_category.toLowerCase()}`}
      >
        {text}
      </NavLink>
    );
  }

  return <NavLink to={`/`}>{text}</NavLink>;
};

import React from "react";

export const defaultPageSize = 10;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "power_setting",
    header: "Final Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "power_setting";
      const ascending = !sortState.sortingDescending;
      return `Final Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna_gain",
    header: "Antenna Gain",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna_gain";
      const ascending = !sortState.sortingDescending;
      return `Antenna Gain, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.antenna_gain),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "avg_power",
    header: "Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "avg_power";
      const ascending = !sortState.sortingDescending;
      return `Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tuneup_avg_power",
    header: "Tuneup Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "tuneup_avg_power";
      const ascending = !sortState.sortingDescending;
      return `Tuneup Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.tuneup_avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_avg_power",
    header: "EIRP Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_avg_power";
      const ascending = !sortState.sortingDescending;
      return `EIRP Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tuneup_eirp",
    header: "Tuneup EIRP",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "tuneup_eirp";
      const ascending = !sortState.sortingDescending;
      return `Tuneup EIRP, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.tuneup_eirp),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_limit",
    header: "EIRP Limit",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_limit";
      const ascending = !sortState.sortingDescending;
      return `EIRP Limit, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_limit),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_psd_limit",
    header: "EIRP PSD Limit",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_psd_limit";
      const ascending = !sortState.sortingDescending;
      return `EIRP PSD Limit, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_psd_limit),
    minWidth: "100px",
    visible: true,
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "band";

// define sortable columns
export const sortableColumns = [
  { id: "power_setting", field: "power_setting" },
  { id: "antenna_gain", field: "antenna_gain" },
  { id: "avg_power", field: "avg_power" },
  { id: "tuneup_avg_power", field: "tuneup_avg_power" },
  { id: "eirp_avg_power", field: "eirp_avg_power" },
  { id: "tuneup_eirp", field: "tuneup_eirp" },
  { id: "eirp_limit", field: "eirp_limit" },
  { id: "eirp_psd_limit", field: "eirp_psd_limit" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "power_setting", label: "Final Power Setting", visible: true },
      { id: "antenna_gain", label: "Antenna Gain", visible: true },
      { id: "avg_power", label: "Avg Power", visible: true },
      { id: "tuneup_avg_power", label: "Tuneup Avg Power", visible: true },
      { id: "eirp_avg_power", label: "EIRP Avg Power", visible: true },
      { id: "tuneup_eirp", label: "Tuneup EIRP", visible: true },
      { id: "eirp_limit", label: "EIRP Limit", visible: true },
      { id: "eirp_psd_limit", label: "EIRP PSD Limit", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Final Power Setting",
    propertyKey: "power_setting",
    propertyLabel: "Final Power Setting",
    values: [],
  },
  {
    groupValuesLabel: "Antenna Gain",
    propertyKey: "antenna_gain",
    propertyLabel: "Antenna Gain",
    values: [],
  },
  {
    groupValuesLabel: "Avg Power",
    propertyKey: "avg_power",
    propertyLabel: "Avg Power",
    values: [],
  },
];

export const features = ["propertyFiltering", "pagination", "sorting"];

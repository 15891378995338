import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";
import uploadType from "../types/upload-type";
import { UpdateLog } from "../../model/upload";

export const getUpload = (
  start_date: string,
  end_date: string,
  syncMode: boolean, // flag to set loading status back to 0 or not
  initiative: string
) => ({
  type: uploadType.GET_UPLOAD,
  syncMode,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + endpoints.getUploadEndpoint(start_date, end_date),
        method: "GET",
      },
      commit: {
        type: uploadType.GET_UPLOAD_COMMIT,
        log: {
          initiative: initiative,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: uploadType.GET_UPLOAD_ROLLBACK,
        log: {
          initiative: initiative,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

export const createUpload = (
  values: Array<UpdateLog>,
  initiative: string
): any => ({
  type: uploadType.CREATE_UPLOAD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.uploadEndpoint(),
        method: "POST",
        json: values,
      },
      commit: {
        type: uploadType.CREATE_UPLOAD_COMMIT,
        log: {
          initiative: initiative,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: uploadType.CREATE_UPLOAD_ROLLBACK,
        log: {
          initiative: initiative,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

export const triggerAutoUploadJob = (): any => ({
  type: uploadType.TRIGGER_AUTO_UPLOAD,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.autoUploadEndpoint(),
        method: "POST",
      },
      commit: {
        type: uploadType.TRIGGER_AUTO_UPLOAD_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: uploadType.TRIGGER_AUTO_UPLOAD_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

import landingType from "../types/landing-type";
import constants from "../../constants";
import { LandingResponse } from "../../model/http-json";
// import { changeDateFormat } from "../../utils/date-utils";
import { LANDING_STATUS_TEXT } from "../../constants";

interface state {
  landingData: LandingResponse,
  loadingStatus: number,
}

const initialState: state = {
  landingData:[],
  loadingStatus: constants.LOADING_LOAD,
};

const landingReducer = (state = initialState, action:any ) => {
  switch (action.type) {
    case landingType.LANDING_SERVICE_CALL:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case landingType.LANDING_SERVICE_CALL_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        landingData:
          action.payload.data && Array.isArray(action.payload.data)
            ? action.payload.data.reverse()
            : [],
      };
    case landingType.LANDING_SERVICE_CALL_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default landingReducer;

import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";


type Props = RouteComponentProps<any>;

class CompareTestcase extends Component<Props> {
  render() {
    return (
      <p> Compare Testcases </p>
    );
  }
}

export default withRouter(CompareTestcase);
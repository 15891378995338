import { redirectToLoginPage, getAccessToken } from "../cognito-auth/session";
import store from "../redux/store";
import { logMetric } from "../redux/actions/metric-action";
import { METRICS_STATUS } from "../constants";

export interface Response {
  ok: boolean;
  status_code: number;
  status_text: string;
  json?: any;
}

export async function postData(url, data, logData) {
  const token = await getAccessToken();
  if (token === "") {
    redirectToLoginPage();
  }

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  let jsonResponse: Response = {
    ok: response.ok,
    status_code: response.status,
    status_text: response.statusText,
  };

  jsonResponse.json = await response.json();

  const status: string = jsonResponse.ok
    ? METRICS_STATUS.PASS
    : METRICS_STATUS.FAIL;
  // store.dispatch(logMetric(logData, status));

  return jsonResponse;
}

export async function getData(url, logData) {
  const token = await getAccessToken();
  if (token === "") {
    redirectToLoginPage();
  }

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  });

  let jsonResponse: Response = {
    ok: response.ok,
    status_code: response.status,
    status_text: response.statusText,
  };

  jsonResponse.json = await response.json();

  const status: string = jsonResponse.ok
    ? METRICS_STATUS.PASS
    : METRICS_STATUS.FAIL;
  // store.dispatch(logMetric(logData, status));

  return jsonResponse;
}

export async function putData(url, data, logData) {
  const token = await getAccessToken();
  if (token === "") {
    redirectToLoginPage();
  }

  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });

  let jsonResponse: Response = {
    ok: response.ok,
    status_code: response.status,
    status_text: response.statusText,
  };

  jsonResponse.json = await response.json();

  const status: string = jsonResponse.ok
    ? METRICS_STATUS.PASS
    : METRICS_STATUS.FAIL;
  // store.dispatch(logMetric(logData, status));

  return jsonResponse;
}
import React from "react";
import GraphCell  from "./graph-cell";
import { Dispatch } from "redux";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = (
  front_end_fields,
  category,
  project,
  build,
  build_config,
  dispatch,
  resetSelectedPoints
) => {
  let columns: Array<any> = [];

  if (front_end_fields.includes("test_case_id")) {
    const col = {
      id: "test_case_id",
      header: "Testcase ID",
      label: (sortState) => {
        const isColumnSorted = sortState.sortingColumn === "test_case_id";
        const ascending = !sortState.sortingDescending;
        return `Testcase ID, ${
          isColumnSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.test_case_id),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("power_setting")) {
    const col = {
      id: "power_setting",
      header: "Power Setting",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "power_setting";
        const ascending = !sortState.sortingDescending;
        return `Power Setting, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.power_setting),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("conducted_avg_power")) {
    const col = {
      id: "conducted_avg_power",
      header: "Conducted Avg Power",
      label: (sortState) => {
        const columnIsSorted =
          sortState.sortingColumn === "conducted_avg_power";
        const ascending = !sortState.sortingDescending;
        return `Conducted Avg Power, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.conducted_avg_power),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("actual_radiated_fundamental_signal")) {
    const col = {
      id: "actual_radiated_fundamental_signal",
      header: "Actual Radiated Fundamental Signal",
      label: (sortState) => {
        const columnIsSorted =
          sortState.sortingColumn === "actual_radiated_fundamental_signal";
        const ascending = !sortState.sortingDescending;
        return `Actual Radiated Fundamental Signal, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"FUNDAMENTAL"}
            value={item.actual_radiated_fundamental_signal}
            calculatedValue={item.actual_radiated_fundamental_signal_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("bandedge_peak")) {
    const col = {
      id: "bandedge_peak",
      header: "Bandedge Peak",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "bandedge_peak";
        const ascending = !sortState.sortingDescending;
        return `Bandedge Peak, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"BE_PEAK"}
            value={item.bandedge_peak}
            calculatedValue={item.bandedge_peak_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("bandedge_avg")) {
    const col = {
      id: "bandedge_avg",
      header: "Bandedge avg",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "bandedge_avg";
        const ascending = !sortState.sortingDescending;
        return `Bandedge avg, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"BE_AVG"}
            value={item.bandedge_avg}
            calculatedValue={item.bandedge_avg_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("harmonic_peak")) {
    const col = {
      id: "harmonic_peak",
      header: "Harmonic Peak",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "harmonic_peak";
        const ascending = !sortState.sortingDescending;
        return `Harmonic Peak, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"HARMONIC_PEAK"}
            value={item.harmonic_peak}
            calculatedValue={item.harmonic_peak_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("harmonic_avg")) {
    const col = {
      id: "harmonic_avg",
      header: "Harmonic Avg",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "harmonic_avg";
        const ascending = !sortState.sortingDescending;
        return `Harmonic Avg, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"HARMONIC_AVG"}
            value={item.harmonic_avg}
            calculatedValue={item.harmonic_avg_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("harmonic_rx")) {
    const col = {
      id: "harmonic_rx",
      header: "Harmonic Rx",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "harmonic_rx";
        const ascending = !sortState.sortingDescending;
        return `Harmonic Rx, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"HARMONIC_RX"}
            value={item.harmonic_rx}
            calculatedValue={item.harmonic_rx_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("harmonic_tx")) {
    const col = {
      id: "harmonic_tx",
      header: "Harmonic Tx",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "harmonic_tx";
        const ascending = !sortState.sortingDescending;
        return `Harmonic Tx, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => {
        return (
          <GraphCell
            testCaseId={item.test_case_id}
            powerSetting={item.power_setting}
            data_type={"HARMONIC_TX"}
            value={item.harmonic_tx}
            calculatedValue={item.harmonic_tx_calculated}
            category={category}
            project={project}
            build={build}
            build_config={build_config}
            dispatch={dispatch}
            resetSelectedPoints={resetSelectedPoints}
          />
        );
      },
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("psd")) {
    const col = {
      id: "psd",
      header: "PSD",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "psd";
        const ascending = !sortState.sortingDescending;
        return `PSD, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.psd),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("psd_limit")) {
    const col = {
      id: "psd_limit",
      header: "PSD Limit",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "psd_limit";
        const ascending = !sortState.sortingDescending;
        return `PSD Limit, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.psd_limit),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("eirp_avg_power")) {
    const col = {
      id: "eirp_avg_power",
      header: "EIRP Avg Power",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "eirp_avg_power";
        const ascending = !sortState.sortingDescending;
        return `EIRP Avg Power, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.eirp_avg_power),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("eirp_avg_power_limit")) {
    const col = {
      id: "eirp_avg_power_limit",
      header: "EIRP Avg Power Limit",
      label: (sortState) => {
        const columnIsSorted =
          sortState.sortingColumn === "eirp_avg_power_limit";
        const ascending = !sortState.sortingDescending;
        return `EIRP Avg Power Limit, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.eirp_avg_power_limit),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("eirp_psd")) {
    const col = {
      id: "eirp_psd",
      header: "EIRP PSD",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "eirp_psd";
        const ascending = !sortState.sortingDescending;
        return `EIRP PSD, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.eirp_psd),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  if (front_end_fields.includes("eirp_psd_limit")) {
    const col = {
      id: "eirp_psd_limit",
      header: "EIRP PSD Limit",
      label: (sortState) => {
        const columnIsSorted = sortState.sortingColumn === "eirp_psd_limit";
        const ascending = !sortState.sortingDescending;
        return `EIRP PSD Limit, ${
          columnIsSorted
            ? `sorted ${ascending ? "ascending" : "descending"}`
            : "not sorted"
        }.`;
      },
      cell: (item) => centerAlign(item.eirp_psd_limit),
      minWidth: "100px",
      visible: true,
    };
    columns.push(col);
  }
  return columns;
};

const centerAlign = (item: string) => {
    return (
      <div
      style={{
        width: '100%',
        textAlign: 'center',
      }}
      >
        {item ? item : "__"}
      </div>
    )
}

// define default sorting column
export const defaultSortingColumn = "test_case_id";

// define sortable columns
export const sortableColumns = (front_end_fields) => {
  let columns: Array<any> = [];
  if (front_end_fields.includes("test_case_id")){
    columns.push({id: "test_case_id", field: "test_case_id"})
  }
  if (front_end_fields.includes("power_setting")){
    columns.push({ id: "power_setting", field: "power_setting" })
  }
  if (front_end_fields.includes("conducted_avg_power")){
    columns.push({ id: "conducted_avg_power", field: "conducted_avg_power" })
  }
  if (front_end_fields.includes("actual_radiated_fundamental_signal")){
    columns.push({ id: "actual_radiated_fundamental_signal", field: "actual_radiated_fundamental_signal" })
  }
  if (front_end_fields.includes("bandedge_peak")){
    columns.push({ id: "bandedge_peak", field: "bandedge_peak" })
  }
  if (front_end_fields.includes("bandedge_avg")){
    columns.push({ id: "bandedge_avg", field: "bandedge_avg" })
  }
  if (front_end_fields.includes("harmonic_peak")){
    columns.push({ id: "harmonic_peak", field: "harmonic_peak" })
  }
  if (front_end_fields.includes("harmonic_avg")){
    columns.push({ id: "harmonic_avg", field: "harmonic_avg" })
  }
  if (front_end_fields.includes("harmonic_rx")){
    columns.push({ id: "harmonic_rx", field: "harmonic_rx" })
  }
  if (front_end_fields.includes("harmonic_tx")){
    columns.push({ id: "harmonic_tx", field: "harmonic_tx" })
  }
  if (front_end_fields.includes("psd")){
    columns.push({ id: "psd", field: "psd" })
  }
  if (front_end_fields.includes("psd_limit")){
    columns.push({ id: "psd_limit", field: "psd_limit" })
  }
  if (front_end_fields.includes("eirp_avg_power")){
    columns.push({ id: "eirp_avg_power", field: "eirp_avg_power" })
  }
  if (front_end_fields.includes("eirp_avg_power_limit")){
    columns.push({ id: "eirp_avg_power_limit", field: "eirp_avg_power_limit" })
  }
  if (front_end_fields.includes("eirp_psd")){
    columns.push({ id: "eirp_psd", field: "eirp_psd"})
  }
  if (front_end_fields.includes("eirp_psd_limit")){
    columns.push({ id: "eirp_psd_limit", field: "eirp_psd_limit"})
  }
  return columns;
}



// define visiable columns
export const visibleColumns = (front_end_fields) => {
  let options : Array<any> = [];
  if (front_end_fields.includes("test_case_id")){
    options.push({ id: "test_case_id", label: "Testcase ID", visible: true, editable: false })
  }
  if (front_end_fields.includes("power_setting")){
    options.push({ id: "power_setting", label: "Power Setting", visible: true, editable: false })
  }
  if (front_end_fields.includes("conducted_avg_power")){
    options.push({ id: "conducted_avg_power", label: "Conducted Avg Power", visible: true })
  }
  if (front_end_fields.includes("actual_radiated_fundamental_signal")){
    options.push({ id: "actual_radiated_fundamental_signal", label: "Actual Radiated Fundamental Signal", visible: true })
  }
  if (front_end_fields.includes("bandedge_peak")){
    options.push({ id: "bandedge_peak", label: "Bandedge Peak", visible: true })
  }
  if (front_end_fields.includes("bandedge_avg")){
    options.push({ id: "bandedge_avg", label: "Bandedge Avg", visible: true })
  }
  if (front_end_fields.includes("harmonic_peak")){
    options.push({ id: "harmonic_peak", label: "Harmonic Peak", visible: true })
  }
  if (front_end_fields.includes("harmonic_avg")){
    options.push({ id: "harmonic_avg", label: "Harmonic Avg", visible: true })
  }
  if (front_end_fields.includes("harmonic_rx")){
    options.push({ id: "harmonic_rx", label: "Harmonic Rx", visible: true, editable: false })
  }
  if (front_end_fields.includes("harmonic_tx")){
    options.push({ id: "harmonic_tx", label: "Harmonic Tx", visible: true, editable: false })
  }
  if (front_end_fields.includes("psd")){
    options.push({ id: "psd", label: "PSD", visible: true })
  }
  if (front_end_fields.includes("psd_limit")){
    options.push({ id: "psd_limit", label: "PSD Limit", visible: true })
  }
  if (front_end_fields.includes("eirp_avg_power")){
    options.push({ id: "eirp_avg_power", label: "EIRP Avg Power", visible: true })
  }
  if (front_end_fields.includes("eirp_avg_power_limit")){
    options.push({ id: "eirp_avg_power_limit", label: "EIRP Avg Power Limit", visible: true })
  }
  if (front_end_fields.includes("eirp_psd")){
    options.push({ id: "eirp_psd", label: "EIRP PSD", visible: true })
  }
  if (front_end_fields.includes("eirp_psd_limit")){
    options.push({ id: "eirp_psd_limit", label: "EIRP PSD Limit", visible: true })
  }
  const columns = [ 
    {
      label: "Properties",
      options: options
    }
  ]
  return columns;
}

// define filtering options
export const filteringOptions = (front_end_fields) => {
  let columns : Array<any> = []
  if (front_end_fields.includes("power_setting")){
    const col = {
      groupValuesLabel: "Power Setting",
      propertyKey: "power_setting",
      propertyLabel: "Power Setting",
      values: [],
    }
    columns.push(col);
  }
  return columns;
} 

export const features = [
  "propertyFiltering",
  "pagination",
  "sorting",
];

import React from "react";

import {
  Alert,
  ColumnLayout,
  Tabs,
  SpaceBetween,
  Header,
} from "@amzn/awsui-components-react-v3/polaris";

import { FILE_TYPE, FILE_NAME_FORMAT } from "../../constants";
import ManualUploadPanel from "./manual-upload-module/upload-panel";
import constants from "../../constants";

// Compliance file typess
const SHOW_RF_TYPES = [FILE_TYPE.RF_TESTPLAN, FILE_TYPE.RF_TESTDATA];
const SHOW_EMI_TYPES = [FILE_TYPE.EMI_TESTPLAN, FILE_TYPE.EMI_TESTDATA];

type Props = {
  getUploadStatus: (syncMode: boolean, initiative: string) => void; // func to get table data
  updateLogs: (values: any, initiative: string) => void; // func to update logs on manual upload
};

function ManualUploadMain({ getUploadStatus, updateLogs }: Props) {
  const rfFileSupport = (
    <div>
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Compliancerf Files */}
          <SpaceBetween direction="vertical" size="l">
            {/* column1 title */}
            {/* column1 items */}
            {SHOW_RF_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  {FILE_NAME_FORMAT[type].example}
                </div>
              </div>
            ))}
          </SpaceBetween>
        </div>
      </ColumnLayout>
      <br></br>
      <ManualUploadPanel
        getUploadStatus={getUploadStatus}
        updateLogs={updateLogs}
        initiative={constants.INITIATIVE_RF}
      />
    </div>
  );

  const emiFileSupport = (
    <div>
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Compliancerf Files */}
          <SpaceBetween direction="vertical" size="l">
            {/* column1 title */}
            {/* column1 items */}
            {SHOW_EMI_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  {FILE_NAME_FORMAT[type].example}
                </div>
              </div>
            ))}
          </SpaceBetween>
        </div>
      </ColumnLayout>
      <br></br>
      <ManualUploadPanel
        getUploadStatus={getUploadStatus}
        updateLogs={updateLogs}
        initiative={constants.INITIATIVE_EMI}
      />
    </div>
  );

  const mappedTabInfo = [
    {
      label: FILE_TYPE.RF,
      id: FILE_TYPE.RF,
      content: <div>{rfFileSupport}</div>,
    },
    {
      label: FILE_TYPE.EMI,
      id: FILE_TYPE.EMI,
      content: <div>{emiFileSupport}</div>,
    },
  ];
  return (
    <div>
      <Header variant="h2">Which files are supported</Header>
      <Alert>
        The files you are going to upload should follow below file name formats.
      </Alert>
      <br />
      <Tabs tabs={mappedTabInfo} />
    </div>
  );
}

export default ManualUploadMain;

import { SpaceBetween } from "@amzn/awsui-components-react-v3";
import React from "react";
import { IoIosInformationCircleOutline } from "react-icons/io";

type Props = {
  informatiomText: String;
};
export const InfoText = ({ informatiomText }: Props) => {
  const [hover, setHover] = React.useState(false);
  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <div onMouseEnter={onHover} onMouseLeave={onLeave}>
      {hover ? (
        <SpaceBetween direction="horizontal" size="xs">
          <IoIosInformationCircleOutline />
          <p>{informatiomText} </p>
        </SpaceBetween>
      ) : (
        <IoIosInformationCircleOutline />
      )}
    </div>
  );
};

import overviewType from "../types/overview-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const getTestCaseForAllRegions = (
  category_id: string,
  project_id: string,
  build_id: string,
  build_config_id: number
): any => ({
  type: overviewType.GET_TCS_ALL,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testcasesForAllRegions(
            category_id,
            project_id,
            build_id,
            build_config_id
          ),
        method: "GET",
      },
      commit: {
        type: overviewType.GET_TCS_ALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.OVERVIEW,
        },
      },
      rollback: {
        type: overviewType.GET_TCS_ALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.OVERVIEW,
        },
      },
    },
  },
});

export const resetOverviewData = () => ({
  type: overviewType.RESET_OVERVIEW,
});

export const updateBuildStatus = (
  categoryId: string,
  projectId: string,
  buildId: string,
  values: any
) => ({
  type: overviewType.UPDATE_BUILD_STATUS,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.updateBuildStatusEndpoint(categoryId, projectId, buildId),
        method: "PUT",
        json: values,
      },
      // action to dispatch when effect succeeds:
      commit: {
        type: overviewType.UPDATE_BUILD_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.BUILD,
        },
      },
      // action to dispatch if network action fails permanently:
      rollback: {
        type: overviewType.UPDATE_BUILD_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.BUILD,
        },
      },
    },
  },
});

export const resetUpdateBuildStatus = () => ({
  type: overviewType.RESET_UPDATE_BUILD_STATUS,
});

import React, { useEffect, useState } from "react";
import {
  Select,
  ColumnLayout,
  FormField,
  SelectProps,
  Button,
  SpaceBetween,
  Header,
  Container,
  Modal,
} from "@amzn/awsui-components-react-v3";
import TestPlanSection from "./testplan-section";
import WiseSpinner from "../../../components/spinner/wiseSpinner";
import WiseLoadingFail from "../../../components/spinner/wiseLoadingFail";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import {
  getTestPlan,
  createTestPlan,
  resetCreateTestplan,
  getTestPlanDraftEMI,
  updateTestCases,
  createTestPlanDraftEMI,
  getTestPlans,
  getJiraUsers,
  clearTestOverviewDetailsEMI,
} from "../../../redux/actions/testplan-action-emi";

import {
  getBuildAndVersion,
  getProjectItems,
  getBuildItems,
  getVersionItems,
} from "../../../utils/general-utils";
import constants, { DROPDOWN_DEFAULT } from "../../../constants";
import { username } from "../../../cognito-auth/session";

import HistoryTable from "./testplan-history-table/table";
import {downloadAction} from "../../../redux/actions/download-action";
import {DownloadPreSignedURLRequest} from "../../../model/http-json";
import {parseS3Link} from "../../../utils/s3-utils";

// for loading spinner
const loadingIconStyle = {
  paddingTop: "300px",
  paddingBottom: "100px",
  textAlign: "center",
};

interface StateProps {
  metadataReducer: any;
  testplanReducerEMI: any;
  downloadReducer: any;
}
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

function TestplanForm({
  metadataReducer,
  testplanReducerEMI,
  downloadReducer,
  dispatch,
}: Props) {
  const default_build = "Build";
  const { filter } = metadataReducer;
  const { categoryId, projectId, buildVersionId } =
    testplanReducerEMI?.testOverviewDetails || {};

  const filterCategory = filter[categoryId] || {};
  const categoryName = filterCategory?.name || "";
  const filterProject = filterCategory?.children?.[projectId] || {};
  const projectName = filterProject?.name || "";

  const buildAndVersion =
    filterProject &&
    buildVersionId &&
    getBuildAndVersion(filterProject, buildVersionId);

  const buildName = buildAndVersion?.split("_")[0] || "";
  const versionName = buildAndVersion?.split("_")[1] || "";

  const [categoryData, setCategoryData] = useState({
    categoryError: "",
    category_id: categoryId || "",
    category: categoryName || DROPDOWN_DEFAULT.CATEGORY,
  });
  const [projectData, setProjectData] = useState<{
    project_id: string;
    project: string;
    projectItems: Array<SelectProps.Option>;
    projectError: string;
  }>({
    project_id: projectId || "",
    project: projectName || DROPDOWN_DEFAULT.PROJECT,
    projectItems: getProjectItems(filter, categoryId),
    projectError: "",
  });

  const [buildData, setBuildData] = useState<{
    build: string;
    buildItems: Array<SelectProps.Option>;
    buildError: string;
  }>({
    build: buildName || DROPDOWN_DEFAULT.BUILD,
    buildItems: getBuildItems(filter, categoryId, projectId),
    buildError: "",
  });

  const [versionData, setVersionData] = useState<{
    version_id: string;
    version: string;
    versionItems: Array<SelectProps.Option>;
    versionError: string;
  }>({
    version_id: buildVersionId || "",
    version: versionName || DROPDOWN_DEFAULT.VERSION,
    versionItems: getVersionItems(filter, categoryId, projectId, buildName),
    versionError: "",
  });

  const [loading, setLoading] = useState<{
    createSubmitLoading: boolean;
    createDraftSubmitLoading: boolean;
  }>({
    createSubmitLoading: false,
    createDraftSubmitLoading: false,
  });

  const [disableAssignee, setDisableAssignee] = useState(true);

  const [showLoading, setShowLoading] = useState(false);
  const [showTestPlanForm, setShowTestPlanForm] = useState(false);

  useEffect(() => {
    if (testplanReducerEMI.loadingStatus === constants.LOADING_SUCCESS && (Object.keys(testplanReducerEMI.testPlan).length !== 0
    && testplanReducerEMI.testPlan.s3_link )) {
      const s3_components = parseS3Link(testplanReducerEMI.testPlan.s3_link)
      const data: DownloadPreSignedURLRequest = {
        file_name: s3_components.file_path,
        object_key: s3_components.object_key,
        bucket_name: s3_components.bucket_name,
        version_id: s3_components.version_id
      };
      dispatch(
          downloadAction(data)
      );
    }
  }, [testplanReducerEMI]);

  useEffect(() => {
    if(downloadReducer.url !== '') {
      testplanReducerEMI.testPlan.s3_link = downloadReducer.url;
    }

  }, [downloadReducer]);

  function compare(a, b) {
    if (a.created_time < b.created_time) {
      return -1;
    }
    if (a.created_time > b.created_time) {
      return 1;
    }
    if (a.created_time == b.created_time) {
      return a.sheet_id - b.sheet_id;
    }
    return 0;
  }
  const initializeFields = ({ testPlan, assignee, lab }) => {
    var fields: any[] = [];
    if (testPlan) {
      var testPlanData = {
        id: testPlan.id,
        createdTime: testPlan.created_time,
        createdBy: testPlan.created_by,
        sheets: {},
      };
      if (testPlan.test_cases) {
        for (var i = 0; i < testPlan.test_cases.length; i++) {
          var testCase = testPlan.test_cases[i];
          if (testPlanData.sheets[testCase.sheet_name]) {
            testPlanData.sheets[testCase.sheet_name].push(testCase);
          } else {
            testPlanData.sheets[testCase.sheet_name] = [testCase];
          }
        }
      }
      if (testPlanData.sheets) {
        for (var sheetName in testPlanData.sheets) {
          fields.push({
            name: sheetName,
            testCases: testPlanData.sheets[sheetName].sort(compare),
          });
        }
      }
    }
    return { sections: fields, assignee: assignee, lab: lab };
  };

  const clear = () => {
    setCategoryData({
      category_id: "",
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
    });
    setProjectData({
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectItems: [],
      projectError: "",
    });
    setBuildData({
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
    });
    setVersionData({
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
    });
  };

  const _onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;
    setCategoryData({
      category_id,
      category,
      categoryError: "",
    });
    setProjectData({
      projectItems: getProjectItems(filter, category_id),
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
    });
    setBuildData({
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
    });
    setVersionData({
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
    });
    setShowTestPlanForm(false);
  };
  const _onChangeProject = (event: any): any => {
    const category_id = categoryData.category_id;
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;
    setProjectData({
      ...projectData,
      project_id,
      project,
      projectError: "",
    });
    setBuildData({
      buildItems: getBuildItems(filter, category_id, project_id),
      build: DROPDOWN_DEFAULT.BUILD,
      buildError: "",
    });
    setVersionData({
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
      versionItems: [],
    });
    setShowTestPlanForm(false);
  };
  const _onChangeBuild = (event: any): any => {
    const category_id = categoryData.category_id;
    const project_id = projectData.project_id;
    const build = event.detail.selectedOption.value;
    setBuildData({
      ...buildData,
      build,
      buildError: "",
    });
    setVersionData({
      versionItems: getVersionItems(filter, category_id, project_id, build),
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
    });
    setShowTestPlanForm(false);
  };

  const _onChangeVersion = (event: any): any => {
    const { filter } = metadataReducer;
    const category_id = categoryData.category_id;
    const project_id = projectData.project_id;
    const build = buildData.build;
    const version = event.detail.selectedOption.label;
    const version_id =
      filter[category_id].children[project_id].children[build].children[version]
        .id;
    setVersionData({
      ...versionData,
      version_id,
      version,
      versionError: "",
    });
    setShowTestPlanForm(false);
  };

  const getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });

    return items;
  };

  const categoryItems = getCategoryItems(filter);

  // TODO: update this when draft feature is developed
  const _onCreateDraft = () => {
    if (
      categoryData.category_id != "" &&
      projectData.project_id != "" &&
      buildData.build != default_build &&
      versionData.version_id != ""
    ) {
      dispatch(
        getTestPlanDraftEMI(
          {
            username: username,
            created_by: username,
          },
          categoryData.category_id,
          projectData.project_id,
          versionData.version_id,
          Number(versionData.version)
        )
      );
      setShowTestPlanForm(true);
    }
  };

  const validateTestPlan = (values, testplan = true) => {
    var errorFound = false;
    let alertmessage: any = null;
    const errors: any = {};

    if (!values || !values.sections || values.sections.length === 0) {
      errors.sections = { _error: "At least one sheet must be entered" };
      alertmessage = "ERROR: Please add at least one sheet in the testplan.";
      errorFound = true;
    } else if (testplan && (!values.lab || values.lab === "")) {
      errors.lab = "*";
      alertmessage = "ERROR: Please select the lab value in the dropdown.";
    } else if (testplan && (!values.assignee || values.assignee === "")) {
      errors.assignee = "*";
      alertmessage = "ERROR: Please select the assignee value in the dropdown.";
    } else if (testplan && !/^[a-z]+$/.test(values.assignee)) {
      errors.assignee = "*";
      alertmessage = "ERROR: Assignee should only contain lower case alphabets";
    } else {
      var sectionsArrayErrors: any = [];
      values.sections.forEach((section, sectionIndex) => {
        var sectionErrors: any = {};
        if (!section.name || section.name === "") {
          sectionErrors.name = { _error: "Section name cannot be empty" };
          sectionsArrayErrors[sectionIndex] = sectionErrors;
          errorFound = true;
        }
        if (!section.testCases || section.testCases.length === 0) {
          sectionErrors.testCases = {
            _error: "Section must have at least one test case",
          };
          sectionsArrayErrors[sectionIndex] = sectionErrors;
          errorFound = true;
        }
      });
      errors.sections = sectionsArrayErrors;
      if (errorFound)
        alertmessage =
          "ERROR: Testcase Data Invalid. Please fix the highlight errors before submitting again.";
    }
    if (alertmessage) {
      window.alert(alertmessage);
    }
    if (errorFound) {
      throw new SubmissionError(errors);
    }
    if (alertmessage && !errorFound) return true;
  };

  const formSubmit = (values) => {
    if (validateTestPlan(values)) return;
    console.log("submit values", values);
    var testCases: any = [];
    values.sections.forEach((section, sectionIndex) => {
      section.testCases.forEach((testCase, testCaseIndex) => {
        testCase.build_id = versionData.version_id;
        testCase.sheet_name = section.name;
        testCases.push(testCase);
      });
    });
    if (
      categoryData.category &&
      projectData.project &&
      buildData.build &&
      versionData.version
    ) {
      dispatch(
        createTestPlan(
          {
            test_cases: testCases,
            username: username,
            assignee: values.assignee,
            lab: values.lab,
            created_by: username,
          },
          categoryData.category_id,
          projectData.project_id,
          versionData.version_id
        )
      );
    }
  };

  const saveAsDraft = (values) => {
    if (validateTestPlan(values)) return;
    var testCases: any = [];
    values.sections.forEach((section, sectionIndex) => {
      section.testCases.forEach((testCase, testCaseIndex) => {
        testCase.build_id = versionData.version_id;
        testCase.sheet_name = section.name;
        testCases.push(testCase);
      });
    });
    if (
      categoryData.category &&
      projectData.project &&
      buildData.build &&
      versionData.version
    ) {
      dispatch(
        createTestPlanDraftEMI(
          {
            test_cases: testCases,
            username: username,
            assignee: values.assignee,
            lab: values.lab,
            created_by: username,
            is_active: 0,
            s3_link: testplanReducerEMI.testPlan.s3_link,
          },
          categoryData.category_id,
          projectData.project_id,
          versionData.version_id,
          Number(versionData.version)
        )
      );
    }
  };

  const _onCreate = async () => {
    if (
      categoryData.category_id != "" &&
      projectData.project_id != "" &&
      buildData.build != default_build &&
      versionData.version_id != ""
    ) {
      setShowLoading(true);
      setDisableAssignee(true);
      dispatch(
        getTestPlan(
          categoryData.category_id,
          projectData.project_id,
          versionData.version_id
        )
      );

      await new Promise((resolve) => {
        setTimeout(() => {
          // Resolve the promise
          dispatch(
            getTestPlans(categoryData, projectData, buildData, versionData)
          );
          resolve(console.log("loading history Table"));
          setShowLoading(false);
          setShowTestPlanForm(true);
        }, 1000);
      });
    } else {
      alert("Please select values in dropdown");
    }
  };

  const _setExcelTestCases = async (testCases) => {
    setShowLoading(true);
    if (
      testplanReducerEMI?.testPlan?.test_cases?.length == 0 &&
      testCases.length > 0
    )
      setDisableAssignee(false);
    dispatch(updateTestCases(testCases));

    // workaround to reintialize redux form
    await new Promise((resolve) => {
      setTimeout(() => {
        // Resolve the promise
        resolve(console.log("loading test cases"));
      }, 1000);
    });

    setShowLoading(false);
  };

  const closeSubmitFailedModal = () => {
    dispatch(resetCreateTestplan());
  };
  const closeSubmittedModal = () => {
    dispatch(resetCreateTestplan());
    setShowTestPlanForm(false);
  };

  useEffect(() => {
    dispatch(getJiraUsers());
    dispatch(clearTestOverviewDetailsEMI());
  }, [dispatch]);

  return (
    <div>
      <Container header={<Header variant="h2">Create Testplan</Header>}>
        <SpaceBetween direction="vertical" size="l">
          <ColumnLayout columns={3}>
            <FormField label="Category" errorText={categoryData.categoryError}>
              <Select
                options={categoryItems}
                placeholder="Category"
                onChange={_onChangeCategory}
                selectedOption={{
                  value: categoryData.category_id,
                  label: categoryData.category,
                }}
              ></Select>
            </FormField>
            <FormField label="Project" errorText={projectData.projectError}>
              <Select
                options={projectData.projectItems}
                placeholder="Project"
                onChange={_onChangeProject}
                selectedOption={{
                  value: projectData.project_id,
                  label: projectData.project,
                }}
              ></Select>
            </FormField>
            <FormField label="Build" errorText={buildData.buildError}>
              <Select
                options={buildData.buildItems}
                placeholder="Build"
                onChange={_onChangeBuild}
                selectedOption={{
                  value: buildData.build,
                  label: buildData.build,
                }}
              ></Select>
            </FormField>
            <FormField label="Version" errorText={versionData.versionError}>
              <Select
                options={versionData.versionItems}
                placeholder="Version"
                onChange={_onChangeVersion}
                selectedOption={{
                  value: versionData.version,
                  label: versionData.version,
                }}
              ></Select>
            </FormField>
          </ColumnLayout>

          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={_onCreateDraft}
              loading={
                //loadingStatus === constants.LOAD_DATA_LOADING ||
                loading.createDraftSubmitLoading
              }
            >
              Open Saved Draft
            </Button>
            <Button
              variant="primary"
              onClick={_onCreate}
              loading={
                // loadingStatus === constants.LOAD_DATA_LOADING ||
                loading.createDraftSubmitLoading
              }
            >
              Create/Update Plan
            </Button>
          </SpaceBetween>
        </SpaceBetween>
      </Container>

      <br />
      {showTestPlanForm &&
        (testplanReducerEMI.loadingStatus === constants.LOADING_LOAD ||
          showLoading) && <WiseSpinner style={loadingIconStyle} />}

      {showTestPlanForm &&
        testplanReducerEMI.loadingStatus === constants.LOADING_FAIL && (
          <WiseLoadingFail style={loadingIconStyle} />
        )}

      {showTestPlanForm &&
        testplanReducerEMI.testPlans.length > 0 &&
        testplanReducerEMI.loadingStatus === constants.LOADING_SUCCESS &&
        showLoading === false && (
          <HistoryTable
            tabledata={testplanReducerEMI.testPlans}
            loading={testplanReducerEMI.loadingStatus}
          />
        )}

      <br />

      {showTestPlanForm &&
        testplanReducerEMI.loadingStatus === constants.LOADING_SUCCESS &&
        showLoading === false && (
          <TestPlanSection
            onSubmit={(values) => {
              formSubmit(values);
            }}
            saveAsDraft={(values) => {
              saveAsDraft(values);
            }}
            initialValues={initializeFields(testplanReducerEMI)}
            setExcelTestCases={(testCases) => _setExcelTestCases(testCases)}
            username={username}
            jiraUsers={testplanReducerEMI.data}
            disableAssignee={
              testplanReducerEMI?.testPlan?.test_cases?.length > 0 &&
              disableAssignee
            }
          />
        )}
      <Modal
        visible={
          testplanReducerEMI.submitLoadingStatus == constants.LOADING_LOAD
        }
      >
        <h3 className="submitted-message">Submitting...</h3>
        <WiseSpinner style={{ textAlign: "center" }} />
      </Modal>
      <Modal
        visible={
          testplanReducerEMI.submitLoadingStatus == constants.LOADING_SUCCESS
        }
        onDismiss={closeSubmittedModal}
      >
        <h5 className="submitted-message">{testplanReducerEMI.message}</h5>
      </Modal>
      <Modal
        visible={
          testplanReducerEMI.submitLoadingStatus == constants.LOADING_FAIL
        }
        onDismiss={closeSubmitFailedModal}
      >
        <h5 className="submit-failed-message">{testplanReducerEMI.message}</h5>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
    testplanReducerEMI: state.testplanReducerEMI,
    downloadReducer: state.downloadReducer
  };
};

export default connect<StateProps>(mapStateToProps)(TestplanForm);

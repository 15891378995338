import { put, takeEvery, call } from "redux-saga/effects";
import { redirectToLoginPage, getAccessToken } from "../cognito-auth/session";

//Watcher: liston to specific action and call worker to take actions
// watch upload link action
export function* watchHttpAction() {
  yield takeEvery("*", sendHttpRequest);
}

//Worker
export function* sendHttpRequest(action) {
  if ("http" in action && "cognito" in action.http) {
    yield cognitoRequestSender(action);
  }
}
// send http request to lambda with cognito authorizer
function* cognitoRequestSender(action) {
  const { effect, commit, rollback } = action.http.cognito;
  const token = yield getAccessToken();
  // verify if the session is still valid
  if (token !== "") {
    try {
      const response = yield call(fetch, effect.url, {
        //   credentials: "include",
        method: effect.method,
        headers:
          "headers" in effect
            ? {
                Authorization: token,
                ...effect.headers,
              }
            : {
                Authorization: token,
              },
        body: "json" in effect ? JSON.stringify(deepTrim(effect.json)) : null,
      });
      if (response.ok) {
        // success
        const jsonResponse = yield response.text();
        yield put({
          ...commit,
          statusCode: response.status,
          payload: JSON.parse(jsonResponse.replace(/\bNaN\b/g, "null")),
        });
      } else {
        // fail
        const jsonResponse = yield response.text();
        yield put({
          ...rollback,
          statusCode: response.status,
          payload: JSON.parse(jsonResponse),
        });
      }
    } catch (e) {
      console.log(e);
      yield put(rollback);
    }
  } else {
    // cognito login
    console.log("session expired!");
    redirectToLoginPage();
  }
}

function deepTrim(obj) {
  for (var prop in obj) {
    var value = obj[prop],
      type = typeof value;
    if (
      value != null &&
      (type == "string" || type == "object") &&
      obj.hasOwnProperty(prop)
    ) {
      if (type == "object") {
        deepTrim(obj[prop]);
      } else {
        obj[prop] = obj[prop].trim();
      }
    }
  }
  return obj;
}
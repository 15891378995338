import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";
import uploadType from "../types/upload-type";
import { UpdateLog } from "../../model/upload";
import {DownloadPreSignedURLRequest} from "../../model/http-json";

export const downloadAction = (
    values: DownloadPreSignedURLRequest,
): any => ({
    type: uploadType.GET_DOWNLOAD,
    http: {
        cognito: {
            effect: {
                url: config.UPLOAD_BASE_URL + endpoints.downloadEndpoint(),
                method: "POST",
                json: values,
            },
            commit: {
                type: uploadType.GET_DOWNLOAD_COMMIT,
                log: {
                    feature: METRIC_INFO.META_DATA,
                },
            },
            rollback: {
                type: uploadType.GET_DOWNLOAD_ROLLBACK,
                log: {
                    feature: METRIC_INFO.META_DATA,
                },
            },
        },
    },
});
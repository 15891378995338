import React from "react";
import { Box } from "@amzn/awsui-components-react-v3";

export const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: "xxxs" }} color='text-label'>
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

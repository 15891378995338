import React, { Component } from 'react';
import { Dispatch } from "redux";
import { customizedFilteringFunction } from "../../../components/polaris/table/property-filtering-utils";
import { exportCSVFunc } from "@amzn/lens-react-ui";
import {
    Table,
    TableSorting,
    Button,
    TablePropertyFiltering,
    TablePagination,
    TablePreferences,
    TablePageSizeSelector,
    TableWrapLines,
    TableContentSelector
} from "@amzn/awsui-components-react/polaris"

import {
    defaultPageSize,
    columnDefinitions,
    features,
    defaultSortingColumn,
    sortableColumns,
    filteringOptions,
    visibleColumns
} from "./table-config"

// use this to test with sample data
import { sampleData, sample_front_end_fields } from "./sample-data"
import constants from '../../../constants';

type State = typeof initialState;

const initialState = Object.freeze({
    currentPageIndex: 1,
    pagesCount: 0,
    selectedItems: [],
})

type Props = {
  data: any;
  front_end_fields: any;
  loadingStatus: any;
  category: string;
  project: string;
  build: string;
  build_config: string;
  resetSelectedPoints: any;
  dispatch: Dispatch<any>;
} & typeof defaultProps;

const defaultProps = Object.freeze({})

class TestcaseSummaryTable extends Component<Props , State> {
    static defaultProps = defaultProps;
    state = initialState;
    constructor(props) {
        super(props);

    }

    _onPaginationChange = (event) => {
        const { currentPageIndex, pagesCount } = event.detail;
        this.setState({
            currentPageIndex,
            pagesCount,
        });
    };


    render() {
        const {
          data,
          front_end_fields,
          loadingStatus,
          category,
          project,
          build,
          build_config,
          dispatch,
          resetSelectedPoints,
        } = this.props;                                                                                                    
        return (
          <Table
            loadingText="Loading"
            columnDefinitions={columnDefinitions(
              front_end_fields,
              category,
              project,
              build,
              build_config,
              dispatch,
              resetSelectedPoints
            )}
            items={data}
            loading={loadingStatus == constants.LOADING_LOAD}
            features={features}
            header={
              <div className="awsui-util-action-stripe">
                <div className="awsui-util-action-stripe-title">
                  <h2>
                    Testcase Summary &nbsp;
                    <span className="awsui-util-header-counter">{`(${data.length})`}</span>
                  </h2>
                </div>
                <div className="awsui-util-action-stripe-group">
                  <Button
                    icon="download"
                    variant="primary"
                    disabled={!data || data.length == 0}
                    onClick={() => exportCSVFunc(data, "TestcaseSummary")}
                  >
                    Export Table
                  </Button>
                </div>
              </div>
            }
            empty={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No resources</b>
                </div>
                <p className="awsui-util-mb-s">No resources to display.</p>
              </div>
            }
            noMatch={
              <div className="awsui-util-t-c">
                <div className="awsui-util-pt-s awsui-util-mb-xs">
                  <b>No matches</b>
                </div>
                <p className="awsui-util-mb-s">We can’t find a match.</p>
                <div className="awsui-util-mb-m">
                  <Button>Clear filter</Button>
                </div>
              </div>
            }
            wrapLines={false}
          >
            <TablePropertyFiltering
              filteringOptions={filteringOptions(front_end_fields)}
              filteringFunction={customizedFilteringFunction}
              groupValuesText="Values"
              groupPropertiesText="Properties"
              operationAndText="and"
              operationNotAndText="and not"
              operationOrText="or"
              operationNotOrText="or not"
              clearFiltersText="Clear filter"
              placeholder="Filter resources by property or value"
              allowFreeTextFiltering={true}
              filteringCountTextFunction={(count) =>
                `${count} ${count === 1 ? "match" : "matches"}`
              }
            ></TablePropertyFiltering>
            <TablePagination
              pageSize={defaultPageSize}
              onPaginationChange={this._onPaginationChange}
            ></TablePagination>
            <TableSorting
              sortingColumn={defaultSortingColumn}
              sortableColumns={sortableColumns(front_end_fields)}
              sortingDescending={true}
            ></TableSorting>
            <TablePreferences
              title="Preferences"
              confirmLabel="Confirm"
              cancelLabel="Cancel"
            >
              <TablePageSizeSelector
                title="Page size"
                options={[
                  { value: 3, label: "3 items" },
                  { value: 5, label: "5 items" },
                  { value: 10, label: "10 items" },
                ]}
              />
              <TableWrapLines
                label="Wrap lines"
                description="Enable to wrap table cell content, disable to truncate text."
              />
              <TableContentSelector
                title="Select visible columns"
                options={visibleColumns(front_end_fields)}
              />
            </TablePreferences>
          </Table>
        );
    }
}



export default TestcaseSummaryTable;
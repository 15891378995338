import visualizationEmiType from "../types/visualization-emi-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../constants";
import endpoints from "../../endpoints/endpoints";

// [[1,2,2],[2,3,2]] => [{start:1,end:2,val:2}, {start:2,end:3,val:2}]
export const formLimit = (limits) => {
  let formedLimits: Array<Object> = [];
  limits.forEach((limit) => {
    formedLimits.push({ start: limit[0], end: limit[1], val: limit[2] });
  });
  return formedLimits;
};

export const getTestData = (category, project, build, id, points) => ({
  type: visualizationEmiType.GET_VISUALIZATION_DATA,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL + //endpoints.metadataEndpoint(),
          endpoints.getEmiTestingData(category, project, build, id, points),
        method: "GET",
      },
      commit: {
        type: visualizationEmiType.GET_VISUALIZATION_DATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.BUID_GRAPH_DATA,
        },
      },
      rollback: {
        type: visualizationEmiType.GET_VISUALIZATION_DATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.BUID_GRAPH_DATA,
        },
      },
    },
  },
});

export const getSpecificTestCaseData = (
  category,
  project,
  build,
  testCategory,
  testcase_uid
) => ({
  type: visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_DATA,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url:
          config.BASE_URL +
          endpoints.testcaseDetailEndpoint(
            category,
            project,
            build,
            testcase_uid
          ),
        method: "GET",
      },
      commit: {
        type: visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_DATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.BUID_GRAPH_DATA,
        },
        category,
        project,
        build,
        testCategory,
      },
      rollback: {
        type: visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.BUID_GRAPH_DATA,
        },
      },
    },
  },
});

import React, { Component } from "react";
// polaris
import { Button, Modal } from "@amzn/awsui-components-react";
import { 
        FormField, 
        Input,
        SpaceBetween,
        Popover,
        StatusIndicator,
        TextContent
     } from "@amzn/awsui-components-react-v3"
import  {
    IFRAME
} from "../../constants";
import copy from "copy-to-clipboard";
import "./iframe.css"
import querySearch from "query-string";
import { config } from "../../config/config.js";


// declare prop check
type Props = {
};

const defaultProps = Object.freeze({})

type State = {
  modalOpen: boolean;
  iFrameLink: string; 
};

class Iframe extends Component<Props, State> {
  state: State = Object.freeze({
    modalOpen: false,
    iFrameLink: ""
  });



  _onIframeClick = () => {
    const iFrameLink = window.location.href + IFRAME.QUERY_STRING;
    this.setState({
        iFrameLink: iFrameLink,
        modalOpen: true
    })
  }

  _onGoToWebsite = () => {
    const url = config.WEBSITE_BASE_URL;
    window.open(url, '_blank');
  }

  render() {
    return (
      <>
        {
            window.location &&
            window.location.href &&
            querySearch.parse(window.location.href.split("?")[1]).inIframe &&
            querySearch.parse(window.location.href.split("?")[1]).inIframe === "true" 
            ? 
            <Button
                icon="external"
                variant="primary"
                onClick={this._onGoToWebsite}
            >
                Go to Website
            </Button>
            : 
            <Button
                icon="share"
                variant="primary"
                onClick={this._onIframeClick}
            >
                Share
            </Button>
          }
        
        <Modal
            onDismiss={
                () => this.setState({
                    modalOpen: false
                })
            }
            visible={this.state.modalOpen}
            header = "Share Dashboard"
        >
            <TextContent>
                <p><small>You can share this URL with people to let them load the most recent saved version of this dashboard.</small></p>
            </TextContent>
            <FormField
                label={<strong>Link</strong>}
                constraintText={
                    "Add to your wikis as an Iframe."
                }
            >
                <SpaceBetween direction="horizontal" size="l">
                <Input
                    value={this.state.iFrameLink}
                    ariaRequired
                    readOnly
                    disabled={true}
                    className="IframeInput"
                />
                <Popover
                    size="small"
                    position="top"
                    triggerType="custom"
                    dismissButton={false}
                    content={<StatusIndicator type="success">Iframe Link copied</StatusIndicator>}
                >
                    <Button
                    icon="copy"
                    variant="primary"
                    onClick={() => {
                        copy(this.state.iFrameLink)
                    }}
                    >
                    Copy
                    </Button>
                </Popover>
                </SpaceBetween>
            </FormField>
        </Modal>
      </>
    
    );
  }
}


export default Iframe;


import constants from "../../constants";
import uploadType from "../types/upload-type";

interface state {
  data: Array<any>;
  loadingStatus: number;
}

const initialState: state = {
  data: [],
  loadingStatus: constants.LOADING_LOAD,
};

const uploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case uploadType.GET_UPLOAD:
      if (action.syncMode) {
        return state;
      }
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case uploadType.GET_UPLOAD_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        data:
          action.payload &&
          action.payload.data &&
          Array.isArray(action.payload.data)
            ? action.payload.data
            : [],
      };
    case uploadType.GET_UPLOAD_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case uploadType.CREATE_UPLOAD:
      return state;
    case uploadType.TRIGGER_AUTO_UPLOAD:
      return state;
    default:
      return state;
  }
};

export default uploadReducer;

export const parseS3Link = (s3Link: string) => {
    const url = new URL(s3Link);
    const searchParams = new URLSearchParams(url.search);
    let version_id = searchParams.get('versionId');
    version_id = version_id === null ? "" : version_id;

    const parts = url.pathname.split('/').filter(part => part);
    let bucket_name: string;
    let file_path: string;
    let object_key = "";

    if(url.hostname === 's3-us-west-2.amazonaws.com' || url.hostname === 's3.us-west-2.amazonaws.com') {
        bucket_name = parts[0];
        file_path = parts[1];
    } else {
        bucket_name = url.hostname.split('.')[0];
        file_path = url.pathname.startsWith('/') ? url.pathname.slice(1) : url.pathname;
    }
    const decoded_file_path = decodeURIComponent(file_path)
    return {
        bucket_name,
        file_path: decoded_file_path,
        version_id,
        object_key
    };
}
import React from "react";
import { TEST_STATUS_COLOR } from "../../commons/color-config";
import { UPDATE_TESTCASE, TEST_STATUS } from "../../constants";
import { Box, SpaceBetween, Button } from "@amzn/awsui-components-react-v3";

export const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

export const statusStyle = (item: string) => {
  return (
    <div
      style={{
        color: `${TEST_STATUS_COLOR?.[item?.toLowerCase()] || "black"}`,
      }}
    >
      {item}
    </div>
  );
};

export const publishStatusStyle = (item: string) => {
  if (item === UPDATE_TESTCASE.PUBLISHED) {
    return (
      <div
        style={{
          backgroundColor: "#87CEFA", //LightSkyBlue
          textAlign: "center",
          outline: "none",
        }}
      >
        Published
      </div>
    );
  } else if (item === UPDATE_TESTCASE.REJECTED) {
    return (
      <div
        style={{
          backgroundColor: "#FF6347", //Tomato
          textAlign: "center",
          outline: "none",
        }}
      >
        Rejected
      </div>
    );
  } else {
    return centerAlign("Unpublished");
  }
};

export const TableNoMatchState = (props) => {
  return (
    <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
      <SpaceBetween size="xxs">
        <div>
          <b>No matches</b>
          <Box variant="p" color="inherit">
            We can't find a match.
          </Box>
        </div>
        <Button onClick={props.onClearFilter}>Clear filter</Button>
      </SpaceBetween>
    </Box>
  );
};

export const TableEmptyState = (str: string) => (
  <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
    <div>
      <b>No {str.toLowerCase()}</b>
      <Box variant="p" color="inherit">
        No {str.toLowerCase()} associated with this build.
      </Box>
    </div>
  </Box>
);

export const paginationLabels = {
  nextPageLabel: "Next page",
  previousPageLabel: "Previous page",
  pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
};

export const PROPERTY_FILTERING_I18N_CONSTANTS = {
  filteringAriaLabel: "Your choice",
  dismissAriaLabel: "Dismiss",

  filteringPlaceholder: "Search",
  groupValuesText: "Values",
  groupPropertiesText: "Properties",
  operatorsText: "Operators",

  operationAndText: "and",
  operationOrText: "or",

  operatorLessText: "Less than",
  operatorLessOrEqualText: "Less than or equal",
  operatorGreaterText: "Greater than",
  operatorGreaterOrEqualText: "Greater than or equal",
  operatorContainsText: "Contains",
  operatorDoesNotContainText: "Does not contain",
  operatorEqualsText: "Equals",
  operatorDoesNotEqualText: "Does not equal",

  editTokenHeader: "Edit filter",
  propertyText: "Property",
  operatorText: "Operator",
  valueText: "Value",
  cancelActionText: "Cancel",
  applyActionText: "Apply",
  allPropertiesLabel: "All properties",

  tokenLimitShowMore: "Show more",
  tokenLimitShowFewer: "Show fewer",
  clearFiltersText: "Clear filters",
  removeTokenButtonAriaLabel: () => "Remove token",
  enteredTextLabel: (text) => `Use: "${text}"`,
};

// define page size options
export const PAGE_SIZE_OPTIONS = [
  { value: 3, label: "3 items" },
  { value: 5, label: "5 items" },
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

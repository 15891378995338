import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Sidebar as LensSidebar } from "@amzn/lens-react-ui";
//redux
import { connect } from "react-redux";
// this is a sample config, replace with real data with the same format
// import { sidebarData } from "./sidebar-config";
import constants from "../../constants";

interface StateProps {
  metadataReducer: any;
}

// declare prop check
type Props = {
  collapse: boolean;
  onClick: () => void;
} & typeof defaultProps &
  StateProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class Sidebar extends Component<Props> {
  render() {
    const { collapse, onClick } = this.props;
    const { loadingStatus, sidebarData } = this.props.metadataReducer;
    // static readonly defaultProps = defaultProps;

    //let arr = ["RF", "EMI"];
    let sidebarDataWithEmi = sidebarData.map((val) => {
      val.children.map((val_child) => {
        val_child.children.map((val_child_child) => {
          if (val_child_child.children.length === 0) {
            val_child_child.children.push({
              text: "RF",
              children: [],
              id: "rf",
              href: val_child_child.href + "/rf",
            });
            val_child_child.children.push({
              text: "EMI",
              children: [],
              id: "emi",
              href: val_child_child.href + "/emi",
            });
          }
          delete val_child_child.href;
        });
      });

      return val;
    });
    return (
      <LensSidebar
        title="Project List"
        loading={loadingStatus === constants.LOADING_LOAD}
        failed={loadingStatus === constants.LOADING_FAIL}
        collapse={collapse}
        data={sidebarDataWithEmi}
        onClickToggle={onClick}
        routerHistory={this.props.history}
      />
    );
  }
}

// export default withRouter(Sidebar);
const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(withRouter(Sidebar));

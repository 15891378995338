import React, { Component } from "react";
import {
  PieChart,
  Box,
  Button,
  Header,
} from "@amzn/awsui-components-react-v3/polaris";
import constants from "../../../constants";
import { TEST_STATUS } from "../../../constants";
import Container from "@amzn/awsui-components-react-v3/polaris/container/internal";
import { TEST_STATUS_COLOR } from "../../../commons/color-config";

type Props = {
  data: any;
  loadingStatus: any;
} & typeof defaultProps;
type State = {};
const defaultProps = Object.freeze({});

class BuildOverviewChart extends Component<Props, State> {
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
  }
  getBuildOverviewData = (buildConfigsData: Array<any>) => {
    let statusCountMap: any = {};
    statusCountMap[TEST_STATUS.PASSED] = {
      title: TEST_STATUS.PASSED,
      value: 0,
    };
    statusCountMap[TEST_STATUS.FAILED] = {
      title: TEST_STATUS.FAILED,
      value: 0,
    };
    statusCountMap[TEST_STATUS.UNTESTED] = {
      title: TEST_STATUS.UNTESTED,
      value: 0,
    };

    buildConfigsData.forEach((buildConfig) => {
      if (buildConfig[TEST_STATUS.PASSED])
        statusCountMap[TEST_STATUS.PASSED].value +=
          buildConfig[TEST_STATUS.PASSED];
      if (buildConfig[TEST_STATUS.FAILED])
        statusCountMap[TEST_STATUS.FAILED].value +=
          buildConfig[TEST_STATUS.FAILED];
      if (buildConfig[TEST_STATUS.UNTESTED])
        statusCountMap[TEST_STATUS.UNTESTED].value +=
          buildConfig[TEST_STATUS.UNTESTED];
    });

    let overviewData: Array<any> = [];
    for (var status in statusCountMap) {
      statusCountMap[status]["color"] = TEST_STATUS_COLOR[status];
      overviewData.push(statusCountMap[status]);
    }
    return overviewData;
  };
  getLoadingStatusType = (loadingStatus: number) => {
    switch (loadingStatus) {
      case constants.LOADING_LOAD:
        return "loading";
      case constants.LOADING_SUCCESS:
        return "finished";
      case constants.LOADING_FAIL:
        return "error";
      default:
        return "finished";
    }
  };
  render() {
    const { data, loadingStatus } = this.props;
    const loadingStatusType = this.getLoadingStatusType(loadingStatus);
    const overviewData = this.getBuildOverviewData(data);
    return (
      <PieChart
        hideFilter
        data={overviewData}
        segmentDescription={(datum, sum) =>
          `${datum.value} test cases, ${((datum.value / sum) * 100).toFixed(
            0
          )}%`
        }
        i18nStrings={{
          detailsValue: "Number of test cases",
          detailsPercentage: "Percentage of test cases",
          filterLabel: "Filter displayed data",
          filterPlaceholder: "Filter data",
          filterSelectedAriaLabel: "selected",
          detailPopoverDismissAriaLabel: "Dismiss",
          legendAriaLabel: "Legend",
          chartAriaRoleDescription: "pie chart",
          segmentAriaRoleDescription: "segment",
        }}
        errorText='Error loading data.'
        loadingText='Loading'
        recoveryText='Retry'
        size='medium'
        statusType={loadingStatusType}
        empty={
          <Box textAlign='center' color='inherit'>
            <b>No data available</b>
            <Box variant='p' color='inherit'>
              There is no data available
            </Box>
          </Box>
        }
        noMatch={
          <Box textAlign='center' color='inherit'>
            <b>No matching data</b>
            <Box variant='p' color='inherit'>
              There is no matching data to display
            </Box>
            <Button>Clear filter</Button>
          </Box>
        }
      />
    );
  }
}

export default BuildOverviewChart;

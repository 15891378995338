import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import {
  Container,
  Header,
  Button,
  Input,
  ColumnLayout,
  FormField,
  SpaceBetween,
  Select,
  SelectProps,
} from "@amzn/awsui-components-react-v3";
import TableFreq from "./table-freq/table-freq";
import TableCriticalData from "./table-critical/table-critical-data";
import TableTestcase from "./table-testcase/table-testcase";
import { RouteComponentProps } from "react-router-dom";
import {
  getTestData,
  getSpecificTestCaseData,
} from "../../redux/actions/data-action";
import { getBuildTestcasesData } from "../../redux/actions/build-action-emi";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import "./visualization.css";
import BreadcrumbGroup from "../../components/breadcrumb-group";
import { capitalizeFirstLetter } from "../../utils/general-utils";
import { useLocation } from "react-router-dom";
import constants from "../../constants";
import {
  ContainerFail,
  ContainerNoGraph,
  ContainerSpninner,
} from "../../components/container-status-indicator";
import { getBuildAndVersion } from "../../utils/general-utils";
import { Chip } from "@material-ui/core";
import { awsRum } from "../../index";

interface StateProps {
  dataReducer: any;
  metadataReducer: any;
  buildReducerEMI: any;
}

type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

function VisualizationEMI({
  dataReducer,
  dispatch,
  match,
  metadataReducer,
  buildReducerEMI,
}: Props) {
  const { buildEMIloadingStatus, testCases, message, s3_link } =
    buildReducerEMI;
  const {
    data,
    series,
    tableData,
    tableColumn,
    xName,
    yName,
    failedTable,
    limitName,
    loadingStatus,
    showFailedTable,
    failedInfo,
    selectedDataPointsTableData,
    selectedDataPointsTableColumnNames,
    testcaseSpecific,
  } = dataReducer;

  const [categoryName, setCategoryName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [buildAndVersion, setBuildAndVersion] = useState("");

  const [categoryId, setCategoryId] = useState("");
  const [category, setCategory] = useState("");

  const [projectId, setProjectId] = useState("");
  const [project, setProject] = useState("");

  const [buildId, setBuildId] = useState("");
  const [build, setBuild] = useState("");

  const [versionId, setVersionId] = useState("");
  const [version, setVersion] = useState("");

  const [testCaseId, setTestCaseId] = useState("");
  const [testCase, setTestCase] = useState("");

  const [filter, setFilter] = useState<any>([]);
  const [categoryItems, setCategoryItems] = useState<SelectProps.Option[]>([]);
  const [projectItems, setProjectItems] = useState<SelectProps.Option[]>([]);
  const [buildItems, setBuildItems] = useState<SelectProps.Option[]>([]);
  const [versionItems, setVersionItems] = useState<SelectProps.Option[]>([]);

  const [testCasesOptionsData, setTestCasesOptionsData] = useState(testCases);

  const [plottedTestCase, setPlottedTestCase] = useState<any[]>([]);

  const [seriesData, setSeriesData] = useState<any[]>(series);

  const intialize_component = (
    category: any,
    project: any,
    build: any,
    filter: any
  ) => {
    const categoryName = filter[category].name;
    const projectName = filter[category].children[project].name;
    const buildAndVersion = getBuildAndVersion(
      filter[category].children[project],
      build
    );

    setCategoryName(categoryName);
    setProjectName(projectName);
    setBuildAndVersion(buildAndVersion);
    setFilter(filter);
  };

  const location = useLocation();

  const getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });
    setCategoryItems(items);
    return items;
  };

  const onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;

    setCategory(category);
    setCategoryId(category_id);

    let projectItems: Array<SelectProps.Option> = [];

    Object.keys(filter[category_id].children).forEach((project_id) => {
      const project = filter[category_id].children[project_id];
      const item: SelectProps.Option = {
        value: project_id,
        label: project.name,
      };
      projectItems.push(item);
    });

    setProjectItems(projectItems);
  };

  const onChangeProject = (event: any): any => {
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;

    setProject(project);
    setProjectId(project_id);

    let buildItems: Array<SelectProps.Option> = [];

    Object.keys(filter[categoryId].children[project_id].children).forEach(
      (build) => {
        const item: SelectProps.Option = {
          value: build,
          label: build,
        };
        buildItems.push(item);
      }
    );

    setBuildItems(buildItems);
  };

  const onChangeBuild = (event: any): any => {
    const build_id = event.detail.selectedOption.value;
    const build = event.detail.selectedOption.label;

    setBuild(build);
    setBuildId(build_id);

    let versionItems: Array<SelectProps.Option> = [];

    Object.keys(
      filter[categoryId].children[projectId].children[build_id].children
    ).forEach((version) => {
      const item: SelectProps.Option = {
        value: version,
        label: version,
      };
      versionItems.push(item);
    });

    setVersionItems(versionItems);
  };

  const onChangeVersion = (event: any): any => {
    const version_id = event.detail.selectedOption.value;
    const version = event.detail.selectedOption.label;

    setVersion(version);
    setVersionId(version_id);

    const selectedBuildId =
      filter[categoryId].children[projectId].children[buildId].children[
        version_id
      ].id;

    dispatch(getBuildTestcasesData(categoryId, projectId, selectedBuildId));
  };

  const onChangeTestCase = (event: any): any => {
    const test_case_id = event.detail.selectedOption.value;
    const test_case = event.detail.selectedOption.label;

    setTestCase(test_case);
    setTestCaseId(test_case_id);
  };

  const formatTestCaseIdOptions = (data: any): any => {
    const passedTestCases = data.filter(
      (testCase) => testCase.status === "Passed"
    );

    let selectItems: Array<SelectProps.Option> = [];
    passedTestCases.forEach((testCase) => {
      const item: SelectProps.Option = {
        value: testCase.uid,
        label: testCase.uid,
        description: testCase.test_case_id,
      };
      selectItems.push(item);
    });

    return selectItems;
  };

  const onAddPlotButtonClick = (event: any): any => {
    // Send custom event to CloudWatch RUM
    awsRum?.recordEvent("comparison_feature_event", {
      isExternal: event.detail.external,
      href: event.detail.href,
      metricCategory: "feature_metric",
    });

    if (categoryId && projectId && buildId && versionId && testCaseId) {
      const selectedBuildId =
        filter[categoryId].children[projectId].children[buildId].children[
          versionId
        ].id;

      dispatch(
        getTestData(categoryId, projectId, selectedBuildId, testCaseId, points)
      );

      setPlottedTestCase([
        ...plottedTestCase,
        {
          category: category,
          project: project,
          build: build + "_" + version,
          testCase: testCaseId,
        },
      ]);
    }
  };

  const incrementHexByX = (hexCode: string, inc: any) => {
    // Convert hex code to decimal
    let decimalValue = parseInt(hexCode.slice(1), 16);

    // Increment by inc
    decimalValue += inc;

    // Ensure the value stays within the valid range (0 to 255 for each color channel)
    decimalValue = Math.min(decimalValue, 255);

    // Convert back to hex code
    let incrementedHexCode = decimalValue.toString(16);

    // Ensure the hex code has two digits for each color channel
    incrementedHexCode = "#" + ("00" + incrementedHexCode).slice(-2);

    return incrementedHexCode;
  };

  useEffect(() => {
    const { series } = dataReducer;
    setSeriesData([...seriesData, series]);
  }, [dataReducer.series]);

  useEffect(() => {
    setTestCasesOptionsData(testCases);
  }, [testCases]);

  //var prevUrl = usePrevious(match);
  useEffect(() => {
    const { category, project, build } = match.params;
    const { filter } = metadataReducer;
    if (Object.keys(filter).length > 0) {
      getCategoryItems(filter);
      intialize_component(category, project, build, filter);
    }
  }, [location, metadataReducer.filter]);

  var maxi_x = 0,
    mini_x = 1000;
  var maxi_y = 0,
    mini_y = 1000;

  const plotMultipleGraphs = () => {
    const graph = [{}];

    const colorHexCodes = [
      "#1f77b4",
      "#ff7f0e",
      "#2ca02c",
      "#d62728",
      "#9467bd",
    ];

    for (let ind = 1; ind < seriesData.length; ind++) {
      const colorsToBeUsed = colorHexCodes.map((hexCode) =>
        incrementHexByX(hexCode, (ind - 1) * 10)
      );
      const graphData = createVisualizationData(
        seriesData[ind],
        colorsToBeUsed,
        ind
      );
      graph.push(...graphData);
    }
    return graph;
  };

  const createVisualizationData = (
    currentSeriesData: any,
    colorHexCodes: any,
    index: any
  ) => {
    var visualization_data = [{}];
    for (let ind = 0; ind < currentSeriesData.length; ind++) {
      const color = colorHexCodes[ind];

      let testCaseName = "";
      if (ind === 1) {
        // Original plot
        const { category, project, build, id } = match.params;
        testCaseName = id;
      } else {
        testCaseName = plottedTestCase[index - 1]["testCase"];
      }

      if (currentSeriesData[ind].type == "line") {
        visualization_data.push({
          x:
            currentSeriesData &&
            currentSeriesData[ind]?.data?.map((val) => {
              if (maxi_x < val[0]) {
                maxi_x = val[0];
              }
              if (mini_x > val[0]) {
                mini_x = val[0];
              }
              return val[0];
            }),
          y:
            currentSeriesData &&
            currentSeriesData[ind]?.data?.map((val) => {
              if (maxi_y < val[1]) {
                maxi_y = val[1];
              }
              if (mini_y > val[1]) {
                mini_y = val[1];
              }
              return val[1];
            }),
          mode: currentSeriesData && currentSeriesData[ind]?.type,
          name:
            currentSeriesData &&
            currentSeriesData[ind]?.name.indexOf("PK+_MAXH") >= 0
              ? currentSeriesData[ind]?.name.split(":")[1] +
                " (" +
                testCaseName +
                ")"
              : currentSeriesData[ind]?.name + " (" + testCaseName + ")",
          line: {
            color: color,
          },
        });
      } else {
        visualization_data.push({
          x:
            currentSeriesData &&
            currentSeriesData[ind]?.data?.map((val) => {
              if (maxi_x < val[0]) {
                maxi_x = val[0];
              }
              if (mini_x > val[0]) {
                mini_x = val[0];
              }
              return val[0];
            }),
          y:
            currentSeriesData &&
            currentSeriesData[ind]?.data?.map((val) => {
              if (maxi_y < val[1]) {
                maxi_y = val[1];
              }
              if (mini_y > val[1]) {
                mini_y = val[1];
              }
              return val[1];
            }),
          mode: "markers",
          name:
            currentSeriesData &&
            currentSeriesData[ind]?.name + " (" + testCaseName + ")",
          marker: {
            color: color,
            size: 8,
            symbol: currentSeriesData && currentSeriesData[ind]?.symbol,
          },
        });
      }
    }
    return visualization_data;
  };

  const defaultXRange = {
    xMin: "",
    xMax: "",
  };
  const defaultYRange = {
    yMin: "",
    yMax: "",
  };
  const [xRange, setXRange] = useState(defaultXRange);
  const [yRange, setYRange] = useState(defaultYRange);

  const [points, setPoints] = useState("2000");

  useEffect(() => {
    window.scrollTo(0, 0);
    const { category, project, build, id } = match.params;
    dispatch(getTestData(category, project, build, id, points));
    dispatch(getSpecificTestCaseData(category, project, build, "EMI", id));

    setPlottedTestCase([
      ...plottedTestCase,
      {
        category: categoryName,
        project: projectName,
        build: buildAndVersion,
        testCase: id,
      },
    ]);
  }, [points]);

  const formatChipLabel = (
    categoryName: string,
    projectName: string,
    buildAndVersion: string,
    testCase: string
  ) => {
    return (
      categoryName + "-" + projectName + "-" + buildAndVersion + "-" + testCase
    );
  };

  const _defaultPointsClick = () => {
    setPoints("2000");
  };

  const _allPointsClick = () => {
    setPoints("all");
  };

  const _clearXRange = () => {
    setXRange(defaultXRange);
  };
  const _clearYRange = () => {
    setYRange(defaultYRange);
  };
  const _onChangeXMin = (event: any) => {
    let xVal = {
      ...xRange,
      xMin: event.detail.value,
    };
    setXRange(xVal);
  };
  const _onChangeXMax = (event: any) => {
    let xVal = {
      ...xRange,
      xMax: event.detail.value,
    };
    setXRange(xVal);
  };
  const _onChangeYMin = (event: any) => {
    let yVal = {
      ...yRange,
      yMin: event.detail.value,
    };
    setYRange(yVal);
  };
  const _onChangeYMax = (event: any) => {
    let yVal = {
      ...yRange,
      yMax: event.detail.value,
    };
    setYRange(yVal);
  };

  const _checkDisabledDefaultPoints = (): any => {
    if (points === "2000") return true;
    return false;
  };

  const _checkDisabledAllPoints = (): any => {
    if (points === "all") return true;
    return false;
  };

  const _checkDisabledXRange = (): any => {
    if (xRange.xMin || xRange.xMax) return false;
    return true;
  };

  const _checkDisabledYRange = (): any => {
    if (yRange.yMin || yRange.yMax) return false;
    return true;
  };

  const onRemoveTestCaseFromPlot = (index: any): any => {
    setPlottedTestCase((prevState) => {
      const updatedPllottedTestCase = [...prevState];
      updatedPllottedTestCase.splice(index + 1, 1);
      return updatedPllottedTestCase;
    });

    setSeriesData((prevState) => {
      const updatedSeriesData = [...prevState];
      updatedSeriesData.splice(index + 1, 1);
      return updatedSeriesData;
    });
  };

  let content: any = null;
  if (loadingStatus === constants.LOADING_LOAD) {
    content = <ContainerSpninner className="visualization-alert-container" />;
  } else if (loadingStatus === constants.LOADING_FAIL) {
    content = <ContainerFail className="visualization-alert-container" />;
  } else if (!series || series.length === 0) {
    content = <ContainerNoGraph className="visualization-alert-container" />;
  } else if (loadingStatus === constants.LOADING_SUCCESS && seriesData) {
    content = (
      <Plot
        useResizeHandler={true}
        data={plotMultipleGraphs()}
        style={{ width: "100%", height: "650px" }}
        layout={{
          title: "Testing Data",
          xaxis: {
            title: "Frequency MHz",
            showgrid: false,
            showline: true,
            ticks: "outside",
            dtick:
              (xRange.xMax != ""
                ? parseInt(xRange.xMax)
                : maxi_x != 0
                ? maxi_x
                : 1000) / 5,
            range: [
              xRange.xMin != "" ? parseInt(xRange.xMin) : 0,
              xRange.xMax != ""
                ? parseInt(xRange.xMax)
                : maxi_x != 0
                ? maxi_x
                : 1000,
            ],
            linecolor: "#f2f3f3",
          },
          yaxis: {
            title: "PK+_MAXH dBμV/m",
            showline: false,
            ticks: "outside",
            range: [
              yRange.yMin != "" ? parseInt(yRange.yMin) : 0,
              yRange.yMax != ""
                ? parseInt(yRange.yMax)
                : maxi_y != 0
                ? maxi_y
                : 60,
            ],
            linecolor: "#f2f3f3",
          },
          displaylogo: false,
        }}
      />
    );
  }

  return (
    <div>
      <BreadcrumbGroup
        textGroup={[
          { text: capitalizeFirstLetter(categoryName) }, // Category
          { text: capitalizeFirstLetter(projectName) }, // project
          { text: buildAndVersion }, // build
          { text: "EMI" },
          { text: "Testing Data" },
        ]}
      />
      <br />
      <Container
        className="visualization-box-fix-height"
        header={<Header variant="h2">Testing Data</Header>}
      >
        <div>
          <ColumnLayout columns={5} minColumnWidth={10}>
            <FormField label="Category">
              <Select
                options={categoryItems}
                placeholder="Category"
                onChange={onChangeCategory}
                selectedOption={{
                  value: categoryId,
                  label: category,
                }}
              ></Select>
            </FormField>
            <FormField label="Project">
              <Select
                options={projectItems}
                placeholder="Project"
                onChange={onChangeProject}
                selectedOption={{
                  value: projectId,
                  label: project,
                }}
              />
            </FormField>
            <FormField label="Build">
              <Select
                options={buildItems}
                placeholder="Build"
                onChange={onChangeBuild}
                selectedOption={{
                  value: buildId,
                  label: build,
                }}
              />
            </FormField>
            <FormField label="Version">
              <Select
                options={versionItems}
                placeholder="Version"
                onChange={onChangeVersion}
                selectedOption={{
                  value: versionId,
                  label: version,
                }}
              />
            </FormField>
            <FormField label="Test Case ID">
              <Select
                options={formatTestCaseIdOptions(testCasesOptionsData)}
                placeholder="Test Case ID"
                onChange={onChangeTestCase}
                selectedOption={{
                  value: testCaseId,
                  label: testCaseId,
                  description: testCase,
                }}
              />
            </FormField>

            <Button onClick={onAddPlotButtonClick}>Add Plot</Button>
          </ColumnLayout>
          <br />

          <div style={{ margin: "8px" }}>
            <Chip
              key={0}
              label={formatChipLabel(
                categoryName,
                projectName,
                buildAndVersion,
                plottedTestCase[0] !== undefined
                  ? plottedTestCase[0]["testCase"]
                  : ""
              )}
              color="primary"
              variant="outlined"
            />

            {plottedTestCase.slice(1).map((testCase, index) => (
              <Chip
                key={index}
                label={formatChipLabel(
                  testCase["category"],
                  testCase["project"],
                  testCase["build"],
                  testCase["testCase"]
                )}
                color="primary"
                variant="outlined"
                onDelete={() => onRemoveTestCaseFromPlot(index)}
              />
            ))}
          </div>
        </div>

        <div className="d-flex justify-content-center">{content}</div>
        <div>
          <ColumnLayout columns={4}>
            <FormField
              label="xMin"
              constraintText="Numbers only"
              // errorText={this.state.categoryError}
            >
              <Input
                value={xRange.xMin.toString()}
                onChange={_onChangeXMin}
              ></Input>
            </FormField>
            <FormField label="xMax" constraintText="Numbers only">
              <Input
                value={xRange.xMax ? xRange.xMax.toString() : ""}
                onChange={_onChangeXMax}
              ></Input>
            </FormField>
            <FormField label="yMin" constraintText="Numbers only">
              <Input
                value={yRange.yMin.toString()}
                onChange={_onChangeYMin}
              ></Input>
            </FormField>
            <FormField label="yMax" constraintText="Numbers only">
              <Input
                value={yRange.yMax ? yRange.yMax.toString() : ""}
                onChange={_onChangeYMax}
              ></Input>
            </FormField>
          </ColumnLayout>

          <ColumnLayout columns={2}>
            <Button
              variant="primary"
              disabled={_checkDisabledXRange()}
              onClick={_clearXRange}
            >
              Clear xRange
            </Button>
            <Button
              variant="primary"
              disabled={_checkDisabledYRange()}
              onClick={_clearYRange}
            >
              Clear yRange
            </Button>
          </ColumnLayout>
          <br />
          <SpaceBetween direction="horizontal" size="xs">
            <Button
              variant="primary"
              disabled={_checkDisabledDefaultPoints()}
              onClick={_defaultPointsClick}
            >
              2000 points (Default)
            </Button>
            <Button
              variant="primary"
              disabled={_checkDisabledAllPoints()}
              onClick={_allPointsClick}
            >
              All points
            </Button>
          </SpaceBetween>
        </div>
        <br />
        <TableTestcase tabledata={testcaseSpecific} loading={loadingStatus} />
      </Container>
      <br />
      {showFailedTable && (
        <TableCriticalData tabledata={failedTable} loading={loadingStatus} />
      )}
      <br />
      <TableFreq
        tableColumn={tableColumn}
        tabledata={tableData}
        loading={loadingStatus}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    dataReducer: state.visualizationEMIReducer,
    metadataReducer: state.metadataReducer,
    buildReducerEMI: state.buildReducerEMI,
  };
};

export default connect<StateProps>(mapStateToProps)(VisualizationEMI);

import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import React from "react";
import { Button } from "@amzn/awsui-components-react-v3/polaris";

export const PAGE_SIZE_OPTIONS = [
  { value: 3, label: "3 items" },
  { value: 5, label: "5 items" },
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

// define columns
export const TESTPLAN_HISTORY_TABLE_COLUMN_DEFINITIONS= (s3Url: string,onDownloadButtonClick ) =>  [
  {
    id: "Project",
    header: "Project",
    cell: (item) => item["Project"],
    sortingField: "Project",
    visible: true,
    minWidth: 150,
  },
  {
    id: "Build",
    header: "Build",
    cell: (item) => item["Build"],
    sortingField: "Build",
    visible: true,
    minWidth: 200,
  },
  {
    id: "Version",
    header: "Version",
    cell: (item) => item["Version"],
    sortingField: "Version",
    visible: true,
    minWidth: 100,
  },
  {
    id: "CreatedBy",
    header: "Created By",
    cell: (item) => item["CreatedBy"],
    sortingField: "CreatedBy",
    visible: true,
    minWidth: 150,
  },
  {
    id: "CreatedTime",
    header: "Created Time",
    cell: (item) => item["CreatedTime"],
    sortingField: "CreatedTime",
    visible: true,
    minWidth: 150,
  },
  {
    id: "URL",
    header: "Download",
    cell: (e) => (
      <Button
        className="button-with-margin"
        iconName="download"
        variant="primary"
        onClick={() => {onDownloadButtonClick(e.URL)}}
        disabled={!e.URL}></Button>
    ),
    minWidth: 150,
    visible: true,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Test Plan History",
    options: [
      {
        id: "Project",
        label: "Project",
        visible: true,
        editable: true,
      },
      {
        id: "Build",
        label: "Build",
        visible: true,
        editable: false,
      },
      {
        id: "Version",
        label: "Version",
        visible: true,
        editable: false,
      },
      {
        id: "CreatedBy",
        label: "Created By",
        visible: true,
        editable: false,
      },
      {
        id: "URL",
        label: "Download",
        visible: true,
        editable: true,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: "Project",
    key: "Project",
    groupValuesLabel: "Project",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Build",
    key: "Build",
    groupValuesLabel: "Build",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Version",
    key: "Version",
    groupValuesLabel: "Version",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Created By",
    key: "CreatedBy",
    groupValuesLabel: "CreatedBy",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Download",
    key: "URL",
    groupValuesLabel: "Download",
    operators: [":", "!:", "=", "!="],
  },
];

// define default preferences
export const DEFAULT_PREFERENCES = (s3Url: string,onDownloadButtonClick) => {
  return {
    pageSize: 5,
    visibleContent: TESTPLAN_HISTORY_TABLE_COLUMN_DEFINITIONS(s3Url ,onDownloadButtonClick).filter(
      (column) => column.visible
    ).map((column) => column.id),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

// define default sorting
export const DEFAULT_SORTING_COLUMN = {
  id: "CreatedTime",
  sortingField: "CreatedTime",
};
export const DEFAULT_SORTING_DESCENDING = true;

const visualizationType = {
  // get metadata
  GET_TESTCASE_SUMMARY: "GET_TESTCASE_SUMMARY",
  GET_TESTCASE_SUMMARY_COMMIT: "GET_TESTCASE_SUMMARY_COMMIT",
  GET_TESTCASE_SUMMARY_ROLLBACK: "GET_TESTCASE_SUMMARY_ROLLBACK",

  // get rawdata
  GET_TESTCASE_RAW_DATA: "GET_TESTCASE_RAW_DATA",
  GET_TESTCASE_RAW_DATA_COMMIT: "GET_TESTCASE_RAW_DATA_COMMIT",
  GET_TESTCASE_RAW_DATA_ROLLBACK: "GET_TESTCASE_RAW_DATA_ROLLBACK",

  RESET_VISUALIZATION: "RESET_VISUALIZATION",
};
  
  export default visualizationType;
const buildConfigTypes = {
  CREATE_BUILD_CONFIG: "CREATE_BUILD_CONFIG",
  CREATE_BUILD_CONFIG_COMMIT: "CREATE_BUILD_CONFIG_COMMIT",
  CREATE_BUILD_CONFIG_ROLLBACK: "CREATE_BUILD_CONFIG_ROLLBACK",

  RESET_BUILD_CONFIG: "RESET_BUILD_CONFIG",

  GET_BUILD_CONFIGS: "GET_BUILD_CONFIGS",
  GET_BUILD_CONFIG_COMMITS: "GET_BUILD_CONFIG_COMMITS",
  GET_BUILD_CONFIG_ROLLBACKS: "GET_BUILD_CONFIG_ROLLBACKS",
};

export default buildConfigTypes;

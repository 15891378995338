import React, { Component } from "react";
import { TEST_STATUS } from "../../../constants";
import { StatusSchema } from "../../../model/overview";

const progressColor = {
  0: "var(--test-status-passed)", //pass
  1: "var(--test-status-failed)", //fail
  2: "var(--test-status-warning)", //warning
  3: "var(--test-status-untested)", //untested
  4: "var(--test-status-no-limit)", // no_limit
};

const progressLabel = ["Pass", "Fail", "Warning", "Untested", "No Limit"];

const progressKey = [
  TEST_STATUS.PASSED,
  TEST_STATUS.FAILED,
  TEST_STATUS.WARNING,
  TEST_STATUS.UNTESTED,
  TEST_STATUS.NO_LIMIT,
];


// declare prop check
type Props = {
    progressData: StatusSchema;
} & typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class DashboardProgressCell extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const { progressData } = this.props;

    return (
      <div
        id={"engineering-landing-progress"}
        style={{
          display: "flex",
          borderRadius: "6px",
          width: "100%",
          height: "15px",
        }}
      >
        {progressKey.map((key, index) => (
          <div
            key={index}
            style={{
              //pass progress
              width: `${
                (progressData.total === 0
                  ? key === "untested"
                    ? 1
                    : 0
                  : progressData[key] / progressData.total) * 100
              }%`,
              height: "100%",
              backgroundColor: progressColor[index],
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
              transition: "all .2s ease-out",
            }}
          ></div>
        ))}
        <span className={"engineering-tooltips"}>
          {progressKey.map((key, index) => (
            <div
              key={index}
              style={{
                paddingRight: "5px",
                display:
                  progressData[key] !== 0 ||
                  (progressData[key] === 0 &&
                    key === "untested" &&
                    progressData.total === 0)
                    ? "block"
                    : "none",
              }}
            >
              <span
                style={{
                  height: "100%",
                  borderRadius: "16px",
                  padding: "2px",
                  color: progressColor[index],
                  fontSize: "12px",
                  fontWeight: "bold",
                  transition: "all .2s ease-out",
                }}
              >
                {progressLabel[index]}
              </span>
              <span style={{ fontSize: "12px" }}>
                :{" "}
                {(
                  (progressData.total === 0
                    ? 1
                    : progressData[key] / progressData.total) * 100
                ).toFixed(2) + "%"}
              </span>
            </div>
          ))}
        </span>
      </div>
    );
  }
}

export default DashboardProgressCell;
import React, { Component } from "react";
import { connect } from "react-redux";
import { config } from "../../config/config";

class Analytics extends Component {
  render() {
    return (
      <div>
        <iframe
          sandbox="allow-scripts allow-same-origin"
          title="Analytics"
          src={config.ANALYTICS_DASHBOARD}
          height="1250"
          width="1460"
        ></iframe>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    environmentReducer: state.environmentReducer,
  };
};
export default connect(mapStateToProps)(Analytics);

import visualizationType from "../types/visualization-type";
import constants from "../../constants";

interface state {
  loadingStatus: number;
  rawDataLoadingStatus: number;
  data: Array<any>;
  configData: Array<any>;
  finalPowerData: Array<any>;
  front_end_fields: Array<any>;
  rawData: Array<any>;
  rawDataType: string;
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  rawDataLoadingStatus: constants.LOADING_DEFAULT,
  data: [],
  configData: [],
  finalPowerData: [],
  front_end_fields: [],
  rawData: [],
  rawDataType: "",
};

const visualizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case visualizationType.RESET_VISUALIZATION:
      return {
        ...state,
        rawDataLoadingStatus: constants.LOADING_DEFAULT,
        rawData: [],
        rawDataType: "",
      };
    case visualizationType.GET_TESTCASE_SUMMARY:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case visualizationType.GET_TESTCASE_SUMMARY_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        front_end_fields:
          action.payload &&
          action.payload.data &&
          Array.isArray(action.payload.data.front_end_fields)
            ? action.payload.data.front_end_fields
            : [],
        data:
          action.payload &&
          action.payload.data &&
          action.payload.data.data &&
          Array.isArray(action.payload.data.data)
            ? action.payload.data.data
            : [],
        configData:
          action.payload && 
          action.payload.data &&
          action.payload.data.config_data &&
          Array.isArray(action.payload.data.config_data)
          ? action.payload.data.config_data
          : [],
        finalPowerData:
          action.payload && 
          action.payload.data &&
          action.payload.data.final_power_data &&
          Array.isArray(action.payload.data.final_power_data)
          ? action.payload.data.final_power_data
          : [],
      };
    case visualizationType.GET_TESTCASE_SUMMARY_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case visualizationType.GET_TESTCASE_RAW_DATA:
      return {
        ...state,
        rawDataLoadingStatus: constants.LOADING_LOAD,
      };
    case visualizationType.GET_TESTCASE_RAW_DATA_COMMIT:
      return {
        ...state,
        rawDataLoadingStatus: constants.LOADING_SUCCESS,
        rawDataType:
          action.payload && action.payload.data && action.payload.data.data_type
            ? action.payload.data.data_type
            : "",
        rawData:
          action.payload &&
          action.payload.data &&
          action.payload.data.data &&
          Array.isArray(action.payload.data.data)
            ? getRawData(action.payload.data.data)
            : [],
      };
    case visualizationType.GET_TESTCASE_RAW_DATA_ROLLBACK:
      return {
        ...state,
        rawDataLoadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

const getRawData = (data) => {
  data.forEach(element => {
    element.line = {dash: "line"}
    element.hovertemplate = element.name + ": %{y:,.2f}<br>" + "%{xaxis.title.text}: %{x:,.2f}<br>"
  });
  return data
}

export default visualizationReducer;


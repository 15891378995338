import * as React from "react";
import { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Navbar as LENSNavbar } from "@amzn/lens-react-ui";
import { menuItems, helpItem, analyticsItem } from "./navbar-config";
import { username } from "../../cognito-auth/session";

type Props = RouteComponentProps<any>;

class Navbar extends Component<Props> {
  render() {
    return (
      <LENSNavbar
        title="LENS Compliance"
        username={username}
        menuItems={menuItems}
        analytics={analyticsItem}
        help={helpItem}
        routerHistory={this.props.history}
      />
    );
  }
}

export default withRouter(Navbar);

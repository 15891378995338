import createBuildTypes from "../types/build-type";
import constants from '../../constants';

const initialState = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
};

const createBuildReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case createBuildTypes.RESET_BUILD:
      return initialState    
    case createBuildTypes.CREATE_BUILD:
      return {
          ...state,
          loadingStatus: constants.LOADING_LOAD
      };
    case createBuildTypes.CREATE_BUILD_COMMIT:
      return {
          ...state,
          loadingStatus: constants.LOADING_SUCCESS,
          message: action.payload.message
      };
    case createBuildTypes.CREATE_BUILD_ROLLBACK:
      return {
          ...state,
          loadingStatus: constants.LOADING_FAIL,
          message: action.payload.message
      };
    default:
      return state;
  }
}

export default createBuildReducer;
import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import Plot from "react-plotly.js";
import {
  ContainerFail,
  ContainerNoGraph,
  ContainerSpninner,
} from "../../components/container-status-indicator";
import constants from "../../constants";
import { Container } from "@amzn/awsui-components-react-v3";
import { Header } from "@amzn/awsui-components-react-v3/polaris";
import "./visualization.css";

interface StateProps {}

type State = {};

type Props = {
  dispatch: Dispatch<any>;
  xMin: Number | null;
  xMax: Number | null;
  yMin: Number | null;
  yMax: Number | null;
  rawData: Array<any>;
  rawDataType: string;
  rawDataLoadingStatus: Number;
  selectedPoints: Array<any>;
  updateSelectedPoints: any;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});

const OFFSET = 2;

class VisualizationChart extends Component<Props, State> {
  componentDidMount() {}

  _getlimitXMin = (data) => {
    let mn = Number.MAX_VALUE;
    data.forEach((row) => {
      row.x.forEach((point) => {
        mn = Math.min(mn, Number(point));
      });
    });
    return mn - OFFSET;
  };

  _getlimitXMax = (data) => {
    let mx = Number.MIN_VALUE;
    data.forEach((row) => {
      row.x.forEach((point) => {
        mx = Math.max(mx, Number(point));
      });
    });
    return mx + OFFSET;
  };

  _getlimitYMin = (data) => {
    let mn = Number.MAX_VALUE;
    data.forEach((row) => {
      row.y.forEach((point) => {
        mn = Math.min(mn, Number(point));
      });
    });
    return mn - OFFSET;
  };

  _getlimitYMax = (data) => {
    let mx = Number.MIN_VALUE;
    data.forEach((row) => {
      row.y.forEach((point) => {
        mx = Math.max(mx, Number(point));
      });
    });
    return mx + OFFSET;
  };
  onClickChart = (e: any) => {
    const {
      rawData,
      rawDataLoadingStatus,
      selectedPoints,
      updateSelectedPoints,
    } = this.props;
    if (
      e &&
      e.points &&
      Array.isArray(e.points) &&
      e.points.length > 0 &&
      rawDataLoadingStatus == constants.LOADING_SUCCESS &&
      rawData.length > 0
    ) {
      let x = e.points[0].x;
      let indexFound = false;
      let index;
      for (index = 0; index < rawData[0].x.length; index++) {
        if (rawData[0].x[index] == x) {
          indexFound = true;
          break;
        }
      }
      if (indexFound) {
        let pointsObject = {
          frequency: x,
        };
        rawData.forEach((element) => {
          pointsObject[element.name] =
            element.y.length >= index ? element.y[index] : null;
        });
        let updatedSelectedPoints = [...selectedPoints, pointsObject];
        updateSelectedPoints(updatedSelectedPoints);
      }
    }
  };

  getDataToShowOnGraph = (rawData: Array<any>) => {
    let dataToDisplay: Array<any> = [];
    rawData.forEach((element) => {
      if (element["show_on_graph"]) {
        dataToDisplay = [...dataToDisplay, element];
      }
    });
    return dataToDisplay;
  };

  render() {
    const { rawData, rawDataType, rawDataLoadingStatus } = this.props;
    let layout = {
      title: {
        text: rawDataType,
        font: {
          family: "Arial",
          size: 17,
        },
      },
      xaxis: {
        title: { text: "frequency" },
        range: [
          this.props.xMin ? this.props.xMin : this._getlimitXMin(rawData),
          this.props.xMax ? this.props.xMax : this._getlimitXMax(rawData),
        ],
      },
      yaxis: {
        title: { text: "power" },
        range: [
          this.props.yMin ? this.props.yMin : this._getlimitYMin(rawData),
          this.props.yMax ? this.props.yMax : this._getlimitYMax(rawData),
        ],
      },
      hovermode: "closest",
      hoverlabel: { bgcolor: "#FFF" },
    };
    let content: any = null;
    if (rawDataLoadingStatus === constants.LOADING_LOAD) {
      content = <ContainerSpninner className="visualization-alert-container" />;
    } else if (rawDataLoadingStatus === constants.LOADING_FAIL) {
      content = <ContainerFail className="visualization-alert-container" />;
    } else if (!rawData || rawData.length === 0) {
      content = <ContainerNoGraph className="visualization-alert-container" />;
    } else if (rawDataLoadingStatus === constants.LOADING_SUCCESS && rawData) {
      content = (
        <Plot
          onClick={this.onClickChart}
          data={this.getDataToShowOnGraph(rawData)}
          layout={layout}
          style={{ width: "100%", height: "650px" }}
          config={{
            responsive: true,
            modeBarButtonsToRemove: [
              "zoom2d",
              "pan2d",
              "select2d",
              "lasso2d",
              "zoomIn2d",
              "zoomOut2d",
              "autoScale2d",
              "toggleHover",
              "toggleSpikelines",
              "hoverCompareCartesian",
              "hoverClosestCartesian",
            ],
            displayModeBar: true,
            displaylogo: false,
          }}
        />
      );
    }

    return <div className="d-flex justify-content-center">{content}</div>;
  }
}
const mapStateToProps = (state) => {
  return {};
};

export default connect<StateProps>(mapStateToProps)(VisualizationChart);

import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  Input,
  Textarea,
  Alert,
} from "@amzn/awsui-components-react/polaris";

import {
  Modal,
  Box,
  Button as AlertButton,
  SpaceBetween,
  FormField,
} from "@amzn/awsui-components-react-v3/polaris";
import "../create-form.css";
import constants, { COMPONENT_ID } from "../../../constants";
import {
  createCategory,
  resetCategory,
} from "../../../redux/actions/category-action";
import { getMetadata } from "../../../redux/actions/metadata-action";
import { createCategoryRequest } from "../../../model/http-json";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category: string; // user input for creating category
  comment: string; // user input
  categoryError: string; // input error
  commentError: string; //input error
  responseError: string; // response error from BE
};

class CategoryForm extends Component<Props, State> {
  state: State = Object.freeze({
    category: "",
    comment: "",
    categoryError: "",
    commentError: "",
    responseError: "",
  });

  //validate input
  validate = (id: string) => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      const category = this.state.category;
      let categoryError: string = "";

      if (!category) {
        categoryError = "Required";
      } else if (!/^[A-Za-z]+$/i.test(category)) {
        categoryError = "Letters only";
      }

      this.setState({
        categoryError,
      });

      if (categoryError) {
        isValid = false;
      }
    }

    // validate comment
    if (id === COMPONENT_ID.COMMENT) {
      const comment = this.state.comment;
      let commentError: string = "";

      if (comment && comment.length > 200) {
        commentError = "Max Length 200";
      }

      this.setState({
        commentError,
      });

      if (commentError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue creating project or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValidCategory = this.validate(COMPONENT_ID.CATEGORY);
    const isValidComment = this.validate(COMPONENT_ID.COMMENT);
    if (!isValidCategory || !isValidComment) {
      return;
    }

    const category: string = this.state.category;
    const comment: string = this.state.comment;
    const values: createCategoryRequest = {
      name: category,
      comment: comment,
    };
    this.props.dispatch(createCategory(values));
  };

  clear = () => {
    this.setState({
      category: "",
      comment: "",
      categoryError: "",
      responseError: "",
    });
  };

  reset = (success: boolean) => {
    // update side bar data
    this.props.dispatch(getMetadata());
    // reset reducer
    this.props.dispatch(resetCategory());
    if (success) this.clear();
  };

  nextForm = (success: boolean) => {
    const category: string = this.state.category;
    // update side bar data
    this.props.dispatch(getMetadata());
    // reset reducer
    this.props.dispatch(resetCategory());
    if (success) this.clear();
    // navigate to create project
    // this.props.history.push("/project/create");
  };

  _onChangeCategory = (event: any) => {
    const category: string = event.detail.value;
    this.setState({
      category,
    });

    // validate user input
    const id: string = event.target.id;
    this.validate(id);
  };

  _onChangeComment = (event: any) => {
    const comment: string = event.detail.value;
    this.setState({
      comment,
    });
    // validate user input
    const id: string = event.target.id;
    this.validate(id);
  };

  render() {
    const { message, loadingStatus, responseData } = this.props.categoryReducer;
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Category</h2>
        </div>
        <div>
          {/* main form portion */}
          <ColumnLayout>
            <div data-awsui-column-layout-root="true">
              <FormField
                label="Category"
                description="* Required"
                errorText={this.state.categoryError}
                constraintText="Letters only"
              >
                <div className="create-form-input">
                  <Input
                    id={COMPONENT_ID.CATEGORY}
                    placeholder="Enter category name"
                    value={this.state.category}
                    onChange={this._onChangeCategory}
                  ></Input>
                </div>
              </FormField>
              <FormField
                label="Comment"
                constraintText="Max Length 200"
                errorText={this.state.commentError}
              >
                <div className="create-form-input">
                  <Textarea
                    id={COMPONENT_ID.COMMENT}
                    value={this.state.comment}
                    onChange={this._onChangeComment}
                  ></Textarea>
                </div>
              </FormField>
            </div>
          </ColumnLayout>
          <br />

          {this.state.responseError && (
            <React.Fragment>
              <Alert
                header="Errors Detected"
                type="error"
                buttonText={this.state.responseError}
              ></Alert>
              <br />
            </React.Fragment>
          )}
          <br />
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              loading={loadingStatus === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
        <Modal
          visible={loadingStatus === constants.LOADING_SUCCESS}
          onDismiss={() => this.reset(true)}
          header="Success"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <AlertButton variant="link" onClick={() => this.reset(true)}>
                  Cancel
                </AlertButton>
                <AlertButton
                  variant="primary"
                  onClick={() => this.nextForm(true)}
                >
                  Ok
                </AlertButton>
              </SpaceBetween>
            </Box>
          }
        >
          {`${message}.`}
        </Modal>
        <Modal
          visible={loadingStatus === constants.LOADING_FAIL}
          onDismiss={() => this.reset(false)}
          header="Failure"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <AlertButton
                  variant="primary"
                  onClick={() => this.reset(false)}
                >
                  Ok
                </AlertButton>
              </SpaceBetween>
            </Box>
          }
        >
          {message}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(CategoryForm);

import { createBuildConfigRequest } from "../../model/http-json";
import buildConfigTypes from "../types/build-config-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const createBuildConfig = (
  category_id: string,
  project_id: string,
  build_id: string,
  values: createBuildConfigRequest
): any => ({
  type: buildConfigTypes.CREATE_BUILD_CONFIG,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.buildConfigEndpoint(category_id, project_id, build_id),
        method: "POST",
        json: values,
      },
      commit: {
        type: buildConfigTypes.CREATE_BUILD_CONFIG_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
      rollback: {
        type: buildConfigTypes.CREATE_BUILD_CONFIG_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
    },
  },
});

export const resetCreateBuildConfig = () => ({
  type: buildConfigTypes.RESET_BUILD_CONFIG,
});

export const getBuildConfigs = (
  category_id: string,
  project_id: string,
  build_id: string
) => ({
  type: buildConfigTypes.GET_BUILD_CONFIGS,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.buildConfigEndpoint(category_id, project_id, build_id),
        method: "GET",
      },
      commit: {
        type: buildConfigTypes.GET_BUILD_CONFIG_COMMITS,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: buildConfigTypes.GET_BUILD_CONFIG_ROLLBACKS,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

import React from "react";

export const defaultPageSize = 10;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "band",
    header: "Band",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "band";
      const ascending = !sortState.sortingDescending;
      return `Band, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.band),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.channel),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "frequency",
    header: "Frequency",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "frequency";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.frequency),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "data_rate",
    header: "Data Rate",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "data_rate";
      const ascending = !sortState.sortingDescending;
      return `Data Rate, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.data_rate),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna",
    header: "Antenna",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna";
      const ascending = !sortState.sortingDescending;
      return `Data Rate, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.antenna),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "country",
    header: "Country",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "country";
      const ascending = !sortState.sortingDescending;
      return `Country, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.country.name),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "target_power_setting",
    header: "Target Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "target_power_setting";
      const ascending = !sortState.sortingDescending;
      return `Target Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.target_power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "minimum_power_setting",
    header: "Minimum Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "minimum_power_setting";
      const ascending = !sortState.sortingDescending;
      return `Minimum Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.minimum_power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "follow_country",
    header: "Follow Country",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "follow_country";
      const ascending = !sortState.sortingDescending;
      return `Follow Country, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.follow_country),
    minWidth: "100px",
    visible: true,
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "band";

// define sortable columns
export const sortableColumns = [
  { id: "band", field: "band" },
  { id: "channel", field: "channel" },
  { id: "frequency", field: "frequency" },
  { id: "data_rate", field: "data_rate" },
  { id: "antenna", field: "antenna" },
  { id: "country", field: "country" },
  { id: "target_power_setting", field: "target_power_setting" },
  { id: "minimum_power_setting", field: "minimum_power_setting" },
  { id: "follow_country", field: "follow_country" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "band", label: "Band", visible: true, editable: false },
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "frequency", label: "Frequency", visible: true, editable: false },
      { id: "data_rate", label: "Data Rate", visible: true, editable: false },
      { id: "antenna", label: "Antenna", visible: true, editable: false },
      { id: "country", label: "Country", visible: true, editable: false },
      { id: "target_power_setting", label: "Target Power Setting", visible: true },
      { id: "minimum_power_setting", label: "Minimum Power Setting", visible: true },
      {
        id: "follow_country",
        label: "Follow Country",
        visible: true,
      },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Band",
    propertyKey: "band",
    propertyLabel: "Band",
    values: [],
  },
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Frequency",
    propertyKey: "frequency",
    propertyLabel: "Frequency",
    values: [],
  },
  {
    groupValuesLabel: "Data Rate",
    propertyKey: "data_rate",
    propertyLabel: "Data Rate",
    values: [],
  },
  {
    groupValuesLabel: "Antenna",
    propertyKey: "antenna",
    propertyLabel: "Antenna",
    values: [],
  },
  {
    groupValuesLabel: "Country",
    propertyKey: "country",
    propertyLabel: "Country",
    values: [],
  },
];

export const features = ["propertyFiltering", "pagination", "sorting"];

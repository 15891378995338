import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { TEST_STATUS } from "../../../../constants";
import constants from "../../../../constants";
import { Button } from "@amzn/awsui-components-react-v3/polaris";
import {
  TEST_STATUS_COLOR,
  TEST_STATUS_TEXT_COLOR,
} from "../../../../commons/color-config";
import "../charts.css";

const SingleProgress = (props) => {
  // init tooltip
  // https://getbootstrap.com/docs/4.5/components/tooltips/
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  const { text, data, showPercentage, onSelectBuildConfig } = props;
  const { total } = data;
  // percentage dict for each test status
  const percentages = {
    [TEST_STATUS.UNTESTED]:
      total !== 0 && data[TEST_STATUS.UNTESTED]
        ? ((data[TEST_STATUS.UNTESTED] / total) * 100).toFixed(2)
        : "0",
    [TEST_STATUS.PASSED]:
      total !== 0 && data[TEST_STATUS.PASSED]
        ? ((data[TEST_STATUS.PASSED] / total) * 100).toFixed(2)
        : "0",
    [TEST_STATUS.FAILED]:
      total !== 0 && data[TEST_STATUS.FAILED]
        ? ((data[TEST_STATUS.FAILED] / total) * 100).toFixed(2)
        : "0",
    [TEST_STATUS.WARNING]:
      total !== 0 && data[TEST_STATUS.WARNING]
        ? ((data[TEST_STATUS.WARNING] / total) * 100).toFixed(2)
        : "0",
    [TEST_STATUS.NO_LIMIT]:
      total !== 0 && data[TEST_STATUS.NO_LIMIT]
        ? ((data[TEST_STATUS.NO_LIMIT] / total) * 100).toFixed(2)
        : "0",
  };

  return (
    <div className="d-flex align-items-center">
      {/* text */}
      <div style={{ width: "30%" }}>
        <span
          className="config-button"
          onClick={() => {
            onSelectBuildConfig(
              data["build_config_id"],
              data["build_config"],
              data["total"]
            );
          }}
        >
          {typeof text === "string" ? text : constants.NA}
        </span>
        &nbsp;
        <span className="text-secondary">{`(${total})`}</span>
      </div>

      {/* progress bar */}
      <div className="progress" style={{ width: "100%", height: "25px" }}>
        {Object.keys(TEST_STATUS).map((key, index) => {
          const testStatus = TEST_STATUS[key];
          if (
            testStatus === TEST_STATUS.PASSED ||
            testStatus === TEST_STATUS.UNTESTED ||
            testStatus === TEST_STATUS.FAILED
          ) {
            return (
              <div
                key={index}
                className="progress-bar"
                role="progressbar"
                style={{
                  width: `${percentages[testStatus]}%`,
                  backgroundColor: TEST_STATUS_COLOR[testStatus],
                  color: TEST_STATUS_TEXT_COLOR[testStatus],
                }}
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
                data-toggle="tooltip"
                title={`${data[testStatus]} ${testStatus} (${percentages[testStatus]}%)`}
              >
                {showPercentage
                  ? `${percentages[testStatus]}%`
                  : data[testStatus]}
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SingleProgress;

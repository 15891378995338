import React, { Component } from "react";
import { Table, TableSelection } from "@amzn/awsui-components-react/polaris";

import { columnDefinitions } from "./table-config";

type State = typeof initialState;

const initialState = Object.freeze({});

type Props = {
  data: any;
  testcaseTableData: any;
  selectedItems: Array<any>;
  setSelectedItems: any;
} & typeof defaultProps;

const defaultProps = Object.freeze({});

class TestcaseOptionsTable extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  _onSelectionChange = (event): any => {
    const { selectedItems } = event.detail;
    this.props.setSelectedItems(selectedItems);
  };

  _isItemDisabled = (item): any => {
    const item_identifier =
      item.region + "_" + item.rule_id + "_" + item.antenna;
    const { testcaseTableData } = this.props;
    let isDisabled = false;
    testcaseTableData.forEach((testcase) => {
      const testcase_identifier =
        testcase.region + "_" + testcase.rule_id + "_" + testcase.antenna;
      if (item_identifier === testcase_identifier) isDisabled = true;
    });
    return isDisabled;
  };

  render() {
    const { data, selectedItems } = this.props;
    return (
      <Table
        columnDefinitions={columnDefinitions}
        items={data}
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
      >
        <TableSelection
          selectedItems={selectedItems}
          onSelectionChange={this._onSelectionChange}
          keepSelection={true}
          isItemDisabled={this._isItemDisabled}
        ></TableSelection>
      </Table>
    );
  }
}

export default TestcaseOptionsTable;

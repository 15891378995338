import overviewType from "../types/overview-type";
import constants from "../../constants";
import { TestcaseOverviewResponse } from "../../model/http-json";
import { changeDateFormat } from "../../utils/date-utils";


interface state {
  testcaseMetadata: TestcaseOverviewResponse,
  testcaseTableData: Array<any>,
  testcaseDataLoadingStatus: number,
  loadingUpdateBuildStatus: number,
  updateBuildStatusMessage: string
}


const initialState: state = {
  testcaseMetadata:[] ,
  testcaseTableData: [],
  testcaseDataLoadingStatus: constants.LOADING_DEFAULT,
  loadingUpdateBuildStatus: constants.LOADING_DEFAULT,
  updateBuildStatusMessage: "",
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case overviewType.RESET_OVERVIEW:
      return initialState;
    case overviewType.GET_TCS_ALL:
      return {
        ...state,
        testcaseDataLoadingStatus: constants.LOADING_LOAD,
      };
    case overviewType.GET_TCS_ALL_COMMIT:
      return {
        ...state,
        testcaseDataLoadingStatus: constants.LOADING_SUCCESS,
        testcaseMetaData: Array.isArray(action.payload.data)
          ? action.payload.data
          : [],
        testcaseTableData: Array.isArray(action.payload.data)
          ? generateTableData(action.payload.data)
          : [],
      };
    case overviewType.GET_TCS_ALL_ROLLBACK:
      return {
        ...state,
        testcaseDataLoadingStatus: constants.LOADING_FAIL,
      };
      case overviewType.UPDATE_BUILD_STATUS:
        return {
            ...state,
            loadingUpdateBuildStatus: constants.LOADING_LOAD,
        }
      case overviewType.UPDATE_BUILD_STATUS_COMMIT:
        return {
            ...state,
            loadingUpdateBuildStatus: constants.LOADING_SUCCESS,
            updateBuildStatusMessage: action.payload.message
        }
      case overviewType.UPDATE_BUILD_STATUS_ROLLBACK:
        return {
          ...state,
          loadingUpdateBuildStatus: constants.LOADING_FAIL,
          updateBuildStatusMessage: action.payload.message
        }
      case overviewType.RESET_UPDATE_BUILD_STATUS:
        return {
          ...state,
          loadingUpdateBuildStatus: constants.LOADING_DEFAULT,
          updateBuildMessage: ""
        }
    default:
      return state;
  }
};

export default overviewReducer;

const generateTableData = (data: Array<{ [key: string]: any }>) => {
    let testcaseTableData: Array<any> = []
    data.forEach((testcase) => {
        const create_time = changeDateFormat(testcase.create_time);
        const update_time = changeDateFormat(testcase.update_time);
        const tableItem = {
            technology: testcase.tech_spec.technology.name,
            technology_id: testcase.tech_spec.technology.id,
            specification: testcase.tech_spec.name,
            specification_id: testcase.tech_spec.technology_id,
            testcase_id: testcase.id,
            band: testcase.band,
            channel: testcase.channel,
            frequency: testcase.frequency,
            data_rate: testcase.data_rate,
            antenna: testcase.antenna,
            country: testcase.country.name,
            country_id: testcase.country.id,
            status: testcase.lab_testing_status,
            internal_status: testcase.compliance_engineer_testing_status,
            target_power_setting: testcase.target_power_setting,
            minimum_power_setting: testcase.minimum_power_setting,
            power_setting: testcase.power_setting,
            antenna_gain: testcase.antenna_gain,
            avg_power: testcase.avg_power,
            eirp_avg_power: testcase.eirp_avg_power,
            tuneup_eirp: testcase.tuneup_eirp,
            tuneup_avg_power: testcase.tuneup_avg_power,
            eirp_limit: testcase.eirp_limit,
            eirp_psd_limit: testcase.eirp_psd_limit,
            power_follow_country: testcase.follow_country,
            create_time,
            update_time,
            publish_status: testcase.publish_status,
        }
        testcaseTableData.push(tableItem);
    })
    return testcaseTableData;
}
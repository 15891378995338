
import Auth from "@aws-amplify/auth";
import Hub from "@aws-amplify/auth";
import { config } from "../config/config";
import constants from "constants";
import { authZCall } from "../redux/actions/authz-action";
import store from "../redux/store";
export let username = "";

/**
 * Redirects to the Cognito login page with the appropriate redirect URL.
 */
function redirectToLoginPage() {
  Auth.configure(config.AMPLIFY_CONFIG.oauth);
  Auth.federatedSignIn({
    provider: "LASSO",
    // use custom state to pass redirect url after sign in
    customState: window.location.href,
  });
  }
  
  /**
   * Starts the session timer and dispatches events when session expiration
   * is approaching, as well as when session HAS expired. To determine when
   * the session will expire we use the "auth_time" + 24 hours which is when
   * the refresh token will expire (1 day).
   */
  async function startSessionTimer() {
    // Values are all in seconds.
    const fiveMinutes = 60 * 5;
    const oneDay = 86400;
    const refreshTokenValidity = config.REFRESH_TOKEN_VALIDITY * oneDay;
    // Will be used to suppress further SESSION_WILL_EXPIRE events after initial event is dispatched.
    let sessionWillExpireNotice = false;
    try {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken();
      const expiresTimestamp = accessToken.getIssuedAt() + refreshTokenValidity;
      setInterval(() => {
        const currentTimestamp = new Date().getTime() / 1000;
        const timeRemaining = expiresTimestamp - currentTimestamp;
        if (!sessionWillExpireNotice && timeRemaining < fiveMinutes) {
          sessionWillExpireNotice = true;
          Hub.dispatch(constants.SESSION_WILL_EXPIRE, {
            event: constants.SESSION_WILL_EXPIRE,
          });
        } else if (timeRemaining <= 0) {
          Hub.dispatch(constants.SESSION_EXPIRED, {
            event: constants.SESSION_EXPIRED,
          });
        }
      }, constants.SESSION_TIMER_INTERVAL);
    } catch (e) {
      Hub.dispatch(constants.SESSION_EXPIRED, {
        event: constants.SESSION_EXPIRED,
      });
    }
  }
  
  /**
   * Retrieves and returns the jwtToken for the currently authenticated user.
   *
   * @async
   * @returns {String} accessToken
   * Cognito jwtToken of currently authenticated user.
   */
  async function getAccessToken() {
    let accessToken = "";
  
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        accessToken = user.signInUserSession.idToken.jwtToken;
      });
    } catch (ex) {
      // This had to be added because Amplify doesn't catch the exception, this means
      // that the user is not logged in, there is other code that redirects to login page.
    }
  
    return accessToken;
  }
  
  /**
   * Determines if access tokens exist (jwtToken) so that the session can be initiated.
   *
   * @async
   * @returns {Boolean} hasAccessTokens
   * Returns true if current user has access tokens already; false otherwise.
   */
  async function hasAccessTokens() {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (e) {
      return false;
    }
  
    return true;
  }
  
  /**
   * Subscribes to auth messages to handle sign in and session expiration. Also starts the session timer.
   *
   * @param {Object} sessionHandlers
   *
   * @param {Function} [sessionHandlers.onSessionExpired]
   * Invoked when session expires to handle any necessary clean up and/or user messaging.
   *
   * @param {Function} [sessionHandlers.onSessionWillExpire]
   * Invoked 5 minutes before session is set to expire to inform user that they should save any changes and reauthenticate.
   *
   * @param {Function} [sessionHandlers.onUserProfileChange]
   * Invoked when current authenticated user is resolved to update the global userProfile object in the Redux store.
   */
  async function initializeSession(
    onSessionExpired,
    onSessionWillExpire,
    onUserProfileChange
  ) {
    await Auth.currentAuthenticatedUser().then(onUserProfileChange);
  
    Hub.listen(constants.SESSION_EXPIRED, onSessionExpired);
    Hub.listen(constants.SESSION_WILL_EXPIRE, () => onSessionWillExpire(true));
  
    setTimeout(startSessionTimer, constants.SESSION_TIMER_INTERVAL);
  }
  
  /**
   * Signs the user out and redirects back to the Cognito sign in page.
   *
   * @async
   */
  async function signOut() {
    await Auth.signOut();
  }
  
  export {
    // startSessionTimer,
    hasAccessTokens,
    initializeSession,
    getAccessToken,
    redirectToLoginPage,
    signOut,
  };
  
  async function getUserInfo() {
    let userInfo = null;
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        userInfo = user.getSignInUserSession().getIdToken().decodePayload();
      });
    } catch (e) {
      // This had to be added because Amplify doesn't catch the exception, this means
      // that the user is not logged in, there is other code that redirects to login page.
      console.log("login fail");
    }
    if (userInfo) {
      // Keeping AmazonFederate for prod site, As response schema changed for lasso
      // handleing with if else condition. Once we are done congnito migration for prod.
      // We can remove AmazonFederate condition
      if(userInfo.identities[0].providerName === "LASSO")
        username = userInfo["custom:username"];
      // else condition handaling AmzonFederate provider
      else
        username = userInfo.identities[0].userId;
      let bindleType = "normal";
      // authz call
      store.dispatch(authZCall(username, bindleType));
    }
    console.log(userInfo)
    return userInfo;
  }
  
  export { getUserInfo };
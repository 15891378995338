import React from "react";
import { TEST_STATUS_COLOR } from "../../../commons/color-config";
import { UPDATE_TESTCASE, TEST_STATUS } from "../../../constants";
import { NavLink } from "react-router-dom";

export const defaultPageSize = 5;

// define columns
export const columnDefinitions = (category, project, build, buildConfigId) => [
  {
    id: "technology",
    header: "Technology",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.technology),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "specification",
    header: "Specification",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "specification";
      const ascending = !sortState.sortingDescending;
      return `Specification, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.specification),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "testcase_id",
    header: "Testcase ID",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "testcase_id";
      const ascending = !sortState.sortingDescending;
      return `Testcase ID, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) =>
      getNavLink(
        item.status,
        item.testcase_id,
        category,
        project,
        build,
        buildConfigId
      ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "band",
    header: "Band",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "band";
      const ascending = !sortState.sortingDescending;
      return `Band, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.band),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "channel",
    header: "Channel",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "channel";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.channel),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "frequency",
    header: "Frequency",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "frequency";
      const ascending = !sortState.sortingDescending;
      return `Channel, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.frequency),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "data_rate",
    header: "Data Rate",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "data_rate";
      const ascending = !sortState.sortingDescending;
      return `Data Rate, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.data_rate),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna",
    header: "Antenna",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna";
      const ascending = !sortState.sortingDescending;
      return `Data Rate, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.antenna),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "country",
    header: "Country",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "country";
      const ascending = !sortState.sortingDescending;
      return `Country, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.country),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <div
        style={{
          color: `${
            item.status in TEST_STATUS_COLOR
              ? TEST_STATUS_COLOR[item.status]
              : `black`
          }`,
          width: "100%",
          textAlign: "center",
        }}
      >
        {item.status}
      </div>
    ),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "internal_status",
    header: "Internal Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "internal_status";
      const ascending = !sortState.sortingDescending;
      return `Internal Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => (
      <div
        style={{
          color: `${
            item.status in TEST_STATUS_COLOR
              ? TEST_STATUS_COLOR[item.internal_status]
              : `black`
          }`,
          width: "100%",
          textAlign: "center",
        }}
      >
        {item.internal_status}
      </div>
    ),
    minWidth: "100px",
    visible: false,
  },
  {
    id: "target_power_setting",
    header: "Target Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "target_power_setting";
      const ascending = !sortState.sortingDescending;
      return `Target Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.target_power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "minimum_power_setting",
    header: "Minimum Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "minimum_power_setting";
      const ascending = !sortState.sortingDescending;
      return `Minimum Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.minimum_power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "power_setting",
    header: "Final Power Setting",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "power_setting";
      const ascending = !sortState.sortingDescending;
      return `Final Power Setting, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.power_setting),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna_gain",
    header: "Antenna Gain",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "antenna_gain";
      const ascending = !sortState.sortingDescending;
      return `Antenna Gain, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.antenna_gain),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "avg_power",
    header: "Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "avg_power";
      const ascending = !sortState.sortingDescending;
      return `Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tuneup_avg_power",
    header: "Tuneup Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "tuneup_avg_power";
      const ascending = !sortState.sortingDescending;
      return `Tuneup Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.tuneup_avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_avg_power",
    header: "EIRP Avg Power",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_avg_power";
      const ascending = !sortState.sortingDescending;
      return `EIRP Avg Power, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_avg_power),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "tuneup_eirp",
    header: "Tuneup EIRP",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "tuneup_eirp";
      const ascending = !sortState.sortingDescending;
      return `Tuneup EIRP, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.tuneup_eirp),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_limit",
    header: "EIRP Limit",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_limit";
      const ascending = !sortState.sortingDescending;
      return `EIRP Limit, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_limit),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "eirp_psd_limit",
    header: "EIRP PSD Limit",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "eirp_psd_limit";
      const ascending = !sortState.sortingDescending;
      return `EIRP PSD Limit, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.eirp_psd_limit),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "power_follow_country",
    header: "Power Follow Country",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "power_follow_country";
      const ascending = !sortState.sortingDescending;
      return `Power Follow Country, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.power_follow_country),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "created_time",
    header: "Created Time",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "created_time";
      const ascending = !sortState.sortingDescending;
      return `Created Time, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.create_time),
    minWidth: "100px",
    visible: false,
  },
  {
    id: "update_time",
    header: "Latest Updated Time",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "update_time";
      const ascending = !sortState.sortingDescending;
      return `Latest Update Time, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => centerAlign(item.update_time),
    minWidth: "100px",
    visible: true,
  },
  {
    id: "publish_status",
    header: "Publish",
    label: (sortState) => {
      const columnIsSorted = sortState.sortingColumn === "publish_status";
      const ascending = !sortState.sortingDescending;
      return `Publish, ${
        columnIsSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      if (item.publish_status === UPDATE_TESTCASE.PUBLISHED) {
        return (
          <div
            style={{
              backgroundColor: "#87CEFA",
              textAlign: "center",
              outline: "none",
            }}
          >
            Published
          </div>
        );
      } else if (item.publish_status === UPDATE_TESTCASE.REJECTED) {
        return (
          <div
            style={{
              backgroundColor: "#FF6347",
              textAlign: "center",
              outline: "none",
            }}
          >
            Rejected
          </div>
        );
      } else {
        return centerAlign("Unpublished");
      }
    },
    minWidth: "100px",
    visible: true,
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "update_time";

// define sortable columns
export const sortableColumns = [
  { id: "technology", field: "technology" },
  { id: "specification", field: "specification" },
  { id: "testcase_id", field: "testcase_id" },
  { id: "band", field: "band" },
  { id: "channel", field: "channel" },
  { id: "frequency", field: "frequency" },
  { id: "data_rate", field: "data_rate" },
  { id: "antenna", field: "antenna" },
  { id: "country", field: "country" },
  { id: "status", field: "status" },
  { id: "internal_status", field: "internal_status" },
  { id: "target_power_setting", field: "target_power_setting" },
  { id: "minimum_power_setting", field: "minimum_power_setting" },
  { id: "power_setting", field: "power_setting" },
  { id: "antenna_gain", field: "antenna_gain" },
  { id: "avg_power", field: "avg_power" },
  { id: "tuneup_avg_power", field: "tuneup_avg_power" },
  { id: "eirp_avg_power", field: "eirp_avg_power" },
  { id: "tuneup_eirp", field: "tuneup_eirp" },
  { id: "eirp_limit", field: "eirp_limit" },
  { id: "eirp_psd_limit", field: "eirp_psd_limit" },
  { id: "power_follow_country", field: "power_follow_country" },
  { id: "created_time", field: "created_time" },
  { id: "update_time", field: "update_time" },
  { id: "publish_status", field: "publish_status" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "technology", label: "Technology", visible: false },
      { id: "specification", label: "Specification", visible: false },
      {
        id: "testcase_id",
        label: "Testcase ID",
        visible: true,
        editable: false,
      },
      { id: "band", label: "Band", visible: true, editable: false },
      { id: "channel", label: "Channel", visible: true, editable: false },
      { id: "frequency", label: "Frequency", visible: true, editable: false },
      { id: "data_rate", label: "Data Rate", visible: true, editable: false },
      { id: "antenna", label: "Antenna", visible: true, editable: false },
      { id: "country", label: "Country", visible: true, editable: false },
      { id: "status", label: "Status", visible: true, editable: false },
      { id: "internal_status", label: "Internal Status", visible: false },
      { id: "target_power_setting", label: "Target Power Setting", visible: true },
      { id: "minimum_power_setting", label: "Minimum Power Setting", visible: true },
      { id: "power_setting", label: "Final Power Setting", visible: true },
      { id: "antenna_gain", label: "Antenna Gain", visible: true },
      { id: "avg_power", label: "Avg Power", visible: true },
      { id: "tuneup_avg_power", label: "Tuneup Avg Power", visible: true },
      { id: "eirp_avg_power", label: "EIRP Avg Power", visible: true },
      { id: "tuneup_eirp", label: "Tuneup EIRP", visible: true },
      { id: "eirp_limit", label: "EIRP Limit", visible: true },
      { id: "eirp_psd_limit", label: "EIRP PSD Limit", visible: true },
      {
        id: "power_follow_country",
        label: "Power Follow Country",
        visible: true,
      },
      { id: "created_time", label: "Created Time", visible: false },
      {
        id: "update_time",
        label: "Latest Updated Time",
        visible: true,
        editable: false,
      },
      {
        id: "publish_status",
        label: "Publish",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    groupValuesLabel: "Testcase ID",
    propertyKey: "testcase_id",
    propertyLabel: "Testcase ID",
    values: [],
  },
  {
    groupValuesLabel: "Band",
    propertyKey: "band",
    propertyLabel: "Band",
    values: [],
  },
  {
    groupValuesLabel: "Channel",
    propertyKey: "channel",
    propertyLabel: "Channel",
    values: [],
  },
  {
    groupValuesLabel: "Frequency",
    propertyKey: "frequency",
    propertyLabel: "Frequency",
    values: [],
  },
  {
    groupValuesLabel: "Data Rate",
    propertyKey: "data_rate",
    propertyLabel: "Data Rate",
    values: [],
  },
  {
    groupValuesLabel: "Antenna",
    propertyKey: "antenna",
    propertyLabel: "Antenna",
    values: [],
  },
  {
    groupValuesLabel: "Country",
    propertyKey: "country",
    propertyLabel: "Country",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: [],
  },
  {
    groupValuesLabel: "Internal Status",
    propertyKey: "internal_status",
    propertyLabel: "Internal Status",
    values: [],
  },
  {
    groupValuesLabel: "Latest Update Time",
    propertyKey: "update_time",
    propertyLabel: "Latest Update Time",
    values: [],
  },
  {
    groupValuesLabel: "Publish",
    propertyKey: "publish_status",
    propertyLabel: "Publish",
    values: [],
  },
];

export const features = [
  "propertyFiltering",
  "pagination",
  "selection",
  "sorting",
];

const getNavLink = (status, text, category, project, build, buildConfigId) => {
  // desense doesn't have an overview page
  if (status === TEST_STATUS.UNTESTED) {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {text ? text : "__"}
      </div>
    );
  }
  return (
    <NavLink
      to={`/categories/${category}/projects/${project}/builds/${build}/build_configs/${buildConfigId}/test_cases/${text}/visualize`}
    >
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}
      >
        {text ? text : "__"}
      </div>
    </NavLink>
  );
};

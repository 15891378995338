import testcaseType from "../types/testcase-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const updateTestCases = (
  category_id: string,
  project_id: string,
  build_id: string,
  build_config_id: number,
  values: any
): any => ({
  type: testcaseType.UPDATE_TCS,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.updateTestcasesEndpoint(
            category_id,
            project_id,
            build_id,
            build_config_id
          ),
        method: "PUT",
        json: values,
      },
      commit: {
        type: testcaseType.UPDATE_TCS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.UPDATE_TESTCASE,
        },
      },
      rollback: {
        type: testcaseType.UPDATE_TCS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.UPDATE_TESTCASE,
        },
      },
    },
  },
});

export const resetUpdateTestCases = (): any => ({
  type: testcaseType.RESET_TCS,
});

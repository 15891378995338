import * as React from 'react';
import { Container } from "@amzn/awsui-components-react-v3";      
import { RouteComponentProps, withRouter } from "react-router-dom";  
import { Header } from "@amzn/awsui-components-react-v3/polaris";
import constants, { TEST_STATUS } from "../../../constants";
import {
  ContainerFail,
  ContainerNoData,
  ContainerSpninner,
} from "../../../components/container-status-indicator";
import ProgressContent from "./progress-content/progress-content";
import { connect } from 'react-redux';
import { Component } from 'react';
import { Dispatch } from 'redux';
import { getTestCaseForAllRegions } from '../../../redux/actions/overview-action';

// declare prop check
type Props = {
  loadingStatus: any;
  data: any;
  category: any;
  project: any;
  build: any;
    onSelectBuildConfig: any;
} & typeof defaultProps;
type State = {};
// declare init state & default props
const defaultProps = Object.freeze({});

class BuildConfigOverview extends Component<Props, State> {
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
  }

  getKeys = (buildConfigsData: Array<any>) => {
    let keys: Array<any> = [];
    buildConfigsData.forEach((buildConfig) => {
      keys.push(buildConfig["build_config"]);
    });
    return keys;
  };
  getData = (buildConfigsData: Array<any>) => {
    let values: any = {};
    buildConfigsData.forEach((buildConfig) => {
      let build_config_name = buildConfig["build_config"];
      values[build_config_name] = { ...buildConfig };
      values[build_config_name]["total"] = 0;
      if (buildConfig[TEST_STATUS.PASSED])
          values[build_config_name]["total"] += buildConfig[TEST_STATUS.PASSED];
      else
          values[build_config_name][TEST_STATUS.PASSED] =0
      if (buildConfig[TEST_STATUS.FAILED])
        values[build_config_name]["total"] += buildConfig[TEST_STATUS.FAILED];
      else values[build_config_name][TEST_STATUS.FAILED] = 0;
      if (buildConfig[TEST_STATUS.UNTESTED])
        values[build_config_name]["total"] += buildConfig[TEST_STATUS.UNTESTED];
      else values[build_config_name][TEST_STATUS.UNTESTED] = 0;
    });
    return values;
  };
  render() {
    const { loadingStatus, data } = this.props;
    let content: any = null;
    if (loadingStatus === constants.LOADING_LOAD) {
      content = (
        <ContainerSpninner className="alert-container d-flex justify-content-center" />
      );
    } else if (loadingStatus === constants.LOADING_FAIL) {
      content = (
        <ContainerFail className="alert-container d-flex justify-content-center" />
      );
    } else if (!data || data.length === 0) {
      content = (
        <ContainerNoData className="alert-container d-flex justify-content-center" />
      );
    } else if (loadingStatus === constants.LOADING_SUCCESS && data) {
      content = (
        <ProgressContent
          keys={this.getKeys(data)}
          values={this.getData(data)}
          onSelectBuildConfig={this.props.onSelectBuildConfig}
        />
      );
    }
    return (
      <Container
        header={<Header variant="h2">Config Overview</Header>}
      >
            {content}
      </Container>
    );
  }
}

export default BuildConfigOverview;
import React, { Component } from "react";
import {
  Table,
  TableSelection,
  Button,
} from "@amzn/awsui-components-react/polaris";

import { columnDefinitions } from "./table-config";

type State = typeof initialState;

const initialState = Object.freeze({});

type Props = {
  data: any;
  selectedItems: Array<any>;
  setSelectedItems: any;
  deleteRow: any;
  // editRow: any;
} & typeof defaultProps;

const defaultProps = Object.freeze({});

class TestcaseTable extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  _onSelectionChange = (event): any => {
    const { selectedItems } = event.detail;
    this.props.setSelectedItems(selectedItems);
  };

  _deleteRow = () => {
    this.props.deleteRow(this.props.selectedItems);
  };
  getDisabled = (): any => {
    if (this.props.selectedItems.length != 0) return false;
    return true;
  };

  render() {
    const { data, selectedItems } = this.props;
    return (
      <Table
        columnDefinitions={columnDefinitions}
        items={data}
        empty={
          <div className='awsui-util-t-c'>
            <div className='awsui-util-pt-s awsui-util-mb-xs'>
              <b>No resources</b>
            </div>
            <p className='awsui-util-mb-s'>No resources to display.</p>
          </div>
        }
        header={
          <div className='awsui-util-action-stripe'>
            <div></div>
            <div className='awsui-util-action-stripe-group'>
              {/* <Button
                icon="edit"
                disabled={this.getDisabled()}
                variant="primary"
                onClick={() => editRow(selectedItems)}
              >
                Edit
              </Button> */}
              <Button
                icon='close'
                disabled={this.getDisabled()}
                variant='primary'
                onClick={() => this._deleteRow()}>
                Delete
              </Button>
              {/* <Button
                    disabled={this.getExcelDisabled()}
                    icon="download"
                    variant="primary"
                    onClick={() => this.getExcel()}
                >
                    Download Excel
                </Button> */}
              {/* <Button
                    disabled={!jiraId}
                    icon="external"
                    variant="primary"
                    onClick={() => this.viewJira(jiraId)}
                >
                    Jira
                </Button> */}
            </div>
          </div>
        }>
        <TableSelection
          selectedItems={selectedItems}
          onSelectionChange={this._onSelectionChange}
          keepSelection={true}></TableSelection>
      </Table>
    );
  }
}

export default TestcaseTable;

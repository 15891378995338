
export const columnDefinitions = [
  {
    id: "technology",
    header: "Technology",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "technology";
      const ascending = !sortState.sortingDescending;
      return `Technology, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.technology,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "specification",
    header: "Specification",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "specification";
      const ascending = !sortState.sortingDescending;
      return `Specification, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.specification,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna",
    header: "Antenna",
    cell: (item) => item.antenna,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "internal_margin",
    header: "Internal Margin",
    cell: (item) => item.internal_margin,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "target",
    header: "Target Power",
    cell: (item) => item.target,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "minimum_power",
    header: "Min Power",
    cell: (item) => item.minimum_power,
    minWidth: "100px",
    visible: true,
  },
  {
    id: "antenna_gain",
    header: "Antenna Gain (Peak)",
    cell: (item) => item.antenna_gain,
    minWidth: "100px",
    visible: true,
  },
];

export const defaultSortingColumn = "technology";

export const sortableColumns = [
    { id: "technology", field: "technology" },
    { id: "specification", field: "specification" },
    { id: "antenna", field: "antenna" },
    { id: "internal_margin", field: "internal_margin" },
    { id: "target", field: "target" },
    { id: "minimum_power", field: "minimum_power" },
    { id: "antenna_gain", field: "antenna_gain" },
  ];

export const features = [
    "selection",
    "sorting",
  ];


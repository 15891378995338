import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Button,
  ColumnLayout,
  FormField,
  Input,
  ExpandableSection,
  Container,
  TextContent,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";

import VisualizationChart from "./visualization-chart";
import TestcaseSummaryTable from "./table/table";
import { getTestcaseSummary, resetVisualizationData } from "../../redux/actions/visualization-action";
import { getBuildAndVersion } from "../../utils/general-utils";
import BreadcrumbGroup from "../../components/breadcrumb-group";
import {
  DATA_VISUALIZATION_INSTRUCTION,
  DATA_VISUALIZATION_LEGEND,
} from "../../constants";
import "./visualization.css";
import VisualizationTable from "./visualization-table/table";
import ConfigTable from "./config-table/table"
import FinalPowerTable from "./final-power-table/table"
import { Header } from "@amzn/awsui-components-react-v3";

interface StateProps {
  visualizationReducer: any;
  metadataReducer: any;
}

type State = {
  xMin: Number | null;
  xMax: Number | null;
  yMin: Number | null;
  yMax: Number | null;
  test_case_id: Number | null;
  categoryName: string | null;
  projectName: string | null;
  buildAndVersion: string | null;
  buildConfigName: string | null;
  selectedPoints: Array<any>;
};

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  category: string;
  project: string;
  build: string;
  buildConfig: string;
} & StateProps &
  RouteComponentProps<any>;

// declare init state & default props
const defaultProps = Object.freeze({});

class Visualization extends Component<Props, State> {
  static defaultProps = defaultProps;
  state: State = Object.freeze({
    xMin: null,
    xMax: null,
    yMin: null,
    yMax: null,
    test_case_id: null,
    categoryName: null,
    projectName: null,
    buildAndVersion: null,
    buildConfigName:null,
    selectedPoints: [],
  });

  componentDidMount() {
    window.scrollTo(0, 0);
    const {
      category,
      project,
      build,
      build_config,
      test_case_id,
    } = this.props.match.params;
    this.props.dispatch(getTestcaseSummary(Number(test_case_id)));
    this.props.dispatch(resetVisualizationData());
    const { filter } = this.props.metadataReducer;
    if (Object.keys(filter).length > 0) {
      const categoryName = filter[category].name;
      const projectName = filter[category].children[project].name;
      const buildAndVersion = getBuildAndVersion(
        filter[category].children[project],
        build
      );
      
      const buildConfigName =
        filter[category].children[project].children[
          buildAndVersion.split("_")[0]
        ].children[buildAndVersion.split("_")[1]].children[build_config].name;
      this.setState({
        test_case_id: Number(test_case_id),
        categoryName,
        projectName,
        buildAndVersion,
        buildConfigName,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props.metadataReducer;
    const {
      category,
      project,
      build,
      build_config,
      test_case_id,
    } = this.props.match.params;
    // this will run if we refresh the page
    if (prevProps.metadataReducer.filter != filter) {
      if (Object.keys(filter).length > 0) {
        const categoryName = filter[category].name;
        const projectName = filter[category].children[project].name;
        const buildAndVersion = getBuildAndVersion(
          filter[category].children[project],
          build
        );
        const buildConfigName =
          filter[category].children[project].children[
            buildAndVersion.split("_")[0]
          ].children[buildAndVersion.split("_")[1]].children[build_config].name;
        this.setState({
          test_case_id: Number(test_case_id),
          categoryName,
          projectName,
          buildAndVersion,
          buildConfigName,
        });
      }
    }
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }

  _onChangeXMin = (event: any) => {
    const xMin: Number | null = event.detail.value;
    this.setState({
      xMin,
    });
  };

  _onChangeXMax = (event: any) => {
    const xMax: Number | null = event.detail.value;
    this.setState({
      xMax,
    });
  };

  _onChangeYMin = (event: any) => {
    const yMin: Number | null = event.detail.value;
    this.setState({
      yMin,
    });
  };

  _onChangeYMax = (event: any) => {
    const yMax: Number | null = event.detail.value;
    this.setState({
      yMax,
    });
  };

  _checkDisabledXRange = (): any => {
    if (this.state.xMin || this.state.xMax) return false;
    return true;
  };

  _checkDisabledYRange = (): any => {
    if (this.state.yMin || this.state.yMax) return false;
    return true;
  };

  _clearXRange = (): any => {
    this.setState({
      xMin: null,
      xMax: null,
    });
  };

  _clearYRange = (): any => {
    this.setState({
      yMin: null,
      yMax: null,
    });
  };

  updateSelectedPoints = (selectedPoints) => {
    this.setState({ selectedPoints: selectedPoints });
  };

  resetSelectedPoints = () => {
    this.setState({ selectedPoints: [] });
  };

  render() {
    const disableXRange = this._checkDisabledXRange();
    const disableYRange = this._checkDisabledYRange();
    const {
      data,
      configData,
      finalPowerData,
      front_end_fields,
      loadingStatus,
      rawData,
      rawDataType,
      rawDataLoadingStatus,
    } = this.props.visualizationReducer;
    const {
      category,
      project,
      build,
      build_config,
      test_case_id,
    } = this.props.match.params;
    const rangeFilter = (
      <div>
        <ColumnLayout columns={4}>
          <FormField
            label="xMin"
            constraintText="Numbers only"
            // errorText={this.state.categoryError}
          >
            <Input
              value={this.state.xMin ? this.state.xMin.toString() : ""}
              onChange={this._onChangeXMin}
            ></Input>
          </FormField>
          <FormField label="xMax" constraintText="Numbers only">
            <Input
              value={this.state.xMax ? this.state.xMax.toString() : ""}
              onChange={this._onChangeXMax}
            ></Input>
          </FormField>
          <FormField label="yMin" constraintText="Numbers only">
            <Input
              value={this.state.yMin ? this.state.yMin.toString() : ""}
              onChange={this._onChangeYMin}
            ></Input>
          </FormField>
          <FormField label="yMax" constraintText="Numbers only">
            <Input
              value={this.state.yMax ? this.state.yMax.toString() : ""}
              onChange={this._onChangeYMax}
            ></Input>
          </FormField>
        </ColumnLayout>

        <ColumnLayout columns={2}>
          <Button
            variant="primary"
            disabled={disableXRange}
            onClick={this._clearXRange}
          >
            Clear xRange
          </Button>
          <Button
            variant="primary"
            disabled={disableYRange}
            onClick={this._clearYRange}
          >
            Clear yRange
          </Button>
        </ColumnLayout>
      </div>
    );

    const instructions = (
      <ExpandableSection defaultExpanded={true} header="Instructions">
        <Container>
          <TextContent>
            <ul>
              {DATA_VISUALIZATION_INSTRUCTION.map((instruction, index) => (
                <li key={index}>{instruction}</li>
              ))}
            </ul>
          </TextContent>
          <br />
          <strong> Legends for Testcase Summary </strong>
          <SpaceBetween direction="horizontal" size="s">
            <div className="legend-box-red"> </div>
            <div> {DATA_VISUALIZATION_LEGEND.RED} </div>
          </SpaceBetween>

          <SpaceBetween direction="horizontal" size="s">
            <div className="legend-box-green"> </div>
            <div> {DATA_VISUALIZATION_LEGEND.GREEN} </div>
          </SpaceBetween>
          <SpaceBetween direction="horizontal" size="s">
            <div className="legend-box-blue"> </div>
            <div> {DATA_VISUALIZATION_LEGEND.BLUE} </div>
          </SpaceBetween>
        </Container>
      </ExpandableSection>
    );

    return (
      <>
        <BreadcrumbGroup
          textGroup={[
            { text: this.state.categoryName }, // Category
            { text: this.state.projectName }, // project
            { text: this.state.buildAndVersion }, // build
            { text: this.state.buildConfigName }, // build_config
            { text: this.state.test_case_id }, // test case id
          ]}
        />
        <br />
        {instructions}
        <br />
        <ConfigTable
          data={configData}
          loadingStatus={loadingStatus}
          test_case_id={String(this.state.test_case_id)}
        />
        <br />
        <FinalPowerTable 
          data={finalPowerData}
          loadingStatus={loadingStatus}
          test_case_id={String(this.state.test_case_id)}
        />
        <br />
        <TestcaseSummaryTable
          data={data}
          front_end_fields={front_end_fields}
          loadingStatus={loadingStatus}
          category={category}
          project={project}
          build={build}
          build_config={build_config}
          dispatch={this.props.dispatch}
          resetSelectedPoints={this.resetSelectedPoints}
        />
        <br />
        <Container
          header={<Header variant="h2">Data Visualization</Header>}
        >
          <>
            <VisualizationChart
              xMin={this.state.xMin}
              xMax={this.state.xMax}
              yMin={this.state.yMin}
              yMax={this.state.yMax}
              rawData={rawData}
              rawDataType={rawDataType}
              rawDataLoadingStatus={rawDataLoadingStatus}
              selectedPoints={this.state.selectedPoints}
              updateSelectedPoints={this.updateSelectedPoints}
            />
            <br />
            {rawData && rawData.length > 0 && rangeFilter}
          </>
        </Container>
        {rawData && rawData.length > 0 && (
          <>
            <br />
            <VisualizationTable
              data={this.state.selectedPoints}
              data_type={rawDataType}
              reset={this.resetSelectedPoints}
              test_case_id={test_case_id}
            ></VisualizationTable>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    visualizationReducer: state.visualizationReducer,
    metadataReducer: state.metadataReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(Visualization);

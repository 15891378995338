import constants from "../../constants";
import buildConfigTypes from '../types/build-config-type';

const initialState = {
  createLoadingStatus: constants.LOADING_DEFAULT,
  createMessage: "",
  getLoadingStatus: constants.LOADING_DEFAULT,
  getMessage: "",
  buildConfigs: []
};

const buildConfigReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case buildConfigTypes.RESET_BUILD_CONFIG:
      return initialState;
    case buildConfigTypes.CREATE_BUILD_CONFIG:
      return {
        createLoadingStatus: constants.LOADING_LOAD,
        createMessage: "",
      };
    case buildConfigTypes.CREATE_BUILD_CONFIG_COMMIT:
      return {
        createLoadingStatus: constants.LOADING_SUCCESS,
        createMessage: action.payload.message,
      };
    case buildConfigTypes.CREATE_BUILD_CONFIG_ROLLBACK:
      return {
        createLoadingStatus: constants.LOADING_FAIL,
        createMessage: action.payload.message,
      };
    case buildConfigTypes.GET_BUILD_CONFIGS:
      return {
        getLoadingStatus: constants.LOADING_LOAD,
        getMessage: "",
        buildConfigs: [],
      };
    case buildConfigTypes.GET_BUILD_CONFIG_COMMITS:
      return {
        getLoadingStatus: constants.LOADING_SUCCESS,
        getMessage: action.payload.message,
        buildConfigs:
          action.payload.data && Array.isArray(action.payload.data)
            ? action.payload.data
            : [],
      };
    case buildConfigTypes.GET_BUILD_CONFIG_ROLLBACKS:
      return {
        getLoadingStatus: constants.LOADING_FAIL,
        getMessage: action.payload.message,
        buildConfigs: [],
      };
    default:
      return state;
  }
};

export default buildConfigReducer;

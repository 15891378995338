import React from "react";
import constants, {
  STATUS_TEXT,
  REPORT_TYPE_TEXT,
} from "../../../constants";
import { Button } from "@amzn/awsui-components-react-v3";

// default page size
export const defaultPageSize = 10;

// page size option
export const pageSizeOptions = [
  { value: 10, label: "10 items" },
  { value: 20, label: "20 items" },
  { value: 50, label: "50 items" },
];

export const columnDefinitions = [
  {
    id: "report_type",
    header: "Report Type",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "report_type";
      const ascending = !sortState.sortingDescending;
      return `Report Type, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.report_type,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "category",
    header: "Category",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "category";
      const ascending = !sortState.sortingDescending;
      return `Category, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.category,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "project",
    header: "Project",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "project";
      const ascending = !sortState.sortingDescending;
      return `Project, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.project,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "build",
    header: "Build",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "build";
      const ascending = !sortState.sortingDescending;
      return `Build, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.build,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "version",
    header: "Version",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "version";
      const ascending = !sortState.sortingDescending;
      return `Version, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.version,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "build_config",
    header: "Build Config",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "build_config";
      const ascending = !sortState.sortingDescending;
      return `Build Config, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.build_config,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "testcase_id",
    header: "Test Case ID",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "testcase_id";
      const ascending = !sortState.sortingDescending;
      return `Test Case ID, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.test_case,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "created_time",
    header: "Created Time",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "created_time";
      const ascending = !sortState.sortingDescending;
      return `Created Time, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => item.created_time,
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "status",
    header: "Status",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "status";
      const ascending = !sortState.sortingDescending;
      return `Status, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {
      let className = "";
      if (item.status === STATUS_TEXT[constants.STATUS_SUCCESS]) {
        className = "text-success";
      }
      // item.status === "error" is temporary to be compatible with current BE
      if (item.status === STATUS_TEXT[constants.STATUS_FAIL]) {
        className = "text-danger";
      }
      return <div className={className}>{item.status}</div>;
    },
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
  {
    id: "url",
    header: "Download",
    label: (sortState) => {
      const isColumnSorted = sortState.sortingColumn === "url";
      const ascending = !sortState.sortingDescending;
      return `Download, ${
        isColumnSorted
          ? `sorted ${ascending ? "ascending" : "descending"}`
          : "not sorted"
      }.`;
    },
    cell: (item) => {

        return (
          <Button
          className="button-with-margin"
          iconName="download"
          variant="primary"
          href={item.s3_url}
          disabled={!item.s3_url}
        ></Button>
        );

    },
    minWidth: constants.TABLE_DEFAULT_MIN_WIDTH,
  },
];

export const defaultSortingColumn = "created_time";

export const sortableColumns = [
  { id: "report_type", field: "report_type" },
  { id: "category", field: "category" },
  { id: "project", field: "project" },
  { id: "build", field: "build" },
  { id: "version", field: "version" },
  { id: "build_config", field: "build_config"},
  { id: "testcase_id", field: "test_case" },
  { id: "created_time", field: "created_time" },
  { id: "status", field: "status" },
  { id: "url", field: "url" },
];

export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "report_type", label: "Report Type", visible: true, editable: false },
      { id: "category", label: "Category", visible: true, editable: false },
      { id: "project", label: "Project", visible: true, editable: false },
      { id: "build", label: "Build", visible: true, editable: false },
      { id: "version", label: "Version", visible: true, editable: false },
      { id: "build_config", label: "Build Config", visible: true, editable: false },
      { id: "testcase_id", label: "Test Case ID", visible: true, editable: false },
      {
        id: "created_time",
        label: "Created Time",
        visible: true,
        editable: false,
      },
      { id: "url", label: "Url", visible: true, editable: false },
      { id: "status", label: "Status", visible: true },
    ],
  },
];

export const filteringOptions = [
  {
    groupValuesLabel: "Report Type",
    propertyKey: "report_type",
    propertyLabel: "Report Type",
    values: Object.values(REPORT_TYPE_TEXT),
  },
  {
    groupValuesLabel: "Category",
    propertyKey: "category",
    propertyLabel: "Category",
    values: [],
  },
  {
    groupValuesLabel: "Project",
    propertyKey: "project",
    propertyLabel: "Project",
    values: [],
  },
  {
    groupValuesLabel: "Build",
    propertyKey: "build",
    propertyLabel: "Build",
    values: [],
  },
  {
    groupValuesLabel: "Version",
    propertyKey: "version",
    propertyLabel: "Version",
    values: [],
  },
  {
    groupValuesLabel: "Build Config",
    propertyKey: "build_config",
    propertyLabel: "Build Config",
    values: [],
  },
  {
    groupValuesLabel: "Test Case ID",
    propertyKey: "test_case",
    propertyLabel: "Test Case ID",
    values: [],
  },
  {
    groupValuesLabel: "Status",
    propertyKey: "status",
    propertyLabel: "Status",
    values: Object.values(STATUS_TEXT),
  },
];

import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { PAGE_SIZE_OPTIONS } from "../../overview/common";
import React from "react";

// define columns
export const CRITICAL_DATA_TABLE_COLUMN_DEFINITIONS = [
  {
    id: "Frequency",
    header: "Frequency",
    cell: (item) => item["Frequency"],
    sortingField: "Frequency",
    visible: true,
    minWidth: 150,
  },
  {
    id: "Height",
    header: "Height",
    cell: (item) => item["Height"],
    sortingField: "Height",
    visible: true,
    minWidth: 150,
  },
  {
    id: "Limit PK+",
    header: "Limit PK+",
    cell: (item) => item["Limit PK+"],
    sortingField: "Limit PK+",
    visible: true,
    minWidth: 150,
  },
  {
    id: "PK+_MAXH",
    header: "PK+_MAXH",
    cell: (item) => item["PK+_MAXH"],
    sortingField: "PK+_MAXH",
    visible: true,
    minWidth: 150,
  },
  {
    id: "Pol",
    header: "Pol",
    cell: (item) => item["Pol"],
    sortingField: "Pol",
    visible: true,
    minWidth: 100,
  },

  {
    id: "status",
    header: "status",
    cell: (item) => item["status"],
    sortingField: "status",
    visible: true,
    minWidth: 150,
  },
];

// define visible columns
export const CRITICAL_DATA_VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Debug Frequency",
    options: [
      {
        id: "Frequency",
        label: "Frequency",
        visible: true,
        editable: false,
      },
      {
        id: "Height",
        label: "Height",
        visible: true,
        editable: false,
      },
      {
        id: "Limit PK+",
        label: "Limit PK+",
        visible: true,
        editable: false,
      },
      {
        id: "PK+_MAXH",
        label: "PK+_MAXH",
        visible: true,
        editable: false,
      },
      {
        id: "Pol",
        label: "Pol",
        visible: true,
        editable: false,
      },
      {
        id: "status",
        label: "status",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const CRITICAL_DATA_FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: "Frequency",
    key: "Frequency",
    groupValuesLabel: "Frequency",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Height",
    key: "Height",
    groupValuesLabel: "Height",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Limit PK+",
    key: "Limit PK+",
    groupValuesLabel: "Limit PK+",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "PK+_MAXH",
    key: "PK+_MAXH",
    groupValuesLabel: "PK+_MAXH",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Pol",
    key: "Pol",
    groupValuesLabel: "Pol",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "status",
    key: "status",
    groupValuesLabel: "status",
    operators: [":", "!:", "=", "!="],
  },
];

// define default preferences
export const CRITICAL_DATA_DEFAULT_PREFERENCES = () => {
  return {
    pageSize: 10,
    visibleContent: CRITICAL_DATA_TABLE_COLUMN_DEFINITIONS.filter(
      (column) => column.visible
    ).map((column) => column.id),
    wrapLines: false,
  };
};

export const CritcalDataPreferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = CRITICAL_DATA_VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

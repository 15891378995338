import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  getTestCaseForAllRegions,
  resetOverviewData,
  updateBuildStatus,
  resetUpdateBuildStatus,
} from "../../redux/actions/overview-action";
import {
  getLatestTestplan,
  setTestOverviewDetails,
} from "../../redux/actions/testplan-action";
import { resetUpdateTestCases } from "../../redux/actions/testcase-action";
import BreadcrumbGroup from "../../components/breadcrumb-group";
import OverviewTable from "./table/table";
import { capitalizeFirstLetter } from "../../utils/general-utils";
import constants from "../../constants";
import BuildOverviewChart from "./chart/build-overview";
import RegionOverviewChart from "./chart/region-overview";
import {
  ColumnLayout,
  Container,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import { getBuildConfigs } from "../../redux/actions/build-config-action";
import BuildConfigOverview from "./chart/build-config-overview";
import { Header } from "@amzn/awsui-components-react-v3/polaris";
import Iframe from "../iframe/iframe";
import { ValueWithLabel } from "../../components/value-with-label";
import { getMetadata } from "../../redux/actions/metadata-action";
import { parseS3Link } from "../../utils/s3-utils";
import { DownloadPreSignedURLRequest } from "../../model/http-json";
import { downloadAction } from "../../redux/actions/download-action";

interface StateProps {
  overviewReducer: any;
  metadataReducer: any;
  testcaseReducer: any;
  testplanReducer: any;
  buildConfigReducer: any;
  downloadReducer: any;
}

interface MatchParams {
  category: string;
  project: string;
  build: string;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

type State = {
  categoryName: string;
  projectName: string;
  projectStreetDate: string;
  buildAndVersion: string;
  buildConfig: string;
  buildConfigId: string;
  buildStatus: string;
  buildStartDate: string;
  buildEndDate: string;
  s3UrlRF: string;
  total: number;
};

class Overview extends Component<Props> {
  state: State = Object.freeze({
    categoryName: "",
    projectName: "",
    projectStreetDate: "",
    buildAndVersion: "",
    buildConfig: "",
    buildConfigId: "",
    buildStatus: "",
    buildStartDate: "",
    buildEndDate: "",
    s3UrlRF: "",
    total: 0,
  });

  componentDidMount() {
    const { category, project, build } = this.props.match.params;
    const { filter } = this.props.metadataReducer;
    const { loadingLatestTestplanStatus } = this.props.testplanReducer;
    const { loadingStatus, url } = this.props.downloadReducer;

    if (Object.keys(filter).length > 0) {
      this.intialize_component(category, project, build, filter);
    }
    this.props.dispatch(getBuildConfigs(category, project, build));
    this.props.dispatch(
      setTestOverviewDetails({
        categoryId: category,
        projectId: project,
        buildVersionId: build,
      })
    );
    this.props.dispatch(resetOverviewData());
   this.setState({s3UrlRF: ""})

  }

  componentDidUpdate(prevProps, prevState) {
    const { filter } = this.props.metadataReducer;
    const { category, project, build } = this.props.match.params;
    const { loadingUpdateTestcasesStatus } = this.props.testcaseReducer;
    const { loadingUpdateBuildStatus } = this.props.overviewReducer;
    const { loadingLatestTestplanStatus } = this.props.testplanReducer;
    const { loadingStatus, url } = this.props.downloadReducer;

    // same component, but when the url changes, e.g project/build/version
    if (prevProps.location.pathname !== this.props.location.pathname) {
      // re-fetch data
      this.props.dispatch(getBuildConfigs(category, project, build));
      this.intialize_component(category, project, build, filter);
      this.props.dispatch(resetOverviewData());
    }

    // this will run if we refresh the page
    if (prevProps.metadataReducer.filter != filter) {
      this.intialize_component(category, project, build, filter);
    }

    // this will refetch metadata when status is updated
    if (loadingUpdateBuildStatus == constants.LOADING_SUCCESS) {
      this.setState({ buildStatus: constants.STATUS_COMPLETED });
      this.props.dispatch(getMetadata());
      this.props.dispatch(resetUpdateBuildStatus());
    }

    // dispatch actions when testcase is published/rejected
    if (loadingUpdateTestcasesStatus === constants.LOADING_SUCCESS) {
      if (this.state.buildConfigId) {
        this.props.dispatch(
          getTestCaseForAllRegions(
            category,
            project,
            build,
            Number(this.state.buildConfigId)
          )
        );
      }
      this.props.dispatch(resetUpdateTestCases());
    }
    if (loadingUpdateTestcasesStatus === constants.LOADING_FAIL) {
      if (this.state.buildConfigId) {
        this.props.dispatch(
          getTestCaseForAllRegions(
            category,
            project,
            build,
            Number(this.state.buildConfigId)
          )
        );
      }
      this.props.dispatch(resetUpdateTestCases());
      const { updateTestcasesResponseData } = this.props.testcaseReducer;
      alert(updateTestcasesResponseData.message);
    }
    if (
      loadingLatestTestplanStatus === constants.LOADING_SUCCESS &&
      this.props.testplanReducer.latestTestplanData.s3_link &&
      this.props.testplanReducer.latestTestplanData.s3_link !==
        prevProps.testplanReducer.latestTestplanData.s3_link
    ) {
      const s3_components = parseS3Link(
        this.props.testplanReducer.latestTestplanData.s3_link
      );
      const data: DownloadPreSignedURLRequest = {
        file_name: s3_components.file_path,
        object_key: s3_components.object_key,
        bucket_name: s3_components.bucket_name,
        version_id: s3_components.version_id,
      };
      this.props.dispatch(downloadAction(data));
    }

    if (loadingStatus === constants.LOADING_SUCCESS) {
      if (this.state.total != 0 && this.state.s3UrlRF !== url) {
        this.setState({ s3UrlRF: url });
      }
    }
  }

  intialize_component = (
    category: any,
    project: any,
    build: any,
    filter: any
  ) => {
    const categoryName = filter[category].name;
    const projectName = filter[category].children[project].name;
    const projectStreetDate = filter[category].children[project].streetDate;
    const buildAndVersion = this.getBuildAndVersion(
      filter[category].children[project],
      build
    );
    const buildStartDate =
      filter[category].children[project].children[buildAndVersion.split("_")[0]]
        .children[buildAndVersion.split("_")[1]].startDate;
    const buildEndDate =
      filter[category].children[project].children[buildAndVersion.split("_")[0]]
        .children[buildAndVersion.split("_")[1]].endDate;
    const buildStatus =
      filter[category].children[project].children[buildAndVersion.split("_")[0]]
        .children[buildAndVersion.split("_")[1]].status;
    this.setState({
      categoryName,
      projectName,
      projectStreetDate,
      buildAndVersion,
      buildStartDate,
      buildEndDate,
      buildStatus,
    });
  };

  getBuildAndVersion = (project: any, build_id: any) => {
    let build = "";
    let version = "";
    Object.keys(project.children).forEach((build_item) => {
      Object.keys(project.children[build_item].children).forEach(
        (version_item) => {
          if (
            project.children[build_item].children[version_item].id === build_id
          ) {
            build = build_item;
            version = version_item;
          }
        }
      );
    });
    return `${build}_${version}`;
  };
  onSelectBuildConfig = (buildConfigId, buildConfig, total) => {
    if (total == 0) {
      this.setState({ s3UrlRF: "" });
    }
    const { category, project, build } = this.props.match.params;
    this.setState({ total: total });

    this.props.dispatch(
      getTestCaseForAllRegions(category, project, build, Number(buildConfigId))
    );
    this.props.dispatch(
      getLatestTestplan(category, project, build, Number(buildConfigId))
    );
    this.setState({
      buildConfig,
      buildConfigId,
    });
  };

  onCompleted = () => {
    const { category, project, build } = this.props.match.params;
    const values = {
      status: constants.STATUS_COMPLETED,
    };
    this.props.dispatch(updateBuildStatus(category, project, build, values));
  };

  render() {
    const { buildConfigs, getLoadingStatus } = this.props.buildConfigReducer;
    const { latestTestplanData } = this.props.testplanReducer;
    const { category, project, build } = this.props.match.params;
    const { loadingUpdateTestcasesStatus } = this.props.testcaseReducer;
    const {
      testcaseTableData,
      testcaseDataLoadingStatus,
      loadingUpdateBuildStatus,
    } = this.props.overviewReducer;
    return (
      <div>
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <BreadcrumbGroup
              textGroup={[
                { text: capitalizeFirstLetter(this.state.categoryName) }, // Category
                { text: capitalizeFirstLetter(this.state.projectName) }, // project
                { text: this.state.buildAndVersion }, // build
                { text: "RF" },
              ]}
            />
          </div>
          <div className="awsui-util-action-stripe-group">
            <Iframe />
          </div>
        </div>
        <br />
        <Container header={<Header variant="h2">Build Overview</Header>}>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                width: "80%",
              }}
            >
              <BuildOverviewChart
                data={buildConfigs}
                loadingStatus={getLoadingStatus}
              />
            </div>
            <div
              className="border-right"
              style={{
                marginRight: "20px",
              }}
            />
            <SpaceBetween size="l">
              <ValueWithLabel label="Status">
                {this.state.buildStatus}
              </ValueWithLabel>
              <ValueWithLabel label="Street Date">
                {this.state.projectStreetDate}
              </ValueWithLabel>
              <ValueWithLabel label="Start Date">
                {this.state.buildStartDate}
              </ValueWithLabel>
              <ValueWithLabel label="End Date">
                {this.state.buildEndDate}
              </ValueWithLabel>
              {/*<ValueWithLabel label="Mark build as completed">
                <Button
                  variant="primary"
                  onClick={this.onCompleted}
                  disabled={
                    this.state.buildStatus === constants.STATUS_COMPLETED
                  }
                  loading={loadingUpdateBuildStatus === constants.LOADING_LOAD}
                  className="mt-2"
                >
                  Complete
                </Button>
                </ValueWithLabel>*/}
            </SpaceBetween>
          </div>
        </Container>
        <br />
        <BuildConfigOverview
          data={buildConfigs}
          loadingStatus={getLoadingStatus}
          category={category}
          project={project}
          build={build}
          onSelectBuildConfig={this.onSelectBuildConfig}
        />
        <br />
        {testcaseDataLoadingStatus != constants.LOADING_DEFAULT && (
          <Container
            header={
              <Header variant="h2">
                {this.state.buildConfig + " Overview"}
              </Header>
            }
          >
            <ColumnLayout columns={1}>
              <RegionOverviewChart
                loadingStatus={testcaseDataLoadingStatus}
                data={testcaseTableData}
              />
              <OverviewTable
                title={"Test Cases"}
                data={testcaseTableData}
                loadingStatus={testcaseDataLoadingStatus}
                history={this.props.history}
                dispatch={this.props.dispatch}
                category={category}
                project={project}
                build={build}
                buildConfigId={this.state.buildConfigId}
                s3Link={this.state.s3UrlRF}
                loadingUpdateTestcasesStatus={loadingUpdateTestcasesStatus}
              />
            </ColumnLayout>
          </Container>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    overviewReducer: state.overviewReducer,
    metadataReducer: state.metadataReducer,
    testcaseReducer: state.testcaseReducer,
    testplanReducer: state.testplanReducer,
    buildConfigReducer: state.buildConfigReducer,
    downloadReducer: state.downloadReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(Overview));

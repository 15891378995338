import React, { Component, useEffect, useState } from "react";
import {
  Table,
  TableSorting,
  TablePropertyFiltering,
  TablePagination,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
  DatePicker,
} from "@amzn/awsui-components-react/polaris";

import {
  defaultPageSize,
  columnDefinitions,
  features,
  defaultSortingColumn,
  sortableColumns,
  filteringOptions,
  visibleColumns,
} from "./table-config";
import constants from "../../../constants";
import { customizedFilteringFunction } from "../../../components/polaris/table/property-filtering-utils";
import { Button } from "@amzn/awsui-components-react-v3";
import { getUpload } from "../../../redux/actions/upload-action";
import { Dispatch } from "redux";
import { METRIC_INFO } from "../../../constants";
import { connect } from "react-redux";

interface StateProps {
  uploadReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

const defaultProps = Object.freeze({});

const UploadTable = ({ dispatch, uploadReducer }: Props) => {
  const start_date: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
  const end_date: Date = new Date();
  const [currentPageIndex, setcurrentPageIndex] = useState(1);
  const [pagesCount, setPagesCount] = useState(0);
  const [startDate, setStartDate] = useState(
    start_date.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState(end_date.toISOString().split("T")[0]);

  useEffect(() => {
    _getUploadStatus();
  }, []);

  useEffect(() => {
    if (startDate && endDate) getFilteredData();
    else if (!startDate && !endDate) _getUploadStatus();
  }, [startDate, endDate]);

  // get upload status data
  const _getUploadStatus = (
    syncMode: boolean = false,
    initiative: string = METRIC_INFO.INITIATIVE_RF,
    start_date: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    end_date: Date = new Date()
  ) => {
    dispatch(
      getUpload(
        start_date.toISOString().split("T")[0],
        end_date.toISOString().split("T")[0],
        syncMode,
        initiative
      )
    );
  };

  const _onPaginationChange = (event) => {
    const { currentPageInd, pagesCnt } = event.detail;
    setcurrentPageIndex(currentPageInd);
    setPagesCount(pagesCnt);
  };

  const getFilteredData = () => {
    let filteredData: Array<any> = [];
    const { data } = uploadReducer;
    if (data && data.length > 0) {
      if (!endDate || !startDate || new Date(endDate) < new Date(startDate))
        filteredData = data;
      else {
        //filter data by date
        let end_date = new Date(endDate);
        let start_date = new Date(startDate);
        _getUploadStatus(
          false,
          METRIC_INFO.INITIATIVE_RF,
          start_date,
          end_date
        );
      }
    }
    return filteredData;
  };

  const setStartDateFun = (detail) => {
    setStartDate(detail.value);
  };
  const setEndDateFun = (detail) => {
    setEndDate(detail.value);
  };
  const { data, loadingStatus } = uploadReducer;
  return (
    <Table
      columnDefinitions={columnDefinitions}
      items={data}
      loading={loadingStatus === constants.LOADING_LOAD}
      features={features}
      header={
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>
              Upload Logs &nbsp;
              <span className="awsui-util-header-counter">{`(${data.length})`}</span>
            </h2>
          </div>
          <div className="awsui-util-action-stripe-group">
            <div style={{ paddingLeft: "0.5rem" }}>
              <DatePicker
                id="start-date"
                placeholder="Start Date"
                todayLabel="Today"
                nextMonthLabel="Next month"
                previousMonthLabel="Previous month"
                onChange={({ detail }) => setStartDateFun(detail)}
                value={startDate}
              ></DatePicker>
            </div>
            <div style={{ paddingLeft: "0.5rem" }}>
              <DatePicker
                id="end-date"
                placeholder="End Date"
                todayLabel="Today"
                nextMonthLabel="Next month"
                previousMonthLabel="Previous month"
                onChange={({ detail }) => setEndDateFun(detail)}
                value={endDate}
              ></DatePicker>
            </div>
            <div style={{ paddingLeft: "0.5rem" }}>
              <Button
                iconName="refresh"
                variant="icon"
                onClick={() => {
                  setStartDate(start_date.toISOString().split("T")[0]);
                  setEndDate(end_date.toISOString().split("T")[0]);
                }}
              >
                Apply Date Filter
              </Button>
            </div>
          </div>
        </div>
      }
      empty={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No resources</b>
          </div>
          <p className="awsui-util-mb-s">No resources to display.</p>
        </div>
      }
      wrapLines={false}
      noMatch={
        <div className="awsui-util-t-c">
          <div className="awsui-util-pt-s awsui-util-mb-xs">
            <b>No matches</b>
          </div>
          <p className="awsui-util-mb-s">We can’t find a match.</p>
          <div className="awsui-util-mb-m">
            <Button>Clear filter</Button>
          </div>
        </div>
      }
    >
      <TablePropertyFiltering
        filteringOptions={filteringOptions}
        filteringFunction={customizedFilteringFunction}
        groupValuesText="Values"
        groupPropertiesText="Properties"
        operationAndText="and"
        operationNotAndText="and not"
        operationOrText="or"
        operationNotOrText="or not"
        clearFiltersText="Clear filter"
        placeholder="Filter resources by property or value"
        allowFreeTextFiltering={true}
        filteringCountTextFunction={(count) =>
          `${count} ${count === 1 ? "match" : "matches"}`
        }
      ></TablePropertyFiltering>
      <TablePagination
        pageSize={defaultPageSize}
        onPaginationChange={_onPaginationChange}
      ></TablePagination>
      <TableSorting
        sortingColumn={defaultSortingColumn}
        sortableColumns={sortableColumns}
        sortingDescending={true}
      ></TableSorting>
      <TablePreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
      >
        <TablePageSizeSelector
          title="Page size"
          options={[
            { value: 3, label: "3 items" },
            { value: 5, label: "5 items" },
            { value: 10, label: "10 items" },
            { value: 20, label: "20 items" },
            { value: 50, label: "50 items" },
          ]}
        />
        <TableWrapLines
          label="Wrap lines"
          description="Enable to wrap table cell content, disable to truncate text."
        />
        <TableContentSelector
          title="Select visible columns"
          options={visibleColumns}
        />
      </TablePreferences>
    </Table>
  );
};

const mapStateToProps = (state) => {
  return {
    uploadReducer: state.uploadReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(UploadTable);

import React, { Component } from "react";
import {
  Form,
  Button,
  Alert,
  Header,
  SpaceBetween,
  Container,
} from "@amzn/awsui-components-react-v3/polaris";
// sub components
import UploadFileDropzone from "./file-dropzone";
import UploadingModal from "./uploading-model";
// util func
import { fileNameCheckCall, fileUploadS3Call, getMetricInfo } from "./utils";
// constans
import constants, {
  UPLOAD_STATUS,
  TEST_CATEGORY,
  METRIC_INFO,
} from "../../../constants";
import { FileStatus, UpdateLog } from "../../../model/upload";

type Props = {
  getUploadStatus: (syncMode: boolean, initiative: string) => void; // func to get table data
  updateLogs: (values: any, initiative: string) => void; // func to update logs on manual upload
  initiative: string; // initiative is emi or rf
};
type State = {
  fileList: Array<File>; // the File object array
  fileCheckDict: { [key: string]: FileStatus }; // file_name -> fileCheck
  fileUploadDict: { [key: string]: FileStatus }; // file_name -> fileUpload
  showModal: boolean;
};

class ManualUploadPanel extends Component<Props, State> {
  state: State = Object.freeze({
    fileList: [],
    fileCheckDict: {},
    fileUploadDict: {},
    showModal: false,
  });

  // event: when on drag and drop file in dropzone
  _onChangeFiles = (files: Array<File>) => {
    const { fileList, fileCheckDict, fileUploadDict } = this.state;

    const newFileList: Array<File> = [];
    const newFileCheckDict: { [key: string]: FileStatus } = {};
    const newFileUploadDict: { [key: string]: FileStatus } = {};

    files.forEach((file: File) => {
      // repeated file
      if (!(file.name in fileCheckDict)) {
        newFileList.push(file);
        newFileCheckDict[file.name] = {
          status: constants.LOADING_LOAD,
          message: "",
          s3_link: "",
        };
        newFileUploadDict[file.name] = {
          status: constants.LOADING_LOAD,
          message: "",
          s3_link: "",
        };
      }
    });

    this.setState({
      fileList: [...fileList, ...newFileList],
      fileCheckDict: { ...fileCheckDict, ...newFileCheckDict },
      fileUploadDict: { ...fileUploadDict, ...newFileUploadDict },
    });

    // check file name
    this._validateFilename();
  };

  // loop each file, and send ajax call to validate file name
  _validateFilename = async () => {
    const { fileList } = this.state;
    fileList.forEach((file: File) => {
      fileNameCheckCall(file, this.props.initiative).then((rst: FileStatus) => {
        const { ...fileCheckDict } = this.state.fileCheckDict;
        fileCheckDict[file.name] = rst;
        this.setState({
          fileCheckDict,
        });
      });
    });
  };

  /*
    loop each file, 
    1. get presigned url
    2. the upload to s3
   */
  _uploadFiles = async () => {
    const { fileList } = this.state;
    let count = 0;
    fileList.forEach((file: File, index) => {
      fileUploadS3Call(file, this.props.initiative).then((rst: FileStatus) => {
        const { ...fileUploadDict } = this.state.fileUploadDict;
        fileUploadDict[file.name] = rst;
        count += 1;
        this.setState({
          fileUploadDict,
        });
        if (count === fileList.length) this._updateLogs();
      });
    });
  };

  _updateLogs = () => {
    const { fileUploadDict } = this.state;
    const metric_val = getMetricInfo(this.props.initiative, METRIC_INFO);
    let items: Array<UpdateLog> = [];
    Object.keys(fileUploadDict).forEach((file: string) => {
      if (fileUploadDict[file].status === 1) {
        const item = {
          file_name: file,
          status: UPLOAD_STATUS.SUCCESS,
          test_type:
            this.props.initiative == constants.INITIATIVE_EMI
              ? TEST_CATEGORY.EMI
              : TEST_CATEGORY.RF,
          s3_link: fileUploadDict[file].s3_link,
        };
        items.push(item);
      }
    });
    this.props.updateLogs(items, metric_val);
  };

  // onClick clear button
  _onClear = () => {
    this.setState({
      fileList: [],
      fileCheckDict: {},
      fileUploadDict: {},
      showModal: false,
    });
  };

  // delete a file
  _deleteFile = (filename: string) => {
    let [...fileList] = this.state.fileList;
    const { ...fileCheckDict } = this.state.fileCheckDict;
    const { ...fileUploadDict } = this.state.fileUploadDict;

    if (filename in fileCheckDict) {
      delete fileCheckDict[filename];
    }

    if (filename in fileUploadDict) {
      delete fileUploadDict[filename];
    }

    fileList = fileList.filter((file: File) => {
      return file.name !== filename;
    });

    this.setState({
      fileList,
      fileCheckDict,
      fileUploadDict,
    });
  };

  /*
    onClick Upload button
    1. fileList is empty => alert
    2. validate file name failed => alert
    3. all good => confirm
        3.1 no => clear and return
        3.2 yes => open model, start upload process
  */
  _onClickUpload = () => {
    // 1. fileList is empty
    if (this.state.fileList.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!this._validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm(
      "Files will be uploaded to LENS. Click OK to confirm. "
    );
    if (!isConfirmed) {
      return;
    }

    this._showModal();

    this._uploadFiles();
  };

  /* 
    check file name validation in fileCheckList
    return false if any of the file name is invalid
    return true if all file names are valid
  */
  _validateForm = () => {
    const { fileList, fileCheckDict } = this.state;
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status === constants.LOADING_FAIL
      ) {
        return false;
      }
    }
    return true;
  };

  /* 
    check if need to disable upload button
    case 1: fileCheckList is empty
    case 2: Any file name is checking or failed
  */
  _checkDisableUploadButton = () => {
    // case 1:
    const { fileList, fileCheckDict } = this.state;
    if (fileList.length === 0) {
      return true;
    }

    // case 2:
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status !== constants.LOADING_SUCCESS
      ) {
        return true;
      }
    }

    // otherwise, do not disable, return false
    return false;
  };

  _showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  _closeModal = () => {
    const metric_val = getMetricInfo(this.props.initiative, METRIC_INFO);
    this.setState({
      showModal: false,
    });

    // clear form
    this._onClear();

    // refetch upload status data, after 3s, give parse time to write log into db
    // tmp solution
    setTimeout(() => this.props.getUploadStatus(true, metric_val), 3000);
  };

  render() {
    const hasError: boolean = !this._validateForm(); // has any file name error flag
    const disableUploadBtn: boolean = this._checkDisableUploadButton(); // if disable upload button flag
    const { showModal, fileList, fileCheckDict, fileUploadDict } = this.state;

    const description =
      this.props.initiative == constants.INITIATIVE_RF
        ? "Drag and drop to upload rf files"
        : "Drag and drop to upload emi files";
    return (
      <Container>
        <div>
          {/* main upload portion */}
          <Form
            header={<Header variant="h1" description={description}></Header>}
          >
            {hasError && (
              <React.Fragment>
                <Alert header="Errors Detected" type="error">
                  Please check you file name, and try again.
                </Alert>
                <br />
              </React.Fragment>
            )}

            <UploadFileDropzone
              fileList={fileList}
              fileCheckDict={fileCheckDict}
              onChange={this._onChangeFiles}
              deleteFile={this._deleteFile}
            />
          </Form>
          <br />

          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this._onClear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onClickUpload}
              disabled={disableUploadBtn}
            >
              Upload
            </Button>
          </SpaceBetween>
        </div>

        {/* Uploading Modal */}
        <UploadingModal
          showModal={showModal}
          onDismiss={this._onClear}
          onClose={this._closeModal}
          fileList={fileList}
          fileUploadDict={fileUploadDict}
        />
      </Container>
    );
  }
}

export default ManualUploadPanel;

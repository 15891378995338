import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import ImportTestplanForm from "./import-testplan-form";
import { Button, Modal } from "@amzn/awsui-components-react-v3";
import constants from "../../constants";

interface StateProps {
  testplanReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  disabled: boolean;
} & StateProps;

type State = {
  openModal: boolean;
};

class ImportTestplan extends Component<Props, State> {
  state: State = Object.freeze({
    openModal: false,
  });

  componentDidUpdate(prevProps, prevState) {
    const prevLoadingImportTestplanStatus =
      prevProps.testplanReducer.loadingImportTestplanStatus;
    const { loadingImportTestplanStatus } = this.props.testplanReducer;
    if (prevLoadingImportTestplanStatus !== loadingImportTestplanStatus) {
      if (loadingImportTestplanStatus === constants.LOADING_SUCCESS) {
        this._closeModal();
      } else if (loadingImportTestplanStatus === constants.LOADING_FAIL) {
        this._closeModal();
        alert("Error while Importing Testplan, please retry.");
      }
    }
  }

  _openModal = () => {
    this.setState({
      openModal: true,
    });
  };

  _closeModal = () => {
    this.setState({
      openModal: false,
    });
  };

  _submitModal = () => {};

  render() {
    return (
      <>
        <Button
          iconName="file"
          variant="primary"
          onClick={() => this._openModal()}
          disabled={this.props.disabled}
        >
          Import Testplan
        </Button>
        <Modal
          visible={this.state.openModal}
          header="Import Testplan"
          onDismiss={() => this._closeModal()}
        >
          <ImportTestplanForm closeModal={this._closeModal} />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testplanReducer: state.testplanReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(ImportTestplan);

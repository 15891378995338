import React from "react";
import { Table } from "react-bootstrap";
import { Button } from "@amzn/awsui-components-react-v3";
import { Field } from "redux-form";
import {
  IoIosClose,
  IoMdCopy,
  IoIosArrowUp,
  IoIosArrowDown,
} from "react-icons/io";
import { sortUp, sortDown, getNextId } from "./testplan-config";

//renders input fields
const renderField = ({ input, label, type, meta: { touched, error } }) => (
  <div>
    <textarea
      className={
        (error
          ? "test-plan-input-error text-input"
          : "test-plan-input text-input") +
        " input-" +
        label
      }
      {...input}
      type={type}
      placeholder={label}
    />
    {error && <span className="test-plan-form-error">{error}</span>}
  </div>
);

//test plan table
const testPlanTable = ({
  fields,
  meta: { error, submitFailed },
  sectionPrefix,
}) => {
  return (
    <div className="wise-main-container test-plan-table-container">
      {error && <span className="test-plan-form-error">{error}</span>}
      <Table className="test-plan-table">
        <thead>
          <tr>
            <th className="row-id wise-bootstrap-table-header table-heading">
              ID &nbsp; &nbsp; &nbsp;
              <IoIosArrowUp
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortUp(arr, "sheet_id");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
              <IoIosArrowDown
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortDown(arr, "sheet_id");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
            </th>
            <th className="row-config wise-bootstrap-table-header table-heading">
              Config &nbsp; &nbsp; &nbsp;
              <IoIosArrowUp
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortUp(arr, "config");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
              <IoIosArrowDown
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortDown(arr, "config");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
            </th>
            <th className="row-section wise-bootstrap-table-header table-heading">
              Section &nbsp; &nbsp; &nbsp;
              <IoIosArrowUp
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortUp(arr, "section");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
              <IoIosArrowDown
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortDown(arr, "section");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
            </th>
            <th className="row-name wise-bootstrap-table-header table-heading">
              Test Case Name &nbsp; &nbsp; &nbsp;
              <IoIosArrowUp
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortUp(arr, "title");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
              <IoIosArrowDown
                className="icon-hover"
                size={15}
                onClick={() => {
                  let arr = fields.getAll();
                  arr = sortDown(arr, "title");
                  fields.removeAll();
                  arr.forEach((item) => {
                    fields.push(item);
                  });
                }}
              />
            </th>
            <th className="row-action wise-bootstrap-table-header">
              <Button
                className="wise-btn normal"
                onClick={(e) => {
                  e.preventDefault();
                  fields.push({
                    sheet_id: getNextId(fields),
                    section: sectionPrefix,
                  });
                }}>
                Add Case
              </Button>
            </th>
          </tr>
        </thead>
        <tbody>
          {/* TODO: update attributes here */}
          {fields.map((testCase, index) => {
            return (
              <tr key={index}>
                <td>
                  <Field
                    name={`${testCase}.sheet_id`}
                    type="text"
                    component={renderField}
                    label="id"
                    value={testCase.sheet_id}
                  />
                </td>
                <td>
                  <Field
                    name={`${testCase}.config`}
                    type="text"
                    component={renderField}
                    label="config"
                    value={testCase.config}
                  />
                </td>
                <td>
                  <Field
                    name={`${testCase}.section`}
                    type="text"
                    component={renderField}
                    label="section"
                    value={testCase.section}
                  />
                </td>
                <td>
                  <Field
                    name={`${testCase}.name`}
                    type="text"
                    component={renderField}
                    label="name"
                    value={testCase.name}
                  />
                </td>
                <td>
                  <Button
                    className="wise-btn normal icon-button btn-outline-light"
                    onClick={(e) => {
                      e.preventDefault();
                      var toCopyCase = fields.get(index);
                      var copiedCase = {
                        sheet_id: getNextId(fields),
                        name: toCopyCase.name,
                        section: toCopyCase.section,
                        config: toCopyCase.config,
                      };
                      fields.splice(index + 1, 0, copiedCase);
                    }}>
                    <IoMdCopy size={25} />
                  </Button>
                  &emsp;
                  <Button
                    className="wise-btn normal icon-button btn-outline-light"
                    onClick={(e) => {
                      e.preventDefault();
                      fields.remove(index);
                    }}>
                    <IoIosClose size={25} />
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default testPlanTable;

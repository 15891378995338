import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { statusStyle, PAGE_SIZE_OPTIONS } from "../../overview/common";
import React from "react";

// define columns
export const TESTCASE_TABLE_COLUMN_DEFINITIONS = [
  {
    id: "section",
    header: "Section",
    cell: (item) => item["section"],
    sortingField: "section",
    visible: true,
    minWidth: 150,
  },
  {
    id: "name",
    header: "TestItem",
    cell: (item) => item["name"],
    sortingField: "name",
    visible: true,
    minWidth: 200,
  },
  {
    id: "upload_time",
    header: "Upload Time",
    cell: (item) => item["upload_time"],
    sortingField: "upload_time",
    visible: true,
    minWidth: 100,
  },
  {
    id: "status",
    header: "Status",
    cell: (item) => statusStyle(item.status),
    sortingField: "status",
    visible: true,
    minWidth: 100,
  },

  {
    id: "comment",
    header: "Comment",
    cell: (item) => item["comment"],
    sortingField: "comment",
    visible: true,
    minWidth: 200,
  },
];

// define visible columns
export const TESTCASE_TABLE_DATA_VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Critical Data",
    options: [
      {
        id: "section",
        label: "Section",
        visible: true,
        editable: true,
      },
      {
        id: "name",
        label: "TestItem",
        visible: true,
        editable: true,
      },
      {
        id: "upload_time",
        label: "Upload Time",
        visible: true,
        editable: true,
      },
      {
        id: "status",
        label: "Status",
        visible: true,
        editable: false,
      },
      {
        id: "comment",
        label: "Comment",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const TESTCASE_DATA_FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: "Section",
    key: "section",
    groupValuesLabel: "section",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "TestItem",
    key: "name",
    groupValuesLabel: "name",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "upload Time",
    key: "upload_time",
    groupValuesLabel: "upload_time",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "status",
    key: "status",
    groupValuesLabel: "status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "comment",
    key: "comment",
    groupValuesLabel: "comment",
    operators: [":", "!:", "=", "!="],
  },
];

// define default preferences
export const TESTCASE_DEFAULT_PREFERENCES = () => {
  return {
    pageSize: 10,
    visibleContent: TESTCASE_TABLE_COLUMN_DEFINITIONS.filter(
      (column) => column.visible
    ).map((column) => column.id),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = TESTCASE_TABLE_DATA_VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

export const getNextId = (fields) => {
  var idMax = 0;
  for (var index = 0; index < fields.length; index++) {
    var sheetId = fields.get(index).sheet_id;
    if (sheetId) {
      var idSplit = sheetId.toString().match(/[a-zA-Z]+|[0-9]+/g);
      if (idSplit && idSplit.length > 0 && !isNaN(idSplit[0])) {
        idMax = Math.max(idMax, parseInt(idSplit[0]));
      }
    }
  }
  return (idMax + 1).toString();
};

export const sortUp = (arr, key) => {
  if (key === "sheet_id") {
    arr.sort((a, b) => {
      if (a.sheet_id && b.sheet_id) {
        var aParts = a.sheet_id.match(/[a-zA-Z]+|[0-9]+/g);
        var bParts = b.sheet_id.match(/[a-zA-Z]+|[0-9]+/g);
        if (aParts && bParts) {
          if (aParts.length < 2) aParts.push("");
          if (bParts.length < 2) bParts.push("");
          if (aParts[0] === bParts[0]) {
            if (aParts[1] === bParts[1]) {
              return 0;
            } else {
              return aParts[1] > bParts[1] ? 1 : -1;
            }
          } else if (!isNaN(aParts[0]) && !isNaN(bParts[0])) {
            return parseInt(aParts[0]) > parseInt(bParts[0]) ? 1 : -1;
          } else {
            return 0;
          }
        } else return 0;
      } else return 0;
    });
  } else if (key === "section") {
    arr.sort((a, b) => {
      if (a.section < b.section) return -1;
      if (a.section > b.section) return 1;
      return 0;
    });
  } else if (key === "name") {
    arr.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    });
  } else if (key === "config") {
    arr.sort((a, b) => {
      if (a.config < b.config) return -1;
      if (a.config > b.config) return 1;
      return 0;
    });
  }
  return arr;
};

export const sortDown = (arr, key) => {
  if (key === "sheet_id") {
    arr.sort((a, b) => {
      if (a.sheet_id && b.sheet_id) {
        var aParts = a.sheet_id.match(/[a-zA-Z]+|[0-9]+/g);
        var bParts = b.sheet_id.match(/[a-zA-Z]+|[0-9]+/g);
        if (aParts && bParts) {
          if (aParts.length < 2) aParts.push("");
          if (bParts.length < 2) bParts.push("");
          if (aParts[0] === bParts[0]) {
            if (aParts[1] === bParts[1]) {
              return 0;
            } else {
              return aParts[1] < bParts[1] ? 1 : -1;
            }
          } else if (!isNaN(aParts[0]) && !isNaN(bParts[0])) {
            return parseInt(aParts[0]) < parseInt(bParts[0]) ? 1 : -1;
          } else {
            return 0;
          }
        } else return 0;
      } else return 0;
    });
  } else if (key === "section") {
    arr.sort((a, b) => {
      if (a.section < b.section) return 1;
      if (a.section > b.section) return -1;
      return 0;
    });
  } else if (key === "name") {
    arr.sort((a, b) => {
      if (a.name < b.name) return 1;
      if (a.name > b.name) return -1;
      return 0;
    });
  } else if (key === "config") {
    arr.sort((a, b) => {
      if (a.config < b.config) return 1;
      if (a.config > b.config) return -1;
      return 0;
    });
  }
  return arr;
};

export const sectionList = [
  { value: "FCC Radiated EMI" },
  { value: "FCC Conducted EMI" },
  { value: "CE Radiated EMI" },
  { value: "CE Conducted EMI" },
  { value: "EU Radiated EMI" },
  { value: "FCC Radiated EMI+" },
  { value: "FCC Radiated Emissions" },
];

import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  ColumnLayout,
  Button,
  Input,
  Textarea,
  Select,
  DatePicker,
} from "@amzn/awsui-components-react/polaris";
import {
  Modal,
  Box,
  Button as AlertButton,
  SpaceBetween,
  FormField,
} from "@amzn/awsui-components-react-v3/polaris";
import {
  createProject,
  resetProject,
} from "../../../redux/actions/project-action";
import "../create-form.css";
import constants, {
  COMPONENT_ID,
  deviceCategories,
  DROPDOWN_DEFAULT,
} from "../../../constants";
import { getMetadata } from "../../../redux/actions/metadata-action";
import { createProjectRequest } from "../../../model/http-json";
import { InfoText } from "../info-text/info-text";

interface StateProps {
  categoryReducer: any;
  metadataReducer: any;
  projectReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category_id: number;
  categoryError: string; // input error
  project: string; // user input for creating project
  projectError: string; // input error
  streetDate: string; // input error
  streetDateError: string; // input error
  commentError: string; // input error
  responseError: string; // response error from BE
  comment: string; // user input
  modelNumber: string;
  modelNumberError: string;
  pvtrr: string;
  pvtrrError: string;
  announceDate: string;
  announceDateError: string;
  selectedDeviceCategory: any; // user input
  deviceCategoryError: string;
};

class ProjectForm extends Component<Props, State> {
  state: State = Object.freeze({
    category_id: 0,
    categoryError: "",
    project: "",
    projectError: "",
    streetDate: "",
    streetDateError: "",
    commentError: "",
    responseError: "",
    comment: "",
    modelNumber: "",
    modelNumberError: "",
    pvtrr: "",
    pvtrrError: "",
    announceDate: "",
    announceDateError: "",
    selectedDeviceCategory: null,
    deviceCategoryError: "",
  });

  componentDidMount() {}

  //validate input
  validate = (validateAll: boolean, id: string) => {
    const isCategoryValid: boolean = this.validateById(COMPONENT_ID.CATEGORY);
    const isProjectValid: boolean = this.validateById(COMPONENT_ID.PROJECT);
    const isCommentValid: boolean = this.validateById(COMPONENT_ID.COMMENT);
    const isStreetDateValid: boolean = this.validateById(
      COMPONENT_ID.STREET_DATE
    );
    const isModelNumberValid: boolean = this.validateById(
      COMPONENT_ID.MODEL_NUMBER
    );
    const isDeviceCategoryValid: boolean = this.validateById(
      COMPONENT_ID.DEVICE_CATEGORY
    );
    const isPvtRrValid: boolean = this.validateById(COMPONENT_ID.PVT_RR);
    const isAnnounceDateValid: boolean = this.validateById(
      COMPONENT_ID.ANNOUNCE_DATE
    );
    return (
      isCategoryValid &&
      isProjectValid &&
      isCommentValid &&
      isStreetDateValid &&
      isModelNumberValid &&
      isDeviceCategoryValid &&
      isPvtRrValid &&
      isAnnounceDateValid
    );
  };

  validateById = (id: string): boolean => {
    let isValid: boolean = true;

    // validate category
    if (id === COMPONENT_ID.CATEGORY) {
      let categoryError: string = "";
      if (!this.state.category_id) {
        categoryError = "Required";
        isValid = false;
      }

      this.setState({
        categoryError,
      });
    }

    // validate project
    if (id === COMPONENT_ID.PROJECT) {
      const project = this.state.project;
      let projectError: string = "";

      if (!project) {
        projectError = "Required";
      } else if (!/^[A-Za-z0-9]+$/i.test(project)) {
        projectError = "Letters and numbers only";
      }

      this.setState({
        projectError,
      });

      if (projectError) {
        isValid = false;
      }
    }

    // validate model number
    if (id === COMPONENT_ID.MODEL_NUMBER) {
      const modelNumber = this.state.modelNumber;
      let modelNumberError: string = "";

      if (!modelNumber) {
        modelNumberError = "Required";
      }

      this.setState({
        modelNumberError,
      });

      if (modelNumberError) {
        isValid = false;
      }
    }

    // validate pvt RR
    if (id === COMPONENT_ID.PVT_RR) {
      let pvtrrError: string = "";

      // No input validation according to current requirements

      this.setState({
        pvtrrError,
      });

      if (pvtrrError) {
        isValid = false;
      }
    }

    // validate ANNOUNCE_DATE
    if (id === COMPONENT_ID.ANNOUNCE_DATE) {
      let announceDateError: string = "";

      // No input validation according to current requirements

      this.setState({
        announceDateError,
      });

      if (announceDateError) {
        isValid = false;
      }
    }

    // Validate Device Category
    if (id === COMPONENT_ID.DEVICE_CATEGORY) {
      const deviceCategory = this.state.selectedDeviceCategory;
      let deviceCategoryError: string = "";
      if (!deviceCategory || Object.keys(deviceCategory).length === 0) {
        deviceCategoryError = "Required";
      }
      this.setState({
        deviceCategoryError,
      });

      if (deviceCategoryError) {
        isValid = false;
      }
    }

    // validate comment
    if (id === COMPONENT_ID.COMMENT) {
      const comment = this.state.comment;
      let commentError: string = "";

      if (comment && comment.length > 200) {
        commentError = "Max Length 200";
      }

      this.setState({
        commentError,
      });

      if (commentError) {
        isValid = false;
      }
    }

    if (id === COMPONENT_ID.STREET_DATE) {
      const streetDate = this.state.streetDate;
      let streetDateError: string = "";
      if (!streetDate) {
        streetDateError = "Required";
      }

      this.setState({
        streetDateError,
      });

      if (streetDateError) {
        isValid = false;
      }
    }

    return isValid;
  };

  /* 
    onClick submit button
    1. validate user input, show input error if find any
    2. sent request to BE
    3. show response to user
      3.1 If failed, show response error
      3.2 If success, ask user choose to continue creating build or not
  */
  _onSubmit = () => {
    // clean up response error from last submit
    this.setState({ responseError: "" });

    const isValid = this.validate(true, "");
    if (!isValid) {
      return;
    }

    const category_id: number = this.state.category_id;
    const project: string = this.state.project;
    const streetDate: string = this.state.streetDate;
    const comment: string = this.state.comment;
    const modelNumber: string = this.state.modelNumber;
    const deviceCategories: {} = this.state.selectedDeviceCategory;
    const pvtrr: string = this.state.pvtrr;
    const announceDate: string = this.state.announceDate;
    const values: createProjectRequest = {
      name: project,
      street_date: streetDate,
      model_number: modelNumber,
      device_category: deviceCategories["label"],
      pvt_rr: pvtrr,
      announce_date: announceDate,
      comment,
      category_id,
      initiative: constants.INTIATIVE,
    };
    this.props.dispatch(createProject(values));
  };

  reset = (success: boolean) => {
    // update side bar data
    this.props.dispatch(getMetadata());
    // reset reducer
    this.props.dispatch(resetProject());
    if (success) this.clear();
  };

  nextForm = (success: boolean) => {
    // update side bar data
    this.props.dispatch(getMetadata());
    // reset reducer
    this.props.dispatch(resetProject());

    if (success) this.clear();
    // navigate to create project
    // this.props.history.push("/build/create");
  };

  clear = () => {
    this.setState({
      category_id: 0,
      categoryError: "",
      project: "",
      projectError: "",
      streetDate: "",
      streetDateError: "",
      responseError: "",
      comment: "",
      modelNumber: "",
      modelNumberError: "",
      pvtrr: "",
      pvtrrError: "",
      announceDate: "",
      announceDateError: "",
      selectedDeviceCategory: null,
      deviceCategoryError: "",
    });
  };

  _onChangeCategory = (event: any) => {
    const category_id = event.detail.selectedId;
    this.setState({
      category_id,
      categoryError: "",
    });
  };

  _onChangeProject = (event: any) => {
    const project: string = event.detail.value;
    this.setState({
      project,
    });

    // validate user input
    const id: string = event.target.id;
    this.validateById(id);
  };

  _onChangeModelNumber = (event: any) => {
    const modelNumber: string = event.detail.value;
    this.setState({
      modelNumber,
    });

    // validate user input
    const id: string = event.target.id;
    this.validateById(id);
  };

  _onChangePvtRR = (event: any) => {
    const pvtrr: string = event.detail.value;
    this.setState({
      pvtrr,
    });

    // validate user input
    const id: string = event.target.id;
    this.validateById(id);
  };

  _onChangeAnnounceDate = (event: any) => {
    const announceDate: string = event.detail.value;
    this.setState({
      announceDate: announceDate,
      announceDateError: "",
    });
  };

  _onChangeDeviceCategory = (event) => {
    this.setState({
      deviceCategoryError: "",
      selectedDeviceCategory: event.detail.selectedOption,
    });
  };

  _onChangeStreetDate = (event: any) => {
    const streetDate: string = event.detail.value;
    this.setState({
      streetDate,
    });
    const id: string = event.target.id;
    this.validateById(id);
  };

  _onChangeComment = (event: any) => {
    const comment: string = event.detail.value;
    this.setState({
      comment,
    });

    // validate user input
    const id: string = event.target.id;
    this.validateById(id);
  };

  getCategoryOptions = (treedata: any) => {
    let optionList: Array<Select.Option> = [];
    for (let categoryId in treedata) {
      let category: Select.Option = {
        id: String(categoryId),
        label: treedata[categoryId].name,
      };
      optionList.push(category);
    }
    return optionList;
  };

  getOptions = (values: string[]) => {
    let options: Array<any> = [];
    Array.from(values).forEach((value) => {
      options.push({
        id: value,
        label: value,
      });
    });
    return options;
  };

  render() {
    const { message, loadingStatus, responseData } = this.props.projectReducer;
    const categoryOptions: Array<Select.Option> =
      this.props.metadataReducer && this.props.metadataReducer.filter
        ? this.getCategoryOptions(this.props.metadataReducer.filter)
        : [];

    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Project</h2>
        </div>
        <div>
          {/* main form portion */}

          <div>
            <ColumnLayout columns={4}>
              <div data-awsui-column-layout-root="true">
                <FormField
                  label="Category"
                  errorText={this.state.categoryError}
                  description="* Required"
                >
                  <Select
                    options={categoryOptions}
                    placeholder="Category"
                    onChange={this._onChangeCategory}
                    selectedId={this.state.category_id.toString()}
                  ></Select>
                </FormField>

                <FormField
                  label="Project"
                  constraintText="Letters and numbers only"
                  description="* Required"
                  errorText={this.state.projectError}
                >
                  <div className="create-form-input">
                    <Input
                      id={COMPONENT_ID.PROJECT}
                      placeholder="Enter project name"
                      value={this.state.project}
                      onChange={this._onChangeProject}
                    ></Input>
                  </div>
                </FormField>
              </div>
            </ColumnLayout>

            <ColumnLayout columns={4}>
              <div data-awsui-column-layout-root="true">
                <FormField
                  label={
                    <SpaceBetween direction="horizontal" size="xs">
                      {" "}
                      Model Number{" "}
                      <InfoText informatiomText="Model Number  of device" />{" "}
                    </SpaceBetween>
                  }
                  errorText={this.state.modelNumberError}
                  description="* Required"
                >
                  <div className="create-form-input">
                    <Input
                      id={COMPONENT_ID.MODEL_NUMBER}
                      placeholder="Enter Model Number"
                      value={this.state.modelNumber}
                      onChange={this._onChangeModelNumber}
                    ></Input>
                  </div>
                </FormField>

                <FormField
                  label={
                    <SpaceBetween direction="horizontal" size="xs">
                      {" "}
                      PVT RR{" "}
                      <InfoText informatiomText=" PVT RR Date of device" />{" "}
                    </SpaceBetween>
                  }
                  errorText={this.state.pvtrrError}
                >
                  <div className="create-form-input">
                    <Input
                      id={COMPONENT_ID.PVT_RR}
                      placeholder="Enter PVT RR"
                      value={this.state.pvtrrError}
                      onChange={this._onChangePvtRR}
                    ></Input>
                  </div>
                </FormField>
              </div>
            </ColumnLayout>

            <ColumnLayout columns={4}>
              <div data-awsui-column-layout-root="true">
                <FormField
                  label={
                    <SpaceBetween direction="horizontal" size="xs">
                      {" "}
                      Announce Date{" "}
                      <InfoText informatiomText="Announce Date of device" />{" "}
                    </SpaceBetween>
                  }
                  errorText={this.state.announceDateError}
                >
                  <div className="create-form-date-picker">
                    <DatePicker
                      id="announce_date"
                      placeholder="YYYY/MM/DD"
                      todayLabel="Today"
                      nextMonthLabel="Next month"
                      previousMonthLabel="Previous month"
                      onChange={this._onChangeAnnounceDate}
                      value={this.state.announceDate}
                    ></DatePicker>
                  </div>
                </FormField>
                <FormField
                  label="Device Category"
                  errorText={this.state.deviceCategoryError}
                  description="* Required"
                >
                  <Select
                    selectedOption={this.state.selectedDeviceCategory}
                    onChange={this._onChangeDeviceCategory}
                    placeholder="Device Category"
                    options={this.getOptions(deviceCategories)}
                  ></Select>
                </FormField>
              </div>
            </ColumnLayout>

            <ColumnLayout columns={4}>
              <div data-awsui-column-layout-root="true">
                <FormField
                  label={
                    <SpaceBetween direction="horizontal" size="xs">
                      {" "}
                      Street Date{" "}
                      <InfoText informatiomText="Street Date of device" />{" "}
                    </SpaceBetween>
                  }
                  errorText={this.state.streetDateError}
                  description="* Required"
                >
                  <div className="create-form-date-picker">
                    <DatePicker
                      id="street_date"
                      placeholder="YYYY/MM/DD"
                      todayLabel="Today"
                      nextMonthLabel="Next month"
                      previousMonthLabel="Previous month"
                      onChange={this._onChangeStreetDate}
                      value={this.state.streetDate}
                    ></DatePicker>
                  </div>
                </FormField>
                <FormField
                  label="Comment"
                  errorText={this.state.commentError}
                  constraintText="Max Length 200"
                >
                  <div className="create-form-input">
                    <Textarea
                      value={this.state.comment}
                      onChange={this._onChangeComment}
                    ></Textarea>
                  </div>
                </FormField>
              </div>
            </ColumnLayout>
          </div>

          <br />
          <br />
          <br />

          {/* action stripe group */}
          <div className="awsui-util-action-stripe-group">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onSubmit}
              loading={loadingStatus === constants.LOADING_LOAD}
            >
              Submit
            </Button>
          </div>
        </div>
        <Modal
          visible={loadingStatus === constants.LOADING_SUCCESS}
          onDismiss={() => this.reset(true)}
          header="Success"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <AlertButton variant="link" onClick={() => this.reset(true)}>
                  Cancel
                </AlertButton>
                <AlertButton
                  variant="primary"
                  onClick={() => this.nextForm(true)}
                >
                  Ok
                </AlertButton>
              </SpaceBetween>
            </Box>
          }
        >
          {`${message}.`}
        </Modal>
        <Modal
          visible={loadingStatus === constants.LOADING_FAIL}
          onDismiss={() => this.reset(false)}
          header="Failure"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <AlertButton
                  variant="primary"
                  onClick={() => this.reset(false)}
                >
                  Ok
                </AlertButton>
              </SpaceBetween>
            </Box>
          }
        >
          {message}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
    metadataReducer: state.metadataReducer,
    projectReducer: state.projectReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(ProjectForm);

import React from "react";
import { toast } from "react-toastify";
import { Icon } from "@amzn/awsui-components-react/polaris";
import { username } from '../cognito-auth/session';

const POSITION = "bottom-right";
const AUTO_CLOSE = 10000;

export const createSuccessToast = () => {
  return toast(
    <div className="text-success">
      <p>
        <Icon name="status-positive"></Icon> &nbsp;
        <b>Request is submitted successfully!</b>
      </p>
      <p>
        It takes 2~10 minutes to generate the report. An email with report will
        be sent to
        <b>{` ${username}@amazon.com `}</b>
        when the report is ready.
      </p>
    </div>,
    { position: POSITION, autoClose: AUTO_CLOSE }
  );
};

export const createFailToast = (message: string) => {
  return toast(
    <div className="text-danger">
      <h4>
        <Icon name="status-negative"></Icon> Error!
      </h4>
      <p>{message}</p>
    </div>,
    { position: POSITION, autoClose: false }
  );
};

export const createEmailNotVerifyToast = () => {
  return toast(
    <div className="text-danger">
      <h4>
        <Icon name="status-negative"></Icon> Email not verified!
      </h4>
      <p>
        You're <b>NOT</b> in our verified email list. Please verify your email
        first.
      </p>
      <p>
        A verification email has been sent to
        <b>{` ${username}@amazon.com`}</b>, click the link in the email to
        verify your email address.
      </p>
    </div>,
    { position: POSITION, autoClose: false }
  );
};

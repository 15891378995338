import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Toggle } from "@amzn/awsui-components-react/polaris";
import { ContainerNoData } from "../../../../components/container-status-indicator";
import SingleProgress from "./single-progress";
import TestStatusLegend from "./test-status-legend";

// declare prop check
type Props = {
  keys: Array<string>;
  values: { [key: string]: any };
  onSelectBuildConfig: any;
} & typeof defaultProps &
  RouteComponentProps<any>;

type State = {
  showPercentage: boolean;
};

// declare init state & default props
const defaultProps = Object.freeze({});

class ProgressContent extends Component<Props, State> {
  static defaultProps = defaultProps;
  state: State = Object.freeze({
    showPercentage: true,
  });
  _onChangeToggle = (e) => {
    this.setState({ showPercentage: e.detail.checked });
  };

  render() {
    const { keys, values } = this.props;
    if (keys.length === 0) {
      return <ContainerNoData />;
    }

    return (
      <div>
        {/* show percentage toggle and color legend */}
        <div className="d-flex justify-content-end">
          <Toggle
            checked={this.state.showPercentage}
            onChange={this._onChangeToggle}
          >
            Show Percentage
          </Toggle>
        </div>
        {/* progress list */}
        {keys.map((key, index) => {
          return (
            <div key={index}>
              <SingleProgress
                text={key}
                data={values[key]}
                showPercentage={this.state.showPercentage}
                onSelectBuildConfig={this.props.onSelectBuildConfig}
              />
              {index !== keys.length - 1 && <div className="mb-3"> </div>}
            </div>
          );
        })}
        <br/>
        <TestStatusLegend />
      </div>
    );
  }
}

export default withRouter(ProgressContent);

import { METRIC_INFO } from "../../constants";
import buildEmiTypes from "../types/build-emi-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";

export const getBuildTestcasesData = (
  category_id: string,
  project_id: string,
  build_id: string
) => {
  const log_value = {
    initiative: "Compliance",
    feature: "Build Data",
  };
  return {
    type: buildEmiTypes.BUILD_EMI,
    http: {
      cognito: {
        effect: {
          url:
            config.BASE_URL +
            endpoints.buildEmiEndpoint(category_id, project_id, build_id),
          method: "GET",
        },
        commit: {
          type: buildEmiTypes.BUILD_EMI_COMMIT,
          log: log_value,
        },
        rollback: {
          type: buildEmiTypes.BUILD_EMI_ROLLBACK,
          log: log_value,
        },
      },
    },
  };
};

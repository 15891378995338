import testplanType from "../types/testplan-type";
import constants from "../../constants";

const initialState = {
  loadingStatus: constants.LOADING_DEFAULT,
  submitLoadingStatus: constants.LOADING_DEFAULT,
  testPlan: {},
  testPlans: [],
  sectionList: [],
  assignee: "",
  lab: "",
  message: "",
  data: {},
  testOverviewDetails: {},
};

function getTestPlanData(data) {
  if (data && data.data && data.data) return data.data;
  return {};
}

function getAssignee(data) {
  if (data && data.data && data.data.assignee) return data.data.assignee;
  return "";
}

function getLab(data) {
  if (data && data.data && data.data.lab) return data.data.lab;
  return "";
}

function getMessage(data) {
  if (data && data.message) return data.message;
  return "";
}
function replace(myObj) {
  Object.keys(myObj).forEach(function (key) {
    typeof myObj[key] == "object"
      ? replace(myObj[key])
      : (myObj[key] = String(myObj[key]));
  });
}

function updateTestplanData(payload, currentTestPlanData) {
  try {
    let testCases = payload.data;
    let newTestPlanData = JSON.parse(JSON.stringify(currentTestPlanData));
    let arr = testCases.map((val) => {
      replace(val);
      return val;
    });
    newTestPlanData.test_cases = arr;
    return newTestPlanData;
  } catch (e) {
    console.log(e);
  }
}

function getTestPlans(action) {
  let payload = action.payload;
  try {
    let data = payload.data.map((val) => {
      return {
        Category: action.log.category,
        Project: action.log.project,
        Build: action.log.build,
        Version: action.log.version,
        CreatedBy: val.created_by,
        CreatedTime: val.created_time,
        URL: val.s3_link,
      };
    });
    let testPlans = data;

    return { testPlans };
  } catch (e) {
    console.log(e);
  }
}

//reducer gets data from backend
const testplanReducerEMI = (state = initialState, action) => {
  let messageData;
  switch (action.type) {
    case testplanType.GET_DATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_DATA_COMMIT:
      let testPlanData = getTestPlanData(action.payload);
      return {
        ...state,
        testPlan: testPlanData,
        //sections: testPlanSections,
        assignee: getAssignee(action.payload),
        lab: getLab(action.payload),
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.GET_DATA_ROLLBACK:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        loadingStatus: constants.LOADING_FAIL,
      };

    case testplanType.GET_DRAFT:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_DRAFT_COMMIT:
      let testDraftData = getTestPlanData(action.payload);
      return {
        ...state,
        testPlan: testDraftData,
        //sections: testPlanSections,
        assignee: getAssignee(action.payload),
        lab: getLab(action.payload),
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.GET_DRAFT_ROLLBACK:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        loadingStatus: constants.LOADING_FAIL,
      };

    case testplanType.CREATE_TESTPLAN_EMI:
      return {
        ...state,
        submitLoadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.CREATE_TESTPLAN_COMMIT_EMI:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        submitLoadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.CREATE_TESTPLAN_ROLLBACK_EMI:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        submitLoadingStatus: constants.LOADING_FAIL,
      };

    case testplanType.UPDATE_TESTCASES:
      return {
        ...state,
        testPlan: updateTestplanData(action.payload, state.testPlan),
      };
    case testplanType.RESET_CREATE_TESTPLAN:
      return {
        ...state,
        submitLoadingStatus: constants.LOADING_DEFAULT,
      };

    case testplanType.CREATE_TEST_PLAN_DRAFT_EMI:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.CREATE_TEST_PLAN_DRAFT_COMMIT_EMI:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        submitLoadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.CREATE_TEST_PLAN_DRAFT_ROLLBACK_EMI:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        submitLoadingStatus: constants.LOADING_FAIL,
      };

    case testplanType.GET_TEST_PLANS:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_TEST_PLANS_COMMIT:
      const { testPlans } = getTestPlans(action);
      return {
        ...state,
        testPlans: testPlans,
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.GET_TEST_PLANS_ROLLBACK:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        loadingStatus: constants.LOADING_FAIL,
      };

    case testplanType.GET_JIRA_USERS:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case testplanType.GET_JIRA_USERS_COMMIT:
      return {
        ...state,
        data: action.payload.data,
        loadingStatus: constants.LOADING_SUCCESS,
      };
    case testplanType.GET_JIRA_USERS_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case testplanType.SET_TEST_PLAN_DATA_EMI:
      return {
        ...state,
        testOverviewDetails: action.payload.data,
      };
    case testplanType.CLEAR_TEST_PLAN_DATA_EMI:
      return {
        ...state,
        testOverviewDetails: {},
      };
    default:
      return state;
  }
};

export default testplanReducerEMI;

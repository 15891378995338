import { Button } from "@amzn/awsui-components-react-v3";
import React from "react";
import { TEST_STATUS_COLOR } from '../../../commons/color-config';
import { TEST_STATUS } from "../../../constants";

export const defaultPageSize = 20;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "file_name",
    header: "File Name",
    cell: (e) => e.file_name,
    label: (sortState) => {
      return sort(sortState, "file_name", "File Name");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "auto_upload",
    header: "Upload type",
    cell: (e) => (e.auto_upload ? "Auto Upload" : "Manual Upload"),
    label: (sortState) => {
      return sort(sortState, "auto_upload", "Upload type");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "username",
    header: "User Name",
    cell: (e) => (!e.username ? "NA" : e.username),
    label: (sortState) => {
      return sort(sortState, "username", "User Name");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "system_upload_time",
    header: "System Upload Time",
    cell: (e) => e.system_upload_time,
    label: (sortState) => {
      return sort(sortState, "system_upload_time", "System Upload Time");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "file_time_stamp",
    header: "File Time Stamp",
    cell: (e) => e.file_time_stamp,
    label: (sortState) => {
      return sort(sortState, "file_time_stamp", "File Time Stamp");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "test_case",
    header: "Test Case ID",
    cell: (e) => e.test_case,
    label: (sortState) => {
      return sort(sortState, "test_case", "Test Case ID");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "process",
    header: "Process",
    cell: (e) => e.process,
    label: (sortState) => {
      return sort(sortState, "process", "Process");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "status",
    header: "Status",
    cell: (e) => (
      <div
        style={{
          color: `${
            e.status == "success"
              ? TEST_STATUS_COLOR[TEST_STATUS.PASSED]
              : TEST_STATUS_COLOR[TEST_STATUS.FAILED]
          }`,
          width: "100%",
          textAlign: "center",
        }}
      >
        {e.status}
      </div>
    ),
    label: (sortState) => {
      return sort(sortState, "status", "Status");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "error",
    header: "Message",
    cell: (e) => e.error,
    label: (sortState) => {
      return sort(sortState, "error", "Error Message");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "lab",
    header: "Lab",
    cell: (e) => e.lab,
    label: (sortState) => {
      return sort(sortState, "lab", "Lab");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "test_type",
    header: "Test Type",
    cell: (e) => e.test_type,
    label: (sortState) => {
      return sort(sortState, "test_type", "Test Type");
    },
    minWidth: "100px",
    visible: true,
  },
  {
    id: "s3_link",
    header: "Download Link",
    cell: (e) => (
      <Button
        className="button-with-margin"
        iconName="download"
        variant="primary"
        href={e.s3_link}
        disabled={!e.s3_link}
      ></Button>
    ),
    minWidth: "100px",
    visible: true,
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "system_upload_time";

// define sortable columns
export const sortableColumns = [
  { id: "file_name", field: "file_name" },
  { id: "auto_upload", field: "auto_upload" },
  { id: "username", field: "username" },
  { id: "system_upload_time", field: "system_upload_time" },
  { id: "file_time_stamp", field: "file_time_stamp" },
  { id: "test_case", field: "test_case" },
  { id: "process", label: "Process"},
  { id: "status", field: "status" },
  { id: "error", field: "error" },
  { id: "lab", field: "lab" },
  { id: "test_type", field: "test_type" },
  { id: "s3_link", field: "s3_link" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "file_name", label: "File Name", visible: true },
      { id: "auto_upload", label: "Upload type", visible: true },
      { id: "username", label: "User Name", visible: true },
      { id: "system_upload_time", label: "System Upload Time", visible: true },
      { id: "file_time_stamp", label: "File Time Stamp", visible: false },
      { id: "test_case", label: "Test Case ID", visible: false },
      { id: "process", label: "Process", visible: true },
      { id: "status", label: "Status", visible: true },
      { id: "error", label: "Error Message", visible: true },
      { id: "lab", label: "Lab", visible: true },
      { id: "test_type", label: "Test Type", visible: true },
      { id: "s3_link", label: "Download Link", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    propertyKey: "file_name",
    propertyLabel: "File Name",
    groupValuesLabel: "File Name",
    values: [],
  },
  {
    propertyKey: "username",
    propertyLabel: "User Name",
    groupValuesLabel: "User Name",
    values: [],
  },
  {
    propertyKey: "test_case",
    propertyLabel: "Test Case ID",
    groupValuesLabel: "Test Case ID",
    values: [],
  },
  {
    propertyKey: "process",
    propertyLabel: "Process",
    groupValuesLabel: "Process",
    values: [],
  },
  {
    propertyKey: "status",
    propertyLabel: "Status",
    groupValuesLabel: "Status",
    values: [],
  },
  {
    propertyKey: "error",
    propertyLabel: "Error Message",
    groupValuesLabel: "Error Message",
    values: [],
  },
  {
    propertyKey: "lab",
    propertyLabel: "Lab",
    groupValuesLabel: "Lab",
    values: [],
  },
  {
    propertyKey: "test_type",
    propertyLabel: "Test Type",
    groupValuesLabel: "Test Type",
    values: [],
  },
];

export const features = [
  "propertyFiltering",
  "pagination",
  "sorting",
];

import * as React from "react";
import { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Box,
  ColumnLayout,
  FormField,
  Input,
  Select,
  SelectProps,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";
import {
  Button,
  Modal,
  Checkbox,
} from "@amzn/awsui-components-react-v3/polaris";
import { getMetadata } from "../../../redux/actions/metadata-action";
import { DROPDOWN_DEFAULT } from "../../../constants";
import constants from "../../../constants";
import {
  createBuildConfig,
  resetCreateBuildConfig,
} from "../../../redux/actions/build-config-action";

interface StateProps {
  metadataReducer: any;
  buildConfigReducer: any;
}

type BuildConfigFormProps = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type BuildConfigFormState = {
  category_id: string;
  category: string;
  categoryError: string;
  project_id: string;
  project: string;
  projectError: string;
  build: string;
  buildError: string;
  build_id: string;
  version: string;
  versionError: string;
  projectItems: Array<any>;
  buildItems: Array<any>;
  versionItems: Array<any>;
  buildConfig: string;
  buildConfigError: string;
  isSpotCheck: boolean;
};

class BuildConfigForm extends Component<
  BuildConfigFormProps,
  BuildConfigFormState
> {
  state: BuildConfigFormState = Object.freeze({
    category_id: "",
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project_id: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectItems: [],
    projectError: "",
    build: DROPDOWN_DEFAULT.BUILD,
    buildItems: [],
    buildError: "",
    build_id: "",
    version: DROPDOWN_DEFAULT.BUILD,
    versionItems: [],
    versionError: "",
    buildConfig: "",
    buildConfigError: "",
    isSpotCheck: false,
  });

  validate = () => {
    let isValid = true;
    let categoryError = "";
    let projectError = "";
    let buildError = "";
    let versionError = "";
    let buildConfigError = "";

    if (!this.state.category_id) {
      isValid = false;
      categoryError = "Required field";
    }
    if (!this.state.project_id) {
      isValid = false;
      projectError = "Required field";
    }
    if (!this.state.build || this.state.build === DROPDOWN_DEFAULT.BUILD) {
      isValid = false;
      buildError = "Required field";
    }
    if (
      !this.state.build_id ||
      this.state.build_id === DROPDOWN_DEFAULT.BUILD
    ) {
      isValid = false;
      versionError = "Required field";
    }
    if (!this.state.buildConfig) {
      isValid = false;
      buildConfigError = "Required field";
    } else if (this.state.buildConfig.length < 3) {
      isValid = false;
      buildConfigError = "Minimum length 3";
    } else if (!/^[A-Za-z0-9()/\-. ]+$/i.test(this.state.buildConfig)) {
      isValid = false;
      buildConfigError = "Letters and Numbers only";
    }
    this.setState({
      categoryError,
      projectError,
      buildError,
      versionError,
      buildConfigError,
    });
    return isValid;
  };

  getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });

    return items;
  };

  _onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    let projectItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id].children).forEach((project_id) => {
      const project = filter[category_id].children[project_id];
      const item: SelectProps.Option = {
        value: project_id,
        label: project.name,
      };
      projectItems.push(item);
    });
    this.setState({
      category_id,
      category,
      categoryError: "",
      projectItems,
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      build_id: "",
      version: DROPDOWN_DEFAULT.BUILD,
      versionItems: [],
      versionError: "",
    });
  };

  _onChangeProject = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    let buildItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id].children[project_id].children).forEach(
      (build) => {
        const item: SelectProps.Option = {
          value: build,
          label: build,
        };
        buildItems.push(item);
      }
    );
    this.setState({
      project_id,
      project,
      projectError: "",
      buildItems,
      build: DROPDOWN_DEFAULT.BUILD,
      buildError: "",
      build_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
      versionItems: [],
    });
  };

  _onChangeBuild = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = event.detail.selectedOption.value;
    const { filter } = this.props.metadataReducer;
    let versionItems: Array<SelectProps.Option> = [];
    Object.keys(
      filter[category_id].children[project_id].children[build].children
    ).forEach((version) => {
      const item: SelectProps.Option = {
        value: version,
        label: version,
      };
      versionItems.push(item);
    });
    this.setState({
      build,
      buildError: "",
      build_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems,
      versionError: "",
    });
  };

  _onChangeVersion = (event: any): any => {
    const { filter } = this.props.metadataReducer;
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = this.state.build;
    const version = event.detail.selectedOption.label;
    const version_id =
      filter[category_id].children[project_id].children[build].children[version]
        .id;
    this.setState({
      build_id: version_id,
      version,
      versionError: "",
    });
  };
  clear = (): any => {
    this.setState({
      category_id: "",
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectItems: [],
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      build_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
      buildConfig: "",
      buildConfigError: "",
      isSpotCheck: false,
    });
  };
  _onCreate = (): any => {
    const isValid = this.validate();
    if (!isValid) {
      return;
    }
    const payload = {
      name: this.state.buildConfig,
      is_spot_check: this.state.isSpotCheck,
    };
    this.props.dispatch(
      createBuildConfig(
        this.state.category_id,
        this.state.project_id,
        this.state.build_id,
        payload
      )
    );
  };
  reset = (success: boolean): any => {
    this.props.dispatch(getMetadata());
    this.props.dispatch(resetCreateBuildConfig());
    if (success) this.clear();
  };
  nextForm = (success: boolean) => {
    this.props.dispatch(getMetadata());
    this.props.dispatch(resetCreateBuildConfig());
    if (success) this.clear();
    // navigate to create testplan
    // this.props.history.push("/testplan/rf/create");
  };
  render() {
    const { filter } = this.props.metadataReducer;
    const categoryItems = this.getCategoryItems(filter);
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Build Config</h2>
        </div>
        <ColumnLayout columns={4}>
          {/* <div data-awsui-column-layout-root="true"> */}
          <FormField label="Category" errorText={this.state.categoryError}>
            <Select
              options={categoryItems}
              placeholder="Category"
              onChange={this._onChangeCategory}
              selectedOption={{
                value: this.state.category_id,
                label: this.state.category,
              }}
            ></Select>
          </FormField>
          <FormField label="Project" errorText={this.state.projectError}>
            <Select
              options={this.state.projectItems}
              placeholder="Project"
              onChange={this._onChangeProject}
              selectedOption={{
                value: this.state.project_id,
                label: this.state.project,
              }}
            ></Select>
          </FormField>
        </ColumnLayout>
        <ColumnLayout columns={4}>
          <FormField label="Build" errorText={this.state.buildError}>
            <Select
              options={this.state.buildItems}
              placeholder="Build"
              onChange={this._onChangeBuild}
              selectedOption={{
                value: this.state.build,
                label: this.state.build,
              }}
            ></Select>
          </FormField>
          <FormField label="Version" errorText={this.state.versionError}>
            <Select
              options={this.state.versionItems}
              placeholder="Version"
              onChange={this._onChangeVersion}
              selectedOption={{
                value: this.state.version,
                label: this.state.version,
              }}
            ></Select>
          </FormField>
        </ColumnLayout>
        <ColumnLayout columns={4}>
          <FormField
            label="Config"
            constraintText="Letters and Numbers only"
            description="* Required"
            errorText={this.state.buildConfigError}
          >
            <Input
              onChange={(event) =>
                this.setState({
                  buildConfig: event.detail.value,
                  buildConfigError: "",
                })
              }
              value={this.state.buildConfig}
            ></Input>
          </FormField>
          <div
            style={{
              marginTop: "40px",
            }}
          >
            <FormField constraintText="Select this option to create a Spotcheck Config">
              <Checkbox
                onChange={({ detail }) =>
                  this.setState({
                    isSpotCheck: detail.checked,
                  })
                }
                checked={this.state.isSpotCheck}
              >
                Mark as Spotcheck Config
              </Checkbox>
            </FormField>
          </div>
        </ColumnLayout>
        <div
          className="d-flex justify-content-start"
          style={{ marginRight: "20px" }}
        >
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onCreate}
              loading={
                this.props.buildConfigReducer.createLoadingStatus ===
                constants.LOADING_LOAD
              }
            >
              Create
            </Button>
          </SpaceBetween>
        </div>
        <Modal
          visible={
            this.props.buildConfigReducer.createLoadingStatus ===
            constants.LOADING_SUCCESS
          }
          onDismiss={() => this.reset(true)}
          header="Success"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link" onClick={() => this.reset(true)}>
                  Cancel
                </Button>
                <Button variant="primary" onClick={() => this.nextForm(true)}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {`${this.props.buildConfigReducer.createMessage}.`}
        </Modal>

        <Modal
          visible={
            this.props.buildConfigReducer.createLoadingStatus ===
            constants.LOADING_FAIL
          }
          onDismiss={() => this.reset(false)}
          header="Failure"
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => this.reset(false)}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {this.props.buildConfigReducer.createMessage}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
    buildConfigReducer: state.buildConfigReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(BuildConfigForm);

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Dashboard from "../landing/dashboard";
import CreateBuild from "../create/build/create-build";
import CreateCategory from "../create/category/create-category";
import CreateProject from "../create/project/create-project";
import CreateTestplan from "../create/testplan/create-testplan";
import CreateTestplanEmi from "../create/testplan-emi/create-testplan";
import Upload from "../upload/upload";
import Overview from "../overview/overview";
import OverviewEMI from "../overview/overview-emi";
import CompareTestcase from "../compare/compare-testcase";
import Visualization from "../visualization/visualization";
import ReportHistory from "../report-generation/report-history/report-history";
import CreateBuildConfig from "../create/build-config/create-build-config";
import { AboutUs } from "@amzn/lens-react-ui";
import Analytics from "../analytics/analytics";
import VisualizationEMI from "../visualization-emi/visualization-emi";

// declare prop check
type Props = { collapse: boolean; isIframe: boolean } & typeof defaultProps;

// declare init state & default props
const defaultProps = Object.freeze({});

// styled component
// prettier-ignore
const StyledDiv = styled.div<{ collapse: boolean, isIframe: boolean }>`
  padding: 30px;
`;

class MainRouter extends Component<Props> {
  render() {
    const { collapse, isIframe } = this.props;
    return (
      <StyledDiv collapse={collapse} isIframe={isIframe}>
        <Switch>
          <Route path="/" exact component={Dashboard} />
          <Route
            path="/categories/:category/projects/:project/builds/:build/overview/rf"
            exact
            component={Overview}
          />
          <Route
            path="/categories/:category/projects/:project/builds/:build/overview/emi"
            exact
            component={OverviewEMI}
          />
          <Route
            path="/categories/:category/projects/:project/builds/:build/id/:id/visualize"
            exact
            component={VisualizationEMI}
          />

          <Route
            path="/categories/:category/projects/:project/builds/:build/compare"
            component={CompareTestcase}
          />
          <Route path="/category/create" exact component={CreateCategory} />
          <Route path="/project/create" exact component={CreateProject} />
          <Route path="/build/create" exact component={CreateBuild} />
          <Route path="/testplan/rf/create" exact component={CreateTestplan} />
          <Route
            path="/testplan/emi/create"
            exact
            component={CreateTestplanEmi}
          />
          <Route
            path="/build_config/create"
            exact
            component={CreateBuildConfig}
          />
          <Route path="/upload/:test" exact component={Upload} />
          <Route path="/upload" exact component={Upload} />
          <Route path="/reports/history" exact component={ReportHistory} />
          <Route
            path="/categories/:category/projects/:project/builds/:build/build_configs/:build_config/test_cases/:test_case_id/visualize"
            exact
            component={Visualization}
          />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/about-us" exact component={AboutUs} />
          <Route path="/analytics" component={Analytics} />
        </Switch>
      </StyledDiv>
    );
  }
}

export default MainRouter;

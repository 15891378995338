import React, { Component } from "react";
import constants, { TEST_STATUS } from "../../../constants";
import { TEST_CASE_STATUS } from "../../../constants";
import Plot from "react-plotly.js";
import {
  ContainerFail,
  ContainerNoData,
  ContainerSpninner,
} from "../../../components/container-status-indicator";
import { TEST_STATUS_COLOR_RGB } from "../../../commons/color-config";
import Container from "@amzn/awsui-components-react-v3/polaris/container/internal";
import { Header } from "@amzn/awsui-components-react-v3";
import "./charts.css";

// declare prop check
type Props = {
  loadingStatus: any;
  data: any;
} & typeof defaultProps;
type State = {};
// declare init state & default props
const defaultProps = Object.freeze({});

class RegionOverviewChart extends Component<Props, State> {
  static defaultProps = defaultProps;
  constructor(props) {
    super(props);
  }
  render() {
    const { loadingStatus, data } = this.props;

    let content: any = null;
    if (loadingStatus === constants.LOADING_LOAD) {
      content = <ContainerSpninner className="alert-container" />;
    } else if (loadingStatus === constants.LOADING_FAIL) {
      content = <ContainerFail className="alert-container" />;
    } else if (!data || data.length === 0) {
      content = <ContainerNoData className="alert-container" />;
    } else if (loadingStatus === constants.LOADING_SUCCESS && data) {
      let countryTotalTestCases = {};
      let statusMap = {};
      TEST_CASE_STATUS.forEach((status) => (statusMap[status] = {}));

      data.forEach((testcase) => {
        TEST_CASE_STATUS.forEach((status) => {
          if (!statusMap[status][testcase.country]) {
            statusMap[status][testcase.country] = 0;
          }
        });
        statusMap[testcase.status][testcase.country] =
          statusMap[testcase.status][testcase.country] + 1;
        if (!countryTotalTestCases[testcase.country]) {
          countryTotalTestCases[testcase.country] = 0;
        }
        countryTotalTestCases[testcase.country] =
          countryTotalTestCases[testcase.country] + 1;
      });
      let chartData: Array<any> = [];
      let colorScheme: any = {
        untested: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.UNTESTED],
        },
        failed: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.FAILED],
        },
        passed: {
          color: TEST_STATUS_COLOR_RGB[TEST_STATUS.PASSED],
        },
      };
      for (let status in statusMap) {
        let chartDataObject: any = {
          x: [],
          y: [],
          name: status,
          type: "bar",
          text: [],
          marker: colorScheme[status],
          textposition: "auto",
          hoverinfo: "none",
          width: [],
        };
        for (let region in statusMap[status]) {
          let percentage =
            (statusMap[status][region] * 100) / countryTotalTestCases[region];
          percentage = Math.trunc(percentage * 10) / 10;
          let numberOfTestCases = statusMap[status][region];
          chartDataObject["x"].push(region);
          chartDataObject["y"].push(percentage);
          chartDataObject["text"].push(
            numberOfTestCases + ", " + percentage + "%"
          );
          chartDataObject["width"].push(0.3);
        }
        chartData.push(chartDataObject);
      }
      content = (
        <Plot
          data={chartData}
          layout={{
            barmode: "stack",
            font: {
              family: "Raleway, sans-serif",
              size: 12,
            },
            yaxis: {
              title: "Percentage of test cases",
            },
            title: "Region Overview",
          }}
          style={{ width: "100%" }}
          config={{
            responsive: true,
            modeBarButtonsToRemove: [
              "zoom2d",
              "pan2d",
              "select2d",
              "lasso2d",
              "zoomIn2d",
              "zoomOut2d",
              "autoScale2d",
              "toggleHover",
              "toggleSpikelines",
              "hoverCompareCartesian",
              "hoverClosestCartesian",
            ],
            displayModeBar: true,
            displaylogo: false,
          }}
        />
      );
    }

    return (
      <div className="d-flex justify-content-center region-overview-box-fix-height">
        {content}
      </div>
    );
  }
}

export default RegionOverviewChart;

import constants from "../../constants";
import uploadType from "../types/upload-type";

interface state {
    url: string;
    loadingStatus: number;
}

const initialState: state = {
    url: "",
    loadingStatus: constants.LOADING_LOAD,
};

const downloadReducer = (state = initialState, action) => {
    switch (action.type) {
        case uploadType.GET_DOWNLOAD:
            return {
                ...state,
                loadingStatus: constants.LOADING_LOAD,
            };
        case uploadType.GET_DOWNLOAD_COMMIT:
            return {
                ...state,
                loadingStatus: constants.LOADING_SUCCESS,
                url: action.payload.preSignedDownloadURL
            };
        case uploadType.GET_DOWNLOAD_ROLLBACK:
            return {
                ...state,
                loadingStatus: constants.LOADING_FAIL
            }
        default:
            return state;
    }
};

export default downloadReducer;

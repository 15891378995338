import projectType from "../types/project-type";
import constants from "../../constants";

interface state {
    loadingStatus: number,
    message: string,
    responseData: any
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {},
};

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case projectType.CREATE_PROJECT:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case projectType.CREATE_PROJECT_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case projectType.CREATE_PROJECT_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data,
      };
    case projectType.RESET_PROJECT:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {}
      };
    default:
      return state;
  }
};

export default projectReducer;
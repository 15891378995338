import React from "react";
import {
  Alert,
  Spinner,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";

const StyledDiv = styled.div`
  width: 100%;
  text-align: center;
`;

// loading -> spinner
export const ContainerSpninner = (config) => {
  return (
    <SpaceBetween direction="horizontal" size="xs" className={config.className}>
      <Spinner variant="disabled" />
      <p style={{ color: "grey" }}>Loading</p>
    </SpaceBetween>
  );
};

// loading failed -> cross mark
export const ContainerFail = (config) => {
  return (
    <div className={config.className}>
      <Alert type="error">Error ocurred. Please try it again.</Alert>
    </div>
  );
};

// no data
export const ContainerNoData = (config) => {
  return (
    <div className={config.className}>
      <Alert type="warning">
        No data found.
      </Alert>
    </div>
  );
};

// no graph selected
export const ContainerNoGraph = (config) => {
  return (
    <div className={config.className}>
      <Alert type="warning">No graph selected.</Alert>
    </div>
  );
};
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import testplanType from "../types/testplan-type";
import { METRIC_INFO } from "../../constants";

export const getTestPlan = (category, project, build, version) => ({
  type: testplanType.GET_DATA,
  http: {
    cognito: {
      //network action to execute:
      effect: {
        url:
          config.BASE_URL +
          endpoints.getTestPlan(category, project, build, version),

        method: "GET",
      },
      //action to dispatch when effect succeeds:
      commit: {
        type: testplanType.GET_DATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
        },
      },
      //action to dispatch if network actin fails
      rollback: {
        type: testplanType.GET_DATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
        },
      },
    },
  },
});

export const getJiraUsers = () => ({
  type: testplanType.GET_JIRA_USERS,
  http: {
    cognito: {
      //network action to execute:
      effect: {
        url:
          config.JIRA_URL +
          endpoints.jiraUsersUrl() +
          "?project_keys=SPROTNLAB,TUVR,ULLAB",

        method: "GET",
      },
      //action to dispatch when effect succeeds:
      commit: {
        type: testplanType.GET_JIRA_USERS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
        },
      },
      //action to dispatch if network actin fails
      rollback: {
        type: testplanType.GET_JIRA_USERS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
        },
      },
    },
  },
});
export const getTestPlans = (category, project, build, version) => ({
  type: testplanType.GET_TEST_PLANS,
  http: {
    cognito: {
      //network action to execute:
      effect: {
        url:
          config.BASE_URL +
          endpoints.getTestPlans(
            category.category_id,
            project.project_id,
            version.version_id
          ),

        method: "GET",
      },
      //action to dispatch when effect succeeds:
      commit: {
        type: testplanType.GET_TEST_PLANS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
          category: category.category,
          project: project.project,
          version: version.version,
          build: build.build,
        },
      },
      //action to dispatch if network actin fails
      rollback: {
        type: testplanType.GET_TEST_PLANS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_EMI,
          feature: METRIC_INFO.TESTPLAN_EMI,
        },
      },
    },
  },
});

export const createTestPlan = (values, category, project, build, version) => {
  const log_value = {
    initiative: METRIC_INFO.INITIATIVE_EMI,
    feature: METRIC_INFO.CREATE_TESTPLAN,
    category: category,
    project: project,
    build: build,
  };
  return {
    type: testplanType.CREATE_TESTPLAN_EMI,
    http: {
      cognito: {
        // the network action to execute:
        effect: {
          url:
            config.BASE_URL +
            endpoints.createTestPlan(category, project, build, version),
          method: "POST",
          json: values,
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: testplanType.CREATE_TESTPLAN_COMMIT_EMI,
          log: log_value,
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: testplanType.CREATE_TESTPLAN_ROLLBACK_EMI,
          log: log_value,
        },
      },
    },
  };
};

export const getTestPlanDraftEMI = (
  values,
  category,
  project,
  build,
  version
) => {
  return {
    type: testplanType.GET_DRAFT,
    http: {
      cognito: {
        // the network action to execute:
        effect: {
          url:
            config.BASE_URL +
            endpoints.getTestPlanEmiDraft(category, project, build, version),

          method: "POST",
          json: values,
        },
        //action to dispatch when effect succeeds:
        commit: {
          type: testplanType.GET_DRAFT_COMMIT,
          log: { initiative: METRIC_INFO.INITIATIVE_EMI, feature: "TestPlans" },
        },
        //action to dispatch if network actin fails
        rollback: {
          type: testplanType.GET_DRAFT_ROLLBACK,
          log: { initiative: METRIC_INFO.INITIATIVE_EMI, feature: "TestPlans" },
        },
      },
    },
  };
};

export const createTestPlanDraftEMI = (
  values,
  category,
  project,
  build,
  version
) => {
  return {
    type: testplanType.CREATE_TEST_PLAN_DRAFT_EMI,
    http: {
      cognito: {
        // the network action to execute:
        effect: {
          url:
            config.BASE_URL +
            endpoints.createTestPlanDraftEMIEndpoint(
              category,
              project,
              build,
              version
            ),
          method: "POST",
          json: values,
        },
        // action to dispatch when effect succeeds:
        commit: {
          type: testplanType.CREATE_TEST_PLAN_DRAFT_COMMIT_EMI,
          log: {
            initiative: "ComplianceEMI",
            feature: "TestPlans",
            category: category,
            project: project,
            build: build,
          },
        },
        // action to dispatch if network action fails permanently:
        rollback: {
          type: testplanType.CREATE_TEST_PLAN_DRAFT_ROLLBACK_EMI,
          log: {
            initiative: "ComplianceEMI",
            feature: "TestPlans",
            category: category,
            project: project,
            build: build,
          },
        },
      },
    },
  };
};

export const resetCreateTestplan = () => ({
  type: testplanType.RESET_CREATE_TESTPLAN,
});

export const updateTestCases = (testCases) => ({
  type: testplanType.UPDATE_TESTCASES,
  payload: { data: testCases },
});

export const setTestOverviewDetailsEMI = (testOverviewDetails) => ({
  type: testplanType.SET_TEST_PLAN_DATA_EMI,
  payload: { data: testOverviewDetails },
});

export const clearTestOverviewDetailsEMI = () => ({
  type: testplanType.CLEAR_TEST_PLAN_DATA_EMI,
});

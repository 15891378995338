import testplanType from "../types/testplan-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";
import { createTestplanRequest } from "../../model/http-json";

export const getTestplanConfig = (): any => ({
  type: testplanType.GET_CONFIG,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.testplanConfigEndpoint(),
        method: "GET",
      },
      commit: {
        type: testplanType.GET_CONFIG_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
      rollback: {
        type: testplanType.GET_CONFIG_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
    },
  },
});

export const createTestplan = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number,
  values: createTestplanRequest
): any => ({
  type: testplanType.CREATE_TESTPLAN,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testplanEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "POST",
        json: values,
      },
      commit: {
        type: testplanType.CREATE_TESTPLAN_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
      rollback: {
        type: testplanType.CREATE_TESTPLAN_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
    },
  },
});

export const createTestplanDraft = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number,
  values: createTestplanRequest
): any => ({
  type: testplanType.CREATE_TESTPLAN_DRAFT,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testplanDraftEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "POST",
        json: values,
      },
      commit: {
        type: testplanType.CREATE_TESTPLAN_DRAFT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN_DRAFT,
        },
      },
      rollback: {
        type: testplanType.CREATE_TESTPLAN_DRAFT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN_DRAFT,
        },
      },
    },
  },
});

export const resetTestplanOnSuccess = (): any => ({
  type: testplanType.RESET_CREATE_TESTPLAN_ON_SUCCESS,
});

export const resetTestplanOnFailure = (): any => ({
  type: testplanType.RESET_CREATE_TESTPLAN_ON_FAILURE,
});

export const resetSendMail = (): any => ({
  type: testplanType.RESET_SEND_MAIL_TESTPLAN,
});

export const getLatestTestplan = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number
): any => ({
  type: testplanType.GET_LATEST_TESTPLAN,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.latestTestplanEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "GET",
      },
      commit: {
        type: testplanType.GET_LATEST_TESTPLAN_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
      rollback: {
        type: testplanType.GET_LATEST_TESTPLAN_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
    },
  },
});

export const getTestplanDraft = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number
): any => ({
  type: testplanType.GET_TESTPLAN_DRAFT,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.testplanDraftEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "GET",
      },
      commit: {
        type: testplanType.GET_TESTPLAN_DRAFT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN_DRAFT,
        },
      },
      rollback: {
        type: testplanType.GET_TESTPLAN_DRAFT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN_DRAFT,
        },
      },
    },
  },
});

export const importTestplan = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number
): any => ({
  type: testplanType.IMPORT_TESTPLAN,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.latestTestplanEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "GET",
      },
      commit: {
        type: testplanType.IMPORT_TESTPLAN_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
      rollback: {
        type: testplanType.IMPORT_TESTPLAN_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CREATE_TESTPLAN,
        },
      },
    },
  },
});

export const sendMailTestplan = (
  category_id: string,
  project_id: string,
  build_id: string,
  config_id: number
): any => ({
  type: testplanType.SEND_MAIL_TESTPLAN,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.sendMailTestplanEndpoint(
            category_id,
            project_id,
            build_id,
            config_id
          ),
        method: "GET",
      },
      commit: {
        type: testplanType.SEND_MAIL_TESTPLAN_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.SEND_MAIL_TESTPLAN,
        },
      },
      rollback: {
        type: testplanType.SEND_MAIL_TESTPLAN_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.SEND_MAIL_TESTPLAN,
        },
      },
    },
  },
});

export const setTestOverviewDetails = (testOverviewDetails: {
  categoryId: string;
  projectId: string;
  buildVersionId: string;
}) => ({
  type: testplanType.SET_TEST_PLAN_DATA,
  payload: { data: testOverviewDetails },
});

export const clearTestOverviewDetails = () => ({
  type: testplanType.CLEAR_TEST_PLAN_DATA,
});

import testcaseType from "../types/testcase-type";
import constants from "../../constants";

interface state {
    loadingUpdateTestcasesStatus: Number,
    updateTestcasesResponseData: any
}

const initialState: state = {
    loadingUpdateTestcasesStatus: constants.LOADING_DEFAULT,
    updateTestcasesResponseData: {}
}

const testcaseReducer = (state = initialState, action) => {
    switch(action.type){
        case testcaseType.UPDATE_TCS:
            return {
                ...state,
                loadingUpdateTestcasesStatus: constants.LOADING_LOAD
            };
        case testcaseType.UPDATE_TCS_COMMIT:
            return {
                ...state,
                loadingUpdateTestcasesStatus: constants.LOADING_SUCCESS,
                updateTestcasesResponseData: action.payload
            }
        case testcaseType.UPDATE_TCS_ROLLBACK:
            return {
                ...state,
                loadingUpdateTestcasesStatus: constants.LOADING_FAIL,
                updateTestcasesResponseData: action.payload
            }
        case testcaseType.RESET_TCS:
            return {
                ...state, 
                loadingUpdateTestcasesStatus: constants.LOADING_DEFAULT,
                updateTestcasesResponseData: {}
            }
        default:
            return state;
    }
}

export default testcaseReducer;
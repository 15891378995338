import React, { Component } from "react";
import { connect } from "react-redux";
import { getLandingData } from "../../redux/actions/landing-action"; // redux action
import DashboardTable from "./table/table";
import { tableData } from "./table/sample-data";
import { Dispatch } from "redux";

interface StateProps {
  landingReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

class Dashboard extends Component<Props> {
  componentDidMount() {
    this._getData();
    // alert("Please enter a sheet name and then click on Add Sheet button.");
  }

  _getData = () => {
    this.props.dispatch(getLandingData());
  };

  render() {
    const { landingData, loadingStatus } = this.props.landingReducer;
    return (
      <div>
        <DashboardTable
          data={landingData}
          loadingStatus={loadingStatus}
          getLandingData={this._getData}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    landingReducer: state.landingReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(Dashboard);

import metadataType from "../types/metadata-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const getMetadata = () => ({
  type: metadataType.GET_METADATA,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.metadataEndpoint(),
        method: "GET",
      },
      commit: {
        type: metadataType.GET_METADATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
      rollback: {
        type: metadataType.GET_METADATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.META_DATA,
        },
      },
    },
  },
});

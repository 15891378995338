import landingType from "../types/landing-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const getLandingData = () => ({
  type: landingType.LANDING_SERVICE_CALL,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.landingEndpoint(),
        method: "GET",
      },
      commit: {
        type: landingType.LANDING_SERVICE_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.LANDING,
        },
      },
      rollback: {
        type: landingType.LANDING_SERVICE_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.LANDING,
        },
      },
    },
  },
});

import base_config from "./base_config";

const dev_config = {
  ...base_config,
  STAGE: "dev",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_98VCRNfCo",
    aws_user_pools_web_client_id: "6utmd5dcgqb781q9q4urlkvv8i",
    oauth: {
      domain: "wise-general-cog-dev.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url
  BASE_URL: "https://rh8p0k1wnk.execute-api.us-west-2.amazonaws.com/dev/v1",
  BASE_AUTHZ_URL: "https://rh8p0k1wnk.execute-api.us-west-2.amazonaws.com/dev",

  //Jira Url
  JIRA_URL: "https://p37i16fad5.execute-api.us-west-2.amazonaws.com/dev",

  // Upload service base url
  UPLOAD_BASE_URL: "https://wrcqb3byf5.execute-api.us-west-2.amazonaws.com/dev",
  WEBSITE_BASE_URL: "http://localhost:3000",
  ANALYTICS_DASHBOARD:
    "https://search-wise-es-fqemetrics-cgj4xsdhpq2rgvcntsyxnxoyue.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/dcbda590-fe7f-11eb-90c1-81bcf3ab5564?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-7d%2Cmode%3Aquick%2Cto%3Anow))",
  // Configuration of Cloudwatch RUM
  APPLICATION_ID: "0b362da7-d7e1-4ac9-b5a0-fdc51b893a39",
  APPLICATION_VERSION: "1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn:
      "arn:aws:iam::563781235045:role/RUM-Monitor-us-west-2-563781235045-4879389948071-Unauth",
    identityPoolId: "us-west-2:eefa5e51-a5a9-45bf-87fe-9e87e692affc",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: [
      "performance",
      "errors",
      ["http", { recordAllRequests: true }],
    ],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true,
  },
};

export default dev_config;

const testplanType = {
  GET_CONFIG: "GET_CONFIG",
  GET_CONFIG_COMMIT: "GET_CONFIG_COMMIT",
  GET_CONFIG_ROLLBACK: "GET_CONFIG_ROLLBACK",

  CREATE_TESTPLAN: "CREATE_TESTPLAN",
  CREATE_TESTPLAN_COMMIT: "CREATE_TESTPLAN_COMMIT",
  CREATE_TESTPLAN_ROLLBACK: "CREATE_TESTPLAN_ROLLBACK",

  CREATE_TESTPLAN_DRAFT: "CREATE_TESTPLAN_DRAFT",
  CREATE_TESTPLAN_DRAFT_COMMIT: "CREATE_TESTPLAN_DRAFT_COMMIT",
  CREATE_TESTPLAN_DRAFT_ROLLBACK: "CREATE_TESTPLAN_DRAFT_ROLLBACK",

  RESET_CREATE_TESTPLAN_ON_FAILURE: "RESET_CREATE_TESTPLAN_ON_FAILURE",
  RESET_CREATE_TESTPLAN_ON_SUCCESS: "RESET_CREATE_TESTPLAN_ON_SUCCESS",
  RESET_CREATE_TESTPLAN: "RESET_CREATE_TESTPLAN",

  GET_LATEST_TESTPLAN: "GET_LATEST_TESTPLAN",
  GET_LATEST_TESTPLAN_COMMIT: "GET_LATEST_TESTPLAN_COMMIT",
  GET_LATEST_TESTPLAN_ROLLBACK: "GET_LATEST_TESTPLAN_ROLLBACK",

  GET_TESTPLAN_DRAFT: "GET_TESTPLAN_DRAFT",
  GET_TESTPLAN_DRAFT_COMMIT: "GET_TESTPLAN_DRAFT_COMMIT",
  GET_TESTPLAN_DRAFT_ROLLBACK: "GET_TESTPLAN_DRAFT_ROLLBACK",

  IMPORT_TESTPLAN: "IMPORT_TESTPLAN",
  IMPORT_TESTPLAN_COMMIT: "IMPORT_TESTPLAN_COMMIT",
  IMPORT_TESTPLAN_ROLLBACK: "IMPORT_TESTPLAN_ROLLBACK",
  RESET_IMPORT_TESTPLAN: "RESET_IMPORT_TESTPLAN",

  SEND_MAIL_TESTPLAN: "SEND_MAIL_TESTPLAN",
  SEND_MAIL_TESTPLAN_COMMIT: "SEND_MAIL_TESTPLAN_COMMIT",
  SEND_MAIL_TESTPLAN_ROLLBACK: "SEND_MAIL_TESTPLAN_ROLLBACK",
  RESET_SEND_MAIL_TESTPLAN: "RESET_SEND_MAIL_TESTPLAN",

  SET_TEST_PLAN_DATA: "SET_TEST_PLAN_DATA",
  CLEAR_TEST_PLAN_DATA: "CLEAR_TEST_PLAN_DATA",

  //EMI
  GET_DATA: "GET_TEST_PLAN",
  GET_DATA_COMMIT: "GET_TEST_PLAN_COMMIT",
  GET_DATA_ROLLBACK: "GET_TEST_PLAN_ROLLBACK",

  GET_TEST_PLANS: "GET_TEST_PLANS",
  GET_TEST_PLANS_COMMIT: "GET_TEST_PLANS_COMMIT",
  GET_TEST_PLANS_ROLLBACK: "GET_TEST_PLANS_ROLLBACK",

  CREATE_TESTPLAN_EMI: "CREATE_TESTPLAN_EMI",
  CREATE_TESTPLAN_COMMIT_EMI: "CREATE_TESTPLAN_COMMIT_EMI",
  CREATE_TESTPLAN_ROLLBACK_EMI: "CREATE_TESTPLAN_ROLLBACK_EMI",

  UPDATE_TESTCASES: "UPDATE_TESTCASES",

  GET_DRAFT: "GET_DRAFT",
  GET_DRAFT_COMMIT: "GET_DRAFT_COMMIT",
  GET_DRAFT_ROLLBACK: "GET_DRAFT_ROLLBACK",

  CREATE_TEST_PLAN_DRAFT_EMI: "CREATE_TEST_PLAN_DRAFT_EMI",
  CREATE_TEST_PLAN_DRAFT_COMMIT_EMI: "CREATE_TEST_PLAN_DRAFT_COMMIT_EMI",
  CREATE_TEST_PLAN_DRAFT_ROLLBACK_EMI: "CREATE_TEST_PLAN_DRAFT_ROLLBACK_EMI",

  GET_JIRA_USERS: "GET_JIRA_USERS",
  GET_JIRA_USERS_COMMIT: "GET_JIRA_USERS_COMMIT",
  GET_JIRA_USERS_ROLLBACK: "GET_JIRA_USERS_ROLLBACK",

  SET_TEST_PLAN_DATA_EMI: "SET_TEST_PLAN_DATA_EMI",
  CLEAR_TEST_PLAN_DATA_EMI: "CLEAR_TEST_PLAN_DATA_EMI",
};

export default testplanType;

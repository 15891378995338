import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getLatestTestplan } from "../../redux/actions/testplan-action";
import constants, { DROPDOWN_DEFAULT } from "../../constants";
import {
  Button,
  Select,
  SelectProps,
  ColumnLayout,
  FormField,
  SpaceBetween,
} from "@amzn/awsui-components-react-v3";

interface StateProps {
  metadataReducer: any;
  testplanReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
  closeModal: any;
} & StateProps;

type State = {
  category_id: string;
  category: string;
  categoryError: string;
  project_id: string;
  project: string;
  projectError: string;
  build: string;
  buildError: string;
  version_id: string;
  version: string;
  versionError: string;
  projectItems: Array<any>;
  buildItems: Array<any>;
  versionItems: Array<any>;
  buildConfig_id: string;
  buildConfig: string;
  buildConfigError: string;
  buildConfigItems: Array<any>;
};

class ImportTestplanForm extends Component<Props, State> {
  state: State = Object.freeze({
    category_id: "",
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project_id: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectItems: [],
    projectError: "",
    build: DROPDOWN_DEFAULT.BUILD,
    buildItems: [],
    buildError: "",
    version_id: "",
    version: DROPDOWN_DEFAULT.VERSION,
    versionItems: [],
    versionError: "",
    buildConfig_id: "",
    buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
    buildConfigError: "",
    buildConfigItems: [],
  });

  validate = () => {
    let isValid = true;
    let categoryError = "";
    let projectError = "";
    let buildError = "";
    let versionError = "";
    let buildConfigError = "";

    if (!this.state.category_id) {
      isValid = false;
      categoryError = "Required field";
    }
    if (!this.state.project_id) {
      isValid = false;
      projectError = "Required field";
    }
    if (!this.state.build || this.state.build === DROPDOWN_DEFAULT.BUILD) {
      isValid = false;
      buildError = "Required field";
    }
    if (!this.state.version_id) {
      isValid = false;
      versionError = "Required field";
    }
    if (!this.state.buildConfig_id || !Number(this.state.buildConfig_id)) {
      isValid = false;
      buildConfigError = "Required field";
    }
    this.setState({
      categoryError,
      projectError,
      buildError,
      versionError,
      buildConfigError,
    });
    return isValid;
  };

  getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });

    return items;
  };

  _onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    let projectItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id]?.children).forEach((project_id) => {
      const project = filter[category_id]?.children[project_id];
      const item: SelectProps.Option = {
        value: project_id,
        label: project.name,
      };
      projectItems.push(item);
    });
    this.setState({
      category_id,
      category,
      categoryError: "",
      projectItems,
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeProject = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    let buildItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id].children[project_id].children).forEach(
      (build) => {
        const item: SelectProps.Option = {
          value: build,
          label: build,
        };
        buildItems.push(item);
      }
    );
    this.setState({
      project_id,
      project,
      projectError: "",
      buildItems,
      build: DROPDOWN_DEFAULT.BUILD,
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
      versionItems: [],
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeBuild = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = event.detail.selectedOption.value;
    const { filter } = this.props.metadataReducer;
    let versionItems: Array<SelectProps.Option> = [];
    Object.keys(
      filter[category_id].children[project_id].children[build].children
    ).forEach((version) => {
      const item: SelectProps.Option = {
        value: version,
        label: version,
      };
      versionItems.push(item);
    });
    this.setState({
      build,
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems,
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeVersion = (event: any): any => {
    const { filter } = this.props.metadataReducer;
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = this.state.build;
    const version = event.detail.selectedOption.label;
    const version_id =
      filter[category_id].children[project_id].children[build].children[version]
        .id;

    let buildConfigItems: Array<SelectProps.Option> = [];
    Object.keys(
      filter[category_id].children[project_id].children[build].children[version]
        .children
    ).forEach((buildConfig_id) => {
      const item: SelectProps.Option = {
        value: buildConfig_id,
        label:
          filter[category_id].children[project_id].children[build].children[
            version
          ].children[buildConfig_id].name,
      };
      buildConfigItems.push(item);
    });

    this.setState({
      version_id,
      version,
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems,
    });
  };

  _onChangeBuildConfig = (event: any): any => {
    const buildConfig_id = event.detail.selectedOption.value;
    const buildConfig = event.detail.selectedOption.label;
    this.setState({
      buildConfig_id,
      buildConfig,
      buildConfigError: "",
    });
  };

  clear = (): any => {
    this.setState({
      category_id: "",
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectItems: [],
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onImport = (): any => {
    const isValid = this.validate();
    if (!isValid) {
      return;
    }
    this.props.dispatch(
      getLatestTestplan(
        this.state.category_id,
        this.state.project_id,
        this.state.version_id,
        Number(this.state.buildConfig_id)
      )
    );
  };

  render() {
    const { filter } = this.props.metadataReducer;
    const categoryItems = this.getCategoryItems(filter);
    const { loadingImportTestplanStatus } = this.props.testplanReducer;
    return (
      <div className="awsui-util-container">
        <div>
          <ColumnLayout columns={3}>
            {/* <div data-awsui-column-layout-root="true"> */}
            <FormField label="Category" errorText={this.state.categoryError}>
              <Select
                options={categoryItems}
                placeholder="Category"
                onChange={this._onChangeCategory}
                selectedOption={{
                  value: this.state.category_id,
                  label: this.state.category,
                }}
              ></Select>
            </FormField>
            <FormField label="Project" errorText={this.state.projectError}>
              <Select
                options={this.state.projectItems}
                placeholder="Project"
                onChange={this._onChangeProject}
                selectedOption={{
                  value: this.state.project_id,
                  label: this.state.project,
                }}
              ></Select>
            </FormField>
            <FormField label="Build" errorText={this.state.buildError}>
              <Select
                options={this.state.buildItems}
                placeholder="Build"
                onChange={this._onChangeBuild}
                selectedOption={{
                  value: this.state.build,
                  label: this.state.build,
                }}
              ></Select>
            </FormField>
            <FormField label="Version" errorText={this.state.versionError}>
              <Select
                options={this.state.versionItems}
                placeholder="Version"
                onChange={this._onChangeVersion}
                selectedOption={{
                  value: this.state.version,
                  label: this.state.version,
                }}
              ></Select>
            </FormField>
            <FormField
              label="Build Config"
              errorText={this.state.buildConfigError}
            >
              <Select
                options={this.state.buildConfigItems}
                placeholder="Build Config"
                onChange={this._onChangeBuildConfig}
                selectedOption={{
                  value: this.state.buildConfig_id,
                  label: this.state.buildConfig,
                }}
              ></Select>
            </FormField>
          </ColumnLayout>
        </div>
        <br />
        <div className="display-center">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              loading={loadingImportTestplanStatus === constants.LOADING_LOAD}
              variant="primary"
              onClick={() => this._onImport()}
            >
              Import
            </Button>
          </SpaceBetween>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
    testplanReducer: state.testplanReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(ImportTestplanForm);

import React, { useState } from "react";
// sub components
import {
  Table,
  Pagination,
  PropertyFilter,
} from "@amzn/awsui-components-react-v3/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  TableEmptyState,
  TableNoMatchState,
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../common";
import {
  EMI_TABLE_COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import { Button } from "@amzn/awsui-components-react";
import constants from "../../../constants";

const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

type Props = {
  tabledata: any;
  loading: any;
  category: string;
  project: string;
  build: string;
  s3_link: string;
};

const TableEMI = ({
  tabledata,
  loading,
  category,
  project,
  build,
  s3_link,
}: Props) => {
  // default table preferences
  const [preferences, setPreferences] = useState(
    DEFAULT_PREFERENCES(category, project, build)
  );

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(tabledata, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES,
      empty: TableEmptyState("Test Case"),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: "upload_time",
        },
        isDescending: true,
      },
    },
    selection: {},
  });
  const onDownloadButtonClick = () => {
    const link = document.createElement('a');
    link.href = s3_link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return (
    <Table
      {...collectionProps}
      columnDefinitions={EMI_TABLE_COLUMN_DEFINITIONS(category, project, build)}
      visibleColumns={preferences.visibleContent}
      items={items}
      loadingText="Loading resources"
      loading={
        loading != constants.LOADING_SUCCESS &&
        loading != constants.LOADING_FAIL
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title">
            <h2>
              Test Cases &nbsp;
              <span className="awsui-util-header-counter">{`(${tabledata.length})`}</span>
            </h2>
          </div>
          <div className="awsui-util-action-stripe-group">
            <Button
              className="button-with-margin"
              icon="download"
              variant="primary"
              href={s3_link}
              onClick={onDownloadButtonClick}
              disabled={!s3_link}
            >
              Testplan
            </Button>
          </div>
        </div>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
    />
  );
};

export default React.memo(TableEMI);

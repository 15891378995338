import visualizationType from "../types/visualization-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";

export const getTestcaseSummary = (test_case_id: number): any => ({
  type: visualizationType.GET_TESTCASE_SUMMARY,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.testcaseSummaryEndpoint(test_case_id),
        method: "GET",
      },
      commit: {
        type: visualizationType.GET_TESTCASE_SUMMARY_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.VISUALIZATION,
        },
      },
      rollback: {
        type: visualizationType.GET_TESTCASE_SUMMARY_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.VISUALIZATION,
        },
      },
    },
  },
});

export const getTestcaseRawData = (
  category: string,
  project: string,
  build: string,
  build_config: number,
  test_case_id: number,
  power_setting: string,
  data_type: string
): any => ({
  type: visualizationType.GET_TESTCASE_RAW_DATA,
  http: {
    cognito: {
      effect: {
        url:
          config.BASE_URL +
          endpoints.getTestcaseRawDataEndpoint(
            category,
            project,
            build,
            build_config,
            test_case_id,
            power_setting,
            data_type
          ),
        method: "GET",
      },
      commit: {
        type: visualizationType.GET_TESTCASE_RAW_DATA_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.VISUALIZATION,
        },
      },
      rollback: {
        type: visualizationType.GET_TESTCASE_RAW_DATA_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.VISUALIZATION,
        },
      },
    },
  },
});

export const resetVisualizationData = () => ({
  type: visualizationType.RESET_VISUALIZATION,
});

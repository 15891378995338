import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { centerAlign, statusStyle, PAGE_SIZE_OPTIONS } from "../common";
import React from "react";
import { NavLink } from "react-router-dom";
import { TEST_STATUS } from "../../../constants";

// define columns
export const EMI_TABLE_COLUMN_DEFINITIONS = (category, project, build) => [
  {
    id: "uid",
    header: "ID",
    cell: (item) => getNavLink(item.status, item.uid, category, project, build),
    sortingField: "uid",
    visible: true,
    minWidth: 300,
  },
  {
    id: "sheet_id",
    header: "Test Sheet ID",
    cell: (item) => centerAlign(item.sheet_id),
    sortingField: "sheet_id",
    visible: true,
    minWidth: 150,
  },
  {
    id: "config",
    header: "Config",
    cell: (item) => centerAlign(item.config),
    sortingField: "config",
    visible: true,
    minWidth: 150,
  },
  {
    id: "name",
    header: "Test Case",
    cell: (item) => centerAlign(item.name),
    sortingField: "name",
    visible: true,
    minWidth: 150,
  },
  {
    id: "section",
    header: "Section",
    cell: (item) => centerAlign(item.section),
    sortingField: "section",
    visible: true,
    minWidth: 200,
  },
  {
    id: "status",
    header: "Regulatory Status",
    cell: (item) => statusStyle(item.status),
    sortingField: "status",
    visible: true,
    minWidth: 100,
  },
  {
    id: "internal_status",
    header: "Internal Status",
    cell: (item) => statusStyle(item.internal_status),
    sortingField: "internal_status",
    visible: true,
    minWidth: 150,
  },
  {
    id: "frequency",
    header: "Frequency",
    cell: (item) => centerAlign(item.frequency),
    sortingField: "frequency",
    visible: true,
    minWidth: 100,
  },
  {
    id: "quasipeak",
    header: "QP Margin",
    cell: (item) => centerAlign(item.quasipeak),
    sortingField: "quasipeak",
    visible: true,
    minWidth: 100,
  },
  {
    id: "average",
    header: "AVG Margin",
    cell: (item) => centerAlign(item.average),
    sortingField: "average",
    visible: true,
    minWidth: 100,
  },
  {
    id: "maxpeak",
    header: "PK Margin",
    cell: (item) => centerAlign(item.maxpeak),
    sortingField: "maxpeak",
    visible: true,
    minWidth: 100,
  },
  {
    id: "polarity",
    header: "Polarity",
    cell: (item) => centerAlign(item.polarity),
    sortingField: "polarity",
    visible: true,
    minWidth: 100,
  },
  {
    id: "upload_time",
    header: "Upload Time",
    cell: (item) => centerAlign(item.upload_time),
    sortingField: "upload_time",
    visible: true,
    minWidth: 200,
  },
  {
    id: "comment",
    header: "Comment",
    cell: (item) => centerAlign(item.comment),
    sortingField: "comment",
    visible: true,
    minWidth: 250,
  },
];

// define visible columns
export const VISIBLE_CONTENT_OPTIONS = [
  {
    label: "Testcases",
    options: [
      {
        id: "uid",
        label: "UID",
        visible: true,
        editable: false,
      },
      {
        id: "sheet_id",
        label: "Test Plan ID",
        visible: true,
        editable: false,
      },
      {
        id: "config",
        label: "Config",
        visible: true,
        editable: false,
      },
      {
        id: "status",
        label: "Status",
        visible: true,
        editable: false,
      },
      {
        id: "name",
        label: "Test Case",
        visible: true,
        editable: false,
      },
      {
        id: "internal_status",
        label: "Internal Status",
        visible: true,
        editable: false,
      },
    ],
  },
];

// define filtering properties
export const FILTERING_PROPERTIES: any[] = [
  {
    propertyLabel: "ID",
    key: "uid",
    groupValuesLabel: "ID",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Test Sheet ID",
    key: "sheet_id",
    groupValuesLabel: "Test Sheet ID",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Config",
    key: "config",
    groupValuesLabel: "Config",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Test Case",
    key: "name",
    groupValuesLabel: "Test Case",
    operators: [":", "!:", "=", "!="],
  },

  {
    propertyLabel: "Internal Status",
    key: "internal_status",
    groupValuesLabel: "Internal Status",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Section",
    key: "section",
    groupValuesLabel: "Section",
    operators: [":", "!:", "=", "!="],
  },
  {
    propertyLabel: "Frequency",
    key: "frequency",
    groupValuesLabel: "Frequency",
    operators: [":", "!:", "=", "!="],
  },
];

// define default preferences
export const DEFAULT_PREFERENCES = (category, project, build) => {
  return {
    pageSize: 10,
    visibleContent: EMI_TABLE_COLUMN_DEFINITIONS(category, project, build)
      .filter((column) => column.visible)
      .map((column) => column.id),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions,
    }}
  />
);

const getNavLink = (status, uid, category, project, build) => {
  // desense doesn't have an overview page
  if (status.toLowerCase() === TEST_STATUS.UNTESTED) {
    return (
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}>
        {uid}
      </div>
    );
  }
  return (
    <NavLink
      to={`/categories/${category}/projects/${project}/builds/${build}/id/${uid}/visualize`}>
      <div
        style={{
          width: "100%",
          textAlign: "center",
        }}>
        {uid}
      </div>
    </NavLink>
  );
};

// AJAX request related
import { config } from "../../../../config/config.js";
import endpoint from "../../../../endpoints/endpoints";
import { UploadFileCheckRequest } from "../../../../model/http-json";
import { postData, Response } from "../../../../utils/cognito-fetch";
// constatns
import { FileStatus } from "../../../../model/upload";
import constants, { METRIC_INFO } from "../../../../constants";
import { getLogData } from "../../../../utils/general-utils";

export const getMetricInfo = (initiative, constant) => {
  return initiative == constants.INITIATIVE_EMI
    ? constant.INITIATIVE_EMI
    : constant.INITIATIVE_RF;
};

export async function fileNameCheckCall(
  file: File,
  initiative: string
): Promise<FileStatus> {
  /*
    Ajax call to check 1 single file name

*/
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
    s3_link: "",
  };

  try {
    const filename = file.name;
    const url: string = config.BASE_URL + endpoint.getuploadExcelFile();
    const data: UploadFileCheckRequest = {
      initiative: initiative,
      file_name: filename,
    };
    const response: Response = await postData(
      url,
      data,
      getLogData(
        METRIC_INFO.MANUAL_UPLOAD,
        getMetricInfo(initiative, METRIC_INFO)
      )
    );
    // http reponse ok
    if (response.ok) {
      const fileCheckResponse = response.json;
      if (
        fileCheckResponse.message &&
        fileCheckResponse.message === constants.FILE_CHECK_SUCCESS
      ) {
        rst.status = constants.LOADING_SUCCESS;
      } else {
        rst.status = constants.LOADING_FAIL;
        rst.message = `${
          fileCheckResponse.data ? fileCheckResponse.data : "Unknown error."
        } ${
          fileCheckResponse.error_description
            ? fileCheckResponse.error_description
            : ""
        }`;
      }
    } else {
      //http resonse on ok
      rst.status = constants.LOADING_SUCCESS;
      rst.message = response.json.data;
    }
  } catch (error) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

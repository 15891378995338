import React, { useState } from "react";
import {
  Modal,
  SpaceBetween,
  ColumnLayout,
  FormField,
  Select,
  Button,
  SelectProps,
} from "@amzn/awsui-components-react-v3/polaris";

import { Dispatch } from "redux";
import { connect } from "react-redux";
import { DROPDOWN_DEFAULT } from "../../../constants";
import { getTestPlan } from "../../../redux/actions/testplan-action-emi";

interface StateProps {
  metadataReducer: any;
}

// declare prop check
type Props = {
  visible: boolean;
  setVisible: any;
  dispatch: Dispatch<any>;
} & StateProps;

function ImportTestplan({
  dispatch,
  visible,
  setVisible,
  metadataReducer,
}: Props) {
  const { filter } = metadataReducer;

  const [categoryData, setCategoryData] = useState({
    categoryError: "",
    category_id: "",
    category: DROPDOWN_DEFAULT.CATEGORY,
  });
  const [projectData, setProjectData] = useState<{
    project_id: string;
    project: string;
    projectItems: Array<SelectProps.Option>;
    projectError: string;
  }>({
    project_id: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectItems: [],
    projectError: "",
  });

  const [buildData, setBuildData] = useState<{
    build: string;
    buildItems: Array<SelectProps.Option>;
    buildError: string;
  }>({
    build: DROPDOWN_DEFAULT.BUILD,
    buildItems: [],
    buildError: "",
  });

  const [versionData, setVersionData] = useState<{
    version_id: string;
    version: string;
    versionItems: Array<SelectProps.Option>;
    versionError: string;
  }>({
    version_id: "",
    version: DROPDOWN_DEFAULT.VERSION,
    versionItems: [],
    versionError: "",
  });

  const getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });

    return items;
  };

  const categoryItems = getCategoryItems(filter);

  const _onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;
    const { filter } = metadataReducer;
    let projectItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id].children).forEach((project_id) => {
      const project = filter[category_id].children[project_id];
      const item: SelectProps.Option = {
        value: project_id,
        label: project.name,
      };
      projectItems.push(item);
    });
    setCategoryData({
      category_id,
      category,
      categoryError: "",
    });
    setProjectData({
      projectItems,
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
    });
    setBuildData({
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
    });
    setVersionData({
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
    });
  };

  const _onChangeProject = (event: any): any => {
    const category_id = categoryData.category_id;
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;
    const { filter } = metadataReducer;
    let buildItems: Array<SelectProps.Option> = [];
    Object.keys(filter[category_id].children[project_id].children).forEach(
      (build) => {
        const item: SelectProps.Option = {
          value: build,
          label: build,
        };
        buildItems.push(item);
      }
    );
    setProjectData({
      ...projectData,
      project_id,
      project,
      projectError: "",
    });
    setBuildData({
      buildItems,
      build: DROPDOWN_DEFAULT.BUILD,
      buildError: "",
    });
    setVersionData({
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
      versionItems: [],
    });
  };
  const _onChangeBuild = (event: any): any => {
    const category_id = categoryData.category_id;
    const project_id = projectData.project_id;
    const build = event.detail.selectedOption.value;
    const { filter } = metadataReducer;
    let versionItems: Array<SelectProps.Option> = [];
    Object.keys(
      filter[category_id].children[project_id].children[build].children
    ).forEach((version) => {
      const item: SelectProps.Option = {
        value: version,
        label: version,
      };
      versionItems.push(item);
    });
    setBuildData({
      ...buildData,
      build,
      buildError: "",
    });
    setVersionData({
      versionItems,
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
    });
  };

  const _onChangeVersion = (event: any): any => {
    const { filter } = metadataReducer;
    const category_id = categoryData.category_id;
    const project_id = projectData.project_id;
    const build = buildData.build;
    const version = event.detail.selectedOption.label;
    const version_id =
      filter[category_id].children[project_id].children[build].children[version]
        .id;
    setVersionData({
      ...versionData,
      version_id,
      version,
      versionError: "",
    });
  };

  const _onfetch = async () => {
    if (
      categoryData.category_id != "" &&
      projectData.project_id != "" &&
      buildData.build != DROPDOWN_DEFAULT.BUILD &&
      versionData.version_id != ""
    ) {
      dispatch(
        getTestPlan(
          categoryData.category_id,
          projectData.project_id,
          versionData.version_id
        )
      );
    } else {
      alert("Please select values in dropdown");
    }
  };

  return (
    <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      header="Import Test Cases from Existing Plan"
    >
      <SpaceBetween direction="vertical" size="l">
        <ColumnLayout columns={3}>
          <FormField label="Category" errorText={categoryData.categoryError}>
            <Select
              options={categoryItems}
              placeholder="Category"
              onChange={_onChangeCategory}
              selectedOption={{
                value: categoryData.category_id,
                label: categoryData.category,
              }}
            ></Select>
          </FormField>
          <FormField label="Project" errorText={projectData.projectError}>
            <Select
              options={projectData.projectItems}
              placeholder="Project"
              onChange={_onChangeProject}
              selectedOption={{
                value: projectData.project_id,
                label: projectData.project,
              }}
            ></Select>
          </FormField>
          <FormField label="Build" errorText={buildData.buildError}>
            <Select
              options={buildData.buildItems}
              placeholder="Build"
              onChange={_onChangeBuild}
              selectedOption={{
                value: buildData.build,
                label: buildData.build,
              }}
            ></Select>
          </FormField>
          <FormField label="Version" errorText={versionData.versionError}>
            <Select
              options={versionData.versionItems}
              placeholder="Version"
              onChange={_onChangeVersion}
              selectedOption={{
                value: versionData.version,
                label: versionData.version,
              }}
            ></Select>
          </FormField>
        </ColumnLayout>

        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="primary" onClick={_onfetch}>
            Fetch Test Plan
          </Button>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(ImportTestplan);

import { combineReducers } from "redux";
import landingReducer from "./reducers/landing-reducer";
import authzReducer from "./reducers/authz-reducer";
import metadataReducer from "./reducers/metadata-reducer";
import categoryReducer from "./reducers/category-reducer";
import createBuildReducer from "./reducers/build-reducer";
import projectReducer from "./reducers/project-reducer";
import testplanReducer from "./reducers/testplan-reducer";
import { reducer as formReducer } from "redux-form";
import testplanReducerEMI from "./reducers/testplan-reducer-emi";
import visualizationEMIReducer from "./reducers/visualization-emi-reducer";
import overviewReducer from "./reducers/overview-reducer";
import reportReducer from "./reducers/report-reducer";
import testcaseReducer from "./reducers/testcase-reducer";
import uploadReducer from "./reducers/upload-reducer";
import visualizationReducer from "./reducers/visualization-reducer";
import buildConfigReducer from "./reducers/build-config-reducer";
import buildReducerEMI from "./reducers/build-reducer-emi";
import downloadReducer from "./reducers/download-reducer";

const reducers = {
  authzReducer,
  landingReducer,
  metadataReducer,
  buildReducerEMI,
  categoryReducer,
  createBuildReducer,
  projectReducer,
  overviewReducer,
  reportReducer,
  testplanReducer,
  testplanReducerEMI,
  visualizationEMIReducer,
  form: formReducer,
  testcaseReducer,
  uploadReducer,
  visualizationReducer,
  buildConfigReducer,
  downloadReducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;

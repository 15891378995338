import moment from "moment";

// change date iso string -> YY-MM-DD hh:mm:ss(default)
export const changeDateFormat = (
  isoDateStr: string,
  format: string = "YYYY-MM-DD HH:mm:ss"
): string => {
  // check iso format
  if (!moment(isoDateStr, moment.ISO_8601).isValid()) {
    return "";
  }
  // load date string, convent to local
  const m = moment(isoDateStr).local();

  const newDateStr = m.format(format);

  return newDateStr;
};
import categoryType from "../types/category-type";
import constants from "../../constants";

interface state {
    loadingStatus: number,
    message: string,
    responseData: any
}

const initialState: state = {
  loadingStatus: constants.LOADING_DEFAULT,
  message: "",
  responseData: {}
};

const categoryReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case categoryType.CREATE_CATEGORY:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case categoryType.CREATE_CATEGORY_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        message: action.payload.message,
        responseData: action.payload.data
      };
    case categoryType.CREATE_CATEGORY_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
        message: action.payload.message,
        responseData: action.payload.data
      };
    case categoryType.RESET_CATEGORY:
      return {
        ...state,
        loadingStatus: constants.LOADING_DEFAULT,
        message: "",
        responseData: {}
      };
    default:
      return state;
  }
};

export default categoryReducer;
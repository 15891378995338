import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { getBuildTestcasesData } from "../../redux/actions/build-action-emi";
import { setTestOverviewDetailsEMI } from "../../redux/actions/testplan-action-emi";
import BreadcrumbGroup from "../../components/breadcrumb-group";
import { capitalizeFirstLetter } from "../../utils/general-utils";
import constants from "../../constants";
import Iframe from "../iframe/iframe";
import { Header } from "@amzn/awsui-components-react-v3/polaris";
import BuildOverviewChart from "./chart/build-overview";
import { ValueWithLabel } from "../../components/value-with-label";
import { Button } from "@amzn/awsui-components-react";
import BuildConfigOverview from "./chart/build-config-overview";
import { getBuildAndVersion } from "../../utils/general-utils";

import TableEMI from "./table-emi/table";

import { Container, SpaceBetween } from "@amzn/awsui-components-react-v3";
import { Dispatch } from "redux";
import {downloadAction} from "../../redux/actions/download-action";
import {DownloadPreSignedURLRequest} from "../../model/http-json";
import {parseS3Link} from "../../utils/s3-utils";
import downloadReducer from "../../redux/reducers/download-reducer";

interface StateProps {
  metadataReducer: any;
  buildReducerEMI: any;
  downloadReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

function OverviewEMI({
  metadataReducer,
  buildReducerEMI,
  downloadReducer,
  match,
  dispatch,
}: Props) {
  const { loadingStatus, testCases, message, s3_link } = buildReducerEMI;
  const [categoryName, setCategoryName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectStreetDate, setProjectStreetDate] = useState("");
  const [buildAndVersion, setBuildAndVersion] = useState("");
  const [buildStatus, setBuildStatus] = useState("");
  const [buildStartDate, setBuildStartDate] = useState("");
  const [buildEndDate, setBuildEndDate] = useState("");
  const [s3Url, setS3Url ] = useState("");


  const intialize_component = (
    category: any,
    project: any,
    build: any,
    filter: any
  ) => {
    const categoryName = filter[category].name;
    const projectName = filter[category].children[project].name;
    const projectStreetDate = filter[category].children[project].streetDate;
    const buildAndVersion = getBuildAndVersion(
      filter[category].children[project],
      build
    );
    const buildAndVersionSplit = buildAndVersion.split("_");
    const buildStartDate =
      filter[category].children[project].children[buildAndVersionSplit[0]]
        .children[buildAndVersionSplit[1]].startDate;
    const buildEndDate =
      filter[category].children[project].children[buildAndVersionSplit[0]]
        .children[buildAndVersionSplit[1]].endDate;
    const buildStatus =
      filter[category].children[project].children[buildAndVersionSplit[0]]
        .children[buildAndVersionSplit[1]].status;

    setCategoryName(categoryName);
    setProjectName(projectName);
    setProjectStreetDate(projectStreetDate);
    setBuildAndVersion(buildAndVersion);
    setBuildStartDate(buildStartDate);
    setBuildEndDate(buildEndDate);
    setBuildStatus(buildStatus);
  };
  const location = useLocation();
  const [data, setData] = useState(testCases);

  //var prevUrl = usePrevious(match);
  useEffect(() => {
    const { category, project, build } = match.params;
    const { filter } = metadataReducer;
    if (Object.keys(filter).length > 0) {
      intialize_component(category, project, build, filter);
    }
    dispatch(
      getBuildTestcasesData(category, project, build)
    );
  }, [location, metadataReducer.filter]);

  useEffect(() => {
    setData(testCases);
  }, [testCases]);

  useEffect(() => {
    if(buildReducerEMI.s3_link) {
      const s3_components = parseS3Link(buildReducerEMI.s3_link)
      const data: DownloadPreSignedURLRequest = {
        file_name: s3_components.file_path,
        object_key: s3_components.object_key,
        bucket_name: s3_components.bucket_name,
        version_id: s3_components.version_id
      };
      dispatch(
          downloadAction(data)
      );
    } else {
      //build does not have a test plan
      setS3Url('');
    }

  }, [buildReducerEMI.s3_link]);

  useEffect(() => {
    dispatch(
      setTestOverviewDetailsEMI({
        categoryId: category,
        projectId: project,
        buildVersionId: build,
      })
    );
  }, []);

  useEffect(() => {
    if (buildReducerEMI.s3_link) {
      setS3Url(downloadReducer.url);
    } else {
      setS3Url('');
    }

  }, [downloadReducer]);


  const onSelectBuildConfig = (buildConfigId, buildConfig) => {
    var newArray = testCases.filter(function (testcase) {
      return testcase.config == buildConfig;
    });
    setData(newArray);
  };

  var holder = {};

  testCases.forEach(function (testcase) {
    if (holder.hasOwnProperty(testcase.config)) {
      if (testcase.status === "Failed") {
        holder[testcase.config].failed += 1;
      }
      if (testcase.status === "Passed") {
        holder[testcase.config].passed += 1;
      }
      if (testcase.status === "Untested") {
        holder[testcase.config].untested += 1;
      }
    } else {
      if (testcase.status === "Failed") {
        holder[testcase.config] = {
          failed: 1,
          passed: 0,
          untested: 0,
        };
      }
      if (testcase.status === "Passed") {
        holder[testcase.config] = {
          failed: 0,
          passed: 1,
          untested: 0,
        };
      }
      if (testcase.status === "Untested") {
        holder[testcase.config] = {
          failed: 0,
          passed: 0,
          untested: 1,
        };
      }
    }
  });

  var buildConfigs: object[] = [];

  for (var prop in holder) {
    buildConfigs.push({
      build_config: prop,
      failed: holder[prop].failed,
      passed: holder[prop].passed,
      untested: holder[prop].untested,
    });
  }
  //const getLoadingStatus = 1;
  const { category, project, build } = match.params;
  return (
    <div>
      <div className="awsui-util-action-stripe">
        <div className="awsui-util-action-stripe-title">
          <BreadcrumbGroup
            textGroup={[
              { text: capitalizeFirstLetter(categoryName) }, // Category
              { text: capitalizeFirstLetter(projectName) }, // project
              { text: buildAndVersion }, // build
              { text: "EMI" },
            ]}
          />
        </div>
        <div className="awsui-util-action-stripe-group">
          <Iframe />
        </div>
      </div>
      <br />
      <Container header={<Header variant="h2">Build Overview</Header>}>
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              width: "80%",
            }}
          >
            <BuildOverviewChart
              data={buildConfigs}
              loadingStatus={loadingStatus}
            />
          </div>
          <div
            className="border-right"
            style={{
              marginRight: "20px",
            }}
          />
          <SpaceBetween size="l">
            <ValueWithLabel label="Status">{buildStatus}</ValueWithLabel>
            <ValueWithLabel label="Street Date">
              {projectStreetDate}
            </ValueWithLabel>
            <ValueWithLabel label="Start Date">{buildStartDate}</ValueWithLabel>
            <ValueWithLabel label="End Date">{buildEndDate}</ValueWithLabel>
            {/*<ValueWithLabel label="Mark build as completed">
              <Button
                variant="primary"
                onClick={() => {}}
                disabled={buildStatus === constants.STATUS_COMPLETED}
                //loading={loadingUpdateBuildStatus === constants.LOADING_LOAD}
                className="mt-2"
              >
                Complete
              </Button>
          </ValueWithLabel>*/}
          </SpaceBetween>
        </div>
      </Container>
      <br />
      <BuildConfigOverview
        data={buildConfigs}
        loadingStatus={loadingStatus}
        category={category}
        project={project}
        build={build}
        onSelectBuildConfig={onSelectBuildConfig}
      />
      <br />

      <TableEMI
        category={category}
        project={project}
        build={build}
        tabledata={data}
        loading={loadingStatus}
        s3_link={s3Url}
      />
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    metadataReducer: state.metadataReducer,
    buildReducerEMI: state.buildReducerEMI,
    downloadReducer: state.downloadReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(OverviewEMI));

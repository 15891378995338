import visualizationEmiType from "../types/visualization-emi-type";
import { formLimit } from "../actions/data-action";
import constants from "../../constants";
// sample data
// import { testcaseData } from "../../containers/visualization-emi/sample-data";

const initialState = {
  data: {},
  series: [],
  attributes: [],
  tableData: [],
  tableColumn: [],
  limits: [],
  failedTable: [],
  loadingStatus: constants.LOADING_DEFAULT,
  showFailedTable: false,
  failedInfo: [],
  selectedDataPointsTableData: [],
  selectedDataPointsTableColumnNames: [],
  testcaseSpecific: {},
};

// debug frequency table column name order
// construct table columns based on this order
const debug_frq_column_name_order = [
  "frequency",
  "maxpeak",
  "quasipeak",
  "average",
  "limit",
  "margin",
  "height",
  "pol",
  "azimuth",
];

function getSpecificTestcase(action) {
  const data = action.payload.data;
  const testcase = {
    testype: "EMI",
    id: data.uid,
    name: data.name,
    section: data.section,
    status: data.status,
    internal_status: data.internal_status,
    upload_time: data.upload_time,
    comment: null,
    category: action.category,
    project: action.project,
    build: action.build,
    testCategory: action.testCategory,
  };
  return testcase;
}

// generate default data plot
function getTestingData(values) {
  try {
    let data = values.data;
    let testingdata = data["testing_data"];
    let attributes = data["attributes"];
    let xName = data["x_name"];
    let yName = data["y_name"];
    let keyTable = data["key_table"];
    let failed_data = data["failed_data"];
    let limit = data["limit"];
    let failed_info = data["failed_info"];
    let seriesData: Array<Object> = [];

    // generate debug frequncy table columns
    // 1. generate unsorted table columns
    type tableColumnsUnsortedType = Array<Object>;
    let tableColumnsUnsorted: tableColumnsUnsortedType = [];
    let keyTableNames = data["key_table_names"];
    for (var i = 0; i < keyTableNames.length; i++) {
      type keyobjectType = {
        Header: string;
        accessor: string;
        style: Object;
      };
      let keyobject: keyobjectType = {
        Header: keyTableNames[i],
        accessor: keyTableNames[i].replace(".", ""),
        style: { whiteSpace: "unset" },
      };

      tableColumnsUnsorted.push(keyobject);
    }

    let tableColumns: Array<Object> = [];
    let tableColumnsHashSet = new Set();
    // 2. first put ordered columns into tableColumns which are in debug_frq_column_name_order
    debug_frq_column_name_order.forEach((eachOrderedColumnName) => {
      tableColumnsUnsorted.forEach((eachInorderedColumnObj: any) => {
        if (
          eachInorderedColumnObj.Header.toLowerCase().indexOf(
            eachOrderedColumnName
          ) !== -1
        ) {
          tableColumns.push(eachInorderedColumnObj);
          tableColumnsHashSet.add(eachInorderedColumnObj.Header);
        }
      });
    });
    // 3. then put the rest columns into tableColumns which are not in debug_frq_column_name_order
    tableColumnsUnsorted.forEach((eachInorderedColumnObj: any) => {
      if (!tableColumnsHashSet.has(eachInorderedColumnObj.Header)) {
        tableColumns.push(eachInorderedColumnObj);
      }
    });

    //generate data plot series
    //peak scan data
    let cur_series = {
      name: "Testcase 1: " + data["y_name"],
      data: data["series_data"],
      type: "line",
      color: "#7CB5EC",
      marker: {
        enabled: true,
        radius: 1,
      },
    };
    seriesData.push(cur_series);

    // pk limit data
    let cur_series1 = {
      name: data["limit"],
      data: data["limit_data"],
      type: "line",
      color: "#F95C69",
      marker: {
        enabled: true,
        radius: 1,
      },
    };
    seriesData.push(cur_series1);

    // generate debug frequency limit
    let debugPlots = data["debug_plots"];

    let ind = 0;
    for (var key in debugPlots) {
      let color_val = ind == 0 ? "#434348" : "#90ED7D";
      var debug_series = {
        type: "scatter",
        name: key,
        data: debugPlots[key],
        color: color_val,
        symbol: ind == 0 ? "square" : "star-triangle-up",
      };
      ind++;
      seriesData.push(debug_series);
    }

    // generate customer limit data
    if (data["customer_limit"] && data["customer_limit"].length > 0) {
      const limit_series = {
        type: "line",
        name: "Customer_Limit",
        data: generateCustomerLimitSeries(formLimit(data["customer_limit"])),
        color: "#008000",
        marker: {
          enabled: true,
          radius: 1,
        },
      };
      seriesData.push(limit_series);
    } else {
      const limit_series = {
        type: "line",
        name: "Internal_Limit",
        data: data["internal_limit"],
        color: "#008000",
        marker: {
          enabled: true,
          radius: 1,
        },
      };
      seriesData.push(limit_series);
    }

    let showFailedStatus = false;
    if (data["failed_data"].length > 0) {
      showFailedStatus = true;
    }

    return {
      testingdata,
      attributes,
      seriesData,
      xName,
      yName,
      keyTable,
      tableColumns,
      failed_data,
      limit,
      showFailedStatus,
      failed_info,
    };
  } catch (e) {
    console.log(e);
  }
}

//generate customer limit series
const generateCustomerLimitSeries = (limits) => {
  const limit_series_data: Array<any> = [];
  limits.forEach((limit) => {
    limit_series_data.push([Number(limit.start), Number(limit.val)]);
    limit_series_data.push([Number(limit.end), Number(limit.val)]);
  });
  return limit_series_data;
};

//reducer gets data from backend
function visualizationEMIReducer(state = initialState, action) {
  switch (action.type) {
    case visualizationEmiType.GET_VISUALIZATION_DATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case visualizationEmiType.GET_VISUALIZATION_DATA_COMMIT:
      // use case: No TestCase Data found
      if (
        action.payload &&
        action.payload.data &&
        Object.keys(action.payload.data).length === 0
      ) {
        return {
          ...state,
          loadingStatus: constants.LOADING_SUCCESS,
        };
      }

      const data: any = getTestingData(action.payload);
      const newLimits =
        action.payload &&
        action.payload.data &&
        action.payload.data["customer_limit"]
          ? formLimit(action.payload.data["customer_limit"])
          : [];
      return {
        ...state,
        data: data?.testingdata,
        series: data?.seriesData,
        attributes: data?.attributes,
        tableData: data?.keyTable,
        tableColumn: data?.tableColumns,
        xName: data?.xName,
        yName: data?.yName,
        limits: newLimits,
        failedTable: data?.failed_data,
        limitName: data?.limit,
        loadingStatus: constants.LOADING_SUCCESS,
        showFailedTable: data?.showFailedStatus,
        failedInfo: data?.failed_info,
      };
    case visualizationEmiType.GET_VISUALIZATION_DATA_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    case visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_DATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_DATA_COMMIT:
      const testcase = getSpecificTestcase(action);
      return {
        ...state,
        testcaseSpecific: testcase,
      };
    case visualizationEmiType.GET_VISUALIZATION_SPECIFIC_TCS_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
}

export default visualizationEMIReducer;

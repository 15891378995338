import React, { Component } from "react";
import { connect } from "react-redux";
import "./testPlan.css";
import { Autosuggest, Button, FormField, SpaceBetween } from "@amzn/awsui-components-react-v3";

class AddSectionForm extends Component {
  state = {
    section: "",
  };
  onInput = ({detail}) => {
    this.setState({
      // section: e.target.value,
      section: detail.value,
    });
  };

  render() {
    const { fields, options, setnewTabIndex } = this.props;
    return (
      <div className="wise-main-container add-sheet-block">
        <FormField>
          <SpaceBetween direction="horizontal" size="xs">
            <Autosuggest 
              onChange={this.onInput}
              value={this.state.section}
              placeholder="Enter Sheet Name"
              options={options}
              enteredTextLabel={value => `Use: "${value}"`}
              className="test-plan-btn-submit"
            />
            <Button
              variant="normal"
              onClick={(e) => {
                if (!this.state.section) {
                  e.preventDefault();
                  alert(
                    "Please enter a sheet name and then click on Add Sheet button."
                  );
                  return;
                } else {
                  let sectionsList = new Set();
                  for (let index = 0; index < fields.length; index++) {
                    sectionsList.add(fields.get(index).name);
                  }
                  if (this.state.section && sectionsList.has(this.state.section)) {
                    e.preventDefault();
                    alert("Sheet Name already exists.");
                    return;
                  }

                  if (this.state.section && !sectionsList.has(this.state.section)) {
                    e.preventDefault();
                    fields.push({
                      name: this.state.section,
                      testCases: [
                        {
                          sheet_id: "1",
                          section: this.state.section,
                        },
                      ],
                    });
                    setnewTabIndex(fields.length);
                  }
                }
              }}
            >
              Add Sheet
            </Button>
          </SpaceBetween>
        </FormField>
      </div>
    );
  }
}

export default connect()(AddSectionForm);

import React, { Component } from "react";
import {
  Form,
  Button,
  Alert,
  Header,
  SpaceBetween,
  Container,
} from "@amzn/awsui-components-react-v3/polaris";
// sub components
import UploadFileDropzone from "./file-dropzone";
import UploadingModal from "./uploading-model";
// util func
import { fileNameCheckCall, getMetricInfo } from "./utils";
// constans
import constants, { METRIC_INFO } from "../../../../constants";
import { FileStatus, TestCases } from "../../../../model/upload";
import * as XLSX from "xlsx";

type Props = {
  initiative: string; // initiative is emi or rf,
  setExcelTestCases: (testcases: any) => {};
};
type State = {
  fileList: Array<File>; // the File object array
  fileCheckDict: { [key: string]: FileStatus }; // file_name -> fileCheck
  fileUploadDict: { [key: string]: FileStatus }; // file_name -> fileUpload
  showModal: boolean;
  excel_testcases: TestCases;
};

class ManualUploadPanelExcel extends Component<Props, State> {
  state: State = Object.freeze({
    fileList: [],
    fileCheckDict: {},
    fileUploadDict: {},
    showModal: false,
    excel_testcases: {
      test_cases: [],
    },
  });

  // event: when on drag and drop file in dropzone
  _onChangeFiles = (files: Array<File>) => {
    const { fileList, fileCheckDict, fileUploadDict } = this.state;

    const newFileList: Array<File> = [];
    const newFileCheckDict: { [key: string]: FileStatus } = {};
    const newFileUploadDict: { [key: string]: FileStatus } = {};

    files.forEach((file: File) => {
      // repeated file
      if (!(file.name in fileCheckDict)) {
        newFileList.push(file);
        newFileCheckDict[file.name] = {
          status: constants.LOADING_LOAD,
          message: "",
          s3_link: "",
        };
        newFileUploadDict[file.name] = {
          status: constants.LOADING_LOAD,
          message: "",
          s3_link: "",
        };
      }
    });

    this.setState({
      fileList: [...fileList, ...newFileList],
      fileCheckDict: { ...fileCheckDict, ...newFileCheckDict },
      fileUploadDict: { ...fileUploadDict, ...newFileUploadDict },
    });

    // check file name
    this._validateFilename();
  };

  // loop each file, and send ajax call to validate file name
  _validateFilename = async () => {
    const { fileList } = this.state;
    fileList.forEach((file: File) => {
      fileNameCheckCall(file, this.props.initiative).then((rst: FileStatus) => {
        const { ...fileCheckDict } = this.state.fileCheckDict;
        fileCheckDict[file.name] = rst;
        this.setState({
          fileCheckDict,
        });
      });
    });
  };

  swapKeysAndValues(obj) {
    const swapped = Object.entries(obj).map(([key, value]) => [value, key]);

    return Object.fromEntries(swapped);
  }

  /*
    loop each file, 
    1. get presigned url
    2. then upload to s3
   */
  _uploadFiles = async () => {
    const { fileList } = this.state;
    let count = 0;
    fileList.forEach((file: File, index) => {
      const reader = new FileReader();

      reader.readAsArrayBuffer(file);
      reader.onload = (e) => {
        if (!!reader.result) {
          const workbook = XLSX.read(e?.target?.result, { type: "buffer" });
          let testcases: Array<Object> = [];
          let status = constants.LOADING_LOAD;
          let message = "";
          for (let j = 0; j < workbook.SheetNames.length; j++) {
            const worksheetName = workbook.SheetNames[j];
            const worksheet = workbook.Sheets[worksheetName];
            const data: Array<Object> = XLSX.utils.sheet_to_json(worksheet);
            let row = -1,
              head_obj = {};
            for (let i = 0; i < data.length; i++) {
              if (data[i][worksheet.A1.v] == "ID") {
                head_obj = this.swapKeysAndValues(data[i]);
                row = i;
                break;
              }
            }
            if (row == -1 || row + 1 == data.length) {
              message = "PARSING ERROR: No testcases were found.";
              status = constants.LOADING_FAIL;
            } else {
              for (let i = row + 1; i < data.length; i++) {
                if (data[i][head_obj["ID"]] === "Test Data") {
                  i++;
                  continue;
                }
                if (
                  data[i][head_obj["Config"]] &&
                  data[i][head_obj["Section"]] &&
                  data[i][head_obj["Test Case Name"]] &&
                  data[i][head_obj["Build"]] &&
                  data[i][head_obj["ID"]]
                ) {
                  testcases.push({
                    sheet_id: data[i][head_obj["ID"]],
                    config: data[i][head_obj["Config"]],
                    section: data[i][head_obj["Section"]],
                    name: data[i][head_obj["Test Case Name"]],
                    sheet_name: data[i][head_obj["Section"]],
                    title:
                      data[i][head_obj["Build"]] + " " + data[i][worksheetName],
                  });
                  status = constants.LOADING_SUCCESS;
                } else {
                  if (data[i][head_obj["Polarity"]]) {
                    continue;
                  }
                  let val = "ID";
                  if (!data[i][head_obj["Config"]]) {
                    val = "Config";
                  } else if (!data[i][head_obj["Section"]]) {
                    val = "Section";
                  } else if (!data[i][head_obj["Test Case Name"]]) {
                    val = "Test Case Name";
                  } else if (!data[i][head_obj["Build"]]) {
                    val = "Build";
                  }
                  message =
                    "PARSING ERROR: Invalid " +
                    val +
                    " found on row " +
                    i +
                    " in sheet " +
                    worksheetName +
                    ".";
                  status = constants.LOADING_FAIL;
                  break;
                }
              }
              if (status == constants.LOADING_FAIL) {
                break;
              }
            }
          }
          const { ...excel_testcases } = this.state.excel_testcases;
          excel_testcases[file.name] = {
            test_cases: testcases,
          };
          this.setState({
            excel_testcases,
          });

          const { ...fileUploadDict } = this.state.fileUploadDict;
          fileUploadDict[file.name] = {
            status: status,
            message: message,
            s3_link: "",
          };
          this.setState({
            fileUploadDict,
          });
        }
      };
    });
  };

  // onClick clear button
  _onClear = () => {
    this.setState({
      fileList: [],
      fileCheckDict: {},
      fileUploadDict: {},
      showModal: false,
    });
  };

  // delete a file
  _deleteFile = (filename: string) => {
    let [...fileList] = this.state.fileList;
    const { ...fileCheckDict } = this.state.fileCheckDict;
    const { ...fileUploadDict } = this.state.fileUploadDict;

    if (filename in fileCheckDict) {
      delete fileCheckDict[filename];
    }

    if (filename in fileUploadDict) {
      delete fileUploadDict[filename];
    }

    fileList = fileList.filter((file: File) => {
      return file.name !== filename;
    });

    this.setState({
      fileList,
      fileCheckDict,
      fileUploadDict,
    });
  };

  /*
    onClick Upload button
    1. fileList is empty => alert
    2. validate file name failed => alert
    3. all good => confirm
        3.1 no => clear and return
        3.2 yes => open model, start upload process
  */
  _onClickUpload = () => {
    // 1. fileList is empty
    if (this.state.fileList.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!this._validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm(
      "Files will be uploaded to LENS. Click OK to confirm. "
    );

    if (!isConfirmed) {
      return;
    }

    this._showModal();

    this._uploadFiles();
  };

  /* 
    check file name validation in fileCheckList
    return false if any of the file name is invalid
    return true if all file names are valid
  */
  _validateForm = () => {
    const { fileList, fileCheckDict } = this.state;
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status === constants.LOADING_FAIL
      ) {
        return false;
      }
    }
    return true;
  };

  /* 
    check if need to disable upload button
    case 1: fileCheckList is empty
    case 2: Any file name is checking or failed
  */
  _checkDisableUploadButton = () => {
    // case 1:
    const { fileList, fileCheckDict } = this.state;
    if (fileList.length === 0) {
      return true;
    }

    // case 2:
    for (let i = 0; i < fileList.length; i++) {
      const filename = fileList[i].name;
      if (
        fileCheckDict[filename] &&
        fileCheckDict[filename].status !== constants.LOADING_SUCCESS
      ) {
        return true;
      }
    }

    // otherwise, do not disable, return false
    return false;
  };

  _showModal = () => {
    this.setState({
      showModal: true,
    });
  };

  _closeModal = () => {
    const { fileList, excel_testcases } = this.state;
    const metric_val = getMetricInfo(this.props.initiative, METRIC_INFO);
    this.setState({
      showModal: false,
    });

    // clear form
    this._onClear();

    if (excel_testcases[fileList[0].name].test_cases.length > 0)
      this.props.setExcelTestCases(
        excel_testcases[fileList[0].name].test_cases
      );
  };

  render() {
    const hasError: boolean = !this._validateForm(); // has any file name error flag
    const disableUploadBtn: boolean = this._checkDisableUploadButton(); // if disable upload button flag
    const { showModal, fileList, fileCheckDict, fileUploadDict } = this.state;

    const description =
      this.props.initiative == constants.INITIATIVE_RF
        ? "Drag and drop to upload rf files"
        : "Drag and drop to upload emi files";
    return (
      <Container>
        <div>
          {/* main upload portion */}
          <Form
            header={<Header variant="h1" description={description}></Header>}
          >
            {hasError && (
              <React.Fragment>
                <Alert header="Errors Detected" type="error">
                  Please check you file name, and try again.
                </Alert>
                <br />
              </React.Fragment>
            )}

            <UploadFileDropzone
              fileList={fileList}
              fileCheckDict={fileCheckDict}
              onChange={this._onChangeFiles}
              deleteFile={this._deleteFile}
            />
          </Form>
          <br />

          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this._onClear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onClickUpload}
              disabled={disableUploadBtn}
            >
              Upload
            </Button>
          </SpaceBetween>
        </div>

        {/* Uploading Modal */}
        <UploadingModal
          showModal={showModal}
          onDismiss={this._onClear}
          onClose={this._closeModal}
          fileList={fileList}
          fileUploadDict={fileUploadDict}
        />
      </Container>
    );
  }
}

export default ManualUploadPanelExcel;

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { createUpload, getUpload } from "../../redux/actions/upload-action";
// sub components
import { UpdateLog } from "../../model/upload";
import ManualUploadMain from "./manual-upload-main";
import AutoUpload from "./auto-upload/auto-upload";
import UploadTable from "./table/table";
import {
  ExpandableSection,
  Container,
  Header,
} from "@amzn/awsui-components-react-v3/polaris";
import { METRIC_INFO } from "../../constants";

interface StateProps {
  uploadReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

// declare init state & default props
const defaultProps = Object.freeze({});

class Upload extends Component<Props> {
  static defaultProps = defaultProps;
  interval: any;

  componentDidMount() {
    this._getUploadStatus();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  // get upload status data
  _getUploadStatus = (
    syncMode: boolean = false,
    initiative: string = METRIC_INFO.INITIATIVE_RF,
    start_date: Date = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    end_date: Date = new Date()
  ) => {
        this.props.dispatch(
          getUpload(
            start_date.toISOString().split("T")[0],
            end_date.toISOString().split("T")[0],
            syncMode,
            initiative
          )
        );
  };

  _updateLogs = (
    values: Array<UpdateLog> = [],
    initiative: string = METRIC_INFO.INITIATIVE_RF
  ) => {
    this.props.dispatch(createUpload(values, initiative));
  };

  render() {
    return (
      <div>
        <Container header={<Header variant="h2">Upload Files</Header>}>
          <ExpandableSection header="Manually Upload Files">
            <ManualUploadMain
              getUploadStatus={this._getUploadStatus}
              updateLogs={this._updateLogs}
            />
          </ExpandableSection>
          <ExpandableSection header="Auto Upload Files">
            <AutoUpload />
          </ExpandableSection>
        </Container>
        <br />
        <UploadTable></UploadTable>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    uploadReducer: state.uploadReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(Upload);

import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import constants, { DROPDOWN_DEFAULT, METRIC_INFO } from "../../../constants";
import {
  getTestplanConfig,
  createTestplan,
  resetTestplanOnSuccess,
  resetTestplanOnFailure,
  getLatestTestplan,
  getTestplanDraft,
  createTestplanDraft,
  clearTestOverviewDetails,
} from "../../../redux/actions/testplan-action";
import Questionnaire from "./questionnaire";
import SpotCheckQuestionnaire from "./spot-check-questionnaire";

import {
  Select,
  ColumnLayout,
  FormField,
  SelectProps,
  Button,
  SpaceBetween,
  Box,
  Modal,
} from "@amzn/awsui-components-react-v3";
import { EmailVerificationRequest } from "../../../model/http-json";
import { config } from "../../../config/config";
import { username } from "../../../cognito-auth/session";
import { Response, postData } from "../../../utils/cognito-fetch";
import endpoints from "../../../endpoints/endpoints";
import {
  getLogData,
  getBuildAndVersion,
  getProjectItems,
  getBuildItems,
  getVersionItems,
  getBuildConfigItems,
} from "../../../utils/general-utils";

interface StateProps {
  metadataReducer: any;
  testplanReducer: any;
}

type Props = {
  dispatch: Dispatch<any>;
  history: any;
} & StateProps;

type State = {
  category_id: string;
  category: string;
  categoryError: string;
  project_id: string;
  project: string;
  projectError: string;
  build: string;
  buildError: string;
  version_id: string;
  version: string;
  versionError: string;
  buildConfig_id: string;
  buildConfig: string;
  buildConfigError: string;
  projectItems: Array<any>;
  buildItems: Array<any>;
  versionItems: Array<any>;
  buildConfigItems: Array<any>;
  displayQuestionnaire: boolean;
  displaySpotCheckQuestionnaire: boolean;
  emailVerificationModal: number;
  createSubmitLoading: boolean;
  createDraftSubmitLoading: boolean;
};

class TestplanForm extends Component<Props, State> {
  state: State = Object.freeze({
    category_id: "",
    category: DROPDOWN_DEFAULT.CATEGORY,
    categoryError: "",
    project_id: "",
    project: DROPDOWN_DEFAULT.PROJECT,
    projectItems: [],
    projectError: "",
    build: DROPDOWN_DEFAULT.BUILD,
    buildItems: [],
    buildError: "",
    version_id: "",
    version: DROPDOWN_DEFAULT.VERSION,
    versionItems: [],
    versionError: "",
    buildConfig_id: "",
    buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
    buildConfigError: "",
    buildConfigItems: [],
    displayQuestionnaire: false,
    displaySpotCheckQuestionnaire: false,
    emailVerificationModal: constants.LOADING_DEFAULT,
    createSubmitLoading: false,
    createDraftSubmitLoading: false,
  });

  componentDidMount() {
    const { filter } = this.props.metadataReducer;
    const { categoryId, projectId, buildVersionId } =
      this.props.testplanReducer?.testOverviewDetails || {};

    const filterCategory = filter[categoryId] || {};
    const categoryName = filterCategory?.name || "";
    const filterProject = filterCategory?.children?.[projectId] || {};
    const projectName = filterProject?.name || "";

    const buildAndVersion =
      filterProject &&
      buildVersionId &&
      getBuildAndVersion(filterProject, buildVersionId);

    const buildName = buildAndVersion?.split("_")[0] || "";
    const versionName = buildAndVersion?.split("_")[1] || "";

    this.setState({
      category_id: categoryId || "",
      category: categoryName || DROPDOWN_DEFAULT.CATEGORY,
      project_id: projectId || "",
      project: projectName || DROPDOWN_DEFAULT.PROJECT,
      projectItems: getProjectItems(filter, categoryId),
      build: buildName || DROPDOWN_DEFAULT.BUILD,
      buildItems: getBuildItems(filter, categoryId, projectId),
      version_id: buildVersionId || "",
      version: versionName || DROPDOWN_DEFAULT.VERSION,
      versionItems: getVersionItems(filter, categoryId, projectId, buildName),
      buildConfigItems: getBuildConfigItems(
        filter,
        categoryId,
        projectId,
        buildName,
        versionName
      ),
    });

    // load testplan config
    this.props.dispatch(getTestplanConfig());
    this.props.dispatch(clearTestOverviewDetails());
  }

  validate = () => {
    let isValid = true;
    let categoryError = "";
    let projectError = "";
    let buildError = "";
    let versionError = "";
    let buildConfigError = "";

    if (!this.state.category_id) {
      isValid = false;
      categoryError = "Required field";
    }
    if (!this.state.project_id) {
      isValid = false;
      projectError = "Required field";
    }
    if (!this.state.build || this.state.build === DROPDOWN_DEFAULT.BUILD) {
      isValid = false;
      buildError = "Required field";
    }
    if (!this.state.version_id) {
      isValid = false;
      versionError = "Required field";
    }
    if (!this.state.buildConfig_id || !Number(this.state.buildConfig_id)) {
      isValid = false;
      buildConfigError = "Required field";
    }
    this.setState({
      categoryError,
      projectError,
      buildError,
      versionError,
      buildConfigError,
    });
    return isValid;
  };

  getCategoryItems = (filter: {}): any => {
    const items: Array<SelectProps.Option> = [];
    Object.keys(filter).forEach((category_id) => {
      const item: SelectProps.Option = {
        value: category_id,
        label: filter[category_id].name,
      };
      items.push(item);
    });

    return items;
  };

  _onChangeCategory = (event: any): any => {
    const category_id = event.detail.selectedOption.value;
    const category = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    this.setState({
      category_id,
      category,
      categoryError: "",
      projectItems: getProjectItems(filter, category_id),
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeProject = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = event.detail.selectedOption.value;
    const project = event.detail.selectedOption.label;
    const { filter } = this.props.metadataReducer;

    this.setState({
      project_id,
      project,
      projectError: "",
      buildItems: getBuildItems(filter, category_id, project_id),
      build: DROPDOWN_DEFAULT.BUILD,
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionError: "",
      versionItems: [],
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeBuild = (event: any): any => {
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = event.detail.selectedOption.value;
    const { filter } = this.props.metadataReducer;
    this.setState({
      build,
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: getVersionItems(filter, category_id, project_id, build),
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
    });
  };

  _onChangeVersion = (event: any): any => {
    const { filter } = this.props.metadataReducer;
    const category_id = this.state.category_id;
    const project_id = this.state.project_id;
    const build = this.state.build;
    const version = event.detail.selectedOption.label;
    const version_id =
      filter[category_id].children[project_id].children[build].children[version]
        .id;

    this.setState({
      version_id,
      version,
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: getBuildConfigItems(
        filter,
        category_id,
        project_id,
        build,
        version
      ),
      displayQuestionnaire: false,
    });
  };

  _onChangeBuildConfig = (event: any): any => {
    const buildConfig_id = event.detail.selectedOption.value;
    const buildConfig = event.detail.selectedOption.label;
    //console.log("build event", event);
    this.setState({
      buildConfig_id,
      buildConfig,
      buildConfigError: "",
    });
  };

  clear = (): any => {
    this.setState({
      category_id: "",
      category: DROPDOWN_DEFAULT.CATEGORY,
      categoryError: "",
      project_id: "",
      project: DROPDOWN_DEFAULT.PROJECT,
      projectItems: [],
      projectError: "",
      build: DROPDOWN_DEFAULT.BUILD,
      buildItems: [],
      buildError: "",
      version_id: "",
      version: DROPDOWN_DEFAULT.VERSION,
      versionItems: [],
      versionError: "",
      buildConfig_id: "",
      buildConfig: DROPDOWN_DEFAULT.BUILD_CONFIG,
      buildConfigError: "",
      buildConfigItems: [],
      displayQuestionnaire: false,
    });
  };

  _onCreate = async () => {
    // set submit loading true and then check for email verification
    this.setState({
      createSubmitLoading: true,
      displaySpotCheckQuestionnaire: false,
      displayQuestionnaire: false,
    });
    // 2) verify email
    const emailVerificationPayload: EmailVerificationRequest = {
      email: `${username}@amazon.com`,
      send_verification_email: true,
    };

    const emailResponse: Response = await postData(
      config.BASE_URL + endpoints.emailVerificationEndpoint(),
      emailVerificationPayload,
      getLogData(METRIC_INFO.REPORT)
    );

    // response not 200
    if (!emailResponse.ok) {
      const responseMsg =
        emailResponse.json && emailResponse.json.message
          ? emailResponse.json.message
          : emailResponse.status_text;
      // reset submit loading to false
      this.setState({
        createSubmitLoading: false,
        emailVerificationModal: constants.LOADING_FAIL,
      });
      return;
    }

    // email not verified
    if (
      !emailResponse.json ||
      emailResponse.json.data.status !== constants.STATUS_SUCCESS
    ) {
      // reset submit loading to false
      this.setState({
        createSubmitLoading: false,
        emailVerificationModal: constants.LOADING_SUCCESS,
      });
      return;
    }

    // reset submit loading to false. Loading now will be governed by redux
    this.setState({
      createSubmitLoading: false,
      emailVerificationModal: constants.LOADING_DEFAULT,
    });

    const isValid = this.validate();
    if (!isValid) {
      return;
    }

    const {
      category_id,
      project_id,
      build,
      version,
      version_id,
      buildConfig_id,
    } = this.state;

    this.props.dispatch(
      getLatestTestplan(
        category_id,
        project_id,
        version_id,
        Number(buildConfig_id)
      )
    );
    const { filter } = this.props.metadataReducer;
    const isSpotCheck =
      filter[category_id].children[project_id].children[build].children[version]
        .children[buildConfig_id].is_spot_check;
    if (isSpotCheck)
      this.setState({
        displaySpotCheckQuestionnaire: true,
      });
    else
      this.setState({
        displayQuestionnaire: true,
      });
  };

  _onCreateDraft = async () => {
    // set submit loading true and then check for email verification
    this.setState({
      createDraftSubmitLoading: true,
      displaySpotCheckQuestionnaire: false,
      displayQuestionnaire: false,
    });
    // 2) verify email
    const emailVerificationPayload: EmailVerificationRequest = {
      email: `${username}@amazon.com`,
      send_verification_email: true,
    };

    const emailResponse: Response = await postData(
      config.BASE_URL + endpoints.emailVerificationEndpoint(),
      emailVerificationPayload,
      getLogData(METRIC_INFO.REPORT)
    );

    // response not 200
    if (!emailResponse.ok) {
      const responseMsg =
        emailResponse.json && emailResponse.json.message
          ? emailResponse.json.message
          : emailResponse.status_text;
      // reset submit loading to false
      this.setState({
        createDraftSubmitLoading: false,
        emailVerificationModal: constants.LOADING_FAIL,
      });
      return;
    }

    // email not verified
    if (
      !emailResponse.json ||
      emailResponse.json.data.status !== constants.STATUS_SUCCESS
    ) {
      // reset submit loading to false
      this.setState({
        createDraftSubmitLoading: false,
        emailVerificationModal: constants.LOADING_SUCCESS,
      });
      return;
    }

    // reset submit loading to false. Loading now will be governed by redux
    this.setState({
      createDraftSubmitLoading: false,
      emailVerificationModal: constants.LOADING_DEFAULT,
    });

    const isValid = this.validate();
    if (!isValid) {
      return;
    }

    const {
      category_id,
      project_id,
      build,
      version,
      version_id,
      buildConfig_id,
    } = this.state;

    this.props.dispatch(
      getTestplanDraft(
        category_id,
        project_id,
        version_id,
        Number(buildConfig_id)
      )
    );
    const { filter } = this.props.metadataReducer;
    const isSpotCheck =
      filter[category_id].children[project_id].children[build].children[version]
        .children[buildConfig_id].is_spot_check;
    if (isSpotCheck)
      this.setState({
        displaySpotCheckQuestionnaire: true,
      });
    else
      this.setState({
        displayQuestionnaire: true,
      });
  };

  _onTestplanSubmit = (values: any, isDraft = false): any => {
    if (isDraft) {
      this.props.dispatch(
        createTestplanDraft(
          this.state.category_id,
          this.state.project_id,
          this.state.version_id,
          Number(this.state.buildConfig_id),
          values
        )
      );
    } else {
      this.props.dispatch(
        createTestplan(
          this.state.category_id,
          this.state.project_id,
          this.state.version_id,
          Number(this.state.buildConfig_id),
          values
        )
      );
    }
  };

  reset = (success: boolean): any => {
    if (success) {
      this.clear();
      this.props.dispatch(resetTestplanOnSuccess());
    } else {
      this.props.dispatch(resetTestplanOnFailure());
    }
  };

  render() {
    const { filter } = this.props.metadataReducer;
    const {
      createStatus,
      createDraftStatus,
      message,
      responseData,
      loadingLatestTestplanStatus,
      loadingTestplanDraftStatus,
    } = this.props.testplanReducer;
    const jira_id = responseData ? responseData.jira_id : "";
    const upload_time = responseData ? responseData.upload_time : "";
    const createdBy = responseData ? responseData.username : "";
    const assignee = responseData ? responseData.assignee : "";
    const lab = responseData ? responseData.lab : "";
    const s3Link = responseData ? responseData.s3_link : "";
    const categoryItems = this.getCategoryItems(filter);
    return (
      <div className="awsui-util-container">
        <div className="awsui-util-container-header">
          <h2>Create Testplan</h2>
        </div>
        <ColumnLayout columns={3}>
          {/* <div data-awsui-column-layout-root="true"> */}
          <FormField label="Category" errorText={this.state.categoryError}>
            <Select
              options={categoryItems}
              placeholder="Category"
              onChange={this._onChangeCategory}
              selectedOption={{
                value: this.state.category_id,
                label: this.state.category,
              }}
            ></Select>
          </FormField>
          <FormField label="Project" errorText={this.state.projectError}>
            <Select
              options={this.state.projectItems}
              placeholder="Project"
              onChange={this._onChangeProject}
              selectedOption={{
                value: this.state.project_id,
                label: this.state.project,
              }}
            ></Select>
          </FormField>
          <FormField label="Build" errorText={this.state.buildError}>
            <Select
              options={this.state.buildItems}
              placeholder="Build"
              onChange={this._onChangeBuild}
              selectedOption={{
                value: this.state.build,
                label: this.state.build,
              }}
            ></Select>
          </FormField>
          <FormField label="Version" errorText={this.state.versionError}>
            <Select
              options={this.state.versionItems}
              placeholder="Version"
              onChange={this._onChangeVersion}
              selectedOption={{
                value: this.state.version,
                label: this.state.version,
              }}
            ></Select>
          </FormField>
          <FormField
            label="Build Config"
            errorText={this.state.buildConfigError}
          >
            <Select
              options={this.state.buildConfigItems}
              placeholder="Build Config"
              onChange={this._onChangeBuildConfig}
              selectedOption={{
                value: this.state.buildConfig_id,
                label: this.state.buildConfig,
              }}
            ></Select>
          </FormField>
        </ColumnLayout>
        <div
          className="d-flex justify-content-start"
          style={{ marginRight: "20px" }}
        >
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={this.clear}>Clear</Button>
            <Button
              variant="primary"
              onClick={this._onCreateDraft}
              loading={
                loadingTestplanDraftStatus === constants.LOADING_LOAD ||
                this.state.createDraftSubmitLoading
              }
            >
              Open Saved Draft
            </Button>
            <Button
              variant="primary"
              onClick={this._onCreate}
              loading={
                loadingLatestTestplanStatus === constants.LOADING_LOAD ||
                this.state.createSubmitLoading
              }
            >
              Create/Update Plan
            </Button>
          </SpaceBetween>
        </div>

        {this.state.displayQuestionnaire &&
          (loadingLatestTestplanStatus === constants.LOADING_SUCCESS ||
            loadingTestplanDraftStatus === constants.LOADING_SUCCESS) && (
            <Questionnaire
              onSubmit={this._onTestplanSubmit}
              categoryId={this.state.category_id}
              projectId={this.state.project_id}
              buildId={this.state.version_id}
              buildConfigId={Number(this.state.buildConfig_id)}
            />
          )}

        {this.state.displaySpotCheckQuestionnaire &&
          (loadingLatestTestplanStatus === constants.LOADING_SUCCESS ||
            loadingTestplanDraftStatus === constants.LOADING_SUCCESS) && (
            <SpotCheckQuestionnaire
              onSubmit={this._onTestplanSubmit}
              categoryId={this.state.category_id}
              projectId={this.state.project_id}
              buildId={this.state.version_id}
              buildConfigId={Number(this.state.buildConfig_id)}
            />
          )}

        <Modal
          visible={createStatus === constants.LOADING_SUCCESS}
          header="Success"
          onDismiss={() => this.reset(true)}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => this.reset(true)}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {`${message}.`}
          <br />
          {/* <strong>Jira ID: </strong> {jira_id} <br /> */}
          <strong>Upload Time: </strong> {upload_time} <br />
          <strong>Created By: </strong> {createdBy} <br />
          <strong>Assignee: </strong> {assignee} <br />
          <strong>Lab: </strong> {lab} <br /> <br />
          <strong>Message: </strong> Test Plan Excel will be sent over by mail
          shortly. The plan will also be available for download on website in
          3-5 mins <br />
          {/* <strong>Download: </strong>{" "} */}
          {/* <a href={s3Link}>
            {" "}
            <Icon name="download" size="normal" variant="link" />{" "}
          </a> */}
        </Modal>
        <Modal
          visible={createDraftStatus === constants.LOADING_SUCCESS}
          header="Success"
          onDismiss={() => this.reset(true)}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => this.reset(true)}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {`${message}.`}
        </Modal>
        <Modal
          visible={
            createStatus === constants.LOADING_FAIL ||
            createDraftStatus === constants.LOADING_FAIL
          }
          header="Failure"
          onDismiss={() => this.reset(false)}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="primary" onClick={() => this.reset(false)}>
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
        >
          {`${message}.`}
        </Modal>
        <Modal
          visible={this.state.emailVerificationModal === constants.LOADING_FAIL}
          header="Failure"
          onDismiss={() =>
            this.setState({
              emailVerificationModal: constants.LOADING_DEFAULT,
            })
          }
        >
          Failed to verify email. Please try again
        </Modal>
        <Modal
          visible={
            this.state.emailVerificationModal === constants.LOADING_SUCCESS
          }
          header="Email not verified"
          onDismiss={() =>
            this.setState({
              emailVerificationModal: constants.LOADING_DEFAULT,
            })
          }
        >
          <p>
            You're <b>NOT</b> in our verified email list. Please verify your
            email first.
          </p>
          <p>
            A verification email has been sent to
            <b>{` ${username}@amazon.com`}</b>, click the link in the email to
            verify your email address.
          </p>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
    testplanReducer: state.testplanReducer,
  };
};
export default connect<StateProps>(mapStateToProps)(TestplanForm);

import React, { Component } from "react";
// polaris
import { Button } from "@amzn/awsui-components-react/polaris";
// sub component
import { ToastContainer } from "react-toastify";
import {
  createEmailNotVerifyToast,
  createFailToast,
  createSuccessToast,
} from "../../components/toast-notification";
// utils
import moment from "moment";
import { postData, Response } from "../../utils/cognito-fetch";
import { ReportRequest, EmailVerificationRequest } from "../../model/http-json";
// constants
import { username } from "../../cognito-auth/session";
import { config } from "../../config/config.js";
import endpoints from "../../endpoints/endpoints";
import constants, {
  REPORT_TYPE,
  REPORT_TYPE_TEXT,
  METRIC_INFO,
} from "../../constants";
import { getLogData} from "../../utils/general-utils";

const MAX_REPORTS = 3;


// declare prop check
type Props = {
  selectedItems: any[],
  category: string,
  project: string,
  build: string,
  buildConfig: string,
};

const defaultProps = Object.freeze({})

type State = {
  reportStatus: Number;
};

class Report extends Component<Props, State> {
  state: State = Object.freeze({
    reportStatus: constants.LOADING_SUCCESS
  });


  _onReportItemClick = async () => {
    const { selectedItems } = this.props;

    // 1) check item length
    if (selectedItems.length === 0) {
      alert("No item was selected.");
      return;
    }
    if (selectedItems.length > MAX_REPORTS) {
      alert(`At most ${MAX_REPORTS} reports can be generated at a time.`);
      return;
    }

    // loading status -> load
    this.setState({
      reportStatus: constants.LOADING_LOAD,
    });

    // 2) verify email
    const emailVerificationPayload: EmailVerificationRequest = {
      email: `${username}@amazon.com`,
      send_verification_email: true,
    };

    const emailResponse: Response = await postData(
      config.BASE_URL + endpoints.emailVerificationEndpoint(),
      emailVerificationPayload,
      getLogData(METRIC_INFO.REPORT)
    );

    // response not 200
    if (!emailResponse.ok) {
      const responseMsg =
        emailResponse.json && emailResponse.json.message
          ? emailResponse.json.message
          : emailResponse.status_text;
      createFailToast(responseMsg);
      this.setState({
        reportStatus: constants.LOADING_FAIL,
      });
      return;
    }

    // email not verified
    if (
      !emailResponse.json ||
      emailResponse.json.data.status !== constants.STATUS_SUCCESS
    ) {
      createEmailNotVerifyToast();
      this.setState({
        reportStatus: constants.LOADING_FAIL,
      });
      return;
    }


    // 3) submit report request
    const reportPayload: ReportRequest = {
      user: username,
      report_type: REPORT_TYPE_TEXT[REPORT_TYPE.TESTCASE_REPORT],
      created_time: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      category: Number(this.props.category),
      project: Number(this.props.project),
      build: Number(this.props.build),
      buildConfig: Number(this.props.buildConfig),
      test_case: selectedItems.map(({ testcase_id }) => Number(testcase_id)),
    };


    const reportResponse: Response = await postData(
      config.BASE_URL + endpoints.reportEndpoint(),
      reportPayload,
      METRIC_INFO.REPORT
    );

    const responseMsg =
      reportResponse.json && reportResponse.json.message
        ? reportResponse.json.message
        : reportResponse.status_text;

    this.setState({
      reportStatus: reportResponse.ok
        ? constants.LOADING_SUCCESS
        : constants.LOADING_FAIL,
    });

    reportResponse.ok ? createSuccessToast() : createFailToast(responseMsg);
  };

  render() {
    return (
      <>
        <Button
          icon="download"
          variant="primary"
          loading={this.state.reportStatus === constants.LOADING_LOAD}
          onClick={this._onReportItemClick}
          disabled={!this.props.selectedItems.length}
        >
            Generate Report
        </Button>
        <ToastContainer />
      </>
    
    );
  }
}


export default Report;

import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import TestplanForm from "./testplan-form";
import "../create-form.css";
import "./testPlan.css";

interface StateProps {
  metadataReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

function CreateTestplanEmi({ metadataReducer, dispatch }: Props) {
  return (
    <div className="create-form">
      <TestplanForm />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    metadataReducer: state.metadataReducer,
  };
};

export default withRouter(
  connect<StateProps>(mapStateToProps)(CreateTestplanEmi)
);

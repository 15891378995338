import constants, {
  STATUS_TEXT,
  REPORT_TYPE_TEXT,
} from "../../constants";
import reportType from "../types/report-type";
import { changeDateFormat } from "../../utils/date-utils";

const initialState = {
  reportHistoryData: [],
  reportHistoryStatus: constants.LOADING_LOAD,
};
const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case reportType.GET_REPORT_STATUS:
      if (action.syncMode) {
        return state;
      }
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_LOAD,
      };
    case reportType.GET_REPORT_STATUS_COMMIT:
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_SUCCESS,
        reportHistoryData: Array.isArray(action.payload.data)
          ? formatReportHistoryData(action.payload.data)
          : [],
      };
    case reportType.GET_REPORT_STATUS_ROLLBACK:
      return {
        ...state,
        reportHistoryStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default reportReducer;

const formatReportHistoryData = ( data: Array<any> ): Array<any> => {
  data.forEach((item) => {
    item.created_time = changeDateFormat(item.created_time);
    item.report_type = REPORT_TYPE_TEXT[item.report_type];
    item.status = STATUS_TEXT[item.status];
  });
  return data;
};

import React, { useEffect, useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
} from "@amzn/awsui-components-react-v3/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  TableEmptyState,
  TableNoMatchState,
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../overview/common";
import {
  EMI_TABLE_COLUMN_DEFINITIONS,
  DEFAULT_PREFERENCES,
  FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import constants from "../../../constants";

const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

type Props = {
  tabledata: any;
  loading: any;
  tableColumn: any;
};

const TableFreq = ({ tabledata, loading, tableColumn }: Props) => {
  const [table_column, setTableColumn] = useState(
    EMI_TABLE_COLUMN_DEFINITIONS(tableColumn)
  );
  // default table preferences
  const [preferences, setPreferences] = useState(
    DEFAULT_PREFERENCES(tableColumn)
  );

  useEffect(() => {
    setTableColumn(EMI_TABLE_COLUMN_DEFINITIONS(tableColumn));
    setPreferences(DEFAULT_PREFERENCES(tableColumn));
  }, [tableColumn]);

  const currentTableData = tabledata.length > 0 ? tabledata : [];

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(currentTableData, {
    propertyFiltering: {
      filteringProperties: FILTERING_PROPERTIES(tableColumn),
      empty: TableEmptyState("Frequency Data"),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });
  return (
    <Table
      {...collectionProps}
      columnDefinitions={table_column}
      visibleColumns={preferences.visibleContent}
      items={items}
      loadingText="Loading resources"
      loading={
        loading != constants.LOADING_SUCCESS &&
        loading != constants.LOADING_FAIL
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header counter={`(${tabledata.length})`}>Debug Frequency</Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
          tableColumn={tableColumn}
        />
      }
    />
  );
};

export default React.memo(TableFreq);

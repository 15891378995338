import React, { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
} from "@amzn/awsui-components-react-v3/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  TableEmptyState,
  TableNoMatchState,
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../overview/common";
import {
  CRITICAL_DATA_TABLE_COLUMN_DEFINITIONS,
  CRITICAL_DATA_DEFAULT_PREFERENCES,
  CRITICAL_DATA_FILTERING_PROPERTIES,
  CritcalDataPreferences,
} from "./table-config";
import constants from "../../../constants";

const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

type Props = {
  tabledata: any;
  loading: any;
};

const TableCriticalData = ({ tabledata, loading }: Props) => {
  // default table preferences
  const [preferences, setPreferences] = useState(
    CRITICAL_DATA_DEFAULT_PREFERENCES()
  );

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(tabledata, {
    propertyFiltering: {
      filteringProperties: CRITICAL_DATA_FILTERING_PROPERTIES,
      empty: TableEmptyState("Critical Data"),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });
  return (
    <Table
      {...collectionProps}
      columnDefinitions={CRITICAL_DATA_TABLE_COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      loadingText="Loading resources"
      loading={
        loading != constants.LOADING_SUCCESS &&
        loading != constants.LOADING_FAIL
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      header={
        <Header counter={`(${tabledata.length})`}>Critical Data Points</Header>
      }
      filter={
        <PropertyFilter
          i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
          {...propertyFilterProps}
          countText={getFilterCounterText(filteredItemsCount)}
        />
      }
      pagination={
        <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      }
      preferences={
        <CritcalDataPreferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
    />
  );
};

export default React.memo(TableCriticalData);

import React, { Component } from "react";
// redux
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { getMetadata } from "../../redux/actions/metadata-action"; // redux action
// sub components
import Navbar from "../navbar/navbar";
import Sidebar from "./../sidebar/sidebar";
import MainRouter from "./main-router";
import styled from "styled-components";
import querySearch from "query-string";
import { Footer } from "@amzn/lens-react-ui";
//css

interface StateProps {}
// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & typeof defaultProps &
  StateProps;

type State = typeof initialState;

// declare init state & default props
const defaultProps = Object.freeze({});

const initialState = Object.freeze({
  collapse: false, // sidebar collpase flag
});

// styled component
// prettier-ignore
const StyledMain = styled.div<{ collapse: boolean, isIframe: boolean }>`
  margin-left: ${(props) =>
    props.isIframe ? "0px" : props.collapse ? "50px" : "250px"};
  margin-top: ${(props) => (props.isIframe ? "0px" : "50px")};
`;

const StyledMainRouter = styled.div`
  min-height: calc(100vh - 50px - 50px);
`;

class MainView extends Component<Props, State> {
  static defaultProps = defaultProps;
  state = initialState;

  componentDidMount() {
    // get metadata
    this.props.dispatch(getMetadata());
  }

  _onClickToggle = () => {
    this.setState({
      collapse: !this.state.collapse,
    });
    window.dispatchEvent(new Event("resize"));
  };

  render() {
    const { collapse } = this.state;
    const isIframe =
      window.location &&
      window.location.href &&
      querySearch.parse(window.location.href.split("?")[1]).inIframe &&
      querySearch.parse(window.location.href.split("?")[1]).inIframe === "true"
        ? true
        : false;
    return (
      <div>
        {isIframe ? null : <Navbar />}
        {isIframe ? null : (
          <Sidebar collapse={collapse} onClick={this._onClickToggle} />
        )}
        <StyledMain collapse={collapse} isIframe={isIframe}>
          <StyledMainRouter>
            <MainRouter collapse={collapse} isIframe={isIframe} />
          </StyledMainRouter>
          {isIframe ? null : <Footer />}
        </StyledMain>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};
export default connect<StateProps>(mapStateToProps)(MainView);

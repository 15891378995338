import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { triggerAutoUploadJob } from "../../../redux/actions/upload-action"
import { Button } from "@amzn/awsui-components-react-v3";

interface StateProps {
  uploadReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

const defaultProps = Object.freeze({});

class AutoUpload extends Component<Props> {
  componentDidMount() {
  }
  static defaultProps = defaultProps;
  state = {};
  constructor(props) {
    super(props);
  }

  _triggerAutoUpload = () => {
    alert(
      "Begin to auto download files, please wait for 5-10 minutes."
    );
    this.props.dispatch(triggerAutoUploadJob());
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <Button
          iconName="upload"
          variant="primary"
          onClick={this._triggerAutoUpload}
        >
          Trigger Auto Upload
        </Button>
        <br />
        <br />
      </div>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    uploadReducer: state.uploadReducer,
  };
};
export default withRouter(connect<StateProps>(mapStateToProps)(AutoUpload));

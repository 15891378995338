import React, { Component } from "react";
import { Dispatch } from "redux";
import { customizedFilteringFunction } from "../../../components/polaris/table/property-filtering-utils";
import { exportCSVFunc } from "@amzn/lens-react-ui";
import constants, { UPDATE_TESTCASE, TEST_STATUS } from "../../../constants";
import {
  Table,
  TableSelection,
  TableSorting,
  Button,
  TablePropertyFiltering,
  TablePagination,
  TablePreferences,
  TablePageSizeSelector,
  TableWrapLines,
  TableContentSelector,
} from "@amzn/awsui-components-react/polaris";

import {
  defaultPageSize,
  columnDefinitions,
  features,
  defaultSortingColumn,
  sortableColumns,
  filteringOptions,
  visibleColumns,
} from "./table-config";

import { updateTestCases } from "../../../redux/actions/testcase-action";
import Report from "../../report-generation/report";


const initialState = Object.freeze({
  currentPageIndex: 1,
  pagesCount: 0,
  selectedItems: [],
});

type Props = {
  title: string;
  data: any;
  loadingStatus: any;
  loadingUpdateTestcasesStatus: any;
  history: any;
  category: string;
  project: string;
  build: string;
  buildConfigId: string;
  s3Link: string;
  dispatch: Dispatch<any>;
} & typeof defaultProps;

const defaultProps = Object.freeze({});

class OverviewTable extends Component<Props> {
  static defaultProps = defaultProps;
  state = initialState;
  constructor(props) {
    super(props);
  }

  _onPaginationChange = (event) => {
    const { currentPageIndex, pagesCount } = event.detail;
    this.setState({
      currentPageIndex,
      pagesCount,
    });
  };

  _onSelectionChange = (event): any => {
    const { selectedItems } = event.detail;
    this.setState({ selectedItems });
  };

  exportReport = (): any => {
    // will update this once the api is ready.
    console.log("Export Report clicked.");
  };

  _onClickCompare = (): any => {
    if (this.state.selectedItems.length === 1) {
      alert("Please select at least two testcases.");
    } else if (this.state.selectedItems.length > 5) {
      alert("Too many test cases were selected(<=5)");
    } else {
      const { category, project, build } = this.props;
      let selectedID: Array<number> = [];
      this.state.selectedItems.forEach((item: any) => {
        selectedID.push(item.testcase_id);
      });
      this.props.history.push(
        `/categories/${category}/projects/${project}/builds/${build}/compare?ids=${selectedID}`
      );
    }
  };

  _onClickPublish = (): any => {
    this.state.selectedItems.forEach((item: any) => {
      if (item.publish_status != UPDATE_TESTCASE.UNPUBLISHED) {
        alert("only unpublished testcases can be published.");
        return;
      }
    });
    const { category, project, build, buildConfigId } = this.props;
    let values: Array<any> = [];
    this.state.selectedItems.forEach((item: any) => {
      const value: any = {
        test_case_id: item.testcase_id,
        publish_status: UPDATE_TESTCASE.PUBLISHED,
      };
      values.push(value);
    });
    this.props.dispatch(
      updateTestCases(
        category,
        project,
        build,
        Number(buildConfigId),
        values
      )
    );
    this.setState({
      selectedItems: [],
    });
  };

  _onClickReject = (): any => {
    this.state.selectedItems.forEach((item: any) => {
      if (item.publish_status != UPDATE_TESTCASE.UNPUBLISHED) {
        alert("only unplished testcases can be published.");
        return;
      }
    });
    const { category, project, build, buildConfigId } = this.props;
    let values: Array<any> = [];
    this.state.selectedItems.forEach((item: any) => {
      const value: any = {
        test_case_id: item.testcase_id,
        publish_status: UPDATE_TESTCASE.REJECTED,
      };
      values.push(value);
    });
    this.props.dispatch(
      updateTestCases(
        category,
        project,
        build,
        Number(buildConfigId),
        values
      )
    );
    this.setState({
      selectedItems: [],
    });
  };

  onDownloadClick = (): any => {
    const link = document.createElement('a');
    link.href = this.props.s3Link;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }

  _isItemDisabled = (item: any): boolean => {
    if (item.status === TEST_STATUS.UNTESTED) return true;
    return false;
  };

  render() {
    const {
      data,
      loadingStatus,
      loadingUpdateTestcasesStatus,
      category,
      project,
      build,
      buildConfigId,
      s3Link,
    } = this.props;
    return (
      <Table
        columnDefinitions={columnDefinitions(
          category,
          project,
          build,
          buildConfigId
        )}
        items={data}
        loading={loadingStatus === constants.LOADING_LOAD}
        features={features}
        header={
          <div className="awsui-util-action-stripe">
            <div className="awsui-util-action-stripe-title">
              <h2>
                {this.props.title} &nbsp;
                <span className="awsui-util-header-counter">{`(${data.length})`}</span>
              </h2>
            </div>
            <div className="awsui-util-action-stripe-group">
              {/* <Button
                    icon="add-plus"
                    variant="primary"
                    onClick={() => this._onClickCompare()}
                    disabled={!this.state.selectedItems.length}
                  >
                    Compare
                  </Button> */}
              <Button
                icon="unlocked"
                variant="primary"
                loading={
                  loadingUpdateTestcasesStatus === constants.LOADING_LOAD
                }
                onClick={() => this._onClickPublish()}
                disabled={!this.state.selectedItems.length}
              >
                Publish
              </Button>
              <Button
                icon="close"
                variant="primary"
                loading={
                  loadingUpdateTestcasesStatus === constants.LOADING_LOAD
                }
                onClick={() => this._onClickReject()}
                disabled={!this.state.selectedItems.length}
              >
                Reject
              </Button>
              {/* <Button
                    icon="download"
                    variant="primary"
                    onClick={() => this.exportReport()}
                    disabled={!this.state.selectedItems.length}
                  >
                    Export Report
                  </Button> */}
              <Report
                selectedItems={this.state.selectedItems}
                category={category}
                project={project}
                build={build}
                buildConfig={buildConfigId}
              />
              <Button
                icon="download"
                variant="primary"
                disabled={!data || data.length == 0}
                onClick={() => exportCSVFunc(data, "TestcaseOverview")}
              >
                Export Table
              </Button>
              <Button
                className="button-with-margin"
                icon="download"
                variant="primary"
                onClick={() => this.onDownloadClick()}
                disabled={!s3Link}
              >
                Testplan
              </Button>
            </div>
          </div>
        }
        empty={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No resources</b>
            </div>
            <p className="awsui-util-mb-s">No resources to display.</p>
          </div>
        }
        noMatch={
          <div className="awsui-util-t-c">
            <div className="awsui-util-pt-s awsui-util-mb-xs">
              <b>No matches</b>
            </div>
            <p className="awsui-util-mb-s">We can’t find a match.</p>
            <div className="awsui-util-mb-m">
              <Button>Clear filter</Button>
            </div>
          </div>
        }
        wrapLines={false}
      >
        <TablePropertyFiltering
          filteringOptions={filteringOptions}
          filteringFunction={customizedFilteringFunction}
          groupValuesText="Values"
          groupPropertiesText="Properties"
          operationAndText="and"
          operationNotAndText="and not"
          operationOrText="or"
          operationNotOrText="or not"
          clearFiltersText="Clear filter"
          placeholder="Filter resources by property or value"
          allowFreeTextFiltering={true}
          filteringCountTextFunction={(count) =>
            `${count} ${count === 1 ? "match" : "matches"}`
          }
        ></TablePropertyFiltering>
        <TableSelection
          selectedItems={this.state.selectedItems}
          onSelectionChange={this._onSelectionChange}
          keepSelection={true}
          isItemDisabled={(item: Table.Item) => this._isItemDisabled(item)}
        ></TableSelection>
        <TablePagination
          pageSize={defaultPageSize}
          onPaginationChange={this._onPaginationChange}
        ></TablePagination>
        <TableSorting
          sortingColumn={defaultSortingColumn}
          sortableColumns={sortableColumns}
          sortingDescending={true}
        ></TableSorting>
        <TablePreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
        >
          <TablePageSizeSelector
            title="Page size"
            options={[
              { value: 3, label: "3 items" },
              { value: 5, label: "5 items" },
              { value: 10, label: "10 items" },
            ]}
          />
          <TableWrapLines
            label="Wrap lines"
            description="Enable to wrap table cell content, disable to truncate text."
          />
          <TableContentSelector
            title="Select visible columns"
            options={visibleColumns}
          />
        </TablePreferences>
      </Table>
    );
  }
}

export default OverviewTable;

import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import reportType from "../types/report-type";
import { METRIC_INFO } from "../../constants";

export const getReportsHistory = (syncMode: boolean) => ({
  type: reportType.GET_REPORT_STATUS,
  syncMode,
  http: {
    cognito: {
      effect: {
        //simple local url here will change it later
        url: config.BASE_URL + endpoints.reportHistoryEndpoint(),
        method: "GET",
      },
      commit: {
        type: reportType.GET_REPORT_STATUS_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.REPORT,
        },
      },
      rollback: {
        type: reportType.GET_REPORT_STATUS_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.REPORT,
        },
      },
    },
  },
});

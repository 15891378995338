import { CollectionPreferences } from "@amzn/awsui-components-react-v3";
import { PAGE_SIZE_OPTIONS } from "../../overview/common";
import React from "react";

// define columns
export const EMI_TABLE_COLUMN_DEFINITIONS = (tableColumns) => {
  type obj = {
    id: string;
    header: string;
    cell: any;
    sortingField: string;
    visible: boolean;
    minWidth: number;
  };
  let arr: Array<obj> = [];
  tableColumns.map((tableColumn) => {
    arr.push({
      id: tableColumn.accessor,
      header: tableColumn.Header,
      cell: (item) => item[tableColumn.accessor],
      sortingField: tableColumn.accessor,
      visible: true,
      minWidth: 150,
    });
  });

  return arr;
};

export const VISIBLE_CONTENT_OPTIONS = (tableColumns) => {
  type obj = {
    id: string;
    label: string;
    visible: boolean;
    editable: boolean;
  };
  let arr: Array<obj> = [];
  tableColumns.map((tableColumn) => {
    arr.push({
      id: tableColumn.accessor,
      label: tableColumn.Header,
      visible: true,
      editable: true,
    });
  });

  return [
    {
      label: "Debug Frequency",
      options: arr,
    },
  ];
};

export const FILTERING_PROPERTIES = (tableColumns) => {
  type obj = {
    propertyLabel: string;
    key: string;
    groupValuesLabel: string;
    operators: any;
  };
  let arr: Array<obj> = [];
  tableColumns.map((tableColumn) => {
    arr.push({
      propertyLabel: tableColumn.Header,
      key: tableColumn.accessor,
      groupValuesLabel: tableColumn.accessor,
      operators: [":", "!:", "=", "!="],
    });
  });

  return arr;
};

// define default preferences
export const DEFAULT_PREFERENCES = (tableColumn) => {
  return {
    pageSize: 10,
    visibleContent: EMI_TABLE_COLUMN_DEFINITIONS(tableColumn)
      .filter((column) => column.visible)
      .map((column) => column.id),
    wrapLines: false,
  };
};

export const Preferences = ({
  preferences,
  setPreferences,
  disabled,
  tableColumn = [],
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  visibleContentOptions = VISIBLE_CONTENT_OPTIONS,
}) => (
  <CollectionPreferences
    title="Preferences"
    confirmLabel="Confirm"
    cancelLabel="Cancel"
    disabled={disabled}
    preferences={preferences}
    onConfirm={({ detail }) => setPreferences(detail)}
    pageSizePreference={{
      title: "Page size",
      options: pageSizeOptions,
    }}
    wrapLinesPreference={{
      label: "Wrap lines",
      description: "Check to see all the text and wrap the lines",
    }}
    visibleContentPreference={{
      title: "Select visible content",
      options: visibleContentOptions(tableColumn),
    }}
  />
);

import React, { Component } from "react";
//redux
import { connect } from "react-redux";
// sub components
import { Button, SpaceBetween, Header } from "@amzn/awsui-components-react-v3";
import ImportTestplan from "../import-testplan/import-testplan";
//config, constants
import constants from "../../constants";
//css

// declare prop check
type Props = {
  s3Link: string;
  getMail: any;
  loadingSendMailTestPlanStatus: Number;
  disableImportTestplan: boolean;
  disableS3Url: string;
};

// declare state check
type State = {};

class QuestionnaireHeader extends Component<Props, State> {
  state: State = Object.freeze({});

  render() {
    return (
      <Header
        variant="h2"
        actions={
          <div className="d-flex justify-content-end">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                className="button-with-margin"
                iconName="download"
                variant="primary"
                href={this.props.s3Link}
                disabled={!this.props.disableS3Url}
              >
                Download Latest
              </Button>
              <Button
                className="button-with-margin"
                iconName="envelope"
                variant="primary"
                disabled={!this.props.disableS3Url}
                loading={
                  this.props.loadingSendMailTestPlanStatus ==
                  constants.LOADING_LOAD
                }
                onClick={this.props.getMail}
              >
                Get Mail
              </Button>
              <ImportTestplan disabled={this.props.disableImportTestplan} />
            </SpaceBetween>
          </div>
        }
      >
        RF Testplan
      </Header>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(QuestionnaireHeader);

import React from "react";

export const defaultPageSize = 10;

const sort = (sortState, id, header) => {
  const isColumnSorted = sortState.sortingColumn === id;
  const ascending = !sortState.sortingDescending;
  return `${header}, ${
    isColumnSorted
      ? `sorted ${ascending ? "ascending" : "descending"}`
      : "not sorted"
  }.`;
};

// define columns
export const columnDefinitions = [
  {
    id: "frequency",
    header: "Frequency",
    cell: (e) => centerAlign(e.frequency),
    label: (sortState) => {
      return sort(sortState, "frequency", "Frequency");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "level",
    header: "Level",
    cell: (e) => centerAlign(e.level),
    label: (sortState) => {
      return sort(sortState, "level", "Level");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "limit",
    header: "Limit",
    cell: (e) => centerAlign(e.limit),
    label: (sortState) => {
      return sort(sortState, "limit", "Limit");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "over",
    header: "Over",
    cell: (e) => centerAlign(e.over),
    label: (sortState) => {
      return sort(sortState, "over", "Over");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "factor",
    header: "Factor",
    cell: (e) => centerAlign(e.factor),
    label: (sortState) => {
      return sort(sortState, "factor", "Factor");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "a_pos",
    header: "A_Pos",
    cell: (e) => centerAlign(e.a_pos),
    label: (sortState) => {
      return sort(sortState, "a_pos", "A_Pos");
    },
    visible: true,
    minWidth: "100px",
  },
  {
    id: "t_pos",
    header: "T_Pos",
    cell: (e) => centerAlign(e.t_pos),
    label: (sortState) => {
      return sort(sortState, "t_pos", "T_Pos");
    },
    visible: true,
    minWidth: "100px",
  },
];

const centerAlign = (item: string) => {
  return (
    <div
      style={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {item ? item : "__"}
    </div>
  );
};

// define default sorting column
export const defaultSortingColumn = "frequency";

// define sortable columns
export const sortableColumns = [
  { id: "frequency", field: "frequency" },
  { id: "limit", field: "limit" },
  { id: "level", field: "level" },
  { id: "over", field: "over" },
  { id: "factor", field: "factor" },
  { id: "a_pos", field: "a_pos" },
  { id: "t_pos", field: "t_pos" },
];

// define visiable columns
export const visibleColumns = [
  {
    label: "Properties",
    options: [
      { id: "frequency", label: "Frequency", visible: true },
      { id: "limit", label: "Limit", visible: true },
      { id: "level", label: "Level", visible: true },
      { id: "over", label: "Over", visible: true },
      { id: "factor", label: "Factor", visible: true },
      { id: "a_pos", label: "A_Pos", visible: true },
      { id: "t_pos", label: "T_Pos", visible: true },
    ],
  },
];

// define filtering options
export const filteringOptions = [
  {
    propertyKey: "frequency",
    propertyLabel: "Frequency",
    groupValuesLabel: "Frequency",
    values: [],
  },
  {
    propertyKey: "limit",
    propertyLabel: "Limit",
    groupValuesLabel: "Limit",
    values: [],
  },
  {
    propertyKey: "level",
    propertyLabel: "Level",
    groupValuesLabel: "Level",
    values: [],
  },
  {
    propertyKey: "over",
    propertyLabel: "Over",
    groupValuesLabel: "Over",
    values: [],
  },
  {
    propertyKey: "factor",
    propertyLabel: "Factor",
    groupValuesLabel: "Factor",
    values: [],
  },
  {
    propertyKey: "a_pos",
    propertyLabel: "A_Pos",
    groupValuesLabel: "A_Pos",
    values: [],
  },
  {
    propertyKey: "t_pos",
    propertyLabel: "T_Pos",
    groupValuesLabel: "T_Pos",
    values: [],
  },
];

export const features = ["propertyFiltering", "pagination", "sorting"];

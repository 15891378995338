import React, { Component } from "react";

const defaultStyle = {
  fontSize: "30px",
  color: "grey",
};

function WiseSpinner({ style }) {
  return (
    <div style={{ ...defaultStyle, ...style }}>
      <i
        className="fas fa-spinner fa-spin"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      />
    </div>
  );
}

export default React.memo(WiseSpinner);

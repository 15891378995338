import React from "react";
import { TEST_STATUS } from "../../../../constants";
import { TEST_STATUS_COLOR } from '../../../../commons/color-config';

const TestStatusLegend = (props) => {
  return (
    <div className="d-flex align-items-center">
      {Object.keys(TEST_STATUS).map((key, index) => {
        const testStatus = TEST_STATUS[key];
        if (
          testStatus === TEST_STATUS.PASSED ||
          testStatus === TEST_STATUS.UNTESTED ||
          testStatus === TEST_STATUS.FAILED
        ) {
          return (
            <div key={index} className="d-flex align-items-center mr-2">
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "4px",
                  backgroundColor: TEST_STATUS_COLOR[testStatus],
                }}
              ></div>
              <div>{testStatus} &nbsp;  </div>
            </div>
          );
        }
      })}
    </div>
  );
};

export default TestStatusLegend;

import React from "react";
import "./wiseLoadingFail.css";

const defaultStyle = {
  fontSize: "30px",
  color: "grey",
};

function WiseLoadingFail({ style }) {
  return (
    <div style={{ ...defaultStyle, ...style }}>
      <i
        className="far fa-times-circle wise-loading-fail-icon"
        style={{ marginRight: "auto", marginLeft: "auto" }}
      />
    </div>
  );
}

export default React.memo(WiseLoadingFail);

import categoryType from "../types/category-type";
import { config } from "../../config/config";
import { METRIC_INFO } from "../../constants";
import endpoints from "../../endpoints/endpoints";
import { createCategoryRequest } from "../../model/http-json";

export const createCategory = (values: createCategoryRequest): any => ({
  type: categoryType.CREATE_CATEGORY,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.categoryEndpoint(),
        method: "POST",
        json: values,
      },
      commit: {
        type: categoryType.CREATE_CATEGORY_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CATEGORY,
        },
      },
      rollback: {
        type: categoryType.CREATE_CATEGORY_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.CATEGORY,
        },
      },
    },
  },
});

export const resetCategory = (): any => ({
  type: categoryType.RESET_CATEGORY,
});

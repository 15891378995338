import base_config from "./base_config";

const beta_config = {
  ...base_config,
  STAGE: "beta",

  // Amplify config for cognito
  AMPLIFY_CONFIG: {
    aws_project_region: base_config.REGION,
    aws_cognito_region: base_config.REGION,
    aws_user_pools_id: "us-west-2_ttcebY1zR",
    aws_user_pools_web_client_id: "37e2vc1v1s80nmbgjslk8dd4vi",
    oauth: {
      domain: "wise-general-cog-beta.auth.us-west-2.amazoncognito.com",
      scope: base_config.TOKEN_SCOPES_ARRAY,
      redirectSignIn: base_config.CALL_BACK_URL,
      redirectSignOut: base_config.CALL_BACK_URL,
      responseType: base_config.OAUTH_RESPONSE_TYPE,
    },
  },

  // Base url (update this with correct url)
  BASE_URL: "https://gitiry4wkl.execute-api.us-west-2.amazonaws.com/beta/v1",
  BASE_AUTHZ_URL: "https://gitiry4wkl.execute-api.us-west-2.amazonaws.com/beta",

  //Jira Url
  JIRA_URL: "https://s42qg6dmv7.execute-api.us-west-2.amazonaws.com/beta",

  // Upload service base url
  UPLOAD_BASE_URL:
    "https://b6u1d4qumj.execute-api.us-west-2.amazonaws.com/beta",
  WEBSITE_BASE_URL: "https://beta.lens-compliance.lab126.amazon.dev",
  ANALYTICS_DASHBOARD:
    "https://search-wise-es-fqemetrics-cgj4xsdhpq2rgvcntsyxnxoyue.us-east-2.es.amazonaws.com/_plugin/kibana/app/kibana#/dashboard/eb629420-fe7f-11eb-b120-dd9225754660?embed=true&_g=(refreshInterval%3A(display%3AOff%2Cpause%3A!f%2Cvalue%3A0)%2Ctime%3A(from%3Anow-7d%2Cmode%3Aquick%2Cto%3Anow))",
  // Configuration of Cloudwatch RUM
  APPLICATION_ID: "17741ad8-14ea-400e-a1f8-903020c8cd71",
  APPLICATION_VERSION: "1.0.0",
  APPLICATION_REGION: "us-west-2",
  rum_config: {
    sessionSampleRate: 1,
    guestRoleArn:
      "arn:aws:iam::841052704235:role/RUM-Monitor-us-west-2-841052704235-0531441158071-Unauth",
    identityPoolId: "us-west-2:87ab2d19-b573-4710-81e6-9cb6c7a03afd",
    endpoint: "https://dataplane.rum.us-west-2.amazonaws.com",
    telemetries: [
      "performance",
      "errors",
      ["http", { recordAllRequests: true }],
    ],
    allowCookies: true,
    enableXRay: false,
    disableAutoPageView: true,
  },
};

export default beta_config;

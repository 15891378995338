import React, { useState } from "react";
import {
  Button,
  FormField,
  Select,
  Input,
  TextContent,
} from "@amzn/awsui-components-react-v3";
import { reduxForm, FieldArray, Field } from "redux-form";
import { Tabs, Tab } from "react-bootstrap";
import AddSectionForm from "./addSectionForm";
import { sectionList } from "./testplan-config";
import { validate, sectionErrorsCopy } from "./validation";
import testPlanTable from "./testplan-table";
import { IoIosClose, IoMdCopy } from "react-icons/io";
import "./bootstrap-table.css";
import "./testPlan.css";
import ImportExcel from "./import-excel";
import ImportTestplan from "./import-testplan";
import { LAB } from "../../../constants";

// section
const sectionTabs = ({ fields, meta: { error } }) => {
  let closedLastTab = false;
  let addNewTabIndex = 0;
  let errorbox: boolean = false;
  sectionErrorsCopy.forEach((section) => {
    if (section.testCases.length || section.testCases._error) errorbox = true;
  });
  const [selectedSheetIndex, setSelectedSheetIndex] = useState(0);

  return (
    <div className="section-container">
      <AddSectionForm
        onSubmit={AddSectionForm}
        fields={fields}
        setnewTabIndex={setSelectedSheetIndex}
        options={sectionList}
      ></AddSectionForm>

      {error && (
        <span className="test-plan-form-error test-plan-meta-heading">
          &emsp; * {error}
        </span>
      )}
      {errorbox && (
        <span className="test-plan-form-error test-plan-meta-heading">
          &emsp; * Sheet containing errors are highlighted in red. Please fix
          them before submitting.
        </span>
      )}
      <Tabs
        id="controlled-tab"
        className="section-tabs"
        activeKey={selectedSheetIndex}
        defaultActiveKey={fields.length - 1}
        onSelect={(k) => {
          if (closedLastTab) {
            setSelectedSheetIndex(0);
            closedLastTab = false;
          } else if (addNewTabIndex !== 0) {
            setSelectedSheetIndex(addNewTabIndex);
            addNewTabIndex = 0;
          } else {
            setSelectedSheetIndex(Number(k));
          }
        }}
      >
        {fields.map((section, index) => {
          let headingClassName = "table-heading";
          if (
            sectionErrorsCopy[index] &&
            (sectionErrorsCopy[index].testCases.length ||
              sectionErrorsCopy[index].testCases._error)
          )
            headingClassName = "table-heading-red";
          return (
            <Tab
              key={index}
              eventKey={index}
              title={
                <div>
                  <label className={headingClassName}>
                    {fields.get(index).name}
                  </label>
                  &emsp;
                  <Button
                    className="wise-btn normal icon-button"
                    onClick={(e) => {
                      e.preventDefault();
                      let text = prompt("Enter the new sheet name.");
                      if (text === null || text.trim() === "") {
                        alert("Operation cancelled");
                      } else {
                        let sectionExists = 0;
                        for (let i = 0; i < fields.length; i++) {
                          if (fields.get(i).name === text) sectionExists = 1;
                        }
                        if (sectionExists === 1) {
                          alert("Sheet name already exists.");
                        } else {
                          let testCasesData = JSON.parse(
                            JSON.stringify(fields.get(index).testCases)
                          );
                          testCasesData.map((item) => (item.section = text));
                          addNewTabIndex = index + 1;
                          fields.splice(index + 1, 0, {
                            name: text,
                            testCases: testCasesData,
                          });
                        }
                      }
                    }}
                  >
                    <IoMdCopy size={15} />
                  </Button>
                  <Button
                    className="wise-btn normal icon-button"
                    onClick={(e) => {
                      e.preventDefault();
                      if (fields && index === fields.length - 1) {
                        closedLastTab = true;
                      }
                      fields.remove(index);
                    }}
                  >
                    <IoIosClose size={25} />
                  </Button>
                </div>
              }
            >
              <FieldArray
                name={`${section}.testCases`}
                component={testPlanTable}
                sectionPrefix={fields.get(index).name}
              />
            </Tab>
          );
        })}
      </Tabs>
    </div>
  );
};

var TestPlanSection = (props) => {
  const [excelVisible, setExcelVisible] = useState(false);
  const [testplanVisible, setTestPlanVisible] = useState(false);

  const [lab, setLabvalue] = useState("SPORTON");
  const _onCreate = (e) => {
    e.preventDefault();
    setExcelVisible(true);
  };

  const _importTestPlan = (e) => {
    e.preventDefault();
    setTestPlanVisible(true);
  };

  const labDropdown = ({ input: { value, onChange } }) => {
    return (
      <Select
        options={[
          {
            label: "Lab",
            options: [
              { label: "TUV", value: "TUV" },
              { label: "SPORTON", value: "SPORTON" },
              { label: "UL", value: "UL" },
            ],
            disabled: props.disableAssignee,
          },
        ]}
        selectedOption={{ label: !value ? "Select Lab" : value, value: value }}
        onChange={(e) => {
          setLabvalue(
            e?.detail?.selectedOption?.value
              ? e?.detail?.selectedOption?.value
              : value
          );
          onChange(e.detail.selectedOption.value);
        }}
        placeholder="Select Lab"
      />
    );
  };

  const assigneeField = ({ input: { value, onChange } }) => {
    return (
      <Select
        options={[
          {
            label: "Assignee",
            options: props.jiraUsers[LAB[lab]]
              ? props.jiraUsers[LAB[lab]].map((val) => {
                  return {
                    label: val.name + " (" + val.displayName + ")",
                    value: val.name,
                  };
                })
              : [],
          },
        ]}
        selectedOption={{
          label: !value ? "Select Assignee" : value,
          value: value,
        }}
        onChange={(e) => {
          onChange(e.detail.selectedOption.value);
        }}
        placeholder="Select Assignee"
      />
    );
  };

  return (
    <div>
      <form
        className="awsui-util-container testplan-section"
        onKeyPress={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <div className="wise-main-container test-plan-meta-info-block">
          <div className="disp-flex">
            <TextContent>
              <h4> EMI Test Plan</h4>
            </TextContent>
            <div className="disp-flex">
              <Button variant="normal" onClick={(e) => _importTestPlan(e)}>
                Import Test Plan
              </Button>
              <Button variant="normal" onClick={(e) => _onCreate(e)}>
                Import Excel
              </Button>
              <Button
                onClick={props.handleSubmit(props.saveAsDraft)}
                variant="normal"
              >
                Save as Draft
              </Button>
              <Button
                onClick={props.handleSubmit(props.onSubmit)}
                variant="primary"
                disabled={props.invalid}
              >
                Submit
              </Button>
            </div>
          </div>
          <div className="testplan-fields">
            <FormField>
              <div className="test-plan-btn-submit">
                <Field name="lab" component={labDropdown} />
              </div>
            </FormField>
            <FormField>
              <div className="test-plan-btn-submit">
                <Field name="assignee" component={assigneeField} />
              </div>
            </FormField>
          </div>
          <br />
        </div>
        <FieldArray name="sections" component={sectionTabs} />
      </form>
      <ImportExcel
        visible={excelVisible}
        setVisible={setExcelVisible}
        setExcelTestCases={props.setExcelTestCases}
      />
      <ImportTestplan
        visible={testplanVisible}
        setVisible={setTestPlanVisible}
      />
    </div>
  );
};

export default reduxForm({
  form: "testPlanSection", // a unique identifier for this form
  validate,
})(TestPlanSection);

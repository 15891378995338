import metadataType from "../types/metadata-type";
import constants from "../../constants";
import { MetadataResponse } from "../../model/http-json";
import { capitalizeFirstLetter } from "../../utils/general-utils";

interface state {
  metadata: MetadataResponse,
  sidebarData: Array<any>,
  filter: {},
  loadingStatus: number
}

interface item {
  text: string,
  id: number,
  children: Array<any>
}

const initialState: state = {
  metadata:[] ,
  sidebarData: [],
  filter: {},
  loadingStatus: constants.LOADING_LOAD,
};

const metadataReducer = (state = initialState, action) => {
  switch (action.type) {
    case metadataType.GET_METADATA:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case metadataType.GET_METADATA_COMMIT:
      return {
        ...state,
        loadingStatus: constants.LOADING_SUCCESS,
        metadata: Array.isArray(action.payload.data)
          ? (action.payload)
          : [],
        sidebarData: Array.isArray(action.payload.data)
          ? generateSidebardata(action.payload.data)
          : [],
        filter: Array.isArray(action.payload.data)
        ? generateFilterdata(action.payload.data)
        : {},
      };
    case metadataType.GET_METADATA_ROLLBACK:
      return {
        ...state,
        loadingStatus: constants.LOADING_FAIL,
      };
    default:
      return state;
  }
};

export default metadataReducer;



const generateSidebardata = (data: Array<{ [key: string]: any }>) => {
  const sidebarData: Array<{ [key: string]: any }> = [];
  data.forEach((category) => {
    const category_item : item  = {
      text: capitalizeFirstLetter(category.name),
      id: category.id,
      children: [],
    };
    if (category.children) {
      category.children.forEach((project) => {
        const project_item: item = {
          text: capitalizeFirstLetter(project.name),
          id: project.id,
          children: [],
        };

        if (project.children) {
          project.children.forEach((build) => {
            const build_item = {
              text: build.name.toUpperCase() + "_" + build.version,
              href: `/categories/${category.id}/projects/${project.id}/builds/${build.id}/overview`,
              id: build.id,
              children: [] ,
            };
            project_item.children.push(build_item);
          });
        }
        category_item.children.push(project_item);
      });
    }
    sidebarData.push(category_item);
  });
  return sidebarData;
};

const generateFilterdata = (data: Array<{ [key: string]: any }>) => {
  const filter : {} = {};
  data.forEach((category) => {
    filter[category.id] = {
      name: category.name,
      children: {}
    }
    if (category.children) {
      category.children.forEach((project) => {
        filter[category.id].children[project.id] = {
          name: project.name,
          streetDate: project.street_date,
          children: {}
        }

        if (project.children) {
          project.children.forEach((build) => {
            if( !(build.name in filter[category.id].children[project.id].children)){
              filter[category.id].children[project.id].children[build.name.toUpperCase()] = {
                children: {}
              }
            }
            filter[category.id].children[project.id].children[
              build.name.toUpperCase()
            ].children[build.version] = {
              id: build.id,
              startDate: build.start_date,
              endDate: build.end_date,
              status: build.status,
              children: {},
            };
            
            if (build.children) {
              build.children.forEach(rfConfig => {
                filter[category.id].children[project.id].children[
                  build.name.toUpperCase()
                ].children[build.version].children[rfConfig.id] = {
                  name: rfConfig.name,
                  is_spot_check: rfConfig.is_spot_check
                };
              });
            }
          });
        }
      });
    }
  });
  return filter;
}
import { all } from "redux-saga/effects";
import { watchHttpAction } from "./http-request-saga";
import { watchCommitRollbackActions } from "./metric-saga";

function* rootSaga() {
  /*The saga is waiting for a action called LOAD_DASHBOARD to be activated */
  // yield all([watchHttpAction(), watchCommitRollbackActions()]);
  yield all([watchHttpAction()]);
}

export default rootSaga;
import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import CategoryForm from "./category-form";
import "./../create-form.css";

interface StateProps {
  categoryReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps &
  RouteComponentProps<any>;

class CreateCategory extends Component<Props> {
  render() {
    return (
      <div className="create-form">
        <CategoryForm history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryReducer: state.categoryReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(CreateCategory));
import React, { useState } from "react";
// sub components
import {
  Table,
  Header,
  Pagination,
  PropertyFilter,
} from "@amzn/awsui-components-react-v3/polaris";
import { useCollection } from "@amzn/awsui-collection-hooks";
//config, constants
import {
  TableEmptyState,
  TableNoMatchState,
  paginationLabels,
  PROPERTY_FILTERING_I18N_CONSTANTS,
} from "../../overview/common";
import {
  TESTCASE_TABLE_COLUMN_DEFINITIONS,
  TESTCASE_DEFAULT_PREFERENCES,
  TESTCASE_DATA_FILTERING_PROPERTIES,
  Preferences,
} from "./table-config";
import constants from "../../../constants";

const getFilterCounterText = (count) =>
  `${count} ${count === 1 ? "match" : "matches"}`;

type Props = {
  tabledata: any;
  loading: any;
};

const TableTestcase = ({ tabledata, loading }: Props) => {
  // default table preferences
  const [preferences, setPreferences] = useState(
    TESTCASE_DEFAULT_PREFERENCES()
  );

  const currentTableData = [
    {
      section: tabledata.section,
      name: tabledata.name,
      upload_time: tabledata.upload_time,
      status: tabledata.status,
      comment: tabledata.comment,
    },
  ];

  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    propertyFilterProps,
    paginationProps,
  } = useCollection(currentTableData, {
    propertyFiltering: {
      filteringProperties: TESTCASE_DATA_FILTERING_PROPERTIES,
      empty: TableEmptyState("Test Case"),
      noMatch: (
        <TableNoMatchState
          onClearFilter={() =>
            actions.setPropertyFiltering({ tokens: [], operation: "and" })
          }
        />
      ),
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {},
    selection: {},
  });
  return (
    <Table
      {...collectionProps}
      columnDefinitions={TESTCASE_TABLE_COLUMN_DEFINITIONS}
      visibleColumns={preferences.visibleContent}
      items={items}
      loadingText="Loading resources"
      loading={
        loading != constants.LOADING_SUCCESS &&
        loading != constants.LOADING_FAIL
      }
      resizableColumns={true}
      wrapLines={preferences.wrapLines}
      // commenting this out as filters are not needed on this table
      // filter={
      //   <PropertyFilter
      //     i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS}
      //     {...propertyFilterProps}
      //     countText={getFilterCounterText(filteredItemsCount)}
      //   />
      // }
      // pagination={
      //   <Pagination {...paginationProps} ariaLabels={paginationLabels} />
      // }
      preferences={
        <Preferences
          preferences={preferences}
          setPreferences={setPreferences}
          disabled={false}
        />
      }
    />
  );
};

export default React.memo(TableTestcase);

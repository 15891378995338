import projectType from "../types/project-type";
import { config } from "../../config/config";
import endpoints from "../../endpoints/endpoints";
import { METRIC_INFO } from "../../constants";
import { createProjectRequest } from "../../model/http-json";

export const createProject = (values: createProjectRequest): any => ({
  type: projectType.CREATE_PROJECT,
  http: {
    cognito: {
      effect: {
        url: config.BASE_URL + endpoints.projectEndpoint(values.category_id),
        method: "POST",
        json: values,
      },
      commit: {
        type: projectType.CREATE_PROJECT_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.PROJECT,
        },
      },
      rollback: {
        type: projectType.CREATE_PROJECT_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.PROJECT,
        },
      },
    },
  },
});

export const resetProject = (): any => ({
  type: projectType.RESET_PROJECT,
});

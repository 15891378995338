import buildEmiTypes from "../types/build-emi-type";
import constants from "../../constants";

const initialState = {
  loadingStatus: constants.LOADING_DEFAULT,
  testCases: [],
  message: "",
  s3_link: "",
};

function getMessage(data) {
  return data?.message;
}

function getTestPlanData(data) {
  return data?.data;
}

function buildReducerEMI(state = initialState, action) {
  let messageData;
  switch (action.type) {
    case buildEmiTypes.BUILD_EMI:
      return {
        ...state,
        loadingStatus: constants.LOADING_LOAD,
      };
    case buildEmiTypes.BUILD_EMI_COMMIT:
      let testCasesData = getTestPlanData(action.payload);
      return {
        ...state,
        testCases: testCasesData.test_cases,
        loadingStatus: constants.LOADING_SUCCESS,
        s3_link: testCasesData.s3_link,
      };
    case buildEmiTypes.BUILD_EMI_ROLLBACK:
      messageData = getMessage(action.payload);
      return {
        ...state,
        message: messageData,
        loadingStatus: constants.LOADING_FAIL,
      };

    default:
      return state;
  }
}

export default buildReducerEMI;

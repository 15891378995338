import { config } from "../../config/config";
import { METRIC_INFO } from "../../constants";
import endpoints from "../../endpoints/endpoints";
import authzType from "../types/authz-type";

// demo authz call
export const authZCall = (username, bindleType) => ({
  type: authzType.AUTHZ_CALL,
  http: {
    cognito: {
      effect: {
        url: config.BASE_AUTHZ_URL + endpoints.authzEndpoint(),
        method: "POST",
        json: { username: username, bindle_type: bindleType },
      },
      commit: {
        type: authzType.AUTHZ_CALL_COMMIT,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.AUTHORIZATION,
        },
      },
      rollback: {
        type: authzType.AUTHZ_CALL_ROLLBACK,
        log: {
          initiative: METRIC_INFO.INITIATIVE_RF,
          feature: METRIC_INFO.AUTHORIZATION,
        },
      },
    },
  },
});

// AJAX request related
import { config } from "../../../config/config.js";
import endpoint from "../../../endpoints/endpoints";
import {
  UploadFileCheckRequest,
  UploadFileCheckResponse,
  UploadPreSignedURLRequest,
  UploadPreSignedURLResponse,
} from "../../../model/http-json";
import { postData, Response } from "../../../utils/cognito-fetch";
// constatns
import { FileStatus } from "../../../model/upload";
import constants, { METRIC_INFO } from "../../../constants";
import { username } from "../../../cognito-auth/session";
import { getLogData } from "../../../utils/general-utils";

export const getMetricInfo = (initiative, constant) => {
  return initiative == constants.INITIATIVE_EMI
    ? constant.INITIATIVE_EMI
    : constant.INITIATIVE_RF;
};

export async function fileNameCheckCall(
  file: File,
  initiative: string
): Promise<FileStatus> {
  /*
    Ajax call to check 1 single file name

*/
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
    s3_link: "",
  };

  try {
    const filename = file.name;
    const url: string = config.BASE_URL + endpoint.uploadFileNameCheck();
    const data: UploadFileCheckRequest = {
      initiative: initiative,
      file_name: filename,
    };
    const response: Response = await postData(
      url,
      data,
      getLogData(
        METRIC_INFO.MANUAL_UPLOAD,
        getMetricInfo(initiative, METRIC_INFO)
      )
    );

    // http reponse ok
    if (response.ok) {
      const fileCheckResponse: UploadFileCheckResponse = response.json;
      if (
        fileCheckResponse.status &&
        fileCheckResponse.status === constants.UPLOAD_CHECK_FILE_SUCCESS
      ) {
        rst.status = constants.LOADING_SUCCESS;
      } else {
        rst.status = constants.LOADING_FAIL;
        rst.message = `${
          fileCheckResponse.error_message
            ? fileCheckResponse.error_message
            : "Unknown error."
        } ${
          fileCheckResponse.error_description
            ? fileCheckResponse.error_description
            : ""
        }`;
      }
    } else {
      //http resonse on ok
      rst.status = constants.LOADING_SUCCESS;
      rst.message = response.status_text;
    }
  } catch (error) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

export async function fileUploadS3Call(
  file: File,
  initiative: string
): Promise<FileStatus> {
  /*
      Ajax call to upload 1 file to s3
      
      Two steps:
       1. send call to upload service to get pre-signed url
       2. put request to s3
  */
  // init return
  const rst: FileStatus = {
    status: constants.LOADING_FAIL,
    message: "",
    s3_link: "",
  };

  try {
    // step 1: get pre-signed url
    const filename = file.name;
    const url: string = config.UPLOAD_BASE_URL + endpoint.uploadPreSignedURL();
    const data: UploadPreSignedURLRequest = {
      initiative: initiative,
      file_name: filename,
      user_name: username,
    };
    // the response1 is our own defined type
    const response1: Response = await postData(
      url,
      data,
      getLogData(
        METRIC_INFO.MANUAL_UPLOAD,
        getMetricInfo(initiative, METRIC_INFO)
      )
    );
    if (!response1.ok) {
      throw response1.status_text;
    }

    const preSignedURLResponse: UploadPreSignedURLResponse = response1.json;
    const preSignedUrl = preSignedURLResponse.url;
    const metaDataDict = preSignedURLResponse.meta_data_result;

    // step2: put request to s3
    // the response2 is fetch native response type
    const response2 = await fetch(preSignedUrl, {
      method: "PUT",
      body: file,
      headers: {
        "content-type": "",
        ...metaDataDict,
      },
    });

    if (!response2.ok) {
      throw response2.statusText;
    }

    rst.status = constants.LOADING_SUCCESS;
    rst.message = "";
    rst.s3_link = preSignedUrl.split("?")[0];
  } catch (error) {
    rst.status = constants.LOADING_FAIL;
    rst.message = error.message;
  } finally {
    return rst;
  }
}

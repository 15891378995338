const visualizationEmiType = {
  GET_VISUALIZATION_DATA: "GET_VISUALIZATION_DATA",
  GET_VISUALIZATION_DATA_COMMIT: "GET_VISUALIZATION_DATA_COMMIT",
  GET_VISUALIZATION_DATA_ROLLBACK: "GET_VISUALIZATION_DATA_ROLLBACK",
  GET_VISUALIZATION_SPECIFIC_TCS_DATA: "GET_VISUALIZATION_SPECIFIC_TCS_DATA",
  GET_VISUALIZATION_SPECIFIC_TCS_DATA_COMMIT:
    "GET_VISUALIZATION_SPECIFIC_TCS_DATA_COMMIT",
  GET_VISUALIZATION_SPECIFIC_TCS_ROLLBACK:
    "GET_VISUALIZATION_SPECIFIC_TCS_ROLLBACK",
};
export default visualizationEmiType;
